import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/services/data.service";

export interface Element {
  date: Date;
  dataset: string;
  model: string;
}

@Component({
  selector: "app-delete-model-popup",
  templateUrl: "./delete-model-popup.component.html",
  styleUrls: ["./delete-model-popup.component.scss"],
})
export class DeleteModelPopupComponent implements OnInit {
  mid: string;
  modelName: string;
  modelFits: Element[];
  FITS_DATA: Element[] = [];
  modelFitsTableData: MatTableDataSource<Element>;
  fitsColumns = ["date", "dataset", "model"];
  noFits: boolean = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteModelPopupComponent>,
    private dataService: DataService
  ) {
    const { mid } = data;
    this.modelName = data.name;
    this.mid = mid;
  }

  async ngOnInit(): Promise<void> {
    // fetch all fits using the model and populate the table
    this.FITS_DATA = await this.dataService.getFitsWithModel(this.mid);
    if (this.FITS_DATA.length > 0) {
      // if model used for fits, display them
      this.modelFitsTableData = new MatTableDataSource(this.FITS_DATA);
    } else {
      // display "no fits" message if model isn't used for any fits
      this.noFits = true;
    }
  }

  async onDelete() {
    try {
      await this.dataService.deleteModel(this.mid);
      this.dialogRef.close(true);
    } catch (err) {
      console.log({ err });
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }
}
