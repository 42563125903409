<div class="popup">
  <h2>Are you sure you want this model deleted?</h2>
  <mat-dialog-content> Model: {{ modelName }} </mat-dialog-content>
  <mat-dialog-content class="popup-content">
    <ng-container *ngIf="!modelFitsTableData && !noFits"
      ><mat-spinner [style.margin]="'auto'"></mat-spinner
    ></ng-container>
    <ng-container *ngIf="modelFitsTableData">
      <h3>The following fits will be deleted:</h3>
      <table
        mat-table
        [dataSource]="modelFitsTableData"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Date</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
          >
            {{ element.date | date : "dd MMM hh:mm a" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="dataset">
          <th mat-header-cell *matHeaderCellDef>Dataset</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
          >
            {{ element.dataset }}
          </td>
        </ng-container>
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef>Model</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
          >
            {{ element.model }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="fitsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: fitsColumns"></tr>
      </table>
    </ng-container>
    <ng-container *ngIf="noFits">
      <h3 [style.text-align]="'center'">No fits were made with this model</h3>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="button-container">
    <button
      mat-raised-button
      color="accent"
      (click)="onDelete()"
      style="margin-right: 10px"
    >
      Delete
    </button>
    <button
      mat-stroked-button
      color="warn"
      (click)="onCancel()"
      style="margin-left: 10px"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</div>
