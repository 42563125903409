<!-- Nav bar is 64px tall -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/home" (click)="drawer.toggle()">Home</a>
      <!-- if logged in, display My Account button -->
      <a
        mat-list-item
        routerLink="/account"
        *ngIf="loggedIn && !isGuest"
        (click)="drawer.toggle()"
        >Account Info</a
      >
      <!-- if not logged in, display Login/Register buttons -->
      <a
        mat-list-item
        routerLink="/login"
        *ngIf="!loggedIn"
        (click)="drawer.toggle()"
        >Login</a
      >
      <a
        mat-list-item
        routerLink="/register"
        *ngIf="!loggedIn"
        (click)="drawer.toggle()"
        >Register</a
      >
      <a mat-list-item routerLink="/datasets" (click)="drawer.toggle()"
        >My Datasets</a
      >
      <a mat-list-item routerLink="/models" (click)="drawer.toggle()"
        >My Models</a
      >
      <a mat-list-item routerLink="/fits" (click)="drawer.toggle()">My Fits</a>
      <a mat-list-item routerLink="/about" (click)="drawer.toggle()">About</a>
      <a mat-list-item (click)="logOut(); drawer.toggle()" *ngIf="loggedIn"
        >Log Out&emsp;<mat-icon>logout</mat-icon></a
      >
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="site-header" color="">
      <button
        class="drawerbutton"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <a id="sivvu-nav-text" routerLink="/home"
        ><img src="../assets/logos/SIVVU.png" alt="SIVVU" class="logo"
      /></a>

      <div id="nav-button-container" class="myNav">
        <a
          mat-button
          class="active navbutton"
          routerLink="/home"
          class="btn home"
          >Home</a
        >

        <button
          mat-button
          class="btn navbutton"
          [matMenuTriggerFor]="account"
          class="btn account"
        >
          Account
        </button>

        <a mat-button class="navbutton" routerLink="/about" class="btn about"
          >About</a
        >

        <button
          mat-button
          class="btnnavbutton"
          [matMenuTriggerFor]="mywork"
          class="btn my-work analyze"
        >
          My Work
        </button>
      </div>
    </mat-toolbar>

    <mat-menu #mywork="matMenu">
      <a mat-menu-item routerLink="/datasets" class="hov-but">My Datasets</a>
      <a mat-menu-item routerLink="/models" class="hov-but">My Models</a>
      <a mat-menu-item routerLink="/fits" class="hov-but">My Fits</a>
    </mat-menu>

    <!-- Menu if they're not logged in -->
    <mat-menu #account="matMenu">
      <a mat-menu-item routerLink="/login" class="hov-but" *ngIf="!loggedIn"
        >Login</a
      >
      <a mat-menu-item routerLink="/register" class="hov-but" *ngIf="!loggedIn"
        >Register</a
      >
      <a
        mat-menu-item
        routerLink="/account"
        class="hov-but"
        *ngIf="loggedIn && !isGuest"
        >My Account</a
      >
      <a
        mat-menu-item
        routerLink="/login"
        (click)="logOut()"
        class="hov-but"
        *ngIf="loggedIn"
        >Log Out</a
      >
    </mat-menu>

    <!-- Add Content Here -->
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
