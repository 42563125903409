import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "src/app/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import * as firebase from "firebase/compat/app";
import {
  Dataset,
  Reagent,
  FirestoreMap,
  MinDataset,
} from "../../shared/interfaces/data.interface";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-data-form-page",
  templateUrl: "./data-form-page.component.html",
  styleUrls: ["./data-form-page.component.scss"],
})
export class DataFormPageComponent implements OnInit, OnDestroy, AfterViewInit {
  //Form Controls
  experimentDateControl = new FormControl(undefined, Validators.required);
  spectrometerControl = new FormControl("", Validators.required);
  nmrControl = new FormControl(false, Validators.required);
  locationControl = new FormControl("", Validators.required);
  pathlengthControl = new FormControl(1, Validators.min(0));
  temperatureControl = new FormControl(298, Validators.min(0));
  solventControl = new FormControl("Water", Validators.required);
  reagentValueControls: FormControl[] = [
    new FormControl("", Validators.required),
    new FormControl("", Validators.required),
  ];
  wavelengthValuesControl: FormControl = new FormControl(
    "",
    Validators.required
  );
  absorbanceValuesControl: FormControl = new FormControl(
    "",
    Validators.required
  );
  absorbanceAreasValuesControl: FormControl = new FormControl(
    "",
    Validators.required
  );
  dataMaskValuesControl: FormControl = new FormControl("");
  commentControl: FormControl = new FormControl("");

  //Misc.
  pastedText: string = "";

  //Popup booleans
  popup: boolean = false; //determines whether or not a popup is being shown
  giveName: boolean = false;
  uploading: boolean = false;
  uploaded: boolean = false;
  error: boolean = false; //popup for showing errors if there are any
  warn: boolean = false; //popup for showing warnings if there are any
  duplicate: boolean = false;
  confirmRemoveReagent: boolean = false;
  reset: boolean = false;
  leave_page_popup: boolean = false;

  warnings: string[] = [];

  loading_popup: boolean = false;

  //Form errors
  reagentDimensionMismatch: boolean = true;
  wavelengthDimensionMismatch: boolean = true;
  dataMaskDimensionMismatch: boolean = true;
  absorbanceDimensionMismatch: boolean = true; // new validation
  absorbanceDimensionsInconsistent: boolean = false;
  absorbanceAreaDimensionsMismatch: boolean = false;
  negativeWavelengths: boolean = false;
  negativeReagents: boolean = false;

  //Form Values
  experimentDate: Date | undefined = undefined;
  spectrometer: string = "";
  pathlength: number = 1;
  temperature: number = 298;
  solvent: string = "Water";
  reagents: Reagent[] = [
    { name: "", values: [] },
    { name: "", values: [] },
  ];
  wavelengths: number[] = [];

  // the dataset
  absorbanceValues: FirestoreMap[] = [];
  areaProvided: boolean = false;
  absorbanceAreaValues: FirestoreMap[] = [];

  dataMask: FirestoreMap[] = [];
  comment: string = "";
  name: string = "";
  highestBtoA: number = 0;

  unsubscribe: Function = () => {};

  // used for mat snack bar
  durationInSeconds = 1;

  //For if the user is editing a dataset
  datasetid: string = "";

  subscriptions: Subscription[] = [];
  miscSubscriptions: Subscription[] = [];

  savedLocation: string = "";
  savedSpectrometer: string = "";

  constructor(
    private database: AngularFirestore,
    private authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private successBar: MatSnackBar
  ) {
    this.titleService.setTitle("Data Form | SIVVU");

    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("data-form");
      this.router.navigate(["login"]);
    }
    let subscription = this.route.params.subscribe(
      (params: any) => (this.datasetid = params.id)
    );
    subscription.unsubscribe();

    // If editing an existing dataset, bring in the existing information
    if (this.datasetid) {
      console.log("new??");
      this.loading_popup = true;
      this.database.firestore
        .collection("datasetsmin")
        .doc(this.datasetid)
        .get()
        .then((doc) => {
          let data = doc.data() as MinDataset;
          if (data?.user === this.authService.uid()) {
            this.database.firestore
              .collection("datasets")
              .doc(this.datasetid)
              .get()
              .then((doc) => {
                let data = doc.data() as Dataset;

                if (data) {
                  console.log(data);
                  this.experimentDateControl.setValue(
                    data.experimentDate.toDate()
                  );
                  console.log(
                    `spectrometerControl value: ${data.spectrometer}`
                  );
                  this.spectrometerControl.setValue(data.spectrometer);
                  this.locationControl.setValue(data.location);
                  this.pathlengthControl.setValue(data.pathlength);
                  this.temperatureControl.setValue(data.temperature);
                  this.solventControl.setValue(data.solvent);
                  this.reagents = data.reagents;
                  // check wavelengths to see if area was provided separately
                  this.wavelengths =
                    data.wavelengths.length === Math.max(...data.wavelengths)
                      ? data.wavelengths
                      : data.wavelengths.slice(
                          0,
                          Math.max(...data.wavelengths)
                        );
                  // if area data exists, split the absorbance data
                  if (
                    data.absorbanceData.length > Math.max(...this.wavelengths)
                  ) {
                    this.areaProvided = true;
                    this.absorbanceValues = data.absorbanceData.slice(
                      0,
                      Math.max(...this.wavelengths)
                    );
                    this.absorbanceAreaValues = data.absorbanceData.slice(
                      Math.max(...this.wavelengths)
                    );
                  }
                  // otherwise use full absorbance data
                  else {
                    this.absorbanceValues = data.absorbanceData;
                  }
                  // set NMR status if user input is NMR data
                  if ("nmr" in data) {
                    this.nmrControl.setValue(data.nmr);
                  }

                  this.subscriptions = [];
                  this.reagentValueControls = [];
                  for (let i = 0; i < data.reagents.length; ++i) {
                    this.reagentValueControls.push(
                      new FormControl(
                        this.arrayToString(data.reagents[i].values),
                        Validators.required
                      )
                    );
                  }
                  for (let i = 0; i < this.reagentValueControls.length; ++i) {
                    this.subscriptions.push(
                      this.reagentValueControls[i].valueChanges.subscribe(
                        (vals: string) => {
                          this.reagents[i].values =
                            this.transformDataToArray(vals);
                        }
                      )
                    );
                  }
                  this.wavelengthValuesControl.setValue(
                    this.arrayToString(this.wavelengths)
                  );
                  this.absorbanceValuesControl.setValue(
                    this.arrayToString(this.mapToMatrix(this.absorbanceValues))
                  );

                  // add area data if provided
                  if (this.areaProvided) {
                    this.absorbanceAreasValuesControl.setValue(
                      this.arrayToString(
                        this.mapToMatrix(this.absorbanceAreaValues)
                      )
                    );
                  }

                  if (data.dataMask.length) {
                    this.dataMask = data.dataMask;
                    this.dataMaskValuesControl.setValue(
                      this.arrayToString(this.mapToMatrix(data.dataMask))
                    );
                  }
                  if (data.comment.length) {
                    this.comment = data.comment;
                    this.commentControl.setValue(this.comment);
                  }
                }
                this.loading_popup = false;
              });
          }
        });
    }
    // if submitting a new dataset
    else {
      this.database.firestore
        .collection("users")
        .doc(this.authService.uid())
        .get()
        .then((doc) => {
          if (doc && doc.data()) {
            let data = doc.data() as any;
            console.log({ data });
            if ("spectrometer" in data) {
              this.savedSpectrometer = data.spectrometer;
              // Don't pre-populate the Spectrometer Make & Model section
              this.spectrometerControl.setValue("");
              // get rid of default Indiana University
              this.locationControl.setValue("");
            }
          }
        });
    }
  }

  ngOnInit(): void {
    for (let i = 0; i < this.reagentValueControls.length; ++i) {
      this.subscriptions.push(
        this.reagentValueControls[i].valueChanges.subscribe((vals: string) => {
          this.reagents[i].values = this.transformDataToArray(vals);
        })
      );
    }
    this.miscSubscriptions.push(
      this.wavelengthValuesControl.valueChanges.subscribe((vals: string) => {
        this.wavelengths = this.transformDataToArray(vals);
      })
    );
    this.miscSubscriptions.push(
      this.absorbanceValuesControl.valueChanges.subscribe((vals: string) => {
        this.absorbanceValues = this.transformDataToMap(vals);
      })
    );
    // the tabular matrix changes accordingly to the peak area inputs
    this.miscSubscriptions.push(
      this.absorbanceAreasValuesControl.valueChanges.subscribe(
        (vals: string) => {
          this.absorbanceAreaValues = this.transformDataToMap(vals);
        }
      )
    );
    this.miscSubscriptions.push(
      this.dataMaskValuesControl.valueChanges.subscribe((vals: string) => {
        this.dataMask = this.transformDataToMap(vals);
      })
    );
  }

  ngAfterViewInit() {
    // window.addEventListener('beforeunload', this.beforeUnload, false);
  }

  // beforeUnload(event: any) {
  //   // this.popup = true;
  //   // this.leave_page_popup = true;
  //   event.preventDefault();
  //   event.returnValue = '';
  // }

  ngOnDestroy(): void {
    // make sure reagents fields haven't been touched
    // let tempBool = false;
    // for(let i = 0; i < this.reagents.length; i++) {
    //   if (this.reagents[i].name == "" && this.reagents[i].values.length == 0) {
    //     tempBool = true;
    //   } else {
    //     tempBool = false;
    //     break;
    //   }
    // }

    // if(
    //   this.experimentDateControl.value != null &&
    //   this.spectrometerControl.value != "" &&
    //   this.reagentValueControls[0].value != '' &&
    //   this.reagentValueControls[1].value != '' &&
    //   tempBool != true &&
    //   this.wavelengthValuesControl.value != "" &&
    //   this.absorbanceValuesControl.value != ""
    // ){
    //   console.log("1");

    // } else {
    //   window.removeEventListener('beforeunload', this.beforeUnload);
    //   console.log("DO NOTHING, LEAVE PAGE");
    // }

    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.miscSubscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    // window.removeAllListeners;
  }

  //Returns an error message for form validation
  getErrorMessage(input: string): string {
    if (
      (input === "spectrometer" &&
        this.spectrometerControl.hasError("required")) ||
      (input === "location" && this.locationControl.hasError("required")) ||
      (input === "solvent" && this.solventControl.hasError("required"))
    ) {
      return "You must enter a value";
    }
    return "";
  }

  //Adds a reagent to the reagents array
  addReagent(): void {
    this.reagents.push({ name: "", values: [] });
    this.reagentValueControls.push(new FormControl("", Validators.required));
    let length = this.reagentValueControls.length - 1;
    this.subscriptions.push(
      this.reagentValueControls[length].valueChanges.subscribe(
        (vals: string) => {
          this.reagents[length].values = this.transformDataToArray(vals);
        }
      )
    );
  }

  removeReagentConfirm(): void {
    if (this.reagents[this.reagents.length - 1].values.length === 0) {
      this.removeReagent();
    } else {
      this.popup = true;
      this.giveName = false;
      this.confirmRemoveReagent = true;
    }
  }

  //Removes a reagent from the reagents array if there is more than 2 in the array
  removeReagent(): void {
    this.closePopup();
    //Makes sure there is at least 2 reagents
    if (this.reagents.length !== 2) {
      this.reagents.pop();
      this.reagentValueControls.pop();
      this.subscriptions[this.subscriptions.length - 1].unsubscribe();
      this.subscriptions.pop();
    } else {
      // TODO: Add an error message
    }
  }

  //https://stackblitz.com/edit/angular-copy-paste-table-from-excel
  //Transforms pasted text to a map for upload to Firestore
  transformDataToMap(pastedText: string): FirestoreMap[] {
    // Create table dataSource
    let data: string[][] = [];
    // let pastedText = clipboardData.getData('text');
    pastedText = pastedText.split("\r").join("");
    //removes all numbers from string to find delimiter
    let filteredText = pastedText.replace(
      /\s*(-)?\d+(([.]+\d*)?([Ee]+[-+]?(\d*))?)?\s*/g,
      ""
    );
    //automatically detects which delimiter to use
    filteredText = filteredText.replace(/\s*/, "");
    let delim = filteredText.length ? filteredText[0] : /\s/;
    let rowdelim = "\n";

    for (let i = 1; i < filteredText.length; ++i) {
      if (
        filteredText[i] !== delim &&
        filteredText[i].replace(/\s*/, "").length
      ) {
        rowdelim = filteredText[i];
        break;
      }
    }

    let row_data = pastedText.split(rowdelim);
    let numData: FirestoreMap[] = [];

    //Splits the data from each row into separate arrays
    row_data.forEach((row) => {
      data.push(row.split(delim));
    });

    for (let i = 0; i < data.length; ++i) {
      numData.push({ values: [] });
      data[i].forEach((val) => {
        if (val.replace(/\s*/, "").length) {
          numData[i].values.push(+val); //converts val to a number
        }
      });
    }
    if (numData[numData.length - 1].values.length === 0) numData.pop();
    return numData;
  }

  //Transforms pasted spreadsheet data to a 1-dimensional array
  transformDataToArray(pastedText: string): number[] {
    //removes all numbers from string to find delimiter
    //\s* - any amount of whitespace
    //(-)?\d+(([.]+\d*)? - numbers with optional decimal places
    //([Ee]+[-+]?(\d*))?)? - scientific notation for large or small numbers
    let filteredText = pastedText.replace(
      /\s*(-)?\d+(([.]+\d*)?([Ee]+[-+]?(\d*))?)?\s*/g,
      ""
    );
    let delim =
      filteredText.replace(/\s/, "").length > 1 ? filteredText[0] : /\s/; //automatically detects which delimiter to use

    for (let i = 0; i < filteredText.length; ++i) {
      if (filteredText[i] !== delim) {
        pastedText = pastedText.replace(filteredText[i], "");
      }
    }

    //Transforms pasted text into an array, returns -1 if the input is just whitespace
    let numArray: number[] = pastedText.split(delim).map((x) => {
      return x.replace(/\s*/, "").length ? +x : -1;
    });
    numArray = numArray.filter((x) => x !== -1); //removes any -1s from the array
    return numArray;
  }

  //Converts an array into a Firestore Map
  arrayToFirestoreMap(values: number[][]): FirestoreMap[] {
    let mapValues: FirestoreMap[] = [];

    values.forEach((x) => {
      mapValues.push({ values: x });
    });

    return mapValues;
  }

  //Converts a FirestorMap back into a regular Matrix type.
  mapToMatrix(map: FirestoreMap[] | Reagent[]): number[][] {
    let matrix: number[][] = [];
    for (let i = 0; i < map.length; ++i) {
      matrix.push([]);
      for (let j = 0; j < map[i].values.length; ++j) {
        matrix[i].push(map[i].values[j]);
      }
    }
    return matrix;
  }

  arrayToString(array: number[] | number[][]) {
    let result = "";
    let isMatrix = Array.isArray(array[0]);
    if (!isMatrix) {
      for (let i = 0; i < array.length; ++i) {
        result += array[i] + "\t";
      }
      result += "\n";
    } else {
      for (let i = 0; i < array.length; ++i) {
        result += this.arrayToString(array[i] as number[]);
      }
    }
    return result;
  }

  //Closes the popup and resets all popup conditions
  closePopup(): void {
    this.popup = false;
    this.giveName = true;
    this.uploading = false;
    this.uploaded = false;
    this.error = false;
    this.name = "";
    this.confirmRemoveReagent = false;
  }

  //Function runs when the form submit button is pressed.
  submit(): void {
    //Assigns the booleans using the results of the function checks
    let [reagentDimensionsMatch, wavelengthDimensionsMatch] =
      this.compareReagentsAndWavelengthToAbsorbance();

    console.log("temp: ", this.temperatureControl.value);

    // console.log({dimensionsFlipped})

    // // check absorbance data matrix
    // this.absorbanceDimensionsInconsistent = false;

    // // absLength is the number of columns
    // let absLength: number = this.absorbanceValues[0].values.length;
    // const numRows = this.absorbanceValues.length;
    // for (let i = 0; i < numRows; ++i) {
    //   if (this.absorbanceValues[i].values.length != absLength) {
    //     this.absorbanceDimensionsInconsistent = true;

    //     // alert("Data Inconsistent: missing cell value");
    //     break;
    //   }
    // }

    // this JUST checks the absorbance data
    let absorbanceDimensionMatch = this.absorbanceDataDimensionsMatch();
    // this checks BOTH the mask and absorbance data dimensions
    let dataMaskDimensionsMatch = this.dataMaskAndAbsorbanceDimensionsMatch();
    // this checks the dimensions for the absorbance data and the peak areas
    let absorbanceAreaDimensionsMatch = this.absorbanceAreaDimensionsMatch();
    console.log({ absorbanceAreaDimensionsMatch });

    this.reagentDimensionMismatch = !reagentDimensionsMatch;
    this.wavelengthDimensionMismatch = !wavelengthDimensionsMatch;
    this.dataMaskDimensionMismatch = !dataMaskDimensionsMatch;
    this.absorbanceDimensionMismatch = !absorbanceDimensionMatch;
    this.absorbanceAreaDimensionsMismatch = !absorbanceAreaDimensionsMatch;
    console.log(this.absorbanceAreaDimensionsMismatch, "dd");

    this.negativeWavelengths = this.wavelengths.some((elem) => elem < 0);
    for (let i = 0; i < this.reagents.length; ++i) {
      this.negativeReagents = this.reagents[i].values.some((elem) => elem < 0);
      if (this.negativeReagents) break;
    }

    this.popup = true;

    //Checks all form validators to make sure they're all valid
    if (
      reagentDimensionsMatch &&
      wavelengthDimensionsMatch &&
      dataMaskDimensionsMatch &&
      absorbanceDimensionMatch &&
      absorbanceAreaDimensionsMatch &&
      !this.negativeWavelengths &&
      !this.negativeReagents
    ) {
      this.warnings = [];
      for (let i = 0; i < this.absorbanceValues.length; ++i) {
        if (this.absorbanceValues[i].values.some((elem) => elem < 0)) {
          this.warnings.push(
            "Your absorbance matrix contains negative numbers. SIVVU's optimization process can not replicate negative numbers in the dataset. This may therefore cause issues when fitting the data to a model. See FAQ's on the homepage for more details."
          );
          break;
        }
      }
      if (!this.warnings.length) {
        this.giveName = true;
        this.warn = false;
      } else {
        this.warn = true;
        this.giveName = false;
      }
    } else {
      this.giveName = false;
      this.error = true;
      this.warn = false;
    }
  }

  //Uploads experiment data to the database
  submitData(): void {
    this.giveName = false;

    //TODO: If absorbance dimensions are equal, ask user for confirmation of dimensions

    // //Assigns the booleans using the results of the function checks
    // let [reagentDimensionsMatch, wavelengthDimensionsMatch, dimensionsFlipped] =
    //   this.compareReagentsAndWavelengthToAbsorbance();

    let dimensionsFlipped = this.compareReagentsAndWavelengthToAbsorbance()[2];

    // let dataMaskDimensionsMatch = this.dataMaskAndAbsorbanceDimensionsMatch();

    //Checks if all the dimension match tests passed

    //If the dimensions of the absorbance data are flip, then flip the x and y of the data
    if (dimensionsFlipped) {
      this.flipDataDimensions();
    }
    this.uploadData();
  }

  //Compares the dimension of the reagent and wavelength arrays to the absorbance matrix
  //to see which pattern of dimensions matches (either reagents matches x or y)
  compareReagentsAndWavelengthToAbsorbance(): boolean[] {
    let reagentDimensionsMatch = true;
    let wavelengthDimensionsMatch = true;
    let dimensionsFlipped = false;

    //Checks to see if the reagents are in the x dimension
    for (const r of this.reagents) {
      if (r.values.length !== this.absorbanceValues[0].values.length) {
        reagentDimensionsMatch = false;
        break;
      }
    }

    //If reagents are in x dimension, make sure wavelengths match y dimension
    if (
      reagentDimensionsMatch &&
      this.wavelengths.length !== this.absorbanceValues.length
    ) {
      wavelengthDimensionsMatch = false;
    }
    //If reagents are not in x dimension, check if they're in the y dimension
    else if (!reagentDimensionsMatch) {
      reagentDimensionsMatch = true;
      dimensionsFlipped = true;
      for (const r of this.reagents) {
        if (r.values.length !== this.absorbanceValues.length) {
          reagentDimensionsMatch = false;
          break;
        }
      }
      //If reagents are in the y dimension, check if wavelengths are in the x dimension
      if (
        reagentDimensionsMatch &&
        this.wavelengths.length !== this.absorbanceValues[0].values.length
      ) {
        wavelengthDimensionsMatch = false;
      }
    }

    // if editing an existing dataset, do not change the dimensions
    if (this.datasetid) {
      dimensionsFlipped = false;
    }

    return [
      reagentDimensionsMatch,
      wavelengthDimensionsMatch,
      dimensionsFlipped,
    ];
  }

  //Compares the dimensions of the Data Mask and Absorbance Matrix to make sure they're equal
  dataMaskAndAbsorbanceDimensionsMatch(): boolean {
    //Checks if there's any values in the datamask
    if (
      this.dataMask.length &&
      //Checks if the y dimension of the absorbance value and data mask matrix match
      this.absorbanceValues.length === this.dataMask.length
    ) {
      for (let i = 0; i < this.absorbanceValues.length; ++i) {
        //Checks if the x dimension of the absorbance value and data mask matrix match
        if (
          this.absorbanceValues[i].values.length !==
          this.dataMask[i].values.length
        ) {
          return false;
        }
      }
    }
    //If there are values, but the dimensions don't match, return false
    else if (this.dataMask.length) {
      return false;
    }

    //If all conditions pass, return true
    return true;
  }

  // Checks the dimensions (rows and columns) of absorbance data from user
  absorbanceDataDimensionsMatch = (): boolean => {
    // number of columns
    let absLength: number = this.absorbanceValues[0].values.length;
    const numRows = this.absorbanceValues.length;
    for (let i = 0; i < numRows; ++i) {
      if (this.absorbanceValues[i].values.length != absLength) {
        return false;
      }
    }
    return true;
  };

  // check absorbance data and peak area dimensions
  absorbanceAreaDimensionsMatch = (): boolean => {
    const absDataRows = this.absorbanceValues.length;
    const absDataColumns = this.absorbanceValues[0].values.length;

    // check number of rows
    // return true if absorbance area data was not provided
    if (absDataRows !== this.absorbanceAreaValues.length) {
      return this.absorbanceAreaValues.length === 0 ? true : false;
    }

    // check columns
    for (let i = 0; i < absDataRows; ++i) {
      if (this.absorbanceAreaValues[i].values.length != absDataColumns) {
        return false;
      }
    }
    return true;
  };

  // add absorbance data
  flipDataDimensions(): void {
    //Change x and y dimensions in absorbance data
    let newAbsorbanceValues: FirestoreMap[] = [];
    for (let i = 0; i < this.absorbanceValues[0].values.length; ++i) {
      newAbsorbanceValues.push({ values: [] });
      for (const row of this.absorbanceValues) {
        newAbsorbanceValues[i].values.push(row.values[i]);
      }
    }
    //set the absorbance values to the new transformed matrix
    this.absorbanceValues = newAbsorbanceValues;
  }

  //Uploads the dataset to the Firestore database
  uploadData(): void {
    // put each model that fits the user login into an array
    this.database.firestore
      .collection("datasetsmin")
      .where("user", "==", this.authService.uid())
      .get()
      .then((querySnapshot) => {
        let duplicate_sets: any[] = [];

        querySnapshot.docs.forEach((doc) => {
          let data = doc.data() as MinDataset;
          duplicate_sets.push({ name: data.name });
        });

        // check for duplicate dataset names
        for (let i = 0; i < duplicate_sets.length; i++) {
          if (this.name == duplicate_sets[i].name) {
            this.duplicate = true;
            break;
          }
        }

        for (let i = 0; i < this.reagents[0].values.length; i++) {
          let quotient: number = 0;
          if (this.reagents[0].values[i] != 0) {
            quotient = this.reagents[1].values[i] / this.reagents[0].values[i];
            if (quotient > this.highestBtoA) {
              this.highestBtoA = Math.round(quotient * 100) / 100;
            } else {
            }
          } else {
          }
        }

        if (!this.duplicate) {
          console.log(
            "temp value from submit!: ",
            this.temperatureControl.value
          );
          this.uploading = true;
          this.database.firestore
            .collection("datasets")
            .add({
              name: this.name,
              user: this.authService.uid(),
              comment: this.commentControl.value,
              spectrometer: this.spectrometerControl.value,
              location: this.locationControl.value,
              uploadDate: firebase.default.firestore.Timestamp.now(),
              experimentDate: firebase.default.firestore.Timestamp.fromDate(
                this.experimentDateControl.value
                  ? this.experimentDateControl.value
                  : new Date()
              ),
              pathlength: this.pathlengthControl.value, //in cm [value (1)]
              temperature: this.temperatureControl.value, //in K [value (298)]
              solvent: this.solventControl.value, //ex: Water
              reagents: this.reagents,
              wavelengths: this.wavelengths,
              absorbanceData: this.nmrControl.value
                ? this.absorbanceValues.concat(this.absorbanceAreaValues)
                : this.absorbanceValues,
              dataMask: this.dataMask,
              tag: "user",
              nmr: this.nmrControl.value,
            })
            .then((x) => {
              this.database.firestore
                .collection("datasetsmin")
                .doc(x.id)
                .set({
                  name: this.name,
                  user: this.authService.uid(),
                  uploadDate: firebase.default.firestore.Timestamp.now(),
                  temperature: this.temperatureControl.value, // aellxx: use the input value instead
                  analyte: this.reagents[0].name,
                  titrant: this.reagents[1].name,
                  dimensions:
                    this.absorbanceValues.length +
                    " x " +
                    this.absorbanceValues[0].values.length,
                  highestBtoA: this.highestBtoA,
                })
                .then((x) => {
                  this.uploading = false;
                  this.uploaded = true;

                  if (
                    !this.authService.isGuest() &&
                    (this.savedLocation !== this.locationControl.value ||
                      this.savedSpectrometer !== this.spectrometerControl.value)
                  ) {
                    this.database.firestore
                      .collection("users")
                      .doc(this.authService.uid())
                      .set(
                        {
                          location: this.locationControl.value,
                          spectrometer: this.spectrometerControl.value,
                        },
                        { merge: true }
                      );
                  }
                });
            });
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  }

  tryAgain() {
    this.duplicate = false;
    this.giveName = true;
  }

  checkReset() {
    // make sure reagents fields haven't been touched
    let tempBool = false;
    for (let i = 0; i < this.reagents.length; i++) {
      if (this.reagents[i].name == "" && this.reagents[i].values.length == 0) {
        tempBool = true;
      } else {
        tempBool = false;
        break;
      }
    }

    // do nothing if all fields are blank
    if (
      this.experimentDateControl.value == null &&
      this.spectrometerControl.value == "" &&
      this.pathlengthControl.value == 1 &&
      this.temperatureControl.value == 298 &&
      this.solventControl.value == "Water" &&
      this.reagentValueControls[0].value == "" &&
      this.reagentValueControls[1].value == "" &&
      tempBool == true &&
      this.wavelengthValuesControl.value == "" &&
      this.absorbanceValuesControl.value == "" &&
      this.absorbanceAreasValuesControl.value == "" &&
      this.dataMaskValuesControl.value == "" &&
      this.commentControl.value == ""
    ) {
      this.successBar.openFromComponent(ResetSuccessBarComponent, {
        duration: this.durationInSeconds * 1200,
      });
    } else {
      this.popup = true;
      this.reset = true;
    }
  }

  //Resets all the form fields
  resetForm(): void {
    this.experimentDateControl.setValue(null);
    this.spectrometerControl.setValue("");
    this.locationControl.setValue("");
    this.pathlengthControl.setValue(1);
    this.temperatureControl.setValue(298);
    this.solventControl.setValue("Water");
    for (let i = 0; i < this.subscriptions.length; ++i) {
      this.subscriptions[i].unsubscribe;
    }
    this.subscriptions = [];
    this.reagentValueControls = [
      new FormControl("", Validators.required),
      new FormControl("", Validators.required),
    ];
    for (let i = 0; i < this.reagentValueControls.length; ++i) {
      this.subscriptions.push(
        this.reagentValueControls[i].valueChanges.subscribe((vals: string) => {
          this.reagents[i].values = this.transformDataToArray(vals);
        })
      );
    }
    this.reagents = [
      { name: "", values: [] },
      { name: "", values: [] },
    ];
    this.wavelengthValuesControl.setValue("");
    this.absorbanceValuesControl.setValue("");
    this.absorbanceAreasValuesControl.setValue("");
    this.dataMaskValuesControl.setValue("");
    this.commentControl.setValue("");
    this.popup = false;
    this.reset = false;
    this.successBar.openFromComponent(DeletedSuccessBarComponent, {
      duration: this.durationInSeconds * 1200,
    });
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function showDialog(e: any) {
  console.log("hi");
  var confirmationMessage = "Your Message Here";
  (e || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "reset_success_bar_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class ResetSuccessBarComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "deleted_success_bar_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class DeletedSuccessBarComponent {}
