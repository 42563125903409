import {
  FirestoreMap,
  Reagent,
} from "src/app/shared/interfaces/data.interface";

export const compare = (
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) => {
  if (typeof a === "string" && typeof b === "string") {
    return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
//Converts an array into a Firestore Map
export const arrayToFirestoreMap = (values: number[][]): FirestoreMap[] => {
  let mapValues: FirestoreMap[] = [];

  values.forEach((x) => {
    mapValues.push({ values: x });
  });

  return mapValues;
};

export const arraysAreEqual = (array1: any[], array2: any[]): boolean => {
  return (
    array1.length === array2.length &&
    array1.every((value, index) => value === array2[index])
  );
};

//Converts a FirestorMap back into a regular Matrix type.
export const mapToMatrix = (map: FirestoreMap[] | Reagent[]): number[][] => {
  let matrix: number[][] = [];
  for (let i = 0; i < map.length; ++i) {
    matrix.push([]);
    for (let j = 0; j < map[i].values.length; ++j) {
      matrix[i].push(map[i].values[j]);
    }
  }
  return matrix;
};
