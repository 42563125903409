<div class="wrapper">
  <div id="section1">
    <div class="videoWrapper">
      <!-- must include "[muted]" so that video autoplays on browsers -->
      <video autoplay loop [muted]="true" muted playsinline class="video-background" poster="../../assets/img/data.mp4">
        <source src="../../assets/img/data.mp4" type="video/mp4" />
        <source src="../../assets/img/data.mp4" type="video/ogg" />
      </video>
      <div class="vidOverlay">
        <h1 class="welcome-text">Welcome to SIVVU&trade; {{ displayName }}</h1>

        <button mat-stroked-button class="start-button" (click)="popup()">GET STARTED</button>

        <p class="or-tag">OR</p>

        <button mat-stroked-button class="find-button" (click)="scrollToElement(section2)">FIND OUT MORE
        </button>
      </div>
    </div>
  </div>

  <div #section2>

    <!-- code for below background animation -->
    <div style="margin-top: 100px">

      <h1>Frequently Asked Questions</h1>

      <div class="expand-buttons">
        <button *ngIf="!allExpandState" mat-button (click)="allExpandState = true">Expand All</button>
        <button *ngIf="allExpandState" mat-button (click)="allExpandState = false">Collapse All</button>
      </div>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
        [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">Do you have an interesting chemical solution?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>If you want to identify chemical species that may have formed in an equilibrated solution, then SIVVU can
          help. This website will also enable you to quantify the spectroscopic signature for each of them, and, most
          importantly, the binding constants for the reactions between.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">What do I need to do?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>The key is that you measure spectra for a series of equilibrated chemical solutions that vary in the ratio of
          the two key components. This can be done by making up a separate chemical solution for each desired ratio of
          component A to component B, but it can also be accomplished by titrating a solution of component A into a
          solution of component B and measuring the spectrum at stops along the way. The latter approach is a
          spectroscopic titration. The dataset is the same regardless of the approach.</p>
        <p>Embedded within such a dataset is the information to answer the following questions:</p>
        <p style="margin-top: -10px">1) How many distinct chemical chromophores form over the course of the titration?
        </p>
        <p style="margin-top: -10px">2) What is the relative stoichiometry of analyte:titrant for each?</p>
        <p style="margin-top: -10px">3) What is the spectroscopic signature curve for each?</p>
        <p style="margin-top: -10px">4) What are the binding constants for the associative reactions between them?</p>
        <p>Upload such a dataset and try to fit it to the equilibrium model of your choice.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">How does SIVVU work?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>You upload a spectroscopic dataset of equilibrated solutions, which can be UV-vis, CD, Raman,
          Fluorescence, or anything for which the signal strength varies directly with concentration and can be additively 
          combined with other signals. You can also upload the peak position and area from NMR spectra. Then, you build a 
          model by picking the chemical species that you think necessary to model your data. SIVVU will try
          and fit your data to the model. Essentially, SIVVU will keep changing the equilibrium constants for reactions
          containing the chemical species of your model until the data is best reproduced (smallest
          root-mean-square-residual between calculated and observed).</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">Can I model NMR titration data too?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Yes, you can also upload peak positions and areas from NMR spectra of a titration. Under conditions of fast exchange, the 
          peak positions will be changing over the course of the titration. Under conditions of slow exchange, the peak areas 
          will be changing over the course of the titration. Either way, the changes are directly proportional to the 
          molarity of the chemical species and therefore SIVVU can model the changes with the model you build. 
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">Why can’t I pick some species for my model?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>When you build a model, you select all the species that you want to be included. You cannot however pick two
          species that have the same ratio of analyte:titrant. The reason is that SIVVU works to
          distinguish species along the composition axis (equivalents of titrant). Two species with the same ratio will not be sufficiently
          resolvable via this approach.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">How does SIVVU pick the chemical reactions?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>You pick complexes that might form between the titrant and analyte species and then SIVVU writes
          a chemical reaction to form each of them. There are many equivalent reactions that could be chosen, but SIVVU builds
          associative reactions that include exactly one titrant molecule as a reactant. Each reaction will also include two
          consecutive species starting with the one with the lowest ratio of titrant molecule and proceeding in order
          to the one with the highest ratio of titrant molecule. If you are interested in different reactions, there is
          always a way to combine SIVVU's sequential reactions to produce the reaction of your choice.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">What can go wrong with this type of analysis?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>There are several pitfalls to watch out for with this type of analysis. First, you always get an answer so
          you must scrutinize the resulting fit, comparing it to alternatives. If the signature spectroscopic curves are
          strange, or the binding constants don’t make sense, keep trying to fit your data with different models.
          When you publish, you should always report all the other models that you tried in order to convince readers that your
          choice model is the best model.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">What if I have negative numbers in my data?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>SIVVU’s optimization process does not allow for negative numbers in the model, so it can not replicate
          negative numbers in the dataset. This helps eliminate local minima in the optimization process. There are
          particular experimental conditions for which it is safe to upshift your data to eliminate negative numbers.
          These include either creating all of your chemical solutions from just two mutually diluting stock solutions
          or making sure that one of your key reagents (e.g. your analyte) is the same concentration in all of your chemical solutions.
          (This can be readily accomplished in a titration by dissolving titrant in the analyte stock solution so that
          the analyte concentration is the same in the titrant and analyte solutions.) Then you can upshift your data
          without compromising the equilibrium constants or the model fit. The signature spectroscopic curves (molar 
          absorptivity curves in the case of UV-vis data) will be upshifted as a result. 
          See <a href="https://doi.org/10.1002/cem.3183">https://doi.org/10.1002/cem.3183</a> for details.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">Can I simulate equilibrium concentrations?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>Yes. With any model that you build, you can and should inspect how the equilibrium concentrations will change as a
          function of concentration and binding constants. We call this the impact of the binding isotherm. This can
          help you to identify the optimal range of equivalents through which to run future titrations to maximize
          sensitivity to the binding isotherms. See <a
            href="https://doi.org/10.1002/cem.3119">https://doi.org/10.1002/cem.3119</a> for a more thorough discussion.
        </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">How is the uncertainty of the binding constants estimated?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>The truest uncertainty estimations for the binding constants in a model are naturally asymmetric and
          computationally expensive. Assuming equilibrium models behave linearly and generating symmetric uncertainty
          ranges is unsatisfactory. Instead, uncertainty should only be estimated by bootstrapping the dataset 1000’s of
          times. This involves building new datasets from randomly seclected columns of the original dataset and fitting
          them to the original model. See <a href="https://doi.org/10.1016/j.aca.2022.339834">https://doi.org/10.1016/j.aca.2022.339834</a> 
          for details. SIVVU can do this at the push of a button, but this feature requires a small payment.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">Can SIVVU search models for me?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>It will soon be possible through cutting edge computational methods to automate the search for models that
          fit a dataset. See <a href="https://doi.org/10.1002/cem.3409">https://doi.org/10.1002/cem.3409</a> 
          for details. </p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">What if I have more questions about SIVVU?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>We are not a tech-supported company, but we are happy to try and help. Please email <a
            href="mailto:sivvu@calvin.edu">sivvu@calvin.edu</a> with your questions.</p>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="allExpandState">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <b style="font-size: 16px">How do I reference this website?</b>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>If you publish any results from this website, please use this reference:</p>
        <p>Vander Griend, D.A.; DeVries, M. J.; Greeley, M.; Kim, Y.; Wang, N.; Buist, D; Ulry, C. SIVVU,
          http://sivvu.org, 2021.</p>
        <p>Thank you.</p>
      </mat-expansion-panel>
    </div>
  </div>

  <footer>
    <div class="foot">
      <p class="foot-text">
        Written by Douglas A. Vander Griend, Professor of Chemistry at Calvin University, with special contributions by
        Michael Vermeer, Matthew Greeley, Youngki Kim, Nathan Wang, Dawson Buist, and Coleman Ulry. Based upon work
        supported by the National Science Foundation under Grants CHE1310402 and CHE2004005. Any opinions, findings, and
        conclusions or recommendations expressed in this material are those of the authors and do not necessarily
        reflect the views of the NSF.
      </p>
    </div>
  </footer>