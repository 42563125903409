import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-account-page",
  templateUrl: "./account-page.component.html",
  styleUrls: ["./account-page.component.scss"],
})
export class AccountPageComponent implements OnInit {
  displayName: string = "";
  email: string = "";
  isNormalAccount: boolean = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("My Account | SIVVU");
    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("account");
      this.router.navigate(["login"]);
    }
    if (authService.isGuest()) this.router.navigate(["fits"]);
    else {
      this.displayName = authService.displayName();
      this.email = authService.email();
      this.isNormalAccount = authService.isNormalAccount();
    }
  }

  ngOnInit(): void {}
}
