<div class="wrapper">
  <h1 mat-dialog-title>Warning</h1>
  <mat-dialog-content
    ><p>You must have at least 1 reaction</p></mat-dialog-content
  >

  <mat-dialog-actions>
    <button mat-raised-button color="accent" [mat-dialog-close]="true">
      Return
    </button>
  </mat-dialog-actions>
</div>
