import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "../nav-pages/home-page/home-page.component";
import { AboutPageComponent } from "../nav-pages/about-page/about-page.component";
import { AccountPageComponent } from "../nav-pages/account-page/account-page.component";
import { HistoryPageComponent } from "../nav-pages/history-page/history-page.component";
import { DatasetsPageComponent } from "../nav-pages/datasets-page/datasets-page.component";
import { ModelsPageComponent } from "../nav-pages/models-page/models-page.component";
import { LoginComponent } from "../admin/login/login.component";
import { RegisterComponent } from "../admin/register/register.component";
import { ForgotPasswordComponent } from "../admin/forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "../admin/verify-email/verify-email.component";
import { ChangePasswordComponent } from "../admin/change-password/change-password.component";
import { ChangeEmailComponent } from "../admin/change-email/change-email.component";
import { DataFormPageComponent } from "../nav-pages/data-form-page/data-form-page.component";
import { AddModelPageComponent } from "../nav-pages/models-page/add-model-page/add-model-page.component";
import { PrivacyPolicyPageComponent } from "../admin/privacy-policy-page/privacy-policy-page.component";
import { AuthGuard } from "../guards/auth.guard";
import { AdvancedModelBuilderComponent } from "../nav-pages/models-page/advanced-model-builder/advanced-model-builder.component";

const routes: Routes = [
  { path: "", component: HomePageComponent },
  { path: "account", component: AccountPageComponent },
  { path: "about", component: AboutPageComponent },
  { path: "fits", component: HistoryPageComponent, canActivate: [AuthGuard] },
  {
    path: "datasets",
    component: DatasetsPageComponent,
    canActivate: [AuthGuard],
  },
  { path: "models", component: ModelsPageComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "verify-email", component: VerifyEmailComponent },
  { path: "change-password", component: ChangePasswordComponent },
  { path: "change-email", component: ChangeEmailComponent },
  {
    path: "data-form",
    component: DataFormPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "data-form/:id",
    component: DataFormPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "add-model",
    component: AddModelPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "advanced-model-builder",
    component: AdvancedModelBuilderComponent,
    canActivate: [AuthGuard],
  },
  { path: "privacy-policy", component: PrivacyPolicyPageComponent },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
