/*
 * reference: https://material.angular.io/components/table/examples
 */

declare let Plotly: any;

import { SelectionModel } from "@angular/cdk/collections";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatSort } from "@angular/material/sort";
import { SVD } from "svd-js";
import * as math from "mathjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Sort } from "@angular/material/sort";
import { Title } from "@angular/platform-browser";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { compare, mapToMatrix } from "../../shared/functions/util";
import { DataService } from "src/app/services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteDatasetPopupComponent } from "./delete-dataset-popup/delete-dataset-popup.component";

export interface Element {
  dataset_name: string;
  date: Date;
  reagent1: string;
  reagent2: string;
  dimensions: string;
  highestBtoA: string;
  temperature: number;
  id: number;
  doc_id: string;
}

@Component({
  selector: "app-datasets-page",
  templateUrl: "./datasets-page.component.html",
  styleUrls: ["./datasets-page.component.scss"],
})
export class DatasetsPageComponent implements OnInit {
  @ViewChild("datasetGraph") datasetGraph: ElementRef;
  @ViewChild("factorGraph") factorGraph: ElementRef;
  @ViewChild("solutionCompositionGraph") solutionCompositionGraph: ElementRef;
  @ViewChild("fwdEvoGraph") fwdEvoGraph: ElementRef;
  @ViewChild("bwdEvoGraph") bwdEvoGraph: ElementRef;

  TABLE_DATA: Element[] = [];
  columns: string[] = [
    "dataset_name",
    "date",
    "reagent1",
    "reagent2",
    "dimensions",
    "highestBtoA",
    "temperature",
    "edit",
    "delete",
  ];

  // All of user's datasets
  myData = new MatTableDataSource(this.TABLE_DATA);
  datasets: any[] = [];

  show_spinner: boolean = true;
  show_graph_spinner: boolean = false;
  show_infotable: boolean = false;
  panelOpenState: boolean = false;
  chosen: boolean = true;

  // for dataset information portion of the page, just above the graphs
  comment: string = "";
  pathlength: number = -1;
  solvent: string = "";
  spectrometer: string = "";
  experimentDate: Date = new Date();

  selection = new SelectionModel<Element>(false, []);
  durationInSeconds = 1;
  dataTabLabel = "Raw Absorbance Data";

  @ViewChild(MatSort) sort!: MatSort;

  // NMR graph variables
  wavelengths: number[] = [];

  factors: any[] = [];
  absGraphDrawn: boolean = false;
  selectedTab: number = 0;
  dataError: boolean = false;
  absorbances: number[][];
  epsilon: number[][];

  constructor(
    private authService: AuthService,
    public router: Router,
    private successBar: MatSnackBar,
    private titleService: Title,
    private dataService: DataService,
    private dialog: MatDialog
  ) {
    this.titleService.setTitle("Datasets | SIVVU");
    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("datasets");
      this.router.navigate(["login"]);
    }
  }

  ngOnInit(): void {
    this.getData();
    this.myData.sort = this.sort;
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.selectedTab = event.index;

    if (this.selectedTab === 0 && !this.absGraphDrawn) {
      this.absGraphDrawn = true;
    }
  }

  /**
   * retrieve data from firestore & populate table
   */
  async getData() {
    // put each dataset that fits the user login into an array
    const datasetsMin = await this.dataService.getUserDatasetsmin(true);
    this.datasets = [...datasetsMin];
    // create object with dataset data
    this.datasets.forEach((dataset, i) => {
      this.TABLE_DATA[i] = {
        dataset_name: dataset.name,
        date: dataset.uploadDate.toDate(),
        reagent1: dataset.analyte,
        reagent2: dataset.titrant,
        dimensions: dataset.dimensions,
        highestBtoA: dataset.highestBtoA,
        temperature: dataset.temperature,
        id: i,
        doc_id: dataset.doc_id,
      };
    });

    this.myData = new MatTableDataSource(this.TABLE_DATA);
    this.show_spinner = false;
  }

  /**
   * Sort table data
   * @param sort
   * @returns
   */
  sortData(sort: Sort) {
    const data = this.TABLE_DATA.slice();
    if (!sort.active || sort.direction === "") {
      this.TABLE_DATA = data;
      return;
    }

    this.TABLE_DATA = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "datasetname":
          return compare(a.dataset_name, b.dataset_name, isAsc);
        case "date":
          return compare(a.date, b.date, isAsc);
        case "analyte":
          return compare(a.reagent1, b.reagent1, isAsc);
        case "titrant":
          return compare(a.reagent2, b.reagent2, isAsc);
        case "dimensions":
          return compare(
            parseInt(a.dimensions.split(" x ")[0]) *
              parseInt(a.dimensions.split(" x ")[1]),
            parseInt(b.dimensions.split(" x ")[0]) *
              parseInt(b.dimensions.split(" x ")[1]),
            isAsc
          );
        case "max":
          return compare(a.highestBtoA, b.highestBtoA, isAsc);
        case "temperature":
          return compare(a.temperature, b.temperature, isAsc);
        default:
          return 0;
      }
    });

    this.myData = new MatTableDataSource(this.TABLE_DATA);
  }

  /**
   * Search bar
   * @param event search bar event
   */
  searchFor(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.myData.filter = filterValue.trim().toLowerCase();
  }

  /**
   * navigate to data form page
   */
  goToForm(): void {
    this.router.navigate([`${"/data-form"}`]);
  }

  /**
   * on click edit dataset
   * @param id dataset row id
   */
  onEdit(id: number): void {
    this.router.navigate(["/data-form/" + this.datasets[id].doc_id]);
  }

  /**
   * on click delete dataset
   * @param i dataset row index
   */
  onDelete(i: number): void {
    // this.deleteButtonClicked = true;
    const did = this.TABLE_DATA[i].doc_id;
    const datasetName = this.TABLE_DATA[i].dataset_name;
    const dialogRef = this.dialog.open(DeleteDatasetPopupComponent, {
      width: "50%",
      data: { did, datasetName },
    });
    dialogRef.afterClosed().subscribe((status) => {
      if (status) this.deleteFromTable(i);
    });
  }

  /**
   * delete selected dataset from UI
   * @param i dataset row index
   */
  deleteFromTable(i: number): void {
    // remove from array
    this.datasets.splice(i, 1);

    this.TABLE_DATA.splice(i, 1);
    this.myData = new MatTableDataSource(this.TABLE_DATA);

    this.successBar.openFromComponent(SuccessBarDatasetComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

  calculateUnrestrictedRSquared(
    NoF: number,
    qSorted: number[],
    u: number[][],
    v: number[][],
    absorbances: number[][],
    denominator: number
  ) {
    const qNoF = qSorted
      .slice(0, NoF)
      .concat(new Array(qSorted.length - NoF).fill(0));
    const diagQ = math.diag(qNoF);

    let URSR: number[][];
    if (absorbances[0].length < absorbances.length) {
      URSR = math.dotPow(
        math.subtract(
          absorbances,
          math.multiply(math.multiply(u, diagQ), math.transpose(v))
        ),
        2
      ) as number[][];
    } else {
      URSR = math.dotPow(
        math.subtract(
          absorbances,
          math.transpose(
            math.multiply(math.multiply(u, diagQ), math.transpose(v))
          )
        ),
        2
      ) as number[][];
    }

    return 1 - math.divide(math.sum(URSR), denominator);
  }

  calculateURMSR(
    NoF: number,
    qSorted: number[],
    u,
    v,
    absorbances: number[][]
  ) {
    const qNoF = qSorted
      .slice(0, NoF)
      .concat(new Array(qSorted.length - NoF).fill(0));
    const diagQ = math.diag(qNoF);

    let URMS: number[][];
    if (absorbances[0].length < absorbances.length) {
      URMS = math.subtract(
        absorbances,
        math.multiply(math.multiply(u, diagQ), math.transpose(v))
      ) as number[][];
    } else {
      URMS = math.subtract(
        absorbances,
        math.transpose(
          math.multiply(math.multiply(u, diagQ), math.transpose(v))
        )
      ) as number[][];
    }

    return math.sqrt(math.mean(math.dotPow(URMS, 2)));
  }

  /**
   * Graph NMR dataset with plotly
   */
  graphNMRDataset() {
    this.dataTabLabel = "NMR Data";
    // check if user provided area information
    const peakAreaProvided =
      this.epsilon.length === Math.max(...this.wavelengths) * 2;
    const layout = {
      title: "NMR Data Graph",
      autosize: true,
      height: 600,
      xaxis: { autorange: "reversed" },
    };
    if (peakAreaProvided) {
      this.wavelengths = this.wavelengths.slice(0, this.wavelengths.length / 2);
      const peakPositions = this.epsilon.slice(0, this.epsilon.length / 2);
      const peakAreas = this.epsilon.slice(this.epsilon.length / 2);
      const yAxis = [...Array(peakPositions[0].length).keys()];

      this.epsilon = peakPositions;

      const nmrGraphData = [];
      peakPositions.forEach((element, i) => {
        nmrGraphData.push({
          x: element,
          y: yAxis,
          name: i + 1,
          mode: "markers",
          marker: {
            size: peakAreas[i].map((n) => n * 5),
          },
        });
      });

      Plotly.newPlot(this.datasetGraph.nativeElement, nmrGraphData, layout, {
        responsive: true,
      });
      // specify the peak area with the peak area data provided
    } else {
      // if peak area was NOT provided, use same size for all the plots
      const yAxis = [...Array(this.epsilon[0].length).keys()];
      const nmrGraphData = [];
      this.epsilon.forEach((element, i) => {
        nmrGraphData.push({
          x: element,
          y: yAxis,
          name: i + 1,
          mode: "markers",
        });
      });
      Plotly.newPlot(this.datasetGraph.nativeElement, nmrGraphData, layout, {
        responsive: true,
      });
    }
  }

  /**
   * Graph raw absorbance data with plotly
   */
  graphRawAbsorbanceDataset() {
    this.dataTabLabel = "Raw Absorbance Data";
    let divnum = Math.round(this.wavelengths.length / 200);
    if (divnum < 1) divnum = 1;
    const absData = [];
    for (let i = 0; i < this.epsilon.length; i += divnum) {
      absData.push([this.wavelengths[i]]);
      for (let j = 0; j < this.epsilon[i].length; ++j) {
        absData[i / divnum].push(this.epsilon[i][j]);
      }
    }

    const absGraphData = [];
    const absDataT = math.transpose(absData);
    const xAxis = absDataT.slice(0, 1)[0].reverse();
    absDataT.slice(1).forEach((element) => {
      absGraphData.push({
        x: xAxis,
        y: element.reverse(),
        mode: "lines",
      });
    });

    const layout = {
      title: "Raw Absorbance Data",
      autosize: true,
      xaxis: {
        title: "Wavelength (nm)",
      },
      yaxis: {
        title: "Absorbance (ABS/cm)",
      },
      showlegend: false,
      margin: {
        r: 30,
      },
    };

    Plotly.newPlot(this.datasetGraph.nativeElement, absGraphData, layout, {
      responsive: true,
    });
  }

  /**
   * graph solution composition for 2nd tab
   * @param reagents { name: string; values: number[] }
   */
  graphSolutionCompositionData(reagents: { name: string; values: number[] }[]) {
    //for solution composition graph
    const solutionCompositionData = [];
    reagents.forEach((reagent) => {
      solutionCompositionData.push({
        x: [...Array(reagent.values.length).keys()],
        y: reagent.values,
        name: reagent.name,
        mode: "lines+markers",
        type: "scatter",
      });
    });

    const solutionCompositionLayout = {
      autosize: true,
      xaxis: {
        title: "Solution Number",
      },
      yaxis: {
        title: "Constituent Molarity",
      },
      hovermode: "x unified",
      showlegend: false,
      margin: {
        t: 30,
        r: 30,
      },
    };

    Plotly.newPlot(
      this.solutionCompositionGraph.nativeElement,
      solutionCompositionData,
      solutionCompositionLayout,
      { responsive: true }
    );
  }

  /**
   * Graph forward & backward evolution graphs
   * @param evoDiv plotly Div
   * @param evo evolution data
   */
  async graphEvolutionData(evoDiv, evo: number[][]) {
    const evoData = [];
    const limitLength = evo[0].length > 10 ? 10 : evo[0].length;
    const evoT = math.transpose(evo).slice(0, limitLength);
    console.log({ evoT });
    evoT.forEach((trace, i) => {
      evoData.push({
        x: [...Array(trace.length).keys()],
        y: trace,
        name: i + 1,
        mode: "lines+markers",
        type: "scatter",
      });
    });

    const evoLayout = {
      autosize: true,
      xaxis: {
        title: "Solution Number",
      },
      yaxis: {
        title: "Relative Significance",
      },
      hovermode: "x unified",
      showlegend: false,
      margin: {
        t: 30,
        r: 30,
      },
    };

    Plotly.newPlot(evoDiv.nativeElement, evoData, evoLayout, {
      responsive: true,
    });
  }

  /**
   * Graph factor data and populate factor table
   * @param absorbances raw absorbance data
   */
  graphFactorData(absorbances: number[][]) {
    //factor significance data
    const vals =
      absorbances[0].length < absorbances.length
        ? SVD(absorbances)
        : SVD(math.transpose(absorbances));

    const u = vals.u;
    const q = vals.q;
    const v = vals.v;

    const qSorted = q.sort((a, b) => b - a);
    let facWeight = qSorted;

    const SStot = math.sum(
      math.dotPow(
        math.subtract(math.mean(math.mean(absorbances)), absorbances),
        2
      ) as number[][]
    ) as number;

    let totalweight = 0;
    // limit 10 rows max, up to length of facWeight
    for (let i = 0; i < 10 && i < facWeight.length; ++i) {
      totalweight += facWeight[i];
      const URSquared = this.calculateUnrestrictedRSquared(
        i + 1,
        qSorted,
        u,
        v,
        absorbances,
        SStot
      );
      const URMSResidual = this.calculateURMSR(
        i + 1,
        qSorted,
        u,
        v,
        absorbances
      );
      this.factors.push({
        num: i + 1,
        weight: facWeight[i] / (math.sum(facWeight) / absorbances[0].length),
        percent: (totalweight / math.sum(facWeight)) * 100,
        rSquared: Math.round(URSquared * 100000) / 100000,
        uRMSResidual: URMSResidual,
      });
    }

    let abs_s = this.rArray(facWeight);
    const factorGraphX = [...Array(abs_s.length).keys()];
    const factorData = [
      {
        x: factorGraphX,
        y: abs_s.map((value) => value - 1),
        mode: "lines+markers",
        type: "scatter",
      },
    ];
    const factorLayout = {
      autosize: true,
      xaxis: {
        title: "Factor Number",
      },
      yaxis: {
        title: "Relative Significance",
      },
      margin: {
        t: 30,
        r: 30,
      },
    };

    Plotly.newPlot(this.factorGraph.nativeElement, factorData, factorLayout, {
      responsive: true,
    });
  }

  async onSelectDataset(docId: string): Promise<void> {
    this.factors = [];

    this.show_graph_spinner = true;
    this.chosen = false;

    try {
      this.chosen = false;
      const data = await this.dataService.getDataset(docId);
      this.dataError = false;
      this.show_graph_spinner = false;

      // to be used in extra dataset information table above graphs
      this.comment = data.comment === "" ? "none" : data.comment;

      this.pathlength = data.pathlength;
      this.solvent = data.solvent;
      this.spectrometer = data.spectrometer;
      this.experimentDate = data.experimentDate.toDate();
      this.wavelengths = data.wavelengths;
      this.epsilon = mapToMatrix(data.absorbanceData);

      // graph dataset for 1st tab
      data.nmr ? this.graphNMRDataset() : this.graphRawAbsorbanceDataset();

      // Solution composition
      this.graphSolutionCompositionData(data.reagents);

      // shape: equal to absorbance matrix user input
      let absorbances = this.epsilon;
      // shape: equal to absorbance matrix user input
      let fwdevo = this.forwardEvolution(absorbances);
      let bwdevo = this.backwardEvolution(absorbances);
      // graph forward and backward evolution graphs
      this.graphEvolutionData(this.fwdEvoGraph, fwdevo);
      this.graphEvolutionData(this.bwdEvoGraph, bwdevo);

      // graph factor graph
      this.graphFactorData(absorbances);

      this.show_graph_spinner = false;
      this.show_infotable = true;

      this.absGraphDrawn = this.selectedTab === 0 ? true : false;
    } catch (error) {
      this.dataError = true;
      this.show_graph_spinner = false;
    }
  }

  /**
   * Computes foward evolution
   * @param absdata absorbances data
   * @returns a numpy array containing ...data
   */
  forwardEvolution(absdata: number[][]): number[][] {
    let efaFrank: number[][] = new Array(absdata.length);
    for (let i = 0; i < efaFrank.length; ++i) {
      efaFrank[i] = new Array(Math.min(absdata[0].length, absdata.length)).fill(
        0
      );
    }
    let absDataSliced: number[][] = [];
    absdata.forEach((a: number[]) => {
      absDataSliced.push([a[0]]);
    });
    efaFrank[0][0] = SVD(absDataSliced).q[0] - 1;
    for (let i = 1; i < Math.min(absdata[0].length, absdata.length); ++i) {
      absDataSliced = [];
      absdata.forEach((a: number[]) => {
        let numArray = [];
        for (let j = 0; j < i + 1; ++j) {
          numArray.push(a[j]);
        }
        absDataSliced.push(numArray);
      });
      let S = SVD(absDataSliced).q;
      S = S.sort((a, b) => {
        return b - a;
      }); //sorts array from biggest to smallest
      S = math.subtract(this.rArray(S), 1) as number[];
      for (let j = 0; j < S.length; ++j) {
        efaFrank[j][i] = S[j];
      }
    }
    efaFrank = math.transpose(efaFrank);
    let efaFrankSliced: number[][] = [];
    for (let i = 0; i < efaFrank.length; ++i) {
      let numArray: number[] = [];
      for (let j = 0; j < Math.min(absdata[0].length, absdata.length); ++j) {
        numArray.push(efaFrank[i][j]);
      }
      efaFrankSliced.push(numArray);
    }
    return efaFrankSliced;
  }

  /**
   * Computes backward evolution
   * @param absdata absorbances data.
   * @return a numpy array containing ... data
   */
  backwardEvolution(absdata: number[][]): number[][] {
    let efaFrank: number[][] = new Array(absdata.length);
    for (let i = 0; i < efaFrank.length; ++i) {
      efaFrank[i] = new Array(Math.min(absdata[0].length, absdata.length)).fill(
        0
      );
    }
    let absDataSliced: number[][] = [];
    absdata.forEach((a: number[]) => {
      absDataSliced.push([a[Math.min(absdata[0].length, absdata.length) - 1]]);
    });
    efaFrank[0][Math.min(absdata[0].length, absdata.length) - 1] =
      SVD(absDataSliced).q[0] - 1;
    for (let i = Math.min(absdata[0].length, absdata.length) - 1; i >= 0; --i) {
      absDataSliced = [];
      absdata.forEach((a: number[]) => {
        let numArray = [];
        for (let j = i; j < Math.min(absdata[0].length, absdata.length); ++j) {
          numArray.push(a[j]);
        }
        absDataSliced.push(numArray);
      });
      let S = SVD(absDataSliced).q;
      S = S.sort((a, b) => {
        return b - a;
      }); //sorts array from biggest to smallest
      S = math.subtract(this.rArray(S), 1) as number[];
      for (let j = 0; j < S.length; ++j) {
        efaFrank[j][i] = S[j];
      }
    }
    efaFrank = math.transpose(efaFrank);
    let efaFrankSliced: number[][] = [];
    for (let i = 0; i < efaFrank.length; ++i) {
      let numArray: number[] = [];
      for (let j = 0; j < Math.min(absdata[0].length, absdata.length); ++j) {
        numArray.push(efaFrank[i][j]);
      }
      efaFrankSliced.push(numArray);
    }
    return efaFrankSliced;
  }

  /**
   *   Ranks the array by ....
   * @param data: a list containing numbers to rank
   * @return an array contain ranked values.
   */
  rArray(data: number[]): number[] {
    let dividedArray: number[] = [];
    for (let i = 0; i < data.length - 1; ++i) {
      dividedArray.push(data[i] / data[i + 1]);
    }
    return dividedArray;
  }
}

// Component used for snack bar popup
@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_dataset_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SuccessBarDatasetComponent {}
