<form (submit)="handleForm($event)" style="display: flex; flex-direction: column; align-items: center;">


    <div #cardElement style="width: 90%; background-color: white; 
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; padding: 10px; border-radius: 5px;">
      <!-- A Stripe Element will be inserted here. -->
    </div>


    <!-- Used to display Element errors. -->
    <p style="color: red">{{ cardErrors }}</p>


  <button mat-raised-button color="accent" [disabled]="loading">Buy for ${{ amount / 100 | number: '1.2-2'}}</button>
</form>