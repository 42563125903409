import { Component, Inject, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/services/data.service";

export interface Element {
  date: Date;
  dataset: string;
  model: string;
}

@Component({
  selector: "app-delete-history-popup",
  templateUrl: "./delete-history-popup.component.html",
  styleUrls: ["./delete-history-popup.component.scss"],
})
export class DeleteHistoryPopupComponent {
  rid: string;
  bootstrapped: boolean;
  FIT_DATA: Element[] = [];
  fitTableData: MatTableDataSource<Element>;
  fitTableColumns = ["date", "dataset", "model"];
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteHistoryPopupComponent>,
    private dataService: DataService
  ) {
    this.rid = data.rid;
    this.bootstrapped = data.bootstrapped;
    this.FIT_DATA.push({
      date: data.date,
      dataset: data.dataset,
      model: data.model,
    });
    this.fitTableData = new MatTableDataSource(this.FIT_DATA);
    console.log(this.FIT_DATA);
  }

  async onDelete() {
    try {
      await this.dataService.deleteFit(this.rid, this.bootstrapped);
      this.dialogRef.close(true);
    } catch (err) {
      this.dialogRef.close(false);
    }
  }
  async onCancel() {
    this.dialogRef.close(false);
  }
}
