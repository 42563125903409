import { Component, OnDestroy } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-main-nav",
  templateUrl: "./main-nav.component.html",
  styleUrls: ["./main-nav.component.scss"],
})
export class MainNavComponent implements OnDestroy {
  loggedIn: boolean = false;
  isGuest: boolean = false;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  authSubscription: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router
  ) {
    this.loggedIn = authService.isLoggedIn();

    this.authSubscription = this.authService.afAuth.authState.subscribe(
      (user) => {
        if (user) {
          this.loggedIn = true;
          this.isGuest = user.isAnonymous;
        } else {
          this.loggedIn = false;
        }
      }
    );
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  async logOut() {
    try {
      await this.authService.logout();
      this.router.navigate(["login"]);
    } catch (err) {
      alert(err);
    }
  }
}
