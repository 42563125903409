import { Reaction, Species } from "../interfaces/reaction.interface";

//Returns the greatest common divisor for 2 numbers;
const getGcd = (a: number, b: number): number => {
  if (!b) return a;
  return getGcd(b, a % b);
};

const getLcm = (a: number, b: number): number => {
  const gcdValue = getGcd(a, b);
  return (a * b) / gcdValue;
};

const getFrac = (
  chemicalSpeciesANum: number[],
  chemicalSpeciesBNum: number[],
  html: boolean,
  i: number,
  numerator: number,
  denominator?: number
): string => {
  if (!denominator)
    denominator =
      chemicalSpeciesANum[i - 1] * chemicalSpeciesBNum[i] -
      chemicalSpeciesANum[i] * chemicalSpeciesBNum[i - 1];

  //simplify the numerator and denominator using their greatest common divisor
  let gcd = getGcd(numerator, denominator);
  numerator = numerator / gcd;
  denominator = denominator / gcd;

  if (denominator === numerator) {
    return "";
  } else if (denominator === 1) {
    return numerator.toString();
  } else {
    return html
      ? "<sup>" + numerator + "</sup>&frasl;<sub>" + denominator + "</sub>"
      : numerator + "/" + denominator + " ";
  }
};

export const getProductsCoefficient = (
  chemicalSpeciesANum: number[],
  chemicalSpeciesBNum: number[],
  html: boolean,
  i: number
): string => {
  if (i === 0) {
    if (chemicalSpeciesBNum[i] !== 1) {
      return html
        ? "<sup>1</sup>&frasl;<sub>" + chemicalSpeciesBNum[i] + "</sub>"
        : "1/" + chemicalSpeciesBNum[i];
    } else {
      return "";
    }
  } else {
    let numerator = chemicalSpeciesANum[i - 1];
    return getFrac(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      true,
      i,
      numerator
    );
  }
};

export const getAnalyteCoefficient = (
  chemicalSpeciesANum: number[],
  chemicalSpeciesBNum: number[],
  html: boolean,
  i: number
): string => {
  let numerator = chemicalSpeciesANum[i];
  let denominator = 0;
  if (i === 0) {
    denominator = chemicalSpeciesBNum[i];
    return getFrac(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      html,
      i,
      numerator,
      denominator
    );
  } else {
    return getFrac(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      html,
      i,
      numerator
    );
  }
};

/**
 * Calculate the coefficients to match user-input reactant/product
 * @param reactants user-input reactant
 * @param products user-input product
 * @param element first non-zero element out of A, B, C
 * @returns coefficients to match the number of 'element' on both sides
 */
const calculateCoefficients = (
  reactants: Species,
  products: Species,
  element: string
): number[] => {
  const reactant = reactants[element];
  const product = products[element];
  // if the element exists in both reactant and product, calculate the least common denominator / coef
  // e.g. reactant: A2B1, Product: A3B2
  //      returns [3, 2]
  if (product !== 0) {
    const lcm = getLcm(reactant, product);
    return [lcm / reactant, lcm / product];
  }
  // otherwise nothing to match
  // e.g. reactant: A1B1, Product: C2
  //      returns [1, 1]
  else {
    return [1, 1];
  }
};

/**
 * Get sequential reaction for rows >= 1
 * @param reactants Products of previous reaction
 * @param products selected spec
 * @returns Sequential Reaction
 */
export const getSequentialReaction = (
  reactants: Species[],
  products: Species[]
): Reaction => {
  // user inputs
  const initialReactant = reactants[0];
  const initialProduct = products[0];
  // coefficients
  const initialReactantsCoef = [];
  const initialProductsCoef = [];
  // elements that exist in reactants & products
  const nonZeroReactants = [];
  const nonZeroProducts = [];

  // From the order A, B, and C, get the element that occurs first
  // e.g. if AB2 and B3C, the firstElem is A
  const firstOccurence = Object.keys(initialReactant).find(
    (elem) => initialReactant[elem] > 0 || initialProduct[elem]
  );

  // Get the elements that exist in the reactants and products
  // e.g. reactants: AB2, products: B3C
  //  nonZeroReactants: AB2 -> [A, B]
  //  nonZeroProducts: B3C -> [B]
  Object.keys(initialReactant).forEach((elem) => {
    // existing reactants
    if (initialReactant[elem] > 0) {
      nonZeroReactants.push(elem);
    }
    // existing products that are NOT in the reactants
    if (initialProduct[elem] > 0 && initialReactant[elem] == 0) {
      nonZeroProducts.push(elem);
    }
  });

  // setup initial coefficients
  const [analyteCoef, productCoef] = calculateCoefficients(
    initialReactant,
    initialProduct,
    firstOccurence
  );
  initialReactantsCoef.push(analyteCoef);
  initialProductsCoef.push(productCoef);

  // add pure A, B, or C to match the number of each element on both sides
  nonZeroReactants.forEach((elem) => {
    const diff =
      analyteCoef * initialReactant[elem] - productCoef * initialProduct[elem];

    if (diff > 0) {
      switch (elem) {
        case "A":
          products.push({ A: 1, B: 0, C: 0 });
          break;
        case "B":
          products.push({ A: 0, B: 1, C: 0 });
          break;
        case "C":
          products.push({ A: 0, B: 0, C: 1 });
          break;
      }
      initialProductsCoef.push(diff);
    } else if (diff < 0) {
      switch (elem) {
        case "A":
          reactants.push({ A: 1, B: 0, C: 0 });
          break;
        case "B":
          reactants.push({ A: 0, B: 1, C: 0 });
          break;
        case "C":
          reactants.push({ A: 0, B: 0, C: 1 });
          break;
      }
      initialReactantsCoef.push(diff * -1);
    }
  });

  // Add pure A, B, or C that exists in the initial product and does NOT yet exist in reactants
  nonZeroProducts.forEach((elem) => {
    const diff =
      analyteCoef * initialReactant[elem] - productCoef * initialProduct[elem];

    if (diff > 0) {
      switch (elem) {
        case "A":
          products.push({ A: 1, B: 0, C: 0 });
          break;
        case "B":
          products.push({ A: 0, B: 1, C: 0 });
          break;
        case "C":
          products.push({ A: 0, B: 0, C: 1 });
          break;
      }
      initialProductsCoef.push(diff);
    } else if (diff < 0) {
      switch (elem) {
        case "A":
          reactants.push({ A: 1, B: 0, C: 0 });
          break;
        case "B":
          reactants.push({ A: 0, B: 1, C: 0 });
          break;
        case "C":
          reactants.push({ A: 0, B: 0, C: 1 });
          break;
      }
      initialReactantsCoef.push(diff * -1);
    }
  });

  // Find Coefficient of single element added to reactants
  const dividingCoef =
    initialReactantsCoef.length > 1 ? initialReactantsCoef[1] : 1;
  const reactantsCoef = initialReactantsCoef.map((coef) => coef / dividingCoef);
  const productsCoef = initialProductsCoef.map((coef) => coef / dividingCoef);

  return {
    reactants,
    products,
    reactantsCoef,
    productsCoef,
  };
};

/**
 * Extract coefficients out of user input
 * @param spec {A:1, B:2, C:1}
 * @returns: {
 *  reactants: [{A: 1, B: 0, C: 0}, {A: 0, B: 1, C: 0}, {A: 0, B: 0, C: 1}],
 *  reactantsCoef: [1, 2, 1]
 * }
 */
export const transformChemicals = (
  spec: Species
): {
  reactants: Species[];
  reactantsCoef: number[];
} => {
  const reactants: Species[] = [];
  const reactantsCoef: number[] = [];

  Object.keys(spec).forEach((key, i) => {
    if (spec.hasOwnProperty(key)) {
      const value = spec[key];

      if (value > 0) {
        const newObj: Species = { A: 0, B: 0, C: 0 };
        newObj[key as keyof Species] = 1;
        reactants.push(newObj);
        reactantsCoef.push(value);
      }
    }
  });

  return { reactants, reactantsCoef };
};

/**
 * calculate K value
 * @param reaction Reaction element
 * @param numReactions number of user-selected species
 * @returns K value
 */
export const calculateK = (reaction: Reaction, numReactions: number) => {
  const { reactants, products, reactantsCoef, productsCoef } = reaction;
  // model built from advanced model builder if C exists
  const fromAdvancedModel = reactants[0].C !== null && products[0].C !== null;
  const totalSpecies = numReactions + (fromAdvancedModel ? 3 : 2);

  // default concentration for each element
  const concentration = 0.01 / totalSpecies;

  // calculate exponent
  const reactantConcen = reactantsCoef.reduce((acc, cur) => {
    return acc + cur;
  }, 0);
  const productConcen = productsCoef.reduce((acc, cur) => {
    return acc + cur;
  }, 0);
  // exponents are subtracted when divided
  const exp = productConcen - reactantConcen;

  // K = concentration^exp
  const kValue = Math.pow(concentration, exp);
  return kValue;
};
