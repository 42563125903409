import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing/app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleChartsModule } from "angular-google-charts";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { StripeModule } from "stripe-angular";

// angular cdk imports
import { LayoutModule } from "@angular/cdk/layout";
import { DragDropModule } from "@angular/cdk/drag-drop";

// angular fire imports
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";

// custom component imports
import { MainNavComponent } from "./main-nav/main-nav.component";
import { AccountPageComponent } from "src/app/nav-pages/account-page/account-page.component";
import { ModelsPageComponent } from "src/app/nav-pages/models-page/models-page.component";
import { HistoryPageComponent } from "./nav-pages/history-page/history-page.component";
import { DatasetsPageComponent } from "./nav-pages/datasets-page/datasets-page.component";
import { AboutPageComponent } from "./nav-pages/about-page/about-page.component";
import { HomePageComponent } from "./nav-pages/home-page/home-page.component";
import { LoginComponent } from "./admin/login/login.component";
import { RegisterComponent } from "./admin/register/register.component";
import { ForgotPasswordComponent } from "./admin/forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "./admin/verify-email/verify-email.component";
import { ChangePasswordComponent } from "./admin/change-password/change-password.component";
import { ChangeEmailComponent } from "./admin/change-email/change-email.component";
import { DataFormPageComponent } from "./nav-pages/data-form-page/data-form-page.component";
import { LoginDialogComponent } from "./admin/login-dialog/login-dialog.component";
import { AddModelPageComponent } from "./nav-pages/models-page/add-model-page/add-model-page.component";
import { StripeCheckoutComponent } from "./components/stripe-checkout/stripe-checkout.component";
import { PrivacyPolicyPageComponent } from "./admin/privacy-policy-page/privacy-policy-page.component";

// material design imports
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTabsModule } from "@angular/material/tabs";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatStepperModule } from "@angular/material/stepper";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatSliderModule } from "@angular/material/slider";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { environment } from "src/environments/environment";
import { DeleteModelPopupComponent } from "./nav-pages/models-page/delete-model-popup/delete-model-popup.component";
import { DeleteDatasetPopupComponent } from "./nav-pages/datasets-page/delete-dataset-popup/delete-dataset-popup.component";
import { DeleteHistoryPopupComponent } from "./nav-pages/history-page/delete-history-popup/delete-history-popup.component";
import { AdvancedModelBuilderComponent } from "./nav-pages/models-page/advanced-model-builder/advanced-model-builder.component";
import { DeleteRowPopupComponent } from "./nav-pages/models-page/advanced-model-builder/delete-row-popup/delete-row-popup.component";
import { ChemicalReactionComponent } from "./nav-pages/models-page/advanced-model-builder/chemical-reaction/chemical-reaction.component";
import { AddModelPopupComponent } from "./nav-pages/models-page/add-model-popup/add-model-popup.component";
import { AddFitFormComponent } from './nav-pages/history-page/add-fit-form/add-fit-form.component';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    AboutPageComponent,
    HomePageComponent,
    AccountPageComponent,
    ModelsPageComponent,
    HistoryPageComponent,
    DatasetsPageComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    DataFormPageComponent,
    LoginDialogComponent,
    AddModelPageComponent,
    StripeCheckoutComponent,
    PrivacyPolicyPageComponent,
    DeleteModelPopupComponent,
    DeleteDatasetPopupComponent,
    DeleteHistoryPopupComponent,
    AdvancedModelBuilderComponent,
    DeleteRowPopupComponent,
    ChemicalReactionComponent,
    AddModelPopupComponent,
    AddFitFormComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatIconModule,
    LayoutModule,
    MatListModule,
    MatMenuModule,
    FormsModule,
    MatGridListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTableModule,
    MatRippleModule,
    MatTooltipModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatStepperModule,
    MatDialogModule,
    MatSelectModule,
    MatPaginatorModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatSortModule,
    GoogleChartsModule,
    NgxChartsModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatExpansionModule,
    StripeModule.forRoot(""),
    MatSlideToggleModule,
    DragDropModule,
  ],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
