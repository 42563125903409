import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DeleteRowPopupComponent } from "./delete-row-popup/delete-row-popup.component";
import { calculateK } from "src/app/shared/functions/calculation.util";
import { FormatterService } from "src/app/services/formatter.service";
import { FirestoreMap, Model } from "src/app/shared/interfaces/data.interface";
import { arrayToFirestoreMap } from "src/app/shared/functions/util";
import { AuthService } from "src/app/services/auth.service";
import * as firebase from "firebase/compat/app";
import { DataService } from "src/app/services/data.service";
import { AddModelPopupComponent } from "../add-model-popup/add-model-popup.component";
import { Router } from "@angular/router";
import { getSequentialReaction } from "src/app/shared/functions/calculation.util";

export interface Species {
  A: number;
  B: number;
  C: number | undefined;
}

export interface Reaction {
  reactants: Species[];
  products: Species[];
  reactantsCoef: number[];
  productsCoef: number[];
}

export interface TableRow {
  nonsequential: Reaction;
  sequential: Reaction;
}

@Component({
  selector: "app-advanced-model-builder",
  templateUrl: "./advanced-model-builder.component.html",
  styleUrls: ["./advanced-model-builder.component.scss"],
})
export class AdvancedModelBuilderComponent implements OnInit {
  DEFAULT_CONCENTRATION = 0.01;
  DEFAULT_PURE_SPECIES = 3;
  modelForm: FormGroup;
  modelFormDataSource: MatTableDataSource<any>;
  species = ["A", "B", "C"];
  speciesColumns = ["A", "B", "C", "Species", "Delete"];
  defaultSpeciesString: string[];
  reactionDataSource = new MatTableDataSource<any>([]);
  reactionColumns: string[] = ["nonsequentialReactions", "sequentialReactions"];
  restricted = {
    A: false,
    B: false,
    C: false,
  };

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private formatterService: FormatterService,
    private authService: AuthService,
    private dataService: DataService
  ) {}

  async ngOnInit() {
    // initialize Angular reactive forms
    this.modelForm = this.fb.group({
      tableRows: this.fb.array(
        [
          ...this.initializeTableFormGroup(), // Initialize with pure species A, B, C
        ],
        [
          this.atLeastOneNonZeroABValidator(),
          this.duplicateSpeciesValidator(),
          this.includesDefaultSpeciesValidator(),
        ]
      ),
    });

    // string format of species to check if user included default species
    this.defaultSpeciesString = [...this.tableRows.value].map((spec) =>
      JSON.stringify(spec)
    );

    // data source for mat-table
    this.modelFormDataSource = new MatTableDataSource(this.tableRows.controls);

    // Listen for table changes: update initial guesses reactions whenever form is valid
    this.modelForm.statusChanges.subscribe((status) => {
      if (status === "VALID") {
        this.updateReactionDataSource();
      }
    });
  }

  /**
   * getter for row data
   */
  get tableRows(): FormArray {
    return this.modelForm.get("tableRows") as FormArray;
  }

  /**
   * Initialize Model with pure A, B, and C
   * The first three rows cannot be modified
   * @returns FormGroup with A, B, C
   */
  initializeTableFormGroup(): FormGroup[] {
    return this.species.map((spec) =>
      this.fb.group({
        A:
          spec === "A"
            ? [{ value: 1, disabled: true }]
            : [{ value: 0, disabled: true }],
        B:
          spec === "B"
            ? [{ value: 1, disabled: true }]
            : [{ value: 0, disabled: true }],
        C:
          spec === "C"
            ? [{ value: 1, disabled: true }]
            : [{ value: 0, disabled: true }],
      })
    );
  }

  /**
   * Checks to see if there is at least 1 row with at least 1 A and at least 1 B
   * @returns validator function for checking species
   */
  atLeastOneNonZeroABValidator() {
    return (formGroup: FormGroup) => {
      const userInputSpecies = formGroup.value;
      const hasAB = userInputSpecies.some(
        (spec: Species) => spec.A > 0 && spec.B > 0
      );

      return hasAB ? null : { atLeastOneNonZeroAB: true };
    };
  }

  /**
   * Checks to see if there are any duplicate species
   * @returns validator function for checking duplicate species
   */
  duplicateSpeciesValidator() {
    return (formGroup: FormGroup) => {
      const userInputSpeciesString = formGroup.value.map((spec) =>
        JSON.stringify(spec)
      );
      const uniqueSpecies = new Set(userInputSpeciesString);

      const includesDefault = userInputSpeciesString.some((spec: string) =>
        this.defaultSpeciesString.includes(spec)
      );
      console.log({ includesDefault });

      // userInputSpeciesString.length: user input species
      // uniqueSpecies: number of unique species
      return userInputSpeciesString.length === uniqueSpecies.size ||
        !includesDefault
        ? null
        : { duplicateSpecies: true };
    };
  }

  /**
   * Checks to see if user input pure A, B, or C
   * @returns validator function for checking for duplicate pure species
   */
  includesDefaultSpeciesValidator() {
    return (formGroup: FormGroup) => {
      const userInputSpeciesString = formGroup.value.map((spec) =>
        JSON.stringify(spec)
      );

      const includesDefault = userInputSpeciesString.some((spec: string) =>
        this.defaultSpeciesString.includes(spec)
      );

      return includesDefault ? { includesDefault: true } : null;
    };
  }

  /**
   * Create a row of chemical spec
   * @returns A row of chemical spec
   */
  createTableRowFormGroup(): FormGroup {
    return this.fb.group({
      A: new FormControl(0, [Validators.pattern("^(?:[0-6])$")]),
      B: new FormControl(0, [Validators.pattern("^(?:[0-6])$")]),
      C: new FormControl(0, [Validators.pattern("^(?:[0-6])$")]),
    });
  }

  /**
   * adds a new row or spec to the form & table
   */
  onAddRow() {
    this.tableRows.push(this.createTableRowFormGroup());
    this.modelFormDataSource.data = this.tableRows.controls;
  }

  /**
   * Delete a spec
   * @param index row index from HTML
   */
  onDeleteRow(index: number) {
    if (this.tableRows.length > 4) {
      this.tableRows.removeAt(index);
      this.modelFormDataSource.data = this.tableRows.controls;
    } else {
      // mat dialog to notify user
      this.dialog.open(DeleteRowPopupComponent);
    }
  }

  /**
   * utility for getting default species
   * @returns Array of default species
   */
  getDefaultSpecies(): Species[] {
    return [
      { A: 1, B: 0, C: 0 },
      { A: 0, B: 1, C: 0 },
      { A: 0, B: 0, C: 1 },
    ];
  }

  /**
   * Edit information to match Model schema
   * @returns Model data
   */
  getModelData(): Model {
    const defaultSpecies = this.getDefaultSpecies();
    const species = this.modelForm.value.tableRows.sort(
      (s1: Species, s2: Species) => this.sortSpecies(s1, s2)
    );

    const { chemicalSpeciesANum, chemicalSpeciesBNum, chemicalSpeciesCNum } =
      this.getChemicalSpecies(species);

    const massBalance = this.getMassBalance(species);
    const massAction = this.getMassAction(
      this.reactionDataSource.data,
      defaultSpecies,
      species
    );
    const restricted = this.getRestricted();
    const logKInitial = this.getLogKs(this.reactionDataSource.data);
    const logKLocked = new Array(chemicalSpeciesANum.length).fill(false);
    const logKChanged = false;
    const name = this.getName(species);

    return {
      name,
      user: this.authService.uid(),
      uploadDate: firebase.default.firestore.Timestamp.now(),
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      chemicalSpeciesCNum,
      massBalance,
      massAction,
      restricted,
      logKInitial,
      logKLocked,
      logKChanged,
      tag: "user",
      advancedModel: true,
    };
  }

  /**
   * Form submission
   */
  async onSubmit(): Promise<void> {
    const models = await this.dataService.getUserModels(false);
    const modelNames = models.map((model) => model.name);
    const modelData = this.getModelData();
    const { name } = modelData;

    // open popup to set name
    const dialog = this.dialog.open(AddModelPopupComponent, {
      data: { name, modelNames, modelData },
    });
    dialog.afterClosed().subscribe((status) => {
      if (status) {
        this.router.navigate(["models"]);
      }
    });
  }

  /**
   * For displaying species on the "Species" Row of the form
   * @param rowIndex Row position of user input
   * @returns Formatted species
   */
  getSpec(rowIndex: number): string {
    const row = this.tableRows.at(rowIndex).value;
    const specString = this.formatterService.spec(row);
    return specString;
  }

  /**
   * Checks if the input cell is valid
   */
  isInvalid(spec: string, rowIndex: number): boolean {
    const control = this.tableRows.at(rowIndex).get(spec);
    return control.invalid && (control.dirty || control.touched);
  }

  /**
   * Update initial guess table
   */
  updateReactionDataSource() {
    const rowData = this.tableRows.controls.slice(3).map((row) => {
      return {
        A: row.value.A,
        B: row.value.B,
        C: row.value.C,
      };
    });

    // sort species based on ratios
    const sortedData = rowData.sort((spec1, spec2) =>
      this.sortSpecies(spec1, spec2)
    );
    // set table data to sorted data
    const reactionData = sortedData.map((spec, i, arr) =>
      this.calculateReaction(spec, i, arr)
    );

    this.reactionDataSource.data = reactionData;
  }

  /**
   * sort species based on ratios
   * @param spec1 Species
   * @param spec2 Species
   * @returns
   */
  sortSpecies(spec1: Species, spec2: Species): number {
    const ratios1 = this.calculateRatio(spec1);
    const ratios2 = this.calculateRatio(spec2);

    // First compare based on A ratio
    if (ratios1.ARatio !== ratios2.ARatio) {
      return ratios2.ARatio - ratios1.ARatio;
    }
    // compare B ratio
    if (ratios1.BRatio !== ratios2.BRatio) {
      return ratios2.BRatio - ratios1.BRatio;
    }
    // compare C ratio
    return ratios2.CRatio - ratios1.CRatio;
  }

  /**
   * Extract coefficients out of user input
   * @param spec {A:1, B:2, C:1}
   * @returns: {
   *  reactants: [{A: 1, B: 0, C: 0}, {A: 0, B: 1, C: 0}, {A: 0, B: 0, C: 1}],
   *  reactantsCoef: [1, 2, 1]
   * }
   */
  transformChemicals(spec: Species): {
    reactants: Species[];
    reactantsCoef: number[];
  } {
    const reactants: Species[] = [];
    const reactantsCoef: number[] = [];

    Object.keys(spec).forEach((key, i) => {
      if (spec.hasOwnProperty(key)) {
        const value = spec[key];

        if (value > 0) {
          const newObj: Species = { A: 0, B: 0, C: 0 };
          newObj[key as keyof Species] = 1;
          reactants.push(newObj);
          reactantsCoef.push(value);
        }
      }
    });

    return { reactants, reactantsCoef };
  }

  /**
   * reaction data for initial guesses table
   * @param spec row species
   * @param i row index
   * @param arr all Species
   * @returns row data for initial guesses table {nonsequential: Reaction, sequential: Reaction}
   */
  calculateReaction(spec: Species, i: number, arr: Species[]): TableRow {
    const { reactants, reactantsCoef } = this.transformChemicals(spec);

    if (i === 0) {
      const divider =
        reactantsCoef.length > 1 && reactantsCoef[1] !== 0
          ? reactantsCoef[1]
          : 1;
      // coef should be 1 for pure B or C added to reactants
      const initialSeqReactantsCoef = reactantsCoef.map(
        (coef) => coef / divider
      );
      return {
        nonsequential: {
          reactants: reactants,
          products: [spec],
          reactantsCoef,
          productsCoef: [1],
        },
        sequential: {
          reactants: reactants,
          products: [spec],
          reactantsCoef: initialSeqReactantsCoef,
          productsCoef: [1 / divider],
        },
      };
    } else {
      return {
        nonsequential: {
          reactants: reactants,
          products: [spec],
          reactantsCoef,
          productsCoef: [1],
        },
        sequential: getSequentialReaction([arr[i - 1]], [spec]),
      };
    }
  }

  /**
   * Calculate ratio of each element in a species
   * @param species One of the species for the model
   * @returns A, B, C ratio for spec
   */
  calculateRatio(species: Species) {
    // calculate total coefficients
    const sum = Object.values(species).reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    // return ratios
    return {
      ARatio: species.A / sum,
      BRatio: species.B / sum,
      CRatio: species.C / sum,
    };
  }

  /**
   * Toggle spec status
   * @param spec Species A, B, or C
   */
  onChangeMode(spec: string) {
    this.restricted[spec] = !this.restricted[spec];
  }

  /**
   * Format entire chemical reaction
   * @param reaction from user input
   * @returns formatted reaction string
   */
  getReaction(reaction: Reaction) {
    return this.formatterService.reaction(reaction);
  }

  /**
   * format subscripts for log values
   * @param reaction Reaction
   * @returns formatted string
   */
  getLogBetaSub(reaction: Reaction): string {
    return this.formatterService.logBetaSub(reaction);
  }
  getLogKSub(reaction: Reaction, index: number): string {
    return this.formatterService.logKSub(reaction, index);
  }

  /**
   * Calculate Beta value
   * @param element table row with nonsequential and sequential reactions
   * @param i table row index
   * @returns beta value for nonsequential reaction
   */
  calculateBeta(element: TableRow, i: number) {
    const { nonsequential, sequential } = element;
    if (i === 0) {
      // calculate the amount that was divided to result in singular B,
      // or in cases where B doesn't exist, the amount divided to result in singular C
      const divisor =
        nonsequential.reactantsCoef[0] / sequential.reactantsCoef[0];
      return Math.pow(
        calculateK(sequential, this.reactionDataSource.data.length),
        divisor
      );
    } else {
      // amount of initial product in nonsequential == 1
      // amount of initial product in sequential 1 / divisor
      // inverse the sequential product coefficient to match amount of product
      const productCoefInversed = 1 / sequential.productsCoef[0];
      const product = Math.pow(
        calculateK(sequential, this.reactionDataSource.data.length),
        productCoefInversed
      );
      // get coefficients of initial reactant
      const reactantCoef = sequential.reactantsCoef[0];
      const reactant = Math.pow(
        this.calculateBeta(this.reactionDataSource.data[i - 1], i - 1),
        productCoefInversed * reactantCoef
      );
      return product * reactant;
    }
  }

  getLogK(reaction: Reaction): number {
    const K = calculateK(reaction, this.reactionDataSource.data.length);
    return Math.round(Math.log10(K) * 10) / 10;
  }
  getLogBeta(element: TableRow, i: number): number {
    const beta = this.calculateBeta(element, i);
    return Math.round(Math.log10(beta) * 10) / 10;
  }

  /**
   * Calculate log value for logBeta and logK
   * @param value K value or Beta value
   * @returns logK or logB value
   */
  calculateLogValue(value: number): number {
    return Math.round(Math.log10(value) * 10) / 10;
  }

  /**
   * Build default name for model
   * e.g. if user selects A1B2, A2B1C2, the default name is A12B21C02
   */
  getName(species: Species[]): string {
    // const species = this.modelForm.value.tableRows.slice(3);
    let AName = this.restricted.A ? "A#" : "A";
    let BName = this.restricted.B ? "B" : "(B)";
    let CName = this.restricted.C ? "C" : "(C)";
    console.log({ species });
    species.forEach((spec) => {
      const { A, B, C } = spec;
      AName += A;
      BName += B;
      CName += C;
    });
    return AName + BName + CName;
  }

  /**
   * Build unabsorbing array
   * -1: locked
   * 0: unabsorbing
   * 1: normal
   * @returns array based on A, B, C status
   */
  getRestricted(): number[] {
    const restricted = new Array(this.modelForm.value.tableRows.length).fill(1);
    Object.keys(this.restricted).forEach((spec, i) => {
      switch (spec) {
        case "A":
          restricted[i] = this.restricted[spec] ? -1 : 1;
          break;
        case "B":
          restricted[i] = this.restricted[spec] ? 1 : 0;
          break;
        case "C":
          restricted[i] = this.restricted[spec] ? 1 : 0;
          break;
      }
    });
    return restricted;
  }

  /**
   * Build massAction matrix
   * @param reactions reaction data with reactants, products, and their coefficients
   * @param defaultSpecies [{A: 1, B: 0, C: 0}, {A: 0, B: 1, C: 0}, {A: 0, B: 0, C: 1}]
   * @param species user input species
   * @returns massAction matrix
   */
  getMassAction(
    reactions: TableRow[],
    defaultSpecies: Species[],
    species: Species[]
  ): FirestoreMap[] {
    console.log({ defaultSpecies }, { species });
    // map species to its index (species are ordered properly)
    const speciesIdx = new Map();
    // include pure A, B, C in the lookup map
    const allSpecies = defaultSpecies.concat(species);
    allSpecies.forEach((spec, i) => {
      speciesIdx.set(JSON.stringify(spec), i); // convert Species object to string for comparison
    });

    // massAction.len == the number of user-input species
    const massAction = [];
    // an inner array for each reaction shown on screen
    reactions.forEach((reaction, reactionIdx) => {
      // value for each species (A, B, C, and user-input species)
      massAction.push(
        new Array(reactions.length + this.DEFAULT_PURE_SPECIES).fill(0)
      );
      // extract reaction data
      const {
        sequential: { reactants, reactantsCoef, products, productsCoef },
      } = reaction;

      // reactants are consumed, so multiply -1 to indicate consumption
      reactants.forEach((reactant, reactantIdx) => {
        console.log({ reactant }, speciesIdx.get(JSON.stringify(reactant)));
        massAction[reactionIdx][speciesIdx.get(JSON.stringify(reactant))] =
          -1 * reactantsCoef[reactantIdx];
      });

      // products are produced, so add value as is
      products.forEach((product, productIdx) => {
        massAction[reactionIdx][speciesIdx.get(JSON.stringify(product))] =
          productsCoef[productIdx];
      });
    });

    return arrayToFirestoreMap(massAction);
  }

  /**
   * Build massBalance Matrix
   * @param species user-input species
   * @returns massBalance matrix
   */
  getMassBalance(species: Species[]): FirestoreMap[] {
    // initialize the first three reactions with only A, B, and C
    const massBalance = [
      [1, 0, 0], // pure A
      [0, 1, 0], // pure B
      [0, 0, 1], // pure C
    ];

    // push how much of A, B, and C are in each user-input species
    species.forEach((spec) => {
      massBalance[0].push(spec.A);
      massBalance[1].push(spec.B);
      massBalance[2].push(spec.C);
    });

    return arrayToFirestoreMap(massBalance);
  }

  /**
   * Transform the species data into a format used by firestore
   * @param species user-input species
   * @returns an object containing arrays of the amount of A, B, C
   */
  getChemicalSpecies(species: Species[]): {
    chemicalSpeciesANum: number[];
    chemicalSpeciesBNum: number[];
    chemicalSpeciesCNum: number[];
  } {
    const chemicalSpeciesANum = [];
    const chemicalSpeciesBNum = [];
    const chemicalSpeciesCNum = [];
    species.forEach((spec) => {
      chemicalSpeciesANum.push(spec.A);
      chemicalSpeciesBNum.push(spec.B);
      chemicalSpeciesCNum.push(spec.C);
    });
    return { chemicalSpeciesANum, chemicalSpeciesBNum, chemicalSpeciesCNum };
  }

  /**
   * get list of initial logK values
   * @param reactions species
   * @returns list of initial logK values
   */
  getLogKs(reactions: TableRow[]): number[] {
    const logKs = [];
    reactions.forEach((reaction) => {
      logKs.push(this.getLogK(reaction.sequential));
    });
    return logKs;
  }
}
