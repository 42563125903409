<div *ngIf="form; else loadingSpinner">
  <form [formGroup]="form">
    <div class="form-field-wrapper dataset">
      <mat-form-field appearance="fill">
        <mat-label>Dataset</mat-label>
        <mat-select formControlName="dataset">
          <mat-option *ngFor="let dataset of datasets" [value]="dataset">{{
            dataset.name
          }}</mat-option>
        </mat-select>
        <mat-error *ngIf="requiresSpecialDataset"
          >Dataset requires 3 reagents</mat-error
        >
      </mat-form-field>

      <button
        mat-raised-button
        color="accent"
        type="button"
        (click)="onAddDataset()"
      >
        Add Dataset
      </button>
    </div>
    <div class="form-field-wrapper model">
      <mat-form-field appearance="fill">
        <mat-label>Model</mat-label>
        <mat-select formControlName="model">
          <mat-option *ngFor="let model of models" [value]="model">{{
            model.name
          }}</mat-option>
        </mat-select> </mat-form-field
      ><button
        mat-raised-button
        color="accent"
        type="button"
        (click)="onAddModel()"
      >
        Add Model
      </button>
    </div>
    <div class="button-container upload">
      <button
        mat-raised-button
        color="accent"
        [disabled]="form.invalid"
        (click)="onUploadFit()"
      >
        Fit Model to Dataset
      </button>
    </div>
  </form>
</div>
<ng-template #loadingSpinner>
  <mat-spinner></mat-spinner>
</ng-template>
