import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { Dataset, Model } from "src/app/shared/interfaces/data.interface";

@Component({
  selector: "app-add-fit-form",
  templateUrl: "./add-fit-form.component.html",
  styleUrls: ["./add-fit-form.component.scss"],
})
export class AddFitFormComponent implements OnInit {
  form: FormGroup;
  datasets: Dataset[];
  datasetsAll: Dataset[];
  datasetsWithThreeReagents: Dataset[];
  models: Model[];
  requiresSpecialDataset = false;

  @Output() datasetModel = new EventEmitter<{
    dataset: Dataset;
    model: Model;
  }>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService
  ) {}

  async ngOnInit(): Promise<void> {
    // load datasets and models
    await this.getData();
    // initialize form after loading data so the dropdown menus are properly filled
    this.form = this.fb.group({
      dataset: new FormControl("", Validators.required),
      model: new FormControl("", Validators.required),
    });
    // Add logic to disable options based on model selection
    this.form.get("model").valueChanges.subscribe((model) => {
      const isAdvanced = model.advancedModel;
      if (isAdvanced) {
        this.datasets = this.datasetsWithThreeReagents;
        this.form.get("dataset").setValue("");
        this.requiresSpecialDataset = true;
      } else {
        this.datasets = this.datasetsAll;
        this.requiresSpecialDataset = false;
      }
    });
  }

  async getData() {
    this.models = await this.dataService.getUserModels();

    const datasetsMin = await this.dataService.getUserDatasetsmin();
    // there is data inconsistency between datasetsMin and datasets
    const existingDatasetIds = datasetsMin.map(
      (datasetMin) => datasetMin.doc_id
    );
    const datasets = await this.dataService.getUserDatasets();
    const filteredDatasets = datasets.filter((dataset) =>
      existingDatasetIds.includes(dataset.doc_id)
    );
    this.datasetsAll = [...filteredDatasets];
    this.datasets = [...filteredDatasets];
    this.datasetsWithThreeReagents = this.datasets.filter(
      (dataset) => dataset.reagents.length === 3
    );
  }

  onAddDataset() {
    this.router.navigate(["/data-form"]);
  }

  onAddModel() {
    this.router.navigate(["/add-model"]);
  }

  onUploadFit() {
    this.datasetModel.emit(this.form.value);
  }
}
