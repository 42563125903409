// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyBHKCSLdmCqnJsk7d9TwBB03sfwzItIlNU",
    authDomain: "calvin-sivvu.firebaseapp.com",
    projectId: "calvin-sivvu",
    storageBucket: "calvin-sivvu.appspot.com",
    messagingSenderId: "556283506926",
    appId: "1:556283506926:web:68a0e41e48ff4bcfc543ff"
  },
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
