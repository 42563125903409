<div class="container">
  <h1 style="margin-top: 20px">My Datasets</h1>

  <!-- search bar -->
  <div class="searchbar-con">
    <mat-form-field class="searchbar">
      <mat-label>Search Datasets...</mat-label>
      <input
        matInput
        (keyup)="searchFor($event)"
        placeholder="Ex. Dataset 1"
        #input
      />
    </mat-form-field>

    <!-- add dataset button -->
    <div class="add-button-con">
      <button
        mat-raised-button
        (click)="goToForm()"
        color="accent"
        class="add-button"
      >
        Add Dataset
      </button>
    </div>
  </div>

  <!-- table -->
  <div class="table-wrapper">
    <table
      mat-table
      [dataSource]="myData"
      style="width: 100%"
      matSort
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="dataset_name">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="datasetname"
        >
          Dataset Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dataset_name }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="date"
        >
          Upload Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date : "dd MMM hh:mm a" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="reagent1">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="analyte"
        >
          Analyte
        </th>
        <td mat-cell *matCellDef="let element">{{ element.reagent1 }}</td>
      </ng-container>

      <ng-container matColumnDef="reagent2">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="titrant"
        >
          Titrant
        </th>
        <td mat-cell *matCellDef="let element">{{ element.reagent2 }}</td>
      </ng-container>

      <ng-container matColumnDef="dimensions">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="dimensions"
        >
          Dimensions
        </th>
        <td mat-cell *matCellDef="let element" class="center-dim">
          {{ element.dimensions }}
        </td>
      </ng-container>

      <ng-container matColumnDef="highestBtoA">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="max"
        >
          Max B:A
        </th>
        <td mat-cell *matCellDef="let element" class="center-dim">
          {{ element.highestBtoA }}
        </td>
      </ng-container>

      <ng-container matColumnDef="temperature">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="table-header"
          mat-sort-header="temperature"
        >
          Temperature (K)
        </th>
        <td mat-cell *matCellDef="let element" class="center-temp">
          {{ element.temperature }}
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="font-size: 15px; color: black"
        >
          Edit
        </th>
        <td mat-cell *matCellDef="let element">
          <button class="center-data" (click)="onEdit(element.id)">
            <mat-icon style="font-size: 22px; padding-top: 2px">edit</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th
          mat-header-cell
          *matHeaderCellDef
          style="font-size: 15px; color: black"
        >
          Delete
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button
            (click)="onDelete(i)"
            class="center-data"
            style="margin-left: 5px"
          >
            <mat-icon style="font-size: 22px; padding-top: 2px"
              >delete</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <tr *matNoDataRow class="error-mes">
        <td colspan="8">
          <h4 *ngIf="!show_spinner">No data matched this search...</h4>
          <div class="spin-container">
            <mat-spinner *ngIf="show_spinner" class="spinner"></mat-spinner>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns"
        (click)="selection.toggle(row); onSelectDataset(row.doc_id)"
        [ngClass]="{
          hovered: row.hovered,
          highlighted: selection.isSelected(row)
        }"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false"
      ></tr>
    </table>
  </div>

  <div class="center">
    <mat-card class="card">
      <!-- invalid dataset -->
      <ng-container matCardTitle *ngIf="dataError">
        Data unabled to be graphed: Please edit the current dataset and try
        again
      </ng-container>

      <!-- dataset extra information display -->
      <mat-card-content *ngIf="show_infotable && !chosen && !dataError">
        <h2><u>Dataset Information:</u></h2>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: -10px;
            margin-bottom: -20px;
            flex-wrap: wrap;
          "
        >
          <p style="display: inline-block; margin-right: 20px">
            <b>Spectrometer:</b> {{ spectrometer }}
          </p>
          <p
            style="display: inline-block; margin-right: 20px; margin-left: 20px"
          >
            <b>Pathlength:</b> {{ pathlength }}
          </p>
          <p
            style="display: inline-block; margin-right: 20px; margin-left: 20px"
          >
            <b>Solvent:</b> {{ solvent }}
          </p>
          <p style="display: inline-block; margin-left: 20px">
            <b>Experiment Date:</b>
            {{ experimentDate | date : "MMM dd, yyyy" }}
          </p>
        </div>
        <div class="comment-con">
          <p style="text-align: center"><b>Comment:</b> {{ comment }}</p>
        </div>
      </mat-card-content>

      <mat-card-content>
        <mat-tab-group
          *ngIf="!dataError"
          style="width: 100%"
          mat-align-tabs="center"
          dynamicHeight
          dynamicWidth
          mat-align-tabs="center"
          (selectedTabChange)="onTabChanged($event)"
        >
          <!-- Raw Absorbance data for all types -->
          <mat-tab [label]="dataTabLabel">
            <h2
              *ngIf="chosen"
              style="min-width: 80vw; margin-top: 20px; margin-bottom: 20px"
            >
              Choose a Dataset to graph
            </h2>
            <div class="spin-con">
              <mat-spinner
                *ngIf="show_graph_spinner"
                class="graph_spinner"
              ></mat-spinner>
            </div>

            <div class="dataset-graph-container">
              <div #datasetGraph></div>
            </div>
          </mat-tab>

          <!-- other 4 graphs -->
          <mat-tab label="Unrestricted Analysis">
            <h2 *ngIf="chosen" style="margin-top: 20px; margin-bottom: 20px">
              Choose a Dataset to graph
            </h2>
            <div class="spin-con" *ngIf="show_graph_spinner">
              <mat-spinner class="graph_spinner"></mat-spinner>
            </div>

            <div class="unrestricted-analysis-graphs" *ngIf="!chosen">
              <div class="factor-graph">
                <h3>
                  Factor Significance
                  <mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="When this graph approaches zero, it points to factors that no longer correspond to real chemical species, but rather just signal noise in the data. Mathematically, the significance of a factor is defined as the ratio of the factor weight to the next most weighty factor, less one. Since the weight of factors representing random noise tend to be similar, the ratios tend to be just above 1 and the significance as graphed tends to be just above zero."
                  >
                    help
                  </mat-icon>
                </h3>

                <div #factorGraph>
                  <div
                    class="factor-table"
                    cdkDragBoundary=".factor-graph"
                    cdkDrag
                  >
                    <div class="example-handle" cdkDragHandle>
                      <mat-icon [inline]="true"> open_with </mat-icon>
                    </div>
                    <mat-grid-list cols="5" rowHeight="5:1">
                      <mat-grid-tile><b>Factor</b></mat-grid-tile>
                      <mat-grid-tile><b>Weight</b></mat-grid-tile>
                      <mat-grid-tile><b>%</b></mat-grid-tile>
                      <mat-grid-tile
                        ><b>R<sup>2</sup></b></mat-grid-tile
                      >
                      <mat-grid-tile><b>RMSR</b></mat-grid-tile>
                      <ng-container *ngFor="let fac of factors">
                        <mat-grid-tile>{{ fac.num }}</mat-grid-tile>
                        <mat-grid-tile>{{
                          fac.weight | number : "1.0-5"
                        }}</mat-grid-tile>
                        <mat-grid-tile>{{
                          fac.percent | number : "1.0-2"
                        }}</mat-grid-tile>
                        <mat-grid-tile>{{
                          fac.rSquared | number : "1.5-5"
                        }}</mat-grid-tile>
                        <mat-grid-tile>{{
                          fac.uRMSResidual | number : "1.0-5"
                        }}</mat-grid-tile>
                      </ng-container>
                    </mat-grid-list>
                  </div>
                </div>
              </div>
              <div class="comp-graph">
                <h3>Solution Composition</h3>
                <div #solutionCompositionGraph></div>
              </div>
              <div class="forward-graph">
                <h3>
                  Forward Evolution
                  <mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="This graph relies on the same calculations for factor significance as above, but instead of analyzing the entire dataset it considers only the first n chemical solutions of the dataset, where n is the solution number on the x-axis. This allows you to see at what point the number of significant factors increases as you move through the dataset. Real chemical species that are equilibrated tend to appear in sequence."
                  >
                    help
                  </mat-icon>
                </h3>
                <div #fwdEvoGraph></div>
              </div>
              <div class="backward-graph">
                <h3>
                  Backward Evolution
                  <mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="This graph relies on the same calculations for factor significance as above, but instead of analyzing the entire dataset it considers only the last n chemical solutions of the dataset, where n is the solution number on the x-axis. This allows you to see at what point the number of significant factors decreases as you move through the dataset. Real chemical species that are equilibrated tend to disappear in sequence."
                  >
                    help
                  </mat-icon>
                </h3>
                <div #bwdEvoGraph></div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
