<title>About Us</title>
<div class="content-wrapper">
  <h1 class="mat-display-1" style="margin-top: 20px;">About SIVVU&trade;</h1>
  <p style="margin-top: -30px; text-align: center">SIVVU&trade; is a web-based analytical engine that simulates multi-equilibrium 
    systems to model spectroscopic data. </p>
  <p style="margin-top: -10px; text-align: center">It was born out of the need to quantify the binding constants for solution-phase association reactions. </p>

  <img src="../../../assets/img/SWImage1.png" style="width: 100%;">
  <p>
    The key is to start with a sufficiently large dataset that consists of spectroscopic curves (UV-vis, NMR, CD,
    Fluorescence, Raman, etc.) for a series of chemical solutions in which the ratio of two chemical species is varied.
    It is crucial that the spectroscopic signal changes linearly with concentration. Presumably these two chemical species, 
    for example A and B, bind together to form various complexes, A<sub>n</sub>B<sub>m</sub>, which each have a distinct 
    spectral contribution to the overall signal. SIVVU then finds the associative binding constants for the complexes that 
    lead to the best fit of the data - all the wavelengths and all of the chemical solutions. Specifically, the 
    root-mean-square-residual between the data and the model is minimized.
  </p>

  <div class="expand-buttons">
    <button *ngIf="!allExpandState" mat-button (click)="allExpandState = true">Expand All</button>
    <button *ngIf="allExpandState" mat-button (click)="allExpandState = false">Collapse All</button>
  </div>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false" [expanded]="allExpandState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b style="font-size: 18px">Features</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <p>SIVVU&trade; allows users to:</p>
      <ol>
        <li style="margin-bottom: 3px">Upload and store datasets that correspond to spectroscopic titrations.</li>
        <li>Create chemical models that consist of sets of complexes of the form A<sub>n</sub>B<sub>m</sub> (n, m = 1 - 6).</li>
        <li style="margin-bottom: 3px; margin-top: 1px">Simulate the concentration profiles of any model.</li>
        <li style="margin-bottom: 3px">Quantify the associative binding constants for any model to fit any dataset.</li>
        <li style="margin-bottom: 3px">Analyze uncertainty in the optimized binding constants via bootstrapping.</li>
        <li style="margin-bottom: 3px">Optimize experimental conditions to maximize the sensitivity of binding constants to data.</li>
      </ol>
    </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false" [expanded]="allExpandState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b style="font-size: 18px">Resources</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
  <ul>
    <li>See this paper for details on how the entire problem can be represented well using matrix math
      <ul>
        <li>Wallace, R. M.
          ANALYSIS OF ABSORPTION SPECTRA OF MULTICOMPONENT SYSTEMS1. The Journal of Physical Chemistry <b>1960</b>, 64
          (7),
          899–901. <a href="https://doi.org/doi: 10.1021/j100836a019">https://doi.org/doi: 10.1021/j100836a019</a>.
        </li>
      </ul>
    </li>
    <br>
    <li>
      Here is a paper that details one of the first uses of the program:
      <ul>
        <li>
          Vander Griend, D. A.; Bediako, D. K.; DeVries,
          M. J.; DeJong, N. A.; Heeringa, L. P. Detailed Spectroscopic, Thermodynamic, and Kinetic Characterization of
          Nickel(II) Complexes with 2,2‘-Bipyridine and 1,10-Phenanthroline Attained via Equilibrium-Restricted Factor
          Analysis. Inorganic Chemistry <b>2008</b>, 47 (2), 656–662. <a
            href="https://doi.org/doi: 10.1021/ic700553d">https://doi.org/doi: 10.1021/ic700553d.</a>
        </li>
      </ul>
    </li>
    <br>
    <li>
      See this paper to help decide the range of equivalents to cover with your chemical solutions to best ascertain the
      binding constants:
      <ul>
        <li>
          Kazmierczak, N. P.; Chew, J. A.; Michmerhuizen, A. R.; Kim, S. E.; Drees, Z. D.; Rylaarsdam,
          A.; Thong, T.; Laar, L. V.; Griend, D. A. V. Sensitivity Limits for Determining 1:1 Binding Constants from
          Spectrophotometric Titrations via Global Analysis. Journal of Chemometrics <b>2019</b>, 33 (5), 3119.
          <a href="https://doi.org/10.1002/cem.3119">https://doi.org/10.1002/cem.3119</a>.
        </li>
      </ul>
    </li>
    <br>
    <li>
      See this paper to help decide how best to handle negative values in your data or model
      <ul>
        <li>
          Kazmierczak, N. P.; Vander
          Griend, D. A. Properly Handling Negative Values in the Calculation of Binding Constants by Physicochemical
          Modeling of Spectroscopic Titration Data. Journal of Chemometrics <b>2019</b>, 33 (11), 3183–3196. <a
            href="https://doi.org/10.1002/cem.3183">https://doi.org/10.1002/cem.3183</a>.
        </li>
      </ul>
    </li>
    <br>
    <li>
      See this paper to understand how bootstrapping is used to estimate the uncertainty in the binding constants
      <ul>
        <li>
          Kazmierczak, N. P.; Chew, J. A.; Vander Griend, D. A. Bootstrap methods for quantifying the uncertainty of binding 
          constants in the hard modeling of spectrophotometric titration data. Analytic Chimica Acta <b>2022</b>, 339834. <a
            href="https://10.1016/j.aca.2022.339834">https://10.1016/j.aca.2022.339834</a>.
        </li>
      </ul>
    </li>
  </ul>
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false" style="margin-bottom: 5px" [expanded]="allExpandState">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <b style="font-size: 18px">Contact Us</b>
      </mat-panel-title>
    </mat-expansion-panel-header>
      <div style="display: flex; align-items: center; justify-content: center;">
        <div class="credentials">
          <h2 class="DVG-name">Prof. Douglas A. Vander Griend</h2>
          <h3>Calvin University</h3>
          <div style="margin-left: 8vw; margin-top: -14px;">
            <!-- <mat-icon>phone</mat-icon>
            <a href="tel:6165268729" style="margin-left: 10px;">(616) 526-8729</a>
            <br> -->
            <mat-icon>email</mat-icon> <a style="margin-left: 10px;" href="mailto: sivvu@calvin.edu">Send Email</a>:  sivvu@calvin.edu
            <br>
            <mat-icon>language</mat-icon> <a style="margin-left: 10px;" href="https://calvin.edu/directory/people/douglas-a-vander-griend">Website</a>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

</div>

<footer>
  <div class="foot">
    <p class="foot-text">
      Written by Douglas A. Vander Griend, Professor of Chemistry at Calvin University, with special contributions by Michael Vermeer, Matthew Greeley, Youngki Kim, Nathan Wang, Dawson Buist, and Coleman Ulry. Based upon work supported by the National Science Foundation under Grants CHE1310402 and CHE2004005. Any opinions, findings, and conclusions or recommendations expressed in this material are those of the authors and do not necessarily reflect the views of the NSF.
    </p>
  </div>
</footer>