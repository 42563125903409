import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  email: string = "";
  password: string = "";
  name: string = "";
  error_message: string = "";
  num: any = null;

  durationInSeconds = 2;

  constructor(
    private authService: AuthService,
    public router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Login | SIVVU");
    if (authService.isLoggedIn()) this.router.navigate(["fits"]);
  }

  ngOnInit(): void {}

  //Calls the authservice to log in the user with the provided credentials
  async loginUser(): Promise<void> {
    if (this.email != "" && this.password != "") {
      this.error_message = "";
      const num = await this.authService.login(this.email, this.password);
      if (num == 0) {
        this.error_message = "Credentials not found in our records. Try again.";
      }
    } else {
      this.error_message = "Please input email and password";
    }
  }

  loginAsGuest(): void {
    this.authService.loginAsGuest();
  }

  loginWithGoogle(): void {
    this.authService.loginWithGoogle();
  }
}
