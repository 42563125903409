import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  AfterViewInit,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { AuthService } from "../../services/auth.service";
import firebase from "firebase/compat/app";

declare var Stripe: any; // : stripe.StripeStatic;

@Component({
  selector: "stripe-checkout",
  templateUrl: "./stripe-checkout.component.html",
  styleUrls: ["./stripe-checkout.component.scss"],
})
export class StripeCheckoutComponent implements AfterViewInit, OnDestroy {
  constructor(private auth: AuthService) {}

  @Input() amount: number = 0;
  @Input() description: string = "";
  @Output() checkoutStatus = new EventEmitter<boolean>();
  @ViewChild("cardElement") cardElement!: ElementRef;

  stripe: any; // : stripe.Stripe;
  card: any;
  cardErrors: any;

  loading = false;
  confirmation: any;

  errorMessage = ({ error }: any) => {
    this.cardErrors = error && error.message;
  };

  ngAfterViewInit() {
    this.stripe = Stripe(
      "pk_live_51J8ng9K3EJEbJSNmQnmoINrKbzRcu3IzM9vFUNAFZ9qp8B0AyGTPd0W1gV82hoJ6CxlYr1u2cSPd1hxKyCX3Tgii007eqnAH8X"
    );
    const elements = this.stripe.elements();

    this.card = elements.create("card");
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener("change", this.errorMessage, false);
  }

  ngOnDestroy() {
    this.card.removeEventListener("change", this.errorMessage, false);
  }

  async handleForm(e: Event) {
    this.loading = true;
    e.preventDefault();

    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      // Inform the customer that there was an error.
      this.cardErrors = error.message;
      this.loading = false;
    } else {
      // Send the token to your server.
      const fun = firebase
        .functions()
        .httpsCallable("stripeCreateCharge", { timeout: 540000 });
      fun({
        source: source.id,
        uid: this.auth.uid(),
        amount: this.amount,
        description: this.description,
        email: this.auth.email(),
      })
        .then((confirmation) => {
          this.confirmation = confirmation.data;
          if (this.confirmation) {
            firebase
              .firestore()
              .collection("users")
              .doc(this.auth.uid())
              .collection("charges")
              .doc(this.confirmation.id)
              .set({
                amount: this.amount,
                description: this.description,
                date: firebase.firestore.Timestamp.now(),
              })
              .then(() => {
                this.checkoutStatus.emit(true);
                this.loading = false;
              });
          } else {
            this.loading = false;
            this.cardErrors =
              "There was an error processing your card details. Please verify that you entered them correctly and try again.";
          }
        })
        .catch((e) => {
          console.error(e);
          this.checkoutStatus.emit(false);
        });
    }
  }
}
