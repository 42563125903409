<div class="container">
  <div class="reaction">
    {{ reactants }} ⇌
    <span *ngIf="currentFactors.A"
      >A<sub *ngIf="currentFactors.A !== 1">{{ currentFactors.A }}</sub></span
    >
    <span *ngIf="currentFactors.B"
      >B<sub *ngIf="currentFactors.B !== 1">{{ currentFactors.B }}</sub></span
    >
    <span *ngIf="currentFactors.C"
      >C<sub *ngIf="currentFactors.C !== 1">{{ currentFactors.C }}</sub></span
    >
  </div>
  <div class="log-k">
    {{ type === "nonsequential" ? "logβ" : "logK"
    }}<sub
      >{{ currentFactors.A }}{{ currentFactors.B }}{{ currentFactors.C }}</sub
    >
    =
  </div>
</div>
