<div class="background">
  <div class="content-wrapper">
    <mat-card class="card">
      <h1 class="card-header" style="margin-top: 10px;">Change Email</h1>
      <div class="center">
        <form (ngSubmit)="changeEmail()">

          <mat-form-field class="email-field">
          <mat-label>Current Email</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="email"
            name="email"
            required
          />
          </mat-form-field>
          
          <mat-form-field class="email-field">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            [(ngModel)]="password"
            name="password"
            required
          />
          </mat-form-field>

          <br />
          
          <mat-form-field class="email-field">
          <mat-label>New Email</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="newEmail"
            name="newEmail"
            required
          />
          </mat-form-field>

          <mat-form-field class="email-field">
          <mat-label>Confirm New Email</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="confirmEmail"
            name="confirmEmail"
            required
          />
          </mat-form-field>

          <br />

          <div class="email-field">
            <button mat-raised-button type="submit">
              Change Email
            </button>
          </div>

        </form>
        <p class="error">{{ errorMessage }}</p>
      </div>
    </mat-card>
  </div>
</div>
