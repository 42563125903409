<div style="background-image: url('assets/img/chem.png');">
    <div class="vert-center">
        <mat-card class="card">
            <div class="card-header">Register an Account</div>
            <div class="whole-con">
                <form (ngSubmit)="registerUser()">
                    <mat-form-field class="email-field-main center">
                    <input matInput type="text" placeholder="First Name"
                        [(ngModel)]="firstName" name="firstName" autocomplete="given-name" required autofocus>
                    </mat-form-field>

                    <mat-form-field class="center">
                    <input matInput type="text" placeholder="Last Name"
                        [(ngModel)]="lastName" name="lastName" autocomplete="family-name" required autofocus>
                    </mat-form-field>

                    <mat-form-field class="center">
                    <input matInput type="text" placeholder="Email" [(ngModel)]="email"
                        name="email" autocomplete="email" required autofocus>
                    </mat-form-field>

                    <mat-form-field class="center">
                    <input matInput type="password" placeholder="Password"
                        [(ngModel)]="password" name="password" autocomplete="new-password" required>
                    </mat-form-field>

                    <mat-form-field class="email-field-last center">
                    <input matInput type="password" placeholder="Confirm Password"
                        [(ngModel)]="confirmPassword" name="confirmPassword" autocomplete="new-password" required>
                    </mat-form-field> <br>

                    <div class="center">
                        <button mat-raised-button type="submit">Register</button>
                    </div>

                    <div style="width: 80%; text-align: center; margin-left: 10%; font-weight: bold;">
                        By creating an account, you are agreeing to our <a routerLink="/privacy-policy">Privacy Policy</a>.
                    </div>

                </form>
                <p class="error">{{errormessage}}</p>

            </div>
        </mat-card>
    </div>
</div>