<div class="add-name-container" *ngIf="settingName">
  <h2 mat-dialog-title>Add a name for this model</h2>
  <mat-dialog-content
    ><form
      class="model-name-form"
      [formGroup]="modelNameForm"
      (ngSubmit)="onSubmit()"
    >
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          formControlName="name"
          [errorStateMatcher]="errorStateMatcher"
        />
        <mat-error *ngIf="modelNameForm.get('name').hasError('required')"
          >This field is required</mat-error
        >
        <mat-error *ngIf="modelNameForm.get('name').hasError('duplicate')"
          >Name already exists</mat-error
        >
      </mat-form-field>
      <div class="button-container">
        <button
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!modelNameForm.valid"
        >
          Save
        </button>
        <button mat-stroked-button color="warn" [mat-dialog-close]="false">
          Cancel
        </button>
      </div>
    </form></mat-dialog-content
  >
</div>
<!-- while uploading -->
<div *ngIf="!settingName && !modelDoc">
  <mat-dialog-content>
    <mat-spinner></mat-spinner>
    <p>Uploading Model</p>
  </mat-dialog-content>
</div>
<!-- After completing upload -->
<div *ngIf="!settingName && modelDoc">
  <div>
    <h2 mat-dialog-title>Model upload successful</h2>
    <mat-dialog-actions align="center">
      <button mat-raised-button color="accent" [mat-dialog-close]="true">
        OK
      </button>
    </mat-dialog-actions>
  </div>
</div>
