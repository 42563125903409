<div class="whole">
  <h1 mat-dialog-title>Would you like to login? Or continue as a guest?</h1>
  <h3>Warning: your data will not be saved when signed in as a guest.</h3>

  <div class="container">
    <!-- <div> -->
      <button mat-raised-button (click)="login()" class="login">Login</button>
      <button mat-raised-button (click)="guest()" class="guest">Continue as Guest</button>
    <!-- </div> -->
  </div>
</div>
