<div class="container">
  <h1 style="margin-top: 20px">My Models</h1>

  <!-- search bar -->
  <div class="searchbar-con">
    <mat-form-field class="searchbar">
      <mat-label>Search Models...</mat-label>
      <input
        autocomplete="off"
        matInput
        (keyup)="searchFor($event)"
        placeholder="Ex. Dataset 1"
        #input
        autocomplete="off"
      />
    </mat-form-field>

    <!-- add button -->
    <div class="add-model-con">
      <button
        mat-raised-button
        (click)="onAddModel()"
        class="add-button"
        color="accent"
      >
        Add Model
      </button>
    </div>
  </div>

  <!-- table -->
  <div class="table-wrapper">
    <table
      mat-table
      matSort
      (matSortChange)="sortData($event)"
      [dataSource]="myData"
      style="width: 100%"
    >
      <ng-container matColumnDef="model_name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="modelname"
          class="table-header"
        >
          Model Name
        </th>
        <td mat-cell *matCellDef="let element">{{ element.model_name }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="date"
          class="table-header"
        >
          Date Saved
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.date | date : "dd MMM hh:mm a" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="num_species">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="species"
          class="table-header"
        >
          Species
        </th>
        <td mat-cell *matCellDef="let element">
          <div [innerHTML]="element.species"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="table-header">Delete</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <button
            (click)="onDelete(i)"
            class="center-data"
            style="margin-left: 6px"
          >
            <mat-icon style="font-size: 22px; padding-top: 2px"
              >delete</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <tr *matNoDataRow class="error-mes">
        <td colspan="8">
          <h4 *ngIf="!show_spinner">No data matched this search...</h4>
          <div class="spin-container">
            <mat-spinner *ngIf="show_spinner" class="spinner"></mat-spinner>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns"
        (click)="selection.toggle(row); onSelectModel(row.doc_id, row.id)"
        [ngClass]="{
          hovered: row.hovered,
          highlighted: selection.isSelected(row)
        }"
        (mouseover)="row.hovered = true"
        (mouseout)="row.hovered = false"
      ></tr>
    </table>
  </div>

  <div class="legend">
    <span>* - user-customized initial logK's</span>
    <span># - spectrum locked</span>
    <span>(Parentheses) - non-absorbing</span>
    <span><b>bold</b> - associative binding constant pinned</span>
  </div>

  <div class="center">
    <mat-card class="card">
      <h2 *ngIf="!model">Choose a model to graph</h2>

      <div [ngClass]="model ? 'show-content' : 'hide-content'">
        <h2>Equilibrium Concentration Profiles</h2>
        <!-- lists species of that model -->
        <div class="big-wrapper">
          <div
            *ngFor="let reaction of reactions; index as i"
            class="species-line"
          >
            <div class="mess">
              <span class="reaction">
                <span [innerHTML]="getReaction(reaction)"></span>
              </span>

              <ng-container>
                &nbsp; &nbsp; logK<sub>{{ getLogKSub(reaction, i) }}</sub>
              </ng-container>

              = {{ getLogKValue(reaction) }}
            </div>
            <div class="sliders">
              <mat-slider
                thumbLabel
                (change)="redraw()"
                [(ngModel)]="logK[i]"
                min="{{ logKInitial[i] - 10 }}"
                max="{{ logKInitial[i] + 10 }}"
                step="0.01"
                value="log_k[i]"
              >
              </mat-slider>
              <mat-form-field class="logKInput">
                <mat-label
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                  >LogK</mat-label
                >
                <input
                  matInput
                  type="number"
                  (change)="redraw()"
                  [(ngModel)]="logK[i]"
                  style="text-align: center"
                  min="{{ logKInitial[i] - 10 }}"
                  max="{{ logKInitial[i] + 10 }}"
                  step="0.01"
                />
              </mat-form-field>
              <mat-slide-toggle
                (change)="toggleChange(i)"
                class="toggle-con"
                [checked]="logKBoolean[i] ? true : false"
              >
                <h3 style="margin-top: 15px">{{ logKText[i] }}</h3>
              </mat-slide-toggle>
              <mat-icon
                class="tooltip-pin"
                *ngIf="i == 0 && logKBoolean[i] == false"
                #tooltip="matTooltip"
                matTooltip="When pinned, the logK value will not be altered when this model is fit to a dataset."
              >
                help
              </mat-icon>
            </div>

            <!-- impact calculated in ts file -->
            <div class="impact" *ngIf="!chosen">
              <ng-container *ngIf="i === 0">
                <label
                  >Impact: {{ impact_array[i] | number : "1.2-2" }}
                  <mat-icon
                    class="tooltip-imp"
                    *ngIf="i === 0"
                    #tooltip="matTooltip"
                    matTooltip="Impact quantifies the degree to which the concentration profiles shift with logK. The greater the Impact, the more precisely the logK can be modeled."
                  >
                    help
                  </mat-icon></label
                >
              </ng-container>

              <div *ngIf="i !== 0" style="padding-top: 5px">
                <label>Impact: {{ impact_array[i] | number : "1.2-2" }}</label>
              </div>
            </div>
          </div>
          <div *ngIf="!chosen">
            <button
              mat-raised-button
              (click)="onSetInitialLogK()"
              class="setInitial"
            >
              Set Initial LogK Values
            </button>
            <!-- tooltip -->
            <mat-icon
              class="tooltip"
              style="margin-left: 5px; margin-right: 10px"
              #tooltip="matTooltip"
              matTooltip="This button will change your model's initial LogK's to your custom LogK's."
            >
              help
            </mat-icon>
            <button
              mat-raised-button
              (click)="optimize()"
              [disabled]="num_opt !== 0"
              class="green-opt"
            >
              Optimize Experimental Parameters
            </button>
            <mat-icon
              class="tooltip"
              style="margin-left: 5px"
              #tooltip="matTooltip"
              matTooltip="For a given set of chemical reactions with their associated logK values, there will be an optimal set of experimental titration parameters, namely the analyte concentration and the number of equivalents of titrant, that will maximize the Impact of all the reactions. These parameters represent the optimal titration experiment for determining the logK values most precisely. If the optimal experimental parameters are not chemically reasonable, simply adjust them from their mathematically optimal values by changing the axis limits below. Hopefully the Impact values for the reactions are still sufficiently high."
            >
              help
            </mat-icon>
            <div *ngIf="optimized">
              <p style="text-align: center; margin: 6px 0px 0px 0px">
                <b>Best Analyte Concentration:</b> {{ bestH }}
              </p>
              <p style="text-align: center">
                <b>Optimal Number of Titrant Equivalents:</b> {{ bestQ }}
              </p>
            </div>
          </div>
        </div>

        <div
          *ngFor="let log of logK; index as i"
          style="margin-top: 10px; display: block"
        ></div>

        <!-- sets y max of graph -->
        <div>
          <div *ngIf="!chosen" class="h-con">
            <mat-form-field class="h">
              <mat-label style="text-align: center">[Analyte]</mat-label>
              <input
                autocomplete="off"
                matInput
                [(ngModel)]="h"
                (change)="modelChangeH($event)"
                style="text-align: center"
              />
            </mat-form-field>
          </div>
        </div>

        <!-- conc graph -->
        <div class="conc-graph">
          <div class="annotation">
            The shadowed bands on the equilibrium concentration graphs show how
            much the curves change when various K values are doubled or halved.
            Wider bands indicate greater sensitivity of the K values to the
            data.
          </div>
          <div #equilibriumConcentrationGraph class="plotly-container"></div>
        </div>

        <!-- sets x max of graph -->
        <div class="q-con">
          <mat-form-field class="q">
            <mat-label>Axis Max</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="q"
              (change)="modelChangeQ($event)"
              style="text-align: center"
            />
          </mat-form-field>
        </div>

        <!-- export data button -->
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 23px;
          "
        >
          <div class="export">
            <button
              mat-raised-button
              (click)="create_datasheet()"
              class="export"
            >
              <h2 class="export-text">Export Model Data</h2>
              <mat-icon class="export-icon">get_app</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </div>

  <!-- safeguard to make sure user didn't accidentally delete a model -->
  <div class="center">
    <ng-container *ngIf="reset">
      <div class="gray" (click)="closePopup()"></div>

      <div class="popup">
        <ng-container>
          <h2>Thanks for your patience</h2>
          <mat-spinner class="spinner"></mat-spinner>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div class="center"></div>
</div>
