<div style="background-image: url('assets/img/chem.png')">
  <div class="vert-center">
    <mat-card class="card">
      <mat-card-title style="text-align: center; margin-top: 10px;">My Account Info</mat-card-title>
      <mat-card-content>
        <table style="margin-top: 3%">
          <tr>
            <td class="info-header">DISPLAY NAME:</td>
            <td class="info-field">
              {{ displayName }}

              <!-- TODO: add routerLink for change displayName -->
            </td>
            <td *ngIf="isNormalAccount">
              <!-- <a class="change-button" mat-stroked-button>Change</a> -->
            </td>
          </tr>
          <tr>
            <td class="info-header">EMAIL:</td>
            <td class="info-field">
              {{ email }}
            </td>
            <td *ngIf="isNormalAccount">
              <button class="change-button" mat-stroked-button routerLink="/change-email">Change</button>
            </td>
          </tr>
          <tr>
            <td class="info-header">PASSWORD:</td>
            <td class="info-field">********</td>
            <td>
              <button class="change-button" mat-stroked-button routerLink="/change-password">Change</button>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>