<!--
  reference: https://stackblitz.com/edit/swimlane-line-chart?embed=1&file=app/app.component.ts
-->

<div class="container" (window:resize)="onResize()">
  <h1 style="margin-top: 20px">My Fits</h1>

  <div class="center-top-elements">
    <div class="whole-wrapper">
      <h2>Add a Fit:</h2>
      <div class="selection-wrapper">
        <app-add-fit-form
          (datasetModel)="onUploadFit($event)"
        ></app-add-fit-form>
      </div>
    </div>

    <div class="table-container">
      <div class="searchbar-con">
        <label class="import-button">
          <input
            type="file"
            (change)="onFileChanged($event)"
            #fileInput
            id="no-display"
            [disabled]="calculatingOptimization"
          />
          Import Fit
        </label>
        <mat-form-field class="searchbar">
          <mat-label>Search Fits...</mat-label>
          <input
            matInput
            (keyup)="searchFor($event)"
            placeholder="Ex. Dataset 1"
            [disabled]="calculatingCloudBoot || calculatingOptimization"
            #input
          />
        </mat-form-field>
      </div>

      <div
        class="table-con"
        *ngIf="calculatingOptimization"
        style="
          background-color: lightgray;
          width: 97.5%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <h1>
          Fit selection cannot be changed while new optimization is taking place
        </h1>
      </div>

      <div
        class="table-con"
        *ngIf="calculatingCloudBoot"
        style="
          background-color: lightgray;
          width: 97.5%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <h1>
          Fit selection cannot be changed while bootstrapping is taking place
        </h1>
      </div>

      <div
        class="table-con"
        *ngIf="fitCalculationError"
        style="
          background-color: lightgray;
          width: 97.5%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <h1>
          An error occurred while calculating the fit. The page will refresh in
          {{ timeLeftUntilRefresh }} seconds
        </h1>
      </div>

      <div
        class="table-con"
        *ngIf="!calculatingCloudBoot && !calculatingOptimization"
        style="border: 1px solid darkgray"
      >
        <table
          mat-table
          matSort
          (matSortChange)="sortData($event)"
          [dataSource]="myData"
          style="width: 100%"
        >
          <ng-container matColumnDef="dataset_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="datasetname"
              style="font-size: 15px; color: black"
            >
              Dataset Name
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
            >
              {{ element.dataset_name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="model_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="modelname"
              style="font-size: 15px; color: black"
            >
              Model Name
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
            >
              {{ element.model_name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="date"
              style="font-size: 15px; color: black"
            >
              Date Saved
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
            >
              {{ element.date | date : "dd MMM YYYY hh:mm a" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="rmsr">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="rmsr"
              style="font-size: 15px; color: black"
            >
              RMSR
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [style.font-weight]="element.bootstrapped ? 'bold' : 'normal'"
            >
              {{ element.rmsr }}
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="font-size: 15px; color: black"
            >
              Delete
            </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div>
                <button
                  (click)="onDelete(i)"
                  style="z-index: 1000000; margin-left: 6px"
                  class="center-icon"
                >
                  <mat-icon style="font-size: 22px; padding-top: 2px"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
          <tr
            mat-row
            class="main-table"
            *matRowDef="let row; index as i; columns: columns"
            (click)="onSelectFit(row, i)"
            [ngClass]="{
              hovered: row.hovered,
              highlighted: selection.isSelected(row)
            }"
            (mouseover)="row.hovered = true"
            (mouseout)="row.hovered = false"
          ></tr>

          <div class="error-con">
            <tr *matNoDataRow class="error-mes">
              <td colspan="8">
                <h4 *ngIf="!showSpinner">No data matched this search...</h4>
                <div class="spin-container">
                  <mat-spinner
                    *ngIf="showSpinner"
                    class="spinner"
                  ></mat-spinner>
                </div>
              </td>
            </tr>
          </div>
        </table>
      </div>
      <!-- <div> -->
      <p class="explain"><b>bold</b> - fit has been bootstrapped</p>
      <!-- </div> -->
    </div>
  </div>

  <div class="center">
    <mat-card class="card">
      <!-- progress: calculating optimizations (disappears once calculation is done) -->
      <mat-card-content *ngIf="calculatingOptimization">
        <h2>Calculating Optimization</h2>
        <section>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </section>
      </mat-card-content>
      <!-- progress: uploading fit (disappears once calculation is done) -->
      <mat-card-content *ngIf="!calculatingOptimization && uploadingFit">
        <h2>Uploading Fit</h2>
        <section>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </section>
      </mat-card-content>

      <mat-card-actions *ngIf="drawn" class="export-container">
        <button
          mat-raised-button
          class="export-btn"
          (click)="export_fit()"
          [disabled]="calculatingOptimization"
        >
          <mat-icon>get_app</mat-icon>
          Export Fit
        </button>
      </mat-card-actions>

      <mat-card-content *ngIf="drawn" class="chem-table-log-k-container">
        <div class="chem-table-container" *ngIf="!calculatingOptimization">
          <div class="chem-table">
            <table mat-table [dataSource]="myChemData" class="chem_table">
              <ng-container matColumnDef="chem_reactions">
                <th mat-header-cell *matHeaderCellDef style="font-size: 15px">
                  Chemical Reactions
                  <mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="Underlined - spectrum locked
                  Parentheses - non-absorbing"
                  >
                    help
                  </mat-icon>
                </th>
                <td mat-cell *matCellDef="let i">
                  <div [innerHTML]="i.chem_reactions"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="logKInitial">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  logK Initial<mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="SIVVU starts each optimization with savvy choices for each logK that ensure that the data is sensitive to each logK.
                    Alternatively, the user may specify initial logK values, and even lock them, in the model building page."
                  >
                    help
                  </mat-icon>
                </th>
                <ng-container *ngIf="!logKChanged">
                  <td mat-cell *matCellDef="let i" class="log-table">
                    {{ i.logKInitial | number : "1.2-2" }}
                  </td>
                </ng-container>
                <ng-container *ngIf="logKChanged">
                  <td mat-cell *matCellDef="let i" class="log-table">
                    {{ i.logKInitial | number : "1.2-2" }}*
                  </td>
                </ng-container>
              </ng-container>

              <ng-container matColumnDef="logKOptimal">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  logK Optimal
                  <mat-icon
                    style="font-size: 18px; color: blue; cursor: pointer"
                    matTooltip="Click this to save the optimal logK values to your model."
                    (click)="saveLogKOptimal()"
                    *ngIf="logKOptimal.length"
                    >save</mat-icon
                  >
                  <mat-spinner
                    *ngIf="saveSpinner"
                    class="spinner"
                    style="display: inline"
                    diameter="20"
                  >
                  </mat-spinner>
                </th>
                <td mat-cell *matCellDef="let i" class="log-table">
                  {{ i.logKOptimal | number : "1.2-2" }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chemColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: chemColumns"></tr>
            </table>
          </div>
          <div class="results-table">
            <table mat-table [dataSource]="myResultsData" class="results_table">
              <ng-container matColumnDef="results">
                <th mat-header-cell *matHeaderCellDef style="font-size: 15px">
                  Result
                </th>
                <td mat-cell *matCellDef="let i">
                  <div [innerHTML]="i.results"></div>
                </td>
              </ng-container>

              <ng-container matColumnDef="restricted">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  Restricted<mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="Restricted figures of merit correspond to the fitting of the data with the chosen model according to the rules of chemical equilibria."
                  >
                    help
                  </mat-icon>
                </th>
                <td mat-cell *matCellDef="let i" class="log-table">
                  {{ i.restricted }}
                </td>
              </ng-container>

              <ng-container matColumnDef="unrestricted">
                <th mat-header-cell *matHeaderCellDef class="table-header">
                  Unrestricted<mat-icon
                    class="tooltip"
                    #tooltip="matTooltip"
                    matTooltip="Unrestricted figures of merit correspond to the fitting of the data with purely mathematical factors that are not constrained by any chemical or scientific sensibilities like non-negativity, mass balance, or chemical equilibria. They should always be numerically better than the corresponding restricted figures of merit. By necessity these values are calculated with any datamask ignored."
                  >
                    help
                  </mat-icon>
                </th>
                <td mat-cell *matCellDef="let i" class="log-table">
                  {{ i.unrestricted }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="resultsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: resultsColumns"></tr>
            </table>
          </div>
        </div>
        <div class="logK-graph-container">
          <div #logKGraph class="logK-graph"></div>
        </div>
      </mat-card-content>
      <mat-card-content class="graphs-container">
        <mat-tab-group
          [selectedIndex]="selectedTab"
          mat-align-tabs="center"
          (selectedTabChange)="onTabChanged($event)"
          dynamicWidth
          dynamidHeight
          mat-align-tabs="center"
        >
          <!-- Draw either a Raw Absorbance Data or a NMR Data depending on the data type -->
          <mat-tab label="{{ dataGraphLabel }}">
            <h2
              *ngIf="!drawn && !calculatingOptimization"
              style="margin-top: 20px"
            >
              Fit Dataset to a Model, or Choose an Existing Fit
            </h2>
            <h2
              *ngIf="!drawn && calculatingOptimization"
              style="margin-top: 20px"
            >
              Calculating Optimization
            </h2>

            <div class="single-graph-tab" *ngIf="drawn">
              <div class="graph-container">
                <div #datasetGraph class="plotly-graph"></div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="{{ absorptivityGraphLabel }}">
            <h2
              *ngIf="!drawn && !calculatingOptimization"
              style="margin-top: 20px"
            >
              Fit Dataset to a Model, or Choose an Existing Fit
            </h2>
            <h2
              *ngIf="!drawn && calculatingOptimization"
              style="margin-top: 20px"
            >
              Calculating Optimization
            </h2>
            <div class="double-graph-tab" *ngIf="drawn">
              <div class="export-container">
                <button
                  mat-raised-button
                  class="export-btn"
                  (click)="create_abs_datasheet()"
                >
                  <mat-icon>get_app</mat-icon>
                  Export Data
                </button>
              </div>
              <!-- RMS Residual Graph -->
              <div class="graph-container" *ngIf="loadingDelay">
                <div #molarAbsorptivityGraph class="molar-absorptivity"></div>
              </div>
              <div class="graph-container" *ngIf="loadingDelay">
                <div #absAreaGraph class="abs-area"></div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Concentration Data">
            <h2
              *ngIf="!drawn && !calculatingOptimization"
              style="margin-top: 20px"
            >
              Fit Dataset to a Model, or Choose an Existing Fit
            </h2>
            <h2
              *ngIf="!drawn && calculatingOptimization"
              style="margin-top: 20px"
            >
              Calculating Optimization
            </h2>
            <mat-spinner
              *ngIf="loadingSpinner"
              class="graph_spinner"
            ></mat-spinner>
            <div *ngIf="drawn" class="double-graph-tab">
              <div class="export-container">
                <button
                  mat-raised-button
                  class="export-btn"
                  (click)="create_conc_datasheet()"
                >
                  <mat-icon>get_app</mat-icon>
                  Export Data
                </button>
              </div>
              <div class="graph-container">
                <div #concentrationLineGraph class="concentration-line"></div>
              </div>
              <div class="graph-container" *ngIf="loadingDelay">
                <div #concentrationBarGraph class="concentration-bar"></div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Residuals">
            <h2
              *ngIf="!drawn && !calculatingOptimization"
              style="margin-top: 20px"
            >
              Fit Dataset to a Model, or Choose an Existing Fit
            </h2>
            <h2
              *ngIf="!drawn && calculatingOptimization"
              style="margin-top: 20px"
            >
              Calculating Optimization
            </h2>
            <div class="tab-body">
              <mat-spinner
                *ngIf="loadingSpinner"
                class="graph_spinner"
              ></mat-spinner>
              <ng-container *ngIf="drawn">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 90vw;
                  "
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;
                      max-width: 90vw;
                    "
                  >
                    <mat-form-field appearance="fill">
                      <mat-label>Minimum Wavelength</mat-label>
                      <input
                        matInput
                        type="number"
                        [(ngModel)]="xStart"
                        min="0"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Maximum Wavelength</mat-label>
                      <input
                        matInput
                        type="number"
                        [(ngModel)]="xEnd"
                        min="0"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;
                      max-width: 100vw;
                    "
                  >
                    <mat-form-field appearance="fill">
                      <mat-label>First Solution</mat-label>
                      <input
                        matInput
                        type="number"
                        [(ngModel)]="yStart"
                        min="1"
                        required
                      />
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <mat-label>Last Solution</mat-label>
                      <input
                        matInput
                        type="number"
                        [(ngModel)]="yEnd"
                        min="1"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    max-width: 100vw;
                    margin-bottom: 20px;
                  "
                >
                  <button
                    mat-raised-button
                    color="accent"
                    (click)="redraw()"
                    style="margin-right: 15px"
                  >
                    Redraw
                  </button>
                  <button mat-raised-button color="warn" (click)="reset()">
                    Reset
                  </button>
                  <button
                    mat-raised-button
                    class="export-res-btn"
                    (click)="create_residual_datasheet()"
                  >
                    <mat-icon>get_app</mat-icon>
                    Export Data
                  </button>
                </div>

                <br />
              </ng-container>

              <div style="max-width: 1000px; width: 100%; touch-action: none">
                <div #mygraph style="width: 100%"></div>
              </div>

              <ng-container *ngIf="drawn">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: 100%;
                    max-width: 90vw;
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 90vw;
                      min-height: 200px;
                    "
                  >
                    <div #residualsGraph></div>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-tab>
          <mat-tab label="Uncertainty Estimation">
            <h2
              *ngIf="!drawn && !calculatingOptimization"
              style="margin-top: 20px"
            >
              Fit Dataset to a Model, or Choose an Existing Fit
            </h2>
            <h2
              *ngIf="!drawn && calculatingOptimization"
              style="margin-top: 20px"
            >
              Calculating Optimization
            </h2>
            <ng-container *ngIf="drawn">
              <div class="boot-con">
                <p style="text-align: center; margin: 10px 10px 20px 10px">
                  To estimate the 95% confidence interval on the logK values,
                  the model is repeatedly fit to random versions of the dataset
                  to generate histograms for each logK.
                </p>
                <p style="text-align: center; margin: -20px 10px 20px 10px">
                  These 'bootstrap' calculations are computationally expensive,
                  so they are not run on your computer, but on our external
                  server.
                </p>
                <p style="text-align: center; margin: -20px 10px 20px 10px">
                  Therefore, this feature requires a small payment.
                </p>
                <p style="text-align: center; margin: -20px 10px 20px 10px">
                  100 iterations costs $1; 1,000 iterations costs $8; 10,000
                  iterations costs $20.
                </p>
                <p style="text-align: center; margin: -20px 10px 20px 10px">
                  For publication purposes, at least 1,000 bootstraps are
                  recommended
                </p>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: center;
                  margin-bottom: 10px;
                  margin-top: 20px;
                  flex-wrap: wrap;
                "
              >
                <button
                  mat-raised-button
                  color="accent"
                  (click)="bootNum = 100; cost_popup()"
                  [disabled]="calculatingCloudBoot"
                  style="margin-left: 10px; margin-bottom: 10px"
                >
                  Run 100 Bootstraps
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="bootNum = 1000; cost_popup()"
                  [disabled]="calculatingCloudBoot"
                  style="margin-left: 10px; margin-bottom: 10px"
                >
                  Run 1,000 Bootstraps
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="bootNum = 10000; cost_popup()"
                  [disabled]="calculatingCloudBoot"
                  style="margin-left: 10px; margin-bottom: 10px"
                >
                  Run 10,000 Bootstraps
                </button>
              </div>

              <div
                *ngIf="bootData[0][0].length"
                style="
                  display: flex;
                  justify-content: center;
                  margin-bottom: 10px;
                  margin-top: 20px;
                "
              >
                <button
                  mat-raised-button
                  class="boot-button"
                  (click)="create_bootstrap_datasheet()"
                >
                  <mat-icon>get_app</mat-icon>
                  Export Data
                </button>
              </div>

              <div
                *ngIf="bootProgress > 0 && isBootstrapping"
                style="width: 90%; margin: auto"
              >
                Estimated time to finish bootstrapping will be in
                {{ bootProgress }} seconds
                <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
              </div>

              <div
                *ngIf="bootProgress <= 0 && isBootstrapping"
                style="width: 90%; margin: auto"
              >
                Bootstrapping is in progress...
              </div>

              <br />

              <div class="boot-container" *ngIf="bootData[0][0].length">
                <ng-container *ngFor="let data of bootData; index as i">
                  <div class="boot-graph">
                    <span [innerHTML]="chemReactions[i]"></span>&#9;
                    <span style="width: 80%; margin-left: 10%"
                      >logK = {{ confInt[i][2] | number : "1.3-3" }}</span
                    >
                    <p style="width: 80%; margin-left: 10%">
                      95% Confidence Interval: [{{
                        confInt[i][0] | number : "1.3-3"
                      }}, {{ confInt[i][1] | number : "1.3-3" }}]
                    </p>
                    <google-chart
                      [type]="bootType"
                      [data]="data"
                      [options]="bootOptions"
                      [dynamicResize]="true"
                      style="
                        margin-top: -250px;
                        margin-bottom: -200px;
                        height: 800px;
                      "
                    >
                    </google-chart>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- <div class="center">
    <ng-container *ngIf="showPopup">
      <div class="gray" (click)="closePopup()"></div>

      <div class="popup">
        <h2>Are you sure you want this fit deleted?</h2>

        <div>
          <button
            mat-raised-button
            color="accent"
            (click)="onDelete()"
            style="margin-right: 10px"
          >
            Yes
          </button>
          <button
            mat-stroked-button
            color="warn"
            (click)="closePopup()"
            style="margin-left: 10px"
          >
            No
          </button>
        </div>
      </div>
    </ng-container>
  </div> -->

  <div class="center-popup">
    <ng-container *ngIf="duplicate_dataset_name">
      <div class="gray" (click)="closePopup()"></div>
      <div class="popup">
        <h2>An existing dataset of yours already has this name.</h2>
        <h2>Try another name.</h2>
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            [(ngModel)]="new_dataset_name_after_duplicate"
            required
          />
        </mat-form-field>
        <div>
          <button
            mat-raised-button
            color="accent"
            (click)="import_dataset()"
            [disabled]="
              new_dataset_name_after_duplicate.length < 4 ||
              new_dataset_name_after_duplicate.length > 40
            "
          >
            Submit
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="duplicate_confirmation">
      <div class="gray" (click)="closePopup()"></div>
      <div class="popup">
        <h2>
          An existing dataset of yours has matching temperature, analyte,
          titrant, dimensions, and highest B to A ratio data.
        </h2>
        <h2>Would you like to still import this dataset?</h2>
        <div>
          <button
            mat-raised-button
            color="accent"
            (click)="import_dataset()"
            style="margin-right: 10px"
          >
            Yes
          </button>
          <button
            mat-raised-button
            color="warn"
            (click)="duplicate_confirmation = false; import_model()"
            style="margin-left: 10px"
          >
            No
          </button>
        </div>
      </div>
    </ng-container>

    <div *ngIf="guestPopup" class="center-popup">
      <div class="lightgray" (click)="close_guestPopup()"></div>
      <div class="popup" style="text-align: center">
        Only users with an account may perform bootstrapping. If you would like
        to take advantage of this feature, please login or create an account.

        <button
          mat-raised-button
          color="accent"
          (click)="close_guestPopup()"
          style="margin-top: 20px"
        >
          Continue
        </button>
      </div>
    </div>

    <div *ngIf="paymentPopup" class="center-popup">
      <div class="lightgray" (click)="closePayPopup()"></div>
      <div class="popup" *ngIf="checkoutPopup">
        <h2>Purchase {{ description }}</h2>
        <p
          style="color: red; text-align: center"
          *ngIf="absorbances[0].length / 10 / logK.length < 1"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="Bootstrap warning"
            [ngStyle]="{ color: 'red' }"
            style="text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3)"
            >warning</mat-icon
          >
          Warning: at minimum you should have 8 solutions per reaction. However,
          you should have at least 10 per reaction for the best results.
          Currently, your fit has ~{{
            absorbances[0].length / logK.length | number : "1.0-0"
          }}
          solutions per reaction. Having too few solutions per reaction can
          result in calculation errors leading to some bootstraps not being
          calculated properly.
        </p>
        <stripe-checkout
          [amount]="amount"
          [description]="description"
          (checkoutStatus)="updateStatus($event)"
          style="width: 100%"
        ></stripe-checkout>
        <br />
        <div>
          <button
            mat-raised-button
            color="accent"
            (click)="codePopup = true; checkoutPopup = false"
            *ngIf="codeAllowed === true"
            style="margin-right: 10px"
          >
            Use Code
          </button>
          <button
            mat-raised-button
            color="warn"
            (click)="checkoutPopup = false; paymentPopup = false"
            style="margin-left: 10px"
          >
            Back
          </button>
        </div>
      </div>

      <div class="popup" *ngIf="codePopup">
        <h2>Enter code to access free bootstrapping.</h2>
        <div
          style="display: flex; justify-content: center; margin-bottom: 10px"
        >
          <form (ngSubmit)="checkCode()">
            <mat-form-field appearance="fill">
              <mat-label>Code</mat-label>
              <input matInput [(ngModel)]="userCode" name="code" required />
            </mat-form-field>

            <p style="margin: -18px 0px 10px 0px; color: red">
              {{ codeErrorMessage }}
            </p>
            <div style="display: flex; justify-content: center">
              <button
                mat-raised-button
                color="accent"
                type="submit"
                style="margin-right: 10px"
              >
                Submit
              </button>
              <button
                mat-raised-button
                color="warn"
                type="reset"
                (click)="
                  codePopup = false; checkoutPopup = true; codeErrorMessage = ''
                "
                style="margin-left: 10px"
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="center-popup">
      <ng-container *ngIf="show_import_popup">
        <div class="lightgray" (click)="closePopup()"></div>

        <div class="popup">
          <h2>
            Are you sure you want to import {{ selectedFile.name }} to your
            account?
          </h2>

          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="import_fit()"
              style="margin-right: 10px"
            >
              Yes
            </button>
            <button
              mat-stroked-button
              color="warn"
              (click)="close_import_popup()"
              style="margin-left: 10px"
            >
              No
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="duplicate_dataset_name">
        <div class="lightgray" (click)="closePopup()"></div>
        <div class="popup">
          <h2>An existing dataset of yours already has this name.</h2>
          <h2>Try another name.</h2>
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              [(ngModel)]="new_dataset_name_after_duplicate"
              required
            />
          </mat-form-field>
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="import_dataset()"
              [disabled]="
                new_dataset_name_after_duplicate.length < 4 ||
                new_dataset_name_after_duplicate.length > 40
              "
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="duplicate_model_name">
        <div class="gray" (click)="closePopup()"></div>
        <div class="popup">
          <h2>An existing model of yours already has this name.</h2>
          <h2>Try another name.</h2>
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              [(ngModel)]="new_model_name_after_duplicate"
              required
            />
          </mat-form-field>
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="import_model()"
              [disabled]="
                new_model_name_after_duplicate.length < 4 ||
                new_model_name_after_duplicate.length > 40
              "
            >
              Submit
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="wrong_extension">
        <div class="gray" (click)="closePopup()"></div>
        <div class="popup">
          <h2 style="color: red; margin-bottom: 2px">Import Error:</h2>
          <h2>only .sivvu files are accepted.</h2>
          <div>
            <button
              mat-raised-button
              color="accent"
              (click)="close_extension_popup()"
            >
              Ok
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="importLoadingPopup">
        <div class="gray" (click)="closePopup()"></div>
        <div class="popup">
          <h2>This may take a few seconds.</h2>
          <h2>Thanks for your patience.</h2>
          <mat-spinner class="spinner"></mat-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</div>
