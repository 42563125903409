import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(private router: Router, private titleService: Title) {
    this.titleService.setTitle("Verify Email | SIVVU");
   }

  ngOnInit(): void {
  }

  goToLogin() {
    this.router.navigate(['login'])
  }

}
