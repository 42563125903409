<div style="margin: 30px 50px">
    <h1>
        Privacy Policy
    </h1>

    <p style="text-align: center;">
        <b>August 2, 2021</b>
    </p>

    <h2>1. The Basics</h2>

    <h3>A. About Us</h3>

    <p>
        SIVVU is an online analytical engine operated by Douglas A. Vander Griend, a researcher at Calvin University
        located in the State of Michigan in the United States ("<b>we</b>," "<b>us</b>," "<b>our</b>," and
        "<b>SIVVU</b>").
    </p>

    <p>
        Our Service enables our Members to, among other things, archive and analyze experimental data that they upload
        to SIVVU.
    </p>

    <h3>B. Key Terms</h3>

    <p>
        In this privacy policy, these terms have the following meanings:
    </p>

    <p>
        "<b>Member</b>" means any person or entity that is registered with us to use the Service.
    </p>

    <p>
        "<b>Personal Information</b>" means any information that identifies or can be used to identify an individual
        directly or indirectly. Examples of Personal Information include, but are not limited to, first and last name,
        email address, location, or occupation.
    </p>

    <p>
        “<b>Scientific Information</b>” means any information regarding experimental data that is uploaded to the site.
    </p>

    <p>
        “<b>Service</b>” refers to all aspects of the website SIVVU.org, including, but not limited to the calculations,
        database
        archival, and technical support.
    </p>

    <h2>
        2. Privacy for Members
    </h2>

    <p>
        This section applies to the Personal Information we collect and process from a Member or potential Member
        through
        the provision of the Service.
    </p>

    <h3>
        A. Information We Collect
    </h3>

    <p>
        The information we collect broadly falls into the following categories:
    </p>

    <ol>
        <li>
            Personal Information, including your name and email used to create an account as well as various details
            about location regarding datasets.
        </li>
        <li>Scientific Information regarding experimental data</li>
    </ol>

    <p>
        All data collected is stored using Google authentication services in Google’s Firestore database.
    </p>

    <h3>
        B. Use of Personal Information
    </h3>

    <p>
        We may use the Personal Information we collect or receive through the Service (alone or in combination with
        other
        data we source) for the purposes and on the legal bases identified below:
    </p>

    <ul>
        <li>
            To send you system alert messages in reliance on our legitimate interests in administering the Service and
            providing certain features. For example, we may inform you about temporary or permanent changes to our
            Service, such
            as planned outages, or send you account, security or compliance notifications, such as new features, version
            updates, releases, abuse warnings, and changes to this privacy policy.
        </li>

        <li>
            To communicate with you about your account and provide customer support to perform our contract with you for
            the
            use of the Service or where we have not entered into a contract with you, in reliance on our legitimate
            interests in
            administering and supporting our Service.
        </li>

        <li>
            To enforce compliance with our Standard Terms of Use and applicable law, and to protect the rights and
            safety of
            our Members in reliance on our legitimate interest to protect against misuse or abuse of our Service and to
            pursue
            remedies available.
        </li>

        <li>
            To meet legal requirements, including complying with court orders, valid discovery requests, valid
            subpoenas, and
            other appropriate legal mechanisms.
        </li>

        <li>
            To provide information to representatives and advisors, including attorneys and accountants, to help us
            comply
            with legal, accounting, or security requirements in reliance on our legitimate interests.
        </li>

        <li>
            To prosecute and defend a court, arbitration, or similar legal proceeding.
        </li>

        <li>
            To respond to lawful requests by public authorities, including to meet national security or law enforcement
            requirements.
        </li>

        <li>
            To provide, support and improve the Service to perform our contract with you for the use of the Service or
            where
            we have not entered into a contract with you, in reliance on our legitimate interests in administering and
            improving
            the Service and providing certain features.
        </li>

        <li>
            To provide suggestions to you and to provide tailored features within our Service that optimize and
            personalize
            your experience in reliance on our legitimate interests in administering the Service and providing certain
            features.
        </li>

        <li>
            To perform data analytics projects in reliance on our legitimate research interests in improving and
            enhancing our
            products and services for our Members.
        </li>

        <li>
            To combine and anonymize data about our Members and our Member's use of the Service in order to create
            aggregate,
            anonymized statistics which we may use to provide certain features within the Service and for promoting and
            improving the Service in reliance on our legitimate interests.
        </li>

        <li>
            To personalize the Service and content we serve to you in reliance on our legitimate interests in providing
            certain features within the Service.
        </li>
    </ul>

    <h3>
        C. Use of Scientific Information
    </h3>

    <ul>
        <li>
            We may use the Scientific Information we collect or receive through the Service (alone or in combination
            with
            other data we source) To perform data analytics projects in reliance on legitimate scientific research
            interests.
        </li>
    </ul>

    <h3>
        D. Cookies and Tracking Technologies
    </h3>

    <p>
        We do not use cookies, track users, nor show any advertisements.
    </p>

    <h3>
        E. Your Data Protection Rights
    </h3>

    Depending on the country in which you reside, you may have the following data protection rights:

    <ul>
        <li>
            To access; correct; update; port; delete; restrict; or object to our processing of your Personal
            Information.
        </li>

        <li>
            The right to complain to a data protection authority about the collection and use of Personal Information.
            For more information, please contact your local data protection authority. Contact details for data
            protection
            authorities in the EEA and UK are available
            <a
                href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>
            and Switzerland are available
            <a
                href="https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html">here</a>.
        </li>

        <li>
            Similarly, if Personal Information is collected or processed on the basis of consent, the data subject can
            withdraw their consent at any time. Withdrawing your consent will not affect the lawfulness of any
            processing we
            conducted prior to your withdrawal, nor will it affect the processing of your Personal Information conducted
            in
            reliance on lawful processing grounds other than consent.
        </li>
    </ul>

    <p>
        We respond to all requests we receive from individuals wishing to exercise their data protection rights in
        accordance with applicable data protection law. We may ask you to verify your identity in order to help us
        respond
        to your request.
    </p>

    <h2>
        3. General Information
    </h2>

    <h3>
        A. How We Share Information
    </h3>

    <p>
        We will not sure your personal information with any outside party.
    </p>

    <p>
        Your scientific information may be shared with parties with a legitimate scientific research interest for
        conducting
        data analytics projects.
    </p>

    <h3>
        B. Our Security
    </h3>

    <p>
        We take appropriate and reasonable technical and organizational measures designed to protect Personal
        Information
        from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking into account the risks
        involved in the processing and the nature of the Personal Information. If you have any questions about the
        security
        of your Personal Information, you may contact us at sivvu@calvin.com.
    </p>

    <p>
        SIVVU accounts require a username and password to log in. Members must keep their username and password secure,
        and
        never disclose it to a third party.
    </p>

    <h3>
        C. International Transfers
    </h3>

    <p>
        <b>We operate in the United States</b>
    </p>

    <p>
        SIVVU is headquartered in and has offices in the United States and our servers are also located in the United
        States. This means data we process may be transferred to, stored, or processed in the United States.
    </p>

    <h3>
        D. Retention of Personal Information
    </h3>

    <p>
        We retain Personal Information where we have an ongoing legitimate business or legal need to do so. Our
        retention
        periods will vary depending on the type of data involved, but, generally, we'll refer to these criteria to
        determine
        retention period:
    </p>

    <ul>
        <li>
            Whether we have a legal or contractual need to retain the data.
        </li>

        <li>
            Whether the data is necessary to provide our Service.
        </li>

        <li>
            Whether our Members have the ability to access and delete the data within their SIVVU accounts.

        <li>
            Whether our Members would reasonably expect that we would retain the data until they remove it or until
            their SIVVU accounts are closed or terminated.
        </li>
    </ul>

    <p>
        When we have no ongoing legitimate business need to process your Personal Information, we will either delete or
        anonymize it or, if this is not possible (for example, because your Personal Information has been stored in
        backup
        archives), then we will securely store your Personal Information and isolate it from any further processing
        until
        deletion is possible.
    </p>

    <h3>
        E. Retention of Scientific Information
    </h3>

    <p>
        We retain the Scientific Information uploaded to the site indefinitely.
    </p>

    <p>
        If a user uploads data using a guest account, the data will still be stored even after the account expires but
        will
        not be accessible to the user.
    </p>

    <p>
        Members can a request that Scientific Information be removed from the database by submitting justification in
        writing that it is in the best interest of science to purge said information from the database.
    </p>

    <h3>
        F. California Privacy
    </h3>

    <p>
        The California Consumer Privacy Act (“CCPA”) provides consumers with specific rights regarding their Personal
        Information. You have the right to request that businesses subject to the CCPA (which may include our Members
        with
        whom you have a relationship) disclose certain information to you about their collection and use of your
        Personal
        Information over the past 12 months. In addition, you have the right to ask such businesses to delete Personal
        Information collected from you,
        subject to certain exceptions. If the business sells Personal Information, you have a right to opt-out of that
        sale.
        Finally, a business cannot discriminate against you for exercising a CCPA right.
    </p>

    <p>
        When offering services to its Members, SIVVU acts as a “service provider” under the CCPA and our receipt and
        collection of any consumer Personal Information is completed on behalf of our Members in order for us to provide
        the
        Service. Please direct any requests for access or deletion of your Personal Information under the CCPA to the
        Member
        with whom you have a direct relationship.
    </p>

    <p>
        Consistent with California law, if you choose to exercise your applicable CCPA rights, we won’t charge you
        different
        prices or provide you a different quality of services. If we ever offer a financial incentive or product
        enhancement
        that is contingent upon you providing your Personal Information, we will not do so unless the benefits to you
        are
        reasonably related to the value of the Personal Information that you provide to us.
    </p>

    <h3>
        G. Do not Track
    </h3>

    <p>
        Certain state laws require us to indicate whether we honor “Do Not Track” settings in your browser. SIVVU
        adheres to
        the standards set out in this Privacy Policy and does not monitor or follow any Do Not Track browser requests.
    </p>

    <h3>
        H. Changes to this Policy
    </h3>

    <p>
        We may change this privacy policy at any time and from time to time. The most recent version of the privacy
        policy
        is reflected by the version date located at the top of this privacy policy. All updates and amendments are
        effective
        immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised
        version
        of this privacy policy or other notice on the SIVVU Sites. We encourage you to review this privacy policy often
        to
        stay informed of changes that may affect you. Our electronically or otherwise properly stored copies of this
        privacy
        policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this
        privacy policy that was in effect on each respective date you visited the SIVVU Site.
    </p>

    <h3>
        I. Questions & Concerns
    </h3>

    <p>
        If you have any questions or comments, or if you have a concern about the way in which we have handled any
        privacy
        matter, please send an email to sivvu@calvin.com.
    </p>

    <p>
        <b>Or mail at:</b>
    </p>
    <p>
        SIVVU Attn. Douglas A. Vander Griend
        <br />
        Calvin University 3201 Burton Street SE
        <br />
        Grand Rapids, MI 49546
    </p>

</div>