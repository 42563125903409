<div style="background-image: url('assets/img/chem.png')">
    <div class="vert-center">
    <mat-card class="card">
        <div class="card-header">Recover Password</div>
        <div class="row" *ngIf="!sentEmail">
            <div class="center-align">
                <form (ngSubmit)="sendEmail()">

                    <mat-form-field class="email-field">
                    <input matInput type="email" placeholder="Email" [(ngModel)]="email" name="email" required autofocus>
                    </mat-form-field>
                    <br>
                    <button mat-raised-button class="login-button" type="submit">Send Email</button><br>

                </form>

                <a class="login-button-back" routerLink="/login">Back to Login</a>

                <p class="errortext">{{errorMessage}}</p>
            </div>
        </div>
        <div class="row" *ngIf="sentEmail" style="margin-right: 2vw;">
            An e-mail has been sent to {{email}}. Please follow the link to reset your password.
        </div>