import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { LoginDialogComponent } from "src/app/admin/login-dialog/login-dialog.component";
import { MatAccordion } from "@angular/material/expansion";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  //Acount information
  displayName: string = "";

  panelOpenState: boolean = false;
  allExpandState = false;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private titleService: Title
  ) {
    this.titleService.setTitle("SIVVU");
    if (authService.isLoggedIn()) {
      if (this.authService.isGuest()) {
        this.displayName = "Guest";
      } else {
        this.displayName = authService.displayName().split(" ")[0];
      }
    }
  }

  ngOnInit(): void {}

  popup(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(["fits"]);
    } else {
      this.dialog.open(LoginDialogComponent);
    }
  }

  scrollToElement($element: any): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
