import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialogRef<LoginDialogComponent>,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  login(): void {
    this.dialog.close();
    this.router.navigate([`${"/login"}`]);
  }

  guest(): void {
    this.authService.loginAsGuest();
    this.dialog.close();
  }
}
