/*
 * reference: https://material.angular.io/components/table/examples
 * https://stackblitz.com/edit/swimlane-line-chart?embed=1&file=app/app.component.ts
 */
declare let Plotly: any;

import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  Dataset,
  Model,
  Result,
  Bootstrap,
  PyBootstrap,
  ResultCalculation,
} from "src/app/shared/interfaces/data.interface";
import firebase from "firebase/compat/app";
import { ChartType } from "angular-google-charts";
import { Graph3d } from "vis-graph3d/standalone";
import * as math from "mathjs";
import { fcnnls, fcnnlsVector } from "ml-fcnnls";
import { SVD } from "svd-js";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Sort } from "@angular/material/sort";
import * as XLSX from "xlsx";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import {
  arraysAreEqual,
  arrayToFirestoreMap,
  compare,
  mapToMatrix,
} from "src/app/shared/functions/util";
import {
  getProductsCoefficient,
  getAnalyteCoefficient,
} from "src/app/shared/functions/calculation.util";
import {
  Element,
  ChemElement,
  ResultData,
  Row,
  Optimization,
} from "src/app/nav-pages/history-page/history-page.interface";
import { DataService } from "src/app/services/data.service";
import { DeleteHistoryPopupComponent } from "./delete-history-popup/delete-history-popup.component";
import { FormatterService } from "src/app/services/formatter.service";

@Component({
  selector: "app-history-page",
  templateUrl: "./history-page.component.html",
  styleUrls: ["./history-page.component.scss"],
})
export class HistoryPageComponent implements AfterViewInit, OnDestroy {
  myDataset: Dataset | object = {};
  myModel: Model | any = {};
  myResult: Result | object = {};

  amount: number = 1000;
  description: string = "1000 bootstraps with 2 species";
  submittingPayment: boolean = false;

  selectedFile!: File;
  fileContent: any = {};
  fileContentDataset: any = {};
  fileContentModel: any = {};
  fileContentResult: any = {};
  fileContentBootstrap: any = {};

  @ViewChild("datasetGraph") datasetGraph: ElementRef;
  @ViewChild("molarAbsorptivityGraph") molarAbsorptivityGraph!: ElementRef;
  @ViewChild("absAreaGraph") absAreaGraph!: ElementRef;
  @ViewChild("concentrationLineGraph") concentrationLineGraph!: ElementRef;
  @ViewChild("concentrationBarGraph") concentrationBarGraph!: ElementRef;
  @ViewChild("residualsGraph") residualsGraph!: ElementRef;
  @ViewChild("logKGraph") logKGraph: ElementRef;
  @ViewChild("mygraph") myGraph!: ElementRef;

  uploadingFit: boolean = false;

  // Instantiate our graph object.
  container: HTMLElement | null = null;
  Graph3Doptions: any = {};
  Graph3Ddata: Item[] = [];
  showResidualsGraph: boolean = false;
  selectedTab: number = 0;
  xStart: number = 0;
  xEnd: number = 0;
  yStart: number = 0;
  yEnd: number = 0;

  TABLE_DATA: Element[] = [];
  MODEL_DATA: { model_name: string; id: number }[] = [];
  DATASET_DATA: {
    dataset_name: string;
    id: number;
    datasetId: string;
  }[] = [];

  duplicateSets: any[] = [];

  showSpinner: boolean = true;
  drawn: boolean = false;
  loadingDelay: boolean = true;
  loadingSpinner: boolean = false;
  saveSpinner: boolean = false;
  paymentPopup: boolean = false;
  codePopup: boolean = false;
  guestPopup: boolean = false;
  checkoutPopup: boolean = false;
  codeAllowed: boolean = false;
  showPopup: boolean = false;
  uploading: boolean = false;
  uploaded: boolean = false;
  importLoadingPopup: boolean = false;

  userCode: string = "";
  codeErrorMessage: string = "";

  // database fields
  logKOptimal: number[] = [];
  logKInitial: number[] = [];
  logKLocked: boolean[] = [];

  columns: string[] = ["dataset_name", "model_name", "date", "rmsr", "delete"];
  myData = new MatTableDataSource(this.TABLE_DATA);
  chemColumns: string[] = ["chem_reactions", "logKInitial", "logKOptimal"];
  myChemData: any = new MatTableDataSource([]);
  CHEM_DATA: ChemElement[] = [];
  resultsColumns: string[] = ["results", "restricted", "unrestricted"];

  myResults = [
    { results: "RMS Residual", restricted: 0, unrestricted: 0 },
    { results: "Data Reconstruction", restricted: 0, unrestricted: 0 },
    { results: "R<sup>2</sup>", restricted: 0, unrestricted: 0 },
    { results: "Remaining Error", restricted: 0, unrestricted: 0 },
  ];
  myResultsData = new MatTableDataSource(this.myResults);

  resData: ResultData[];

  dataset: number = -1;
  model: number = -1;
  results: any[] = [];
  datasets: any[] = [];
  models: Model[] = [];
  id: number = 0;

  modelsMap: Map<string, Model> = new Map();

  dataset_id: any;
  result_id: any;
  model_id: string = "";
  row_id: number = -1;

  selection = new SelectionModel<Element>(false, []);
  dataGraphLabel: string = "Data Visualization"; // for the label to be either NMR Graph or Raw Absorbance Data Graph
  absorptivityGraphLabel: string = "Molar Absorptivity Data";

  // Raw Absorbance Data graph variables
  absData: number[][];
  // NMR Data graph variables
  isNMR: boolean;
  nmrData: number[][];
  // molar absorbance data
  molarData: number[][];
  molarColumns: string[];
  // Area graph under Molar Absorptivity
  absAreaData: number[][];
  // concentration graph data
  concLineData: number[][];
  concBarData: number[][];
  // bar graph under "residuals" tab
  residBarData: number[][];

  fitCalculationError: boolean = false;
  timeLeftUntilRefresh: number = 5;

  bootNum: number = 50;
  confInt: number[][] = [[]];
  bootProgress: number | null;
  isBootstrapping: boolean = false;
  totalTimeToBootstrapDataset: number = 0;
  bootEstimate: number = -1;
  calculatingOptimization: boolean = false;
  bootType = ChartType.Histogram;
  bootData: any[][][] = [[[]]];
  bootOptions = {
    height: 300,
    hAxis: { slantedText: true, slantedTextAngle: 45 },
    vAxis: {},
    colors: ["#3366cc"],
    legend: { position: "none" },
    histogram: {
      numBucketsRule: "sqrt",
    },
  };

  logK: number[];
  temp = 0;
  concentration: number[][];
  composition: number[][];
  mass_vec: number[][];
  chem_vec: number[][];

  absorbances: number[][];
  mask: number[][];
  wavelengths: number[];
  residuals: number[][];

  pathlength: number = 0;
  chemical_speicies: number[];

  speciesRestricted: number[];
  non_negativity: boolean = true;
  epsilon: number[][];

  chemicalSpeciesANum: number[];
  chemicalSpeciesBNum: number[];
  chemicalSpeciesCNum: number[] | undefined;
  rmsr: number = 0;

  graph: any;

  fit_id_delete = -1;

  deleteButtonClicked = false;
  logKChanged = false;

  optimization: Optimization[];

  durationInSeconds = 1;

  residualsGraphDrawn: boolean = false;
  rawAbsGraphDrawn: boolean = false;
  molarAbsGraphDrawn: boolean = false;
  concentrationGraphDrawn: boolean = false;
  concentrationDataDrawn: boolean = false;
  bootGraphDrawn: boolean = false;
  show_import_popup: boolean = false;
  rerun_dataset: boolean = false;
  rerun_model: boolean = false;
  import_bool: boolean = false;
  duplicate_dataset_name: boolean = false;
  duplicate_dataset: boolean = false;
  duplicate_model_name: boolean = false;
  wrong_extension: boolean = false;
  duplicate_confirmation: boolean = false;

  dataModelDimensionMismatch: boolean = false;

  totalJSON: any = {};

  new_dataset_name_after_duplicate: string = "";
  new_model_name_after_duplicate: string = "";
  newDatasetId: string = "";
  newModelId: string = "";

  compositionError: number[][] = [];

  //For Cloud Bootstrapping
  remainingBoot: number = 100;
  calculatedBoot: number = 0;
  bootPerFunc: number = 0;
  deploys: number = 0;
  calculatingCloudBoot: boolean = false;
  bootIndexes: number[][] = [];
  bootLogKs: number[][] = [];
  chemReactions: string[] = [];
  unsubscribe: Function = () => {};
  runOnBorg: boolean = true;

  constructor(
    private authService: AuthService,
    private formatterService: FormatterService,
    public router: Router,
    public dialog: MatDialog,
    private database: AngularFirestore,
    private successBar: MatSnackBar,
    private titleService: Title,
    private dataService: DataService
  ) {
    this.titleService.setTitle("Fits | SIVVU");
    if (!authService.isLoggedIn()) {
      this.router.navigate(["login"]);
    }
  }

  async ngOnInit() {
    try {
      // check to see if user is allowed code access
      const userCodes = await this.dataService.getUserCodes();
      userCodes.forEach((userCode) => {
        if (typeof userCode.uses === "number" && userCode.uses > 0) {
          this.codeAllowed = true;
        }
      });

      // get configuration to run bootstrapping on Borg
      const bootstrapConfig = await this.dataService.getBootstrapConfig();
      this.runOnBorg = bootstrapConfig.runOnBorg;
    } catch (err) {
      console.log({ err });
    }
  }

  ngAfterViewInit(): void {
    this.container = this.myGraph.nativeElement;
    this.getData();
    window.addEventListener("beforeunload", this.beforeUnload, false);
  }

  ngOnDestroy(): void {
    window.removeEventListener("beforeunload", this.beforeUnload, false);
    this.unsubscribe();
  }

  beforeUnload(e: BeforeUnloadEvent): string | undefined {
    if (!this.calculatingCloudBoot) {
      return undefined;
    }

    let message =
      "Your bootstrap is still being calculated. " +
      "If you leave now, all work thus far will be lost.";

    (e || window.event).returnValue = message;
    return message;
  }

  onResize() {
    if (this.selectedTab !== 0) {
      this.rawAbsGraphDrawn = false;
    }
    if (this.selectedTab !== 1) {
      this.molarAbsGraphDrawn = false;
    }
    if (this.selectedTab !== 2) {
      this.concentrationGraphDrawn = false;
    }
    if (this.selectedTab !== 3) {
      this.residualsGraphDrawn = false;
    }
    if (this.selectedTab !== 4) {
      this.bootGraphDrawn = false;
    }

    if (this.selectedTab === 3) {
      this.drawVisualization();
    }
  }

  cost_popup() {
    if (!this.authService.isGuest()) {
      if (this.bootNum === 100) this.amount = 100;
      else if (this.bootNum === 1000) this.amount = 800;
      else if (this.bootNum === 10000) this.amount = 2000;
      else this.amount = this.bootNum;
      if (this.amount < 50) this.amount = 50;
      this.description =
        this.bootNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        " Bootstraps";
      this.submittingPayment = true;
      this.paymentPopup = true;
      this.checkoutPopup = true;
    } else {
      this.guestPopup = true;
    }
  }

  close_guestPopup() {
    this.guestPopup = false;
  }

  /**
   * Runs after checking out for bootstrap calculation
   * @param status $event parameter
   * note: not refactored due to testing difficulties
   */
  updateStatus(status: boolean) {
    if (status) {
      if (this.results[this.row_id].bootstrapped) {
        this.database.firestore
          .collection("results")
          .doc(this.results[this.row_id].doc_id)
          .get()
          .then((doc) => {
            let data = doc.data() as Result;
            data.date = firebase.firestore.Timestamp.now();

            this.database.firestore
              .collection("results")
              .add(data)
              .then((doc) => {
                this.result_id = doc.id;

                this.database.firestore
                  .collection("resultsmin")
                  .doc(doc.id)
                  .set({
                    user: this.authService.uid(),
                    date: firebase.firestore.Timestamp.now(),
                    datasetName: data.datasetName,
                    datasetId: data.datasetId,
                    modelName: data.modelName,
                    modelId: data.modelId,
                    RMSR: data.RMSR,
                    bootstrapped: true,
                  })
                  .then(() => {
                    this.row_id = this.TABLE_DATA.length;
                    this.results.push({
                      datasetName: data.datasetName,
                      modelName: data.modelName,
                      date: data.date,
                      rmsr: data.RMSR,
                      id: this.TABLE_DATA.length,
                      datasetId: data.datasetId,
                      modelId: data.modelId,
                      doc_id: doc.id,
                      bootstrapped: true,
                    });

                    // update fits table
                    this.TABLE_DATA.push({
                      dataset_name: data.datasetName,
                      model_name: data.modelName,
                      date: new Date(),
                      rmsr: math.round(data.RMSR * 10000000) / 10000000,
                      id: this.TABLE_DATA.length,
                      datasetId: data.datasetId,
                      modelId: data.modelId,
                      resultId: doc.id,
                      bootstrapped: true,
                    });

                    this.results.sort((a, b) => (a.date < b.date ? 1 : -1));
                    this.TABLE_DATA.sort((a, b) => (a.date < b.date ? 1 : -1));
                    this.myData = new MatTableDataSource(this.TABLE_DATA);

                    this.bootData = [[[]]];

                    this.selection.toggle(this.TABLE_DATA[0]);

                    this.result_id = doc.id;

                    this.paymentPopup = false;
                    this.submittingPayment = false;
                    if (this.runOnBorg) {
                      this.runBorgBootstrapping();
                    } else {
                      this.runCloudBootstrapping();
                    }
                  });
              });
          });
      } else {
        this.paymentPopup = false;
        this.submittingPayment = false;
        if (this.runOnBorg) {
          this.runBorgBootstrapping();
        } else {
          this.runCloudBootstrapping();
        }
      }
    }
  }

  /**
   * Retrieve Results data from firestore
   */
  async getData() {
    // Get data from models collection and fill in dropdown menu for models

    // models with user-set logK values are labelled in the field logKChanged
    // this information is not available in resultsmin, so we MAP the docID to the model state
    const modelChangeStatus = new Map();
    const models = await this.dataService.getUserModels();
    this.models = [...models];
    this.models.forEach((model, i) => {
      this.modelsMap.set(model.doc_id, { ...model });
      modelChangeStatus.set(model.doc_id, model.logKChanged);
      const modelName = model.logKChanged ? model.name + " *" : model.name;
      this.MODEL_DATA[i] = { model_name: modelName, id: i };
    });

    // Construct top table of fits
    const resultsMin = await this.dataService.getUserResultsmin();
    this.results = [...resultsMin];
    this.results.forEach((result, i) => {
      let date = result.date.toDate();
      this.TABLE_DATA[i] = {
        dataset_name: result.datasetName,
        // Access model changed status through predefined map
        // if the model logKStatus changed, add * to model name
        model_name: modelChangeStatus.get(result.modelId)
          ? result.modelName + " *"
          : result.modelName,
        date: date,
        rmsr: Math.round(result.RMSR * 10000000) / 10000000,
        id: i,
        datasetId: result.datasetId,
        modelId: result.modelId,
        resultId: result.doc_id,
        bootstrapped: result.bootstrapped,
      };
    });
    this.myData = new MatTableDataSource(this.TABLE_DATA);
    this.showSpinner = false;

    // Construct drop-down menu of datasets
    const datasetsMin = await this.dataService.getUserDatasetsmin();

    this.datasets = [...datasetsMin];

    this.DATASET_DATA = this.datasets.map((dataset, i) => {
      return {
        id: i,
        dataset_name: dataset.name,
        datasetId: dataset.doc_id,
      };
    });
  }

  sortData(sort: Sort) {
    const data = this.TABLE_DATA.slice();
    if (!sort.active || sort.direction === "") {
      this.TABLE_DATA = data;
      return;
    }

    this.TABLE_DATA = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "datasetname":
          return compare(a.dataset_name, b.dataset_name, isAsc);
        case "modelname":
          return compare(a.model_name, b.model_name, isAsc);
        case "date":
          return compare(a.date, b.date, isAsc);
        case "rmsr":
          return compare(a.rmsr, b.rmsr, isAsc);
        default:
          return 0;
      }
    });

    this.myData = new MatTableDataSource(this.TABLE_DATA);
  }

  formatReactions(model: Model, exportMode?: boolean): string[] {
    const {
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      chemicalSpeciesCNum,
      advancedModel,
    } = model;
    // TODO: use the logKOptimized values from the
    const species = this.formatterService.changeSpecFormat(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      chemicalSpeciesCNum
    );
    const reactions = this.formatterService.getSequentialReaction(
      species,
      advancedModel
    );
    const formattedReactions = reactions.map((reaction) =>
      this.formatterService.reaction(reaction, exportMode)
    );
    return formattedReactions;
  }

  /**
   * Show visualizations for existing fits
   * @param row: contains datasetId and modelId
   * @param i
   */
  async onSelectFit(row: Row, i: number): Promise<void> {
    this.drawn = true;
    // show row selection
    this.selection.toggle(row);
    this.row_id = i;

    // get data and model
    const data = await this.dataService.getDataset(row.datasetId);
    // immediatly set NMR status for tab labels
    this.isNMR = data.nmr;

    // get model for selected fit
    this.model_id = row.modelId;
    const model = this.modelsMap.get(row.modelId);
    this.myModel = model;
    this.mass_vec = mapToMatrix(model.massBalance);
    this.chem_vec = mapToMatrix(model.massAction);
    this.logKLocked = model.logKLocked;
    this.speciesRestricted = model.restricted;
    this.logKChanged = model.logKChanged;
    this.logK = model.logKInitial;

    this.chemReactions = this.formatReactions(model);

    // get selected fit
    this.result_id = this.results[i].doc_id;
    const result = await this.dataService.getResult(this.result_id);
    this.fillChemTable(model, result.logKInitial, result.logKOptimal);

    this.graphLogKRMSR(result.optimization);
    this.updateGraphs(result, data, model, row.bootstrapped);

    if (result.bootNum) {
      this.bootNum = result.bootNum;

      this.unsubscribe();

      this.unsubscribe = this.database.firestore
        .collection("results")
        .doc(this.result_id)
        .collection("bootstraps")
        .onSnapshot((snapshot) => {
          this.bootIndexes = [];
          this.bootLogKs = [];
          snapshot.docs.forEach((doc) => {
            let data = doc.data() as Bootstrap;
            if (data.bootIndexes) {
              this.bootIndexes = this.bootIndexes.concat(
                mapToMatrix(data.bootIndexes)
              );
              this.bootLogKs = this.bootLogKs.concat(
                mapToMatrix(data.bootLogKs)
              );
            }
          });

          if (this.bootLogKs.length) {
            this.graphBootData();
            if (this.bootNum <= this.bootLogKs.length) {
              this.calculatingCloudBoot = false;
              this.unsubscribe();
            }
          }
        });
    } else {
      this.bootData = [[[]]];
      this.confInt = [];
    }
  }

  /**
   * Graph dataset data
   * @param data fetched from firebase
   */
  graphData(dataset: Dataset): void {
    // show data graph tab
    this.drawn = true;
    // show graph div container
    this.loadingDelay = true;
    // set NMR status and data tab label
    this.isNMR = dataset.nmr;
    this.dataGraphLabel = this.isNMR
      ? "NMR Data Graph"
      : "Raw Absorbance Data Graph";

    this.epsilon = mapToMatrix(dataset.absorbanceData);
    // if nmr data, graph NMR data--otherwise graph raw absorbance data
    this.isNMR
      ? this.graphNMRData(dataset)
      : this.graphRawAbsorbanceData(dataset);
  }

  /**
   * render raw absorbance dataset graph without making calls to firebase
   * @param datasetId
   * @param data retieved from firebase
   */
  graphRawAbsorbanceData(data: Dataset): void {
    // show raw absorbance data graph
    this.isNMR = false;

    //if (!this.deleteButtonClicked) {
    this.rawAbsGraphDrawn = this.selectedTab !== 0 ? false : true;

    this.myDataset = data;
    this.wavelengths = data.wavelengths;
    this.composition = mapToMatrix(data.reagents);
    this.pathlength = data.pathlength;
    this.absorbances = math.dotDivide(
      mapToMatrix(data.absorbanceData),
      this.pathlength
    ) as number[][];

    let divnum = Math.round(data.wavelengths.length / 200);
    if (divnum < 1) divnum = 1;

    const absGraphData = [];

    this.absData = [];
    for (let i = 0; i < data.absorbanceData.length; i += divnum) {
      this.absData.push([data.wavelengths[i]]);
      for (let j = 0; j < data.absorbanceData[i].values.length; ++j) {
        this.absData[i / divnum].push(data.absorbanceData[i].values[j]);
      }
    }

    const absDataT = math.transpose(this.absData);
    const xAxis = absDataT.slice(0, 1)[0].reverse();
    absDataT.slice(1).forEach((element) => {
      absGraphData.push({
        x: xAxis,
        y: element.reverse(),
        mode: "lines",
      });
    });

    const layout = {
      title: "Raw Absorbance Data",
      autosize: true,
      xaxis: {
        title: "Wavelength (nm)",
      },
      yaxis: {
        title: "Absorbance (ABS/cm)",
      },
      showlegend: false,
    };

    Plotly.newPlot(this.datasetGraph.nativeElement, absGraphData, layout, {
      responsive: true,
    });

    // put each dataset that fits the user login into an array
    // } else {
    //   this.deleteButtonClicked = false;
    // }
  }

  /**
   * render NMR dataset graph without making calls to firebase
   * @param data
   */
  graphNMRData(data: Dataset): void {
    this.isNMR = true;
    // set NMR status to TRUE and get the entire absorbance data
    this.nmrData = [];
    const absData = mapToMatrix(data.absorbanceData);
    this.wavelengths = data.wavelengths;

    // check if user provided area information
    const peakAreaProvided =
      absData.length === Math.max(...this.wavelengths) * 2;

    const layout = {
      title: "NMR Data Graph",
      autosize: true,
      height: 600,
      xaxis: { autorange: "reversed" },
    };

    // if the area information was given, split the absorbance data accordingly
    if (peakAreaProvided) {
      this.wavelengths = this.wavelengths.slice(0, this.wavelengths.length / 2);
      const peakPositions = absData.slice(0, absData.length / 2);
      // re-calculate absorbances if area information included
      this.absorbances = math.dotDivide(
        peakPositions,
        data.pathlength
      ) as number[][];
      const peakAreas = absData.slice(absData.length / 2);
      const yAxis = [...Array(peakPositions[0].length).keys()];

      const nmrGraphData = [];
      peakPositions.forEach((element, i) => {
        nmrGraphData.push({
          x: element,
          y: yAxis,
          name: i + 1,
          mode: "markers",
          marker: {
            size: peakAreas[i].map((n) => n * 5),
          },
        });
      });

      Plotly.newPlot(this.datasetGraph.nativeElement, nmrGraphData, layout);
      // specify the peak area with the peak area data provided
    } else {
      // if peak area was NOT provided, use same size for all the plots
      const yAxis = [...Array(absData[0].length).keys()];
      const nmrGraphData = [];
      absData.forEach((element, i) => {
        nmrGraphData.push({
          x: element,
          y: yAxis,
          name: i + 1,
          mode: "markers",
        });
      });
      Plotly.newPlot(this.datasetGraph.nativeElement, nmrGraphData, layout);
    }
  }

  /**
   * Graph molar absorptivity, concentration, residuals, and logK-RMSR
   * @param i
   */
  updateGraphs(
    result: Result,
    data: Dataset,
    model: Model,
    bootstrapped?: boolean
  ): void {
    this.drawn = true;
    this.rawAbsGraphDrawn = false;
    this.molarAbsGraphDrawn = false;
    this.concentrationGraphDrawn = false;
    this.residualsGraphDrawn = false;
    this.bootGraphDrawn = false;
    // graph the dataset
    this.graphData(data);

    // molar absorptivity data
    this.epsilon = mapToMatrix(result.molarAbsorptivity);
    // concentration data
    this.concentration = mapToMatrix(result.equilibriumConcentrations);
    const concentration = this.concentration.map((arr) => arr.slice());
    const concentrationT = math.transpose(concentration);
    // absorbances data
    const absorbances = math.dotDivide(
      mapToMatrix(data.absorbanceData),
      data.pathlength
    ) as number[][];
    // mask data
    this.mask = mapToMatrix(data.dataMask);
    const mask = this.mask;
    // logK graph arguments
    this.logKOptimal = result.logKOptimal;
    this.logKInitial = result.logKInitial;
    this.logKLocked = result.logKLocked;
    this.chemicalSpeciesANum = result.chemicalSpeciesANum;
    this.chemicalSpeciesBNum = result.chemicalSpeciesBNum;

    // calculate refined epsilon
    const refinedEpsilon = this.calculateRefinedAbsorbingEpsilon(
      concentrationT,
      absorbances,
      mask
    );

    // calculates this.residuals so we don't have to store them in the database
    const rawResiduals = this.calculateResiduals(
      concentrationT,
      absorbances,
      refinedEpsilon
    );

    this.residuals = rawResiduals;
    this.concentration = math.transpose(this.concentration);

    // slice residual matrix in half if NMR: the latter half of NMR matrix indicates peak area
    const wavelengths = data.wavelengths;
    const residuals = this.isNMR
      ? rawResiduals.slice(0, math.max(wavelengths))
      : [...rawResiduals];
    // calculate residuals for absorptivity and residual area graph
    const residBarData = this.calculateResidualBarData(residuals, wavelengths);

    // Molar Absorptivity Graph
    const molarDataT = this.getMolarDataTFromRefinedEpsilon(
      refinedEpsilon,
      wavelengths
    );
    this.molarData = molarDataT;
    this.molarColumns = this.getMolarColumns(
      result.chemicalSpeciesANum,
      result.chemicalSpeciesBNum,
      result.chemicalSpeciesCNum
    );

    const molarColumns = this.molarColumns.map((a) => a);
    const molarAbsorptivityMolarColumns = this.molarColumns.map((a) => a);
    const concentrationMolarColumns = this.molarColumns.map((a) => a).slice(1);

    // if B is unabsorbing, do not graph B's trace
    if (model.restricted[1] === 0) {
      this.molarData.splice(2, 1);
      this.molarColumns.splice(2, 1);
      molarAbsorptivityMolarColumns.splice(2, 1);
    }
    // If C is unabsorbing, do not graph B's trace
    if (model.restricted[2] === 0) {
      this.molarData.splice(2, 1);
      this.molarColumns.splice(2, 1);
      molarAbsorptivityMolarColumns.splice(2, 1);
    }

    // Because prior works incorrectly calculated the URMSR values for fits with unabsorbing B models, we need to calculate the result table values every time a fit is selcted to view
    const { unrestrictedRMSR } = this.calculateResultTableValues(
      result.logKOptimal,
      mapToMatrix(result.molarAbsorptivity),
      mapToMatrix(result.equilibriumConcentrations),
      mapToMatrix(data.absorbanceData)
    );
    this.fillResultTable({
      RMSR: result.RMSR,
      unrestrictedRMSR: unrestrictedRMSR,
      reconstruction: result.reconstruction,
      uReconstruction: result.uReconstruction,
      rSqd: result.rSqd,
      unrestrictedRSqd: result.unrestrictedRSqd,
      imbeddederror: result.imbeddederror,
      Uimbeddederror: result.Uimbeddederror,
    });

    this.graphMolarAbsorptivity(this.molarData, molarAbsorptivityMolarColumns);
    // set class variables for onTabChanged: AbsArea graph
    const absAreaData = [...residBarData];
    const absAreaDataT = math.transpose(absAreaData);
    this.absAreaData = absAreaDataT;
    this.graphAbsArea(this.absAreaData);

    // set class variables for concentration line graph
    this.concLineData = concentration;
    this.graphConcentrationLine(this.concLineData, concentrationMolarColumns);
    // set class variable for concentration bar graph
    this.concBarData = this.calculateConcentrationBarData(residuals);
    this.graphConcentrationBar(this.concBarData);

    // set class variable for residual bar data
    this.residBarData = residBarData;
    this.graphResidualBar(this.residBarData);
  }

  resetResults(): void {
    this.row_id = 0;
    this.myResults = this.myResults.map((row) => ({
      results: row.results,
      restricted: 0,
      unrestricted: 0,
    }));
    this.myResultsData = new MatTableDataSource(this.myResults);
    this.bootData = [[[]]];
    this.bootNum = 50;
  }

  /**
   * calculate results
   * @param nums logKOptimized value
   * @param epsilon
   * @param concentration
   * @param absorbances
   * @returns ResultCalculation
   */
  calculateResultTableValues(
    logKOptimal: number[],
    epsilon: number[][],
    concentration: number[][],
    absorbances: number[][]
  ): ResultCalculation {
    epsilon =
      epsilon.length === concentration.length
        ? math.transpose(epsilon)
        : epsilon;

    this.loadingDelay = true;
    this.loadingSpinner = false;

    this.logKOptimal = logKOptimal;

    // this.updateLogKTable(this.model_id);
    const NoF =
      this.myModel.restricted[1] == 0
        ? this.mass_vec[0].length - 1
        : this.mass_vec[0].length;

    let SValues: number[];
    let vals;
    let u, q, v;
    if (absorbances[0].length < absorbances.length) {
      SValues = SVD(absorbances).q;
      SValues = SValues.sort((a, b) => {
        return b - a;
      });
      vals = SVD(absorbances);
      u = vals.u;
      q = vals.q;
      v = vals.v;
    } else {
      SValues = SVD(math.transpose(absorbances)).q;
      SValues = SValues.sort((a, b) => {
        return b - a;
      });
      vals = SVD(math.transpose(absorbances));
      u = vals.u;
      q = vals.q;
      v = vals.v;
    }
    const concenEpsil = math.transpose(math.multiply(epsilon, concentration));
    const meanratio =
      math.mean(math.mean(concenEpsil)) / math.mean(math.mean(absorbances));
    const calcData = math.divide(concenEpsil, meanratio) as number[][];
    const RSR = math.dotPow(this.residuals, 2) as number[][];
    const SSerr = math.sum(RSR) as number;
    const SStot = math.sum(
      math.dotPow(
        math.subtract(math.mean(math.mean(absorbances)), absorbances),
        2
      ) as number[][]
    ) as number;

    const qSorted = q.sort((a, b) => b - a);
    const qNoF = qSorted
      .slice(0, NoF)
      .concat(new Array(qSorted.length - NoF).fill(0));

    const diagq = math.diag(qNoF);
    let URMS, URSR: number[][];
    if (absorbances[0].length < absorbances.length) {
      URMS = math.subtract(
        absorbances,
        math.multiply(math.multiply(u, diagq), math.transpose(v))
      ) as number[][];

      URSR = math.dotPow(
        math.subtract(
          absorbances,
          math.multiply(math.multiply(u, diagq), math.transpose(v))
        ),
        2
      ) as number[][];
    } else {
      URMS = math.subtract(
        absorbances,
        math.transpose(
          math.multiply(math.multiply(u, diagq), math.transpose(v))
        )
      ) as number[][];

      URSR = math.dotPow(
        math.subtract(
          absorbances,
          math.transpose(
            math.multiply(math.multiply(u, diagq), math.transpose(v))
          )
        ),
        2
      ) as number[][];
    }
    const URsquared = 1 - math.divide(math.sum(URSR), SStot);
    const Rsquared = 1 - SSerr / SStot;
    const imbeddederror =
      this.rmsr * math.sqrt(NoF / (absorbances[0].length - NoF));
    let LoFc;
    if (calcData[0].length < calcData.length) {
      LoFc =
        math.sum(
          SVD(calcData).q.sort((a, b) => {
            return b - a;
          })
        ) / math.sum(SValues);
    } else {
      LoFc =
        math.sum(
          SVD(math.transpose(calcData)).q.sort((a, b) => {
            return b - a;
          })
        ) / math.sum(SValues);
    }
    const LoFm = math.sum(SValues.slice(0, NoF)) / math.sum(SValues);
    const URMSresidual = math.sqrt(math.mean(math.dotPow(URMS, 2)));
    const Uimbeddederror =
      URMSresidual * math.sqrt(NoF / (absorbances[0].length - NoF));

    return {
      RMSR: this.rmsr,
      unrestrictedRMSR: URMSresidual,
      reconstruction: LoFc,
      uReconstruction: LoFm,
      rSqd: Rsquared,
      unrestrictedRSqd: URsquared,
      imbeddederror,
      Uimbeddederror,
    };
  }

  // upload fit to firebase
  async uploadFit(
    calculatedResults: ResultCalculation,
    datasetId: string,
    datasetName: string,
    modelId: string,
    modelName: string,
    logKOptimal: number[],
    refinedEpsilon: number[][]
  ): Promise<void> {
    // construct Result object to upload to firebase
    const result: Result = {
      user: this.authService.uid(),
      date: firebase.firestore.Timestamp.now(),
      datasetName: datasetName,
      datasetId: datasetId,
      modelName: modelName,
      modelId: modelId,
      logKOptimal: logKOptimal,
      logKInitial: this.logKInitial,
      logKLocked: this.logKLocked,
      chemicalSpeciesANum: this.chemicalSpeciesANum,
      chemicalSpeciesBNum: this.chemicalSpeciesBNum,
      chemicalSpeciesCNum: this.chemicalSpeciesCNum
        ? this.chemicalSpeciesCNum
        : null,
      equilibriumConcentrations: arrayToFirestoreMap(this.concentration),
      molarAbsorptivity: arrayToFirestoreMap(refinedEpsilon),
      RMSR: this.rmsr,
      unrestrictedRMSR: calculatedResults.unrestrictedRMSR,
      reconstruction: calculatedResults.reconstruction,
      uReconstruction: calculatedResults.uReconstruction,
      rSqd: calculatedResults.rSqd,
      unrestrictedRSqd: calculatedResults.unrestrictedRSqd,
      imbeddederror: calculatedResults.imbeddederror,
      Uimbeddederror: calculatedResults.Uimbeddederror,
      optimization: this.optimization,
    };

    // add result
    const resultsDoc = await this.dataService.addResult(result);
    this.result_id = resultsDoc.id;

    this.uploadingFit = true;
    // add to resultsmin for caching
    await this.dataService.setResultsmin(resultsDoc.id, {
      user: this.authService.uid(),
      date: firebase.firestore.Timestamp.now(),
      datasetName: datasetName,
      datasetId: datasetId,
      modelName: modelName,
      modelId: modelId,
      RMSR: this.rmsr,
      bootstrapped: false,
    });

    this.uploading = false;
    this.uploaded = true;

    // update UI table
    this.results.push({
      datasetName: datasetName,
      modelName: modelName,
      date: new Date(),
      rmsr: Math.round(this.rmsr * 10000000) / 10000000,
      id: this.TABLE_DATA.length,
      datasetId: datasetId,
      modelId: modelId,
      doc_id: this.result_id,
      bootstrapped: false,
    });

    this.TABLE_DATA.push({
      dataset_name: datasetName,
      model_name: modelName,
      date: new Date(),
      rmsr: Math.round(this.rmsr * 10000000) / 10000000,
      id: this.TABLE_DATA.length,
      datasetId: datasetId,
      modelId: modelId,
      resultId: this.result_id,
      bootstrapped: false,
    });

    this.results.sort((a, b) => (a.date < b.date ? 1 : -1));
    this.TABLE_DATA.sort((a, b) => (a.date < b.date ? 1 : -1));

    // put each dataset that fits the user login into an array
    this.myData = new MatTableDataSource(this.TABLE_DATA);

    this.selection.toggle(this.TABLE_DATA[0]);

    this.resData = [
      {
        results: "RMS Residual",
        restricted: Math.round(this.rmsr * 10000000) / 10000000,
        unrestricted:
          Math.round(calculatedResults.unrestrictedRMSR * 10000000) / 10000000,
      },
      {
        results: "Data Reconstruction",
        restricted:
          Math.round(calculatedResults.reconstruction * 100000) / 100000,
        unrestricted:
          Math.round(calculatedResults.uReconstruction * 100000) / 100000,
      },
      {
        results: "R<sup>2</sup>",
        restricted: Math.round(calculatedResults.rSqd * 100000) / 100000,
        unrestricted:
          Math.round(calculatedResults.unrestrictedRSqd * 100000) / 100000,
      },
      {
        results: "Remaining Error",
        restricted:
          Math.round(calculatedResults.imbeddederror * 100000) / 100000,
        unrestricted:
          Math.round(calculatedResults.Uimbeddederror * 100000) / 100000,
      },
    ];
  }

  fillChemTable(model: Model, logKInit: number[], logKOptim: number[]) {
    const formattedReactions = this.formatReactions(model);

    const chemTable = formattedReactions.map((reaction, i) => {
      return {
        chem_reactions: reaction,
        logKInitial: logKInit[i],
        logKOptimal: Math.round(logKOptim[i] * 100) / 100,
      };
    });
    this.CHEM_DATA = chemTable;
    this.myChemData = chemTable;
  }

  /**
   * Populate results-table
   * @param result Result objct from Firebase
   */
  fillResultTable(result: ResultCalculation): void {
    this.myResults = [
      {
        results: "RMS Residual",
        restricted: Math.round(result.RMSR * 10000000) / 10000000,
        unrestricted: Math.round(result.unrestrictedRMSR * 10000000) / 10000000,
      },
      {
        results: "Data Reconstruction",
        restricted: Math.round(result.reconstruction * 100000) / 100000,
        unrestricted: Math.round(result.uReconstruction * 100000) / 100000,
      },
      {
        results: "R<sup>2</sup>",
        restricted: Math.round(result.rSqd * 100000) / 100000,
        unrestricted: Math.round(result.unrestrictedRSqd * 100000) / 100000,
      },
      {
        results: "Remaining Error",
        restricted: Math.round(result.imbeddederror * 100000) / 100000,
        unrestricted: Math.round(result.Uimbeddederror * 100000) / 100000,
      },
    ];
    // for residual datasheet
    this.resData = this.myResults.map((res) => res);
    // format to mat-table
    this.myResultsData = new MatTableDataSource(this.myResults);
  }

  async onUploadFit(data: { dataset: Dataset; model: Model }) {
    const { dataset, model } = data;
    // display progress bar
    this.calculatingOptimization = true;

    // Values to show in the Uncertainty Estimation tab when fit is bootstrapped
    this.chemReactions = this.formatReactions(model);

    this.loadingDelay = true;
    // show spinner
    this.loadingSpinner = true;
    // hide currently showing graphs
    this.drawn = false;
    // reset results table
    this.resetResults();

    this.myDataset = dataset;
    this.isNMR = dataset.nmr;

    // If NMR data, label the first tab as "NMR data Graph"
    // otherwise, label "Raw Absorbance Data Graph"
    this.dataGraphLabel = this.isNMR
      ? "NMR Data Graph"
      : "Raw Absorbance Data Graph";
    this.epsilon = mapToMatrix(dataset.absorbanceData);
    this.temp = dataset.temperature;
    this.pathlength = dataset.pathlength;
    this.wavelengths = dataset.wavelengths;
    this.composition = mapToMatrix(dataset.reagents);

    this.concentration = [...this.composition];

    this.myModel = { ...model };
    delete this.myModel.doc_id;

    // if the model is a sample given model, set the model id to "sample"
    // otherwise, use the model id generated by Firebase
    this.model_id = model.tag === "sample" ? "Sample Model" : model.doc_id;

    // set up concentration from reagents data
    const start = model.advancedModel ? 3 : 2;
    for (let i = start; i < model.massBalance[0].values.length; ++i) {
      this.concentration.push(new Array(this.concentration[0].length).fill(0));
    }
    const initialConcentration = [...this.concentration];
    // console.log({ initialConcentration });
    this.absorbances = math.dotDivide(
      this.epsilon,
      this.pathlength
    ) as number[][];
    this.mask = mapToMatrix(dataset.dataMask);
    this.mass_vec = mapToMatrix(model.massBalance);
    this.chem_vec = mapToMatrix(model.massAction);
    this.logK = model.logKInitial;
    this.logKInitial = model.logKInitial;
    this.logKOptimal = this.logKInitial;
    this.logKLocked = model.logKLocked;

    this.chemicalSpeciesANum = model.chemicalSpeciesANum;
    this.chemicalSpeciesBNum = model.chemicalSpeciesBNum;
    this.chemicalSpeciesCNum = model.chemicalSpeciesCNum;
    this.speciesRestricted = model.restricted;

    this.rawAbsGraphDrawn = false;
    this.molarAbsGraphDrawn = false;
    this.concentrationGraphDrawn = false;
    this.residualsGraphDrawn = false;
    this.bootGraphDrawn = false;

    // this.updateLogKTable(this.model_id);
    this.residualsGraphDrawn = false;

    if (this.selectedTab === 0) {
      this.rawAbsGraphDrawn = true;
    }

    this.unsubscribe();
    this.drawn = true;
    // fminsearch changes the global epsilon value
    // molar absorptivity from dataset -> (number of species) x (wavelengths)
    // the dimensions for this.epsilon are different before and after this.fminsearch
    const optimizedLogK = await this.fminsearch(model, this.logK);
    // Fill table for Chemical Reactions, logK Initial, logKOptimal
    this.fillChemTable(model, model.logKInitial, optimizedLogK);
    // dimensions: (number of species) x (wavelength)
    const refinedEpsilon = this.epsilon.map((arr) => arr);

    const concentration = this.concentration;
    const absorbances = mapToMatrix(dataset.absorbanceData);

    const calculatedResults = this.calculateResultTableValues(
      optimizedLogK,
      refinedEpsilon,
      concentration,
      absorbances
    );
    // set results table with calculated values
    this.fillResultTable(calculatedResults);
    this.calculatingOptimization = false;

    // upload calculated fit results to firebase
    this.uploadingFit = true;
    await this.uploadFit(
      calculatedResults,
      dataset.doc_id,
      dataset.name,
      this.model_id,
      model.name,
      optimizedLogK,
      refinedEpsilon
    );
    this.uploadingFit = false;

    // graph logK & RMSR optimization graph
    this.graphLogKRMSR(this.optimization);
    // graph raw absorbance or NMR data
    this.graphData(dataset);
  }

  /**
   * Runs when a user uploads a new fit using dataset and model
   */
  // async onUploadFit(): Promise<void> {
  //   this.calculatingOptimization = true;
  //   this.loadingDelay = true;
  //   // show spinner
  //   this.loadingSpinner = true;
  //   // hide currently showing graphs
  //   this.drawn = false;
  //   // reset results table
  //   this.resetResults();

  //   try {
  //     // retrieve dataset from firebase
  //     const datasetDocId = this.datasets[this.dataset].doc_id;
  //     const data = await this.dataService.getDataset(datasetDocId);
  //     const datasetId = data.tag === "sample" ? "Sample Dataset" : datasetDocId;

  //     this.myDataset = data;
  //     this.isNMR = data.nmr;

  //     // If NMR data, label the first tab as "NMR data Graph"
  //     // otherwise, label "Raw Absorbance Data Graph"
  //     this.dataGraphLabel = this.isNMR
  //       ? "NMR Data Graph"
  //       : "Raw Absorbance Data Graph";
  //     this.epsilon = mapToMatrix(data.absorbanceData);
  //     this.temp = data.temperature;
  //     this.pathlength = data.pathlength;
  //     this.wavelengths = data.wavelengths;
  //     this.composition = mapToMatrix(data.reagents);
  //     this.concentration = [...this.composition];

  //     // retrieve model from local cache
  //     const model = { ...this.models[this.model] };
  //     this.myModel = { ...model };
  //     delete this.myModel.doc_id;

  //     // if the model is a sample given model, set the model id to "sample"
  //     // otherwise, use the model id generated by Firebase
  //     this.model_id = model.tag === "sample" ? "Sample Model" : model.doc_id;

  //     for (let i = 2; i < model.massBalance[0].values.length; ++i) {
  //       this.concentration.push(
  //         new Array(this.concentration[0].length).fill(0)
  //       );
  //     }

  //     this.absorbances = math.dotDivide(
  //       this.epsilon,
  //       this.pathlength
  //     ) as number[][];
  //     this.mask = mapToMatrix(data.dataMask);

  //     this.mass_vec = mapToMatrix(model.massBalance);
  //     this.chem_vec = mapToMatrix(model.massAction);

  //     this.logK = model.logKInitial;
  //     this.logKInitial = model.logKInitial;
  //     this.logKOptimal = this.logKInitial;
  //     this.logKLocked = model.logKLocked;

  //     this.chemicalSpeciesANum = model.chemicalSpeciesANum;
  //     this.chemicalSpeciesBNum = model.chemicalSpeciesBNum;

  //     this.speciesRestricted = model.restricted;

  //     this.chemReactions.push(
  //       this.chemicalSpeciesANum[0] +
  //         "A + " +
  //         this.chemicalSpeciesBNum[0] +
  //         "B ⇌ A<sub>" +
  //         this.chemicalSpeciesANum[0] +
  //         "</sub>B<sub>" +
  //         this.chemicalSpeciesBNum[0] +
  //         "</sub>"
  //     );

  //     for (let i = 1; i < this.chemicalSpeciesANum.length; ++i) {
  //       this.chemReactions.push(
  //         getAnalyteCoefficient(
  //           this.chemicalSpeciesANum,
  //           this.chemicalSpeciesBNum,
  //           true,
  //           i
  //         ) +
  //           "A<sub>" +
  //           this.chemicalSpeciesANum[i - 1] +
  //           "</sub>B<sub>" +
  //           this.chemicalSpeciesBNum[i - 1] +
  //           "</sub> + B ⇌ " +
  //           getProductsCoefficient(
  //             this.chemicalSpeciesANum,
  //             this.chemicalSpeciesBNum,
  //             true,
  //             i
  //           ) +
  //           "A<sub>" +
  //           this.chemicalSpeciesANum[i] +
  //           "</sub>B<sub>" +
  //           this.chemicalSpeciesBNum[i] +
  //           "</sub>"
  //       );
  //     }

  //     this.rawAbsGraphDrawn = false;
  //     this.molarAbsGraphDrawn = false;
  //     this.concentrationGraphDrawn = false;
  //     this.residualsGraphDrawn = false;
  //     this.bootGraphDrawn = false;

  //     this.updateLogKTable(this.model_id);
  //     this.residualsGraphDrawn = false;

  //     if (this.selectedTab === 0) {
  //       this.rawAbsGraphDrawn = true;
  //     }

  //     this.unsubscribe();
  //     this.drawn = true;
  //     // fminsearch changes the global epsilon value
  //     // molar absorptivity from dataset -> (number of species) x (wavelengths)
  //     // the dimensions for this.epsilon are different before and after this.fminsearch
  //     const optimizedLogK = await this.fminsearch(model, this.logK);
  //     // dimensions: (number of species) x (wavelength)
  //     const refinedEpsilon = this.epsilon.map((arr) => arr);

  //     const concentration = this.concentration;
  //     const absorbances = mapToMatrix(data.absorbanceData);

  //     const calculatedResults = this.calculateResultTableValues(
  //       optimizedLogK,
  //       refinedEpsilon,
  //       concentration,
  //       absorbances
  //     );
  //     // set results table with calculated values
  //     this.fillResultTable(calculatedResults);
  //     this.calculatingOptimization = false;

  //     // upload calculated fit results to firebase

  //     await this.uploadFit(
  //       calculatedResults,
  //       datasetId,
  //       this.datasets[this.dataset].name,
  //       this.model_id,
  //       model.name,
  //       optimizedLogK,
  //       refinedEpsilon
  //     );
  //     this.uploadingFit = false;

  //     // graph logK & RMSR optimization graph
  //     this.graphLogKRMSR(this.optimization);
  //     // graph raw absorbance or NMR data
  //     this.graphData(data);
  //   } catch (err) {
  //     // if there is an error, notify the user to refresh the page
  //     this.fitCalculationError = true;
  //     this.calculatingOptimization = false;
  //     console.error("Error: ", err);
  //     //Decrement counter: seconds left until refresh
  //     setInterval(() => {
  //       --this.timeLeftUntilRefresh;
  //     }, 1000);
  //     // Refresh the page in 5 seconds
  //     setTimeout(() => {
  //       location.reload();
  //     }, 5000);
  //   }
  // }

  updateLogKTableWithArgs(
    restricted: number[],
    chemicalSpeciesANum: number[],
    chemicalSpeciesBNum: number[],
    logKInitial: number[],
    logKOptimal: number[]
  ): void {
    this.CHEM_DATA = [];
    for (let i = 0; i < this.chemicalSpeciesANum.length; ++i) {
      if (i === 0) {
        if (restricted[0] == -1) {
          this.CHEM_DATA.push({
            chem_reactions:
              getAnalyteCoefficient(
                chemicalSpeciesANum,
                chemicalSpeciesBNum,
                true,
                0
              ) + "<u>A</u> + ",
            logKInitial: logKInitial[i],
            logKOptimal: Math.round(logKOptimal[i] * 100) / 100,
          });
        } else {
          this.CHEM_DATA.push({
            chem_reactions:
              getAnalyteCoefficient(
                chemicalSpeciesANum,
                chemicalSpeciesBNum,
                true,
                0
              ) + "A + ",
            logKInitial: logKInitial[i],
            logKOptimal: Math.round(logKOptimal[i] * 100) / 100,
          });
        }
        restricted[1] == 0
          ? (this.CHEM_DATA[i].chem_reactions += "(B) ⇌ ")
          : (this.CHEM_DATA[i].chem_reactions += "B ⇌ ");
        this.CHEM_DATA[i].chem_reactions +=
          "A<sub>" +
          this.chemicalSpeciesANum[i] +
          "</sub>B<sub>" +
          this.chemicalSpeciesBNum[i] +
          "</sub>";
      } else {
        this.CHEM_DATA.push({
          chem_reactions:
            getAnalyteCoefficient(
              chemicalSpeciesANum,
              chemicalSpeciesBNum,
              true,
              i
            ) + "A",
          logKInitial: logKInitial[i],
          logKOptimal: Math.round(logKOptimal[i] * 100) / 100,
        });
        this.CHEM_DATA[i].chem_reactions +=
          "<sub>" +
          this.chemicalSpeciesANum[i - 1] +
          "</sub>B<sub>" +
          this.chemicalSpeciesBNum[i - 1] +
          "</sub>";
        restricted[1] == 0
          ? (this.CHEM_DATA[i].chem_reactions += " + (B) ⇌ ")
          : (this.CHEM_DATA[i].chem_reactions += " + B ⇌ ");
        this.CHEM_DATA[i].chem_reactions += getProductsCoefficient(
          chemicalSpeciesANum,
          chemicalSpeciesBNum,
          true,
          i
        );
        this.CHEM_DATA[i].chem_reactions +=
          "A <sub>" +
          chemicalSpeciesANum[i] +
          "</sub>B<sub>" +
          chemicalSpeciesBNum[i] +
          "</sub>";
      }
    }

    this.myChemData = new MatTableDataSource(this.CHEM_DATA);
  }

  /**
   * Chemical equations for the logK Table after fit
   * @param modelId model to use to construct chemical equation
   */
  updateLogKTable(modelId: string) {
    const modelData = this.modelsMap.get(modelId);
    this.CHEM_DATA = [];
    for (let i = 0; i < this.chemicalSpeciesANum.length; ++i) {
      if (i === 0) {
        if (modelData.restricted[0] == -1) {
          this.CHEM_DATA.push({
            chem_reactions:
              getAnalyteCoefficient(
                this.chemicalSpeciesANum,
                this.chemicalSpeciesBNum,
                true,
                0
              ) + "<u>A</u> + ",
            logKInitial: this.logKInitial[i],
            logKOptimal: Math.round(this.logKOptimal[i] * 100) / 100,
          });
        } else {
          this.CHEM_DATA.push({
            chem_reactions:
              getAnalyteCoefficient(
                this.chemicalSpeciesANum,
                this.chemicalSpeciesBNum,
                true,
                0
              ) + "A + ",
            logKInitial: this.logKInitial[i],
            logKOptimal: Math.round(this.logKOptimal[i] * 100) / 100,
          });
        }
        modelData.restricted[1] == 0
          ? (this.CHEM_DATA[i].chem_reactions += "(B) ⇌ ")
          : (this.CHEM_DATA[i].chem_reactions += "B ⇌ ");
        this.CHEM_DATA[i].chem_reactions +=
          "A<sub>" +
          this.chemicalSpeciesANum[i] +
          "</sub>B<sub>" +
          this.chemicalSpeciesBNum[i] +
          "</sub>";
      } else {
        this.CHEM_DATA.push({
          chem_reactions:
            getAnalyteCoefficient(
              this.chemicalSpeciesANum,
              this.chemicalSpeciesBNum,
              true,
              i
            ) + "A",
          logKInitial: this.logKInitial[i],
          logKOptimal: Math.round(this.logKOptimal[i] * 100) / 100,
        });
        this.CHEM_DATA[i].chem_reactions +=
          "<sub>" +
          this.chemicalSpeciesANum[i - 1] +
          "</sub>B<sub>" +
          this.chemicalSpeciesBNum[i - 1] +
          "</sub>";
        modelData.restricted[1] == 0
          ? (this.CHEM_DATA[i].chem_reactions += " + (B) ⇌ ")
          : (this.CHEM_DATA[i].chem_reactions += " + B ⇌ ");
        this.CHEM_DATA[i].chem_reactions += getProductsCoefficient(
          this.chemicalSpeciesANum,
          this.chemicalSpeciesBNum,
          true,
          i
        );
        this.CHEM_DATA[i].chem_reactions +=
          "A <sub>" +
          this.chemicalSpeciesANum[i] +
          "</sub>B<sub>" +
          this.chemicalSpeciesBNum[i] +
          "</sub>";
      }
    }

    // convert to table form to be used by material design
    this.myChemData = new MatTableDataSource(this.CHEM_DATA);
  }

  deletePopup(id: number): void {
    this.deleteButtonClicked = true;
    this.fit_id_delete = id;
    this.showPopup = true;
  }

  onDelete(i: number): void {
    const resultToDelete = this.results[i];

    let date: Date;
    try {
      // if date is a Firebase timestamp, convert it to JS formatted date
      date = resultToDelete.date.toDate();
    } catch (err) {
      // otherwise, use the date as is
      date = resultToDelete.date;
    }
    const dialogRef = this.dialog.open(DeleteHistoryPopupComponent, {
      width: "40%",
      data: {
        date: date,
        dataset: resultToDelete.datasetName,
        model: resultToDelete.modelName,
        bootstrapped: resultToDelete.bootstrapped,
        rid: resultToDelete.doc_id,
      },
    });
    dialogRef.afterClosed().subscribe((status) => {
      if (status) {
        this.deleteFitFromTable(i);
      }
    });
  }

  closePopup(): void {
    this.showPopup = false;
  }

  closePayPopup(): void {
    this.paymentPopup = false;
    this.submittingPayment = false;
  }

  // delete result functionality, called when trash can pressed
  async deleteFitFromTable(id: number): Promise<void> {
    // reset the information below the data when a fit is deleted
    this.showPopup = false;
    this.drawn = false;

    if (this.container) this.container.innerHTML = "";
    this.residuals = [];
    this.logKOptimal = [];
    this.logKInitial = [];
    this.myChemData = new MatTableDataSource([]);
    this.myResults = [
      { results: "RMS Residual", restricted: 0, unrestricted: 0 },
      { results: "Data Reconstruction", restricted: 0, unrestricted: 0 },
      { results: "R<sup>2</sup>", restricted: 0, unrestricted: 0 },
      { results: "Remaining Error", restricted: 0, unrestricted: 0 },
    ];
    this.myResultsData = new MatTableDataSource(this.myResults);

    // for (let i = 0; i < this.TABLE_DATA.length; i++) {
    // if (this.TABLE_DATA[i].id == this.fit_id_delete) {
    // let result_id = this.results[id].doc_id;

    //deletes the minimized version of the result from the database
    // this.database.firestore
    //   .collection("resultsmin")
    //   .doc(result_id)
    //   .delete()
    //   .then(() => {
    // remove from array
    this.results.splice(id, 1);
    this.TABLE_DATA.splice(id, 1);
    this.myData = new MatTableDataSource(this.TABLE_DATA);

    this.successBar.openFromComponent(SuccessBarFitsComponent, {
      duration: this.durationInSeconds * 1000,
    });
    // })
    // .catch((error) => {
    //   console.error("Error: ", error);
    // });
    this.deleteButtonClicked = false;
    // break;
    // }
    //}
  }

  /**
   * Graph data for second tab if not NMR data
   * @param molarDataT molar absorptivity data
   * @param molarColumns ["Wavelengths", "A", "B", ...] if B is absorbing ["Wavelengths", "A", ...] if B is unabsorbing
   * @param unabsorbing B is unabsorbing if value is 0
   */
  graphMolarAbsorptivity(molarDataT: number[][], molarColumns: string[]): void {
    if (this.isNMR) {
      this.absorptivityGraphLabel = "Species NMR Spectra";
      const epsilonArea = this.getEpsilonArea(molarDataT);
      this.graphNmrMolarAbsorptivity(molarDataT, epsilonArea, molarColumns);
    } else {
      this.graphRawMolarAbsorptivity(molarDataT, molarColumns);
    }
  }

  /**
   * Graph data for second tab if NMR data
   * @param molarDataT molar absorptivity data
   * @param molarColumns ["Wavelengths", "A", "B", ...] if B is absorbing ["Wavelengths", "A", ...] if B is unabsorbing
   * @param unabsorbing B is unabsorbing if value is 0
   */
  graphNmrMolarAbsorptivity(
    molarT: number[][],
    epsilonArea: number[][],
    molarColumns: string[]
  ): void {
    // Data in plotly format
    const nmrAbsorptivityData = [];
    // construct the data for plotly using existing molar absorption data
    molarT.slice(1).forEach((element, i) => {
      nmrAbsorptivityData.push({
        x: element,
        y: new Array(element.length).fill(i),
        type: "scatter",
        name: molarColumns[i + 1],
        line: {
          width: 3,
        },
        marker: {
          // if area infomration provided, use it to adjust points
          // otherwise, use a marker size of 10 as a default
          size: epsilonArea
            ? epsilonArea[i]
            : new Array(element.length).fill(10),
        },
      });
    });

    // specify graph information
    const layout = {
      autosize: false,
      width: 1000,
      title: "Species NMR Spectra",
      xaxis: {
        title: "ppm (\u03B4)",
        autorange: "reversed",
        dtick: 1.0,
        showline: true,
      },
      yaxis: {
        showticklabels: false,
        dtick: 1.0,
        showgrid: false,
        zeroline: false,
      },
    };

    // render the plotly chart
    Plotly.newPlot(
      this.molarAbsorptivityGraph.nativeElement,
      nmrAbsorptivityData,
      layout
    );
  }

  graphRawMolarAbsorptivity(
    molarDataT: number[][],
    molarColumns: string[]
  ): void {
    console.log({ molarColumns });
    const molarAbsorptivityData: any[] = [];
    const xAxis = molarDataT.slice(0, 1)[0].reverse();
    molarDataT.slice(1).forEach((element, i) => {
      molarAbsorptivityData.push({
        x: xAxis,
        y: element.reverse(),
        mode: "lines",
        name: molarColumns[i + 1],
      });
    });

    const layout = {
      title: "Molar Absorptivity Data",
      autosize: true,
      xaxis: {
        title: "Wavelength (nm)",
      },
      yaxis: {
        title: "Molar Absorptivity (ABS/M/cm)",
      },
      hovermode: "x unified",
    };

    Plotly.newPlot(
      this.molarAbsorptivityGraph.nativeElement,
      molarAbsorptivityData,
      layout
    );
  }

  graphConcentrationLine(
    concentrationData: number[][],
    molarColumns: string[]
  ) {
    console.log(concentrationData);
    // construct the concentration chart
    const xAxis = [...Array(concentrationData[0].length).keys()];
    const data = concentrationData.map((concentration, i) => {
      return {
        x: xAxis,
        y: concentration,
        type: "scatter",
        name: molarColumns[i],
      };
    });

    const layout = {
      title: "Concentration Data",
      autosize: true,
      xaxis: {
        title: "Solution Number",
      },
      yaxis: {
        title: "Concentration (M)",
        exponentformat: "e",
      },
      encoding: "utf-8",
    };

    Plotly.newPlot(this.concentrationLineGraph.nativeElement, data, layout);
  }

  graphConcentrationBar = (concentrationBarData: number[][]) => {
    this.concentrationGraphDrawn = true;
    const data = [
      {
        x: concentrationBarData[0],
        y: concentrationBarData[1],
        type: "bar",
      },
    ];
    const layout = {
      autosize: true,
      xaxis: {
        title: "Solution Number",
      },
      yaxis: {
        title: "RMS Residual",
        exponentformat: "e",
      },
    };
    Plotly.newPlot(this.concentrationBarGraph.nativeElement, data, layout);
  };

  /**
   * Graph area graph under "Molar Absorptivity Data" tab
   * @param absAreaDataT Transposed absArea data
   */
  graphAbsArea = (absAreaDataT: number[][]) => {
    const data = [
      {
        x: absAreaDataT[0].reverse(),
        y: absAreaDataT[1].reverse(),
        stackgroup: "one",
      },
    ];
    const layout = {
      autosize: true,
      xaxis: {
        title: "Wavelength (nm)",
      },
      yaxis: {
        title: "RMS Residual",
        exponentformat: "e",
      },
    };
    Plotly.newPlot(this.absAreaGraph.nativeElement, data, layout);
  };

  /**
   *
   * @param chemicalSpeciesANum an array of the composition of As in the model
   * @param chemicalSpeciesBNum an array of the composition of Bs in the model
   * @returns molar columns (e.g. [A, B, A1B1, A1B2])
   */
  getMolarColumns = (
    chemicalSpeciesANum: number[],
    chemicalSpeciesBNum: number[],
    chemicalSpeciesCNum?: number[] | undefined
  ): string[] => {
    const molarColumns = ["Wavelength", "A", "B"];

    if (chemicalSpeciesCNum) molarColumns.push("C");

    chemicalSpeciesANum.forEach((_: number, i: number) => {
      const element = {
        A: chemicalSpeciesANum[i],
        B: chemicalSpeciesBNum[i],
        C: chemicalSpeciesCNum ? chemicalSpeciesCNum[i] : 0,
      };
      molarColumns.push(this.formatterService.spec(element));
    });

    // for (let i = 0; i < chemicalSpeciesANum.length; ++i) {
    //   molarColumns.push(
    //     "A" + chemicalSpeciesANum[i] + "B" + chemicalSpeciesBNum[i]
    //   );
    // }
    return molarColumns;
  };

  /**
   * Check if peak areas were provided
   * Area is provided if wavelengths are repeated
   * e.g. a wavelength for a nmr graph with area info would be [1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 6]
   * @param wavelengths
   * @returns whether the peak areas were provided for NMR data
   */
  peakAreaProvided(wavelengths: number[]) {
    return Math.max(...wavelengths) * 2 === wavelengths.length;
  }

  /**
   * NMR peak area data
   * @param epsilon absorptivity data
   * @returns peak areas for NMR data, if area was given
   */
  getEpsilonArea(epsilon: number[][]) {
    const epsilonT = math.transpose(epsilon);

    const eArea = math
      .transpose(epsilonT.slice(this.wavelengths.length / 2))
      .map((elementAreas) =>
        [...elementAreas].map((area) => (area === 0 ? 10 : area * 3))
      );
    return eArea;
  }

  /**
   * Calculates molar data from raw molar absorptivity data from database
   * @param epsilon
   * @returns molarData
   */
  getMolarDataTFromRefinedEpsilon(
    refinedEpsilon: number[][],
    wavelengths: number[]
  ) {
    const molarData: number[][] = [];
    if (this.isNMR && this.peakAreaProvided(wavelengths)) {
      const epsilonT = math.transpose(refinedEpsilon);
      const ePeak = math.transpose(
        epsilonT.slice(0, this.wavelengths.length / 2)
      );

      for (let j = 0; j < ePeak[0].length; ++j) {
        molarData.push([this.wavelengths[j]]);
        for (let i = 0; i < ePeak.length; ++i) {
          molarData[j].push(ePeak[i][j]);
        }
      }
    } else {
      for (let j = 0; j < refinedEpsilon[0].length; ++j) {
        molarData.push([this.wavelengths[j]]);
        for (let i = 0; i < refinedEpsilon.length; ++i) {
          molarData[j].push(refinedEpsilon[i][j]);
        }
      }
    }
    return math.transpose(molarData);
  }

  /**
   * Graph logK // RMSR // Iteration graph
   * @param optimizations
   */
  graphLogKRMSR(optimizations: Optimization[]) {
    // Get x axis: # of Iterations == length of the array
    const xAxis = [...Array(optimizations[0].data.length).keys()];
    // Get RMSR data
    const RMSRData = optimizations.find(
      (optimization) => optimization.name === "RMSR"
    );
    // Get logK Data
    const logKData = optimizations.filter(
      (optimization) => optimization.name !== "RMSR"
    );
    // const data = [];
    // logKData.forEach((logK) => {
    //   data.push({
    //     x: xAxis,
    //     y: logK.data,
    //     name: logK.name,
    //     mode: "lines",
    //   });
    // });
    const data: {
      x: number[];
      y: number[];
      name: string;
      mode: string;
      yaxis?: string;
    }[] = logKData.map((logK) => {
      return {
        x: xAxis,
        y: logK.data,
        name: logK.name,
        mode: "lines",
      };
    });

    data.push({
      x: xAxis,
      y: RMSRData.data,
      name: RMSRData.name,
      yaxis: "y2",
      mode: "lines",
    });

    const layout = {
      legend: { orientation: "h" },
      xaxis: { title: "Iteration #" },
      yaxis: { title: "logK" },
      yaxis2: {
        title: "RMSR",
        overlaying: "y",
        side: "right",
      },
      height: 280,
      autosize: true,
      margin: {
        t: 50,
        b: 50,
      },
    };
    Plotly.newPlot(this.logKGraph.nativeElement, data, layout, {
      responsive: true,
    });
  }

  /**
   * Graphs residual bar data
   * @param residuals residual bar data
   */
  graphResidualBar(residuals: number[][]) {
    const residualsT = math.transpose(residuals);
    const data = [
      {
        x: residualsT[0].reverse(),
        y: residualsT[1].reverse(),
        stackgroup: "one",
      },
    ];
    const layout = {
      autosize: true,
      xaxis: {
        title: "Wavelength (nm)",
      },
      yaxis: {
        title: "RMS Residual",
        exponentformat: "e",
      },
    };
    Plotly.newPlot(this.residualsGraph.nativeElement, data, layout);
  }
  /**
   * Utility calculation function for residual bar graph data and absorbance area graph data
   * @param residuals
   * @param wavelengths
   * @returns calculated residual bar/absorbance area graph data
   */
  calculateResidualBarData(
    residuals: number[][],
    wavelengths: number[]
  ): number[][] {
    const residBarData = [];
    for (let r = 0; r < residuals.length; ++r) {
      residBarData.push([
        wavelengths[r],
        math.sqrt(math.mean(math.dotPow(residuals[r], 2))),
      ]);
    }
    return residBarData;
  }

  /**
   * Utility calculation function for concentration bar graph data
   * @param residuals
   * @returns
   */
  calculateConcentrationBarData(residuals: number[][]) {
    const residualsT = math.transpose(residuals);
    const concentrationBarData = [];
    for (let r = 0; r < residualsT.length; ++r) {
      concentrationBarData.push([
        r,
        math.sqrt(math.mean(math.dotPow(residualsT[r], 2))),
      ]);
    }
    return math.transpose(concentrationBarData);
  }

  // search bar functionality
  searchFor(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.myData.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Optimizes current SIVVU datasets by using the given logK values.
   * changes this.concentration
   * @param logK array of logK values
   * @returns RMS residuals
   */
  iterate(logK: number[]) {
    // optimize concentration
    this.concentration = this.optimizeConcentration(logK);
    return this.getRMSR();
  }

  /**
   * Optimizes current concentration value stored in SIVVU dataset
   * @param logK Array of logK values used to optimize concentration data
   * @returns concentration optimized with logK
   */
  optimizeConcentration(logK: number[]): number[][] {
    const lnK = math.multiply(logK, 2.3) as number[];
    let concentration = this.concentration;
    const rawComposition = math.transpose(this.composition);
    const converge = 1e-20;
    const maxconc: number[] = [];
    rawComposition.forEach((x) => {
      maxconc.push(math.sum(x) * 2);
    });
    const mb = this.mass_vec;
    // create a deep copy of this.chem_vec so the original is not affected
    const ma = Array.from(this.chem_vec);
    const R = this.chem_vec.length;
    const B = this.mass_vec.length;

    const zeroesarray: number[] = new Array(ma[0].length).fill(0);
    for (let i = 0; i < B; ++i) {
      ma.push(zeroesarray);
    }

    let K: number[] = new Array(B).fill(0);

    //Sets any 0 composition values to be 1*10^-30
    const composition = Array.from(rawComposition, (row) =>
      Array.from(row, (cell) => cell || 1e-30)
    );

    const compareArray: number[] = [0, Infinity, NaN];

    for (let p = 0; p < concentration.length; ++p) {
      let temp_conc = concentration[p];
      if (
        temp_conc.some((elem) => {
          return compareArray.includes(elem);
        })
      ) {
        temp_conc = new Array(mb[0].length).fill(maxconc[p] + 1e-99);
      }

      //Actual work loop beginning
      let fit2 = 1;
      while (fit2 > converge) {
        for (let i = 0; i < B; ++i) {
          let mbtempconcproduct = math.dotMultiply(
            mb[i],
            temp_conc
          ) as number[];
          //sums up the elements from the mbtempconcproduct array, then divides
          //each element in mbtempconcproduct by the summed value
          ma[R + i] = math.dotDivide(
            mbtempconcproduct,
            math.sum(mbtempconcproduct)
          ) as number[];

          K[i] =
            math.prod(math.dotPow(ma[R + i], ma[R + i])) /
            math.prod(math.dotPow(mb[i], ma[R + i]));
          lnK[R + i] = math.log(K[i]) + math.log(composition[p][i]);
        }

        //performs a linear solve on the problem A * x = b, where A is our
        //mass action matrix, and b is lnK
        //as number[][] is used to tell the compiler to treat the function return
        //as a matrix of numbers
        const lnC = math.lusolve(ma, lnK) as number[][];
        let newconcentration: number[] = [];
        lnC.forEach((row: number[]) => {
          //e^lnC
          newconcentration.push(math.e ** row[0]);
        });
        //Makes sure that none of the concentrations are above our max concentration
        //If they are, we make a list of which ones need to be replaced and change
        //their value to a random non-zero value.
        if (math.max(newconcentration) > maxconc[p]) {
          const replace_indices = [];
          for (let i = 0; i < newconcentration.length; ++i) {
            if (newconcentration[i] > maxconc[p]) {
              replace_indices.push(i);
            }
          }
          replace_indices.forEach((x) => {
            newconcentration[x] = maxconc[p] * Math.random() + 1e-20;
          });
        }
        //Makes sure that none of the concentrations are below 0 (non-negative)
        //If they are, we make a list of which ones need to be replaced and change
        //their value to a random non-zero value.
        if (math.min(newconcentration) <= 0) {
          const replace_indices = [];
          for (let i = 0; i < newconcentration.length; ++i) {
            if (newconcentration[i] <= 0) {
              replace_indices.push(i);
            }
          }
          let minconc = math.exp(
            -100 *
              math.max(
                lnK.map((x: number) => {
                  return Math.abs(x);
                })
              )
          );
          replace_indices.forEach((i) => {
            newconcentration[i] = minconc * Math.random() + 1e-20;
          });
        }

        fit2 = math.sum(
          math.dotPow(math.subtract(newconcentration, temp_conc), 2) as number[]
        );
        temp_conc = newconcentration;
        // Work loop end
      }
      if ((this.myDataset as Dataset).nmr) {
        // When NMR is true, scales equilibrium concetrations relative to first titration component (A).
        temp_conc = math.dotDivide(temp_conc, composition[p][0]) as number[];
      }
      concentration[p] = temp_conc;
      // Main loop end
    }
    return concentration;
  }

  /**
   * Computes rmsr (root mean squared residuals) of the data
   * Updates this.epsilon & this.residuals
   * @param concentration
   * @param absorbances
   * @param mask
   * @returns calculated RMSR
   */
  getRMSR(): number {
    let concentration = this.concentration;
    let absorbances = this.absorbances;
    let mask: number[][] = this.mask;
    const refinedEpsilon = this.calculateRefinedAbsorbingEpsilon(
      concentration,
      absorbances,
      mask
    );
    // update global epsilon with refined value
    this.epsilon = refinedEpsilon;
    const concentrationEpsilon = math.transpose(
      math.multiply(concentration, refinedEpsilon)
    );

    this.residuals = math.subtract(
      absorbances,
      concentrationEpsilon
    ) as number[][];

    if (mask.length > 1) {
      //multiply masked matrix to residuals matrix
      const residuals_masked = math.dotMultiply(
        mask,
        this.residuals
      ) as number[][];
      const ratio = math.sqrt(
        math.prod(math.size(residuals_masked)) / math.sum(mask)
      );
      const rmsr = math.sqrt(math.mean(math.dotPow(residuals_masked, 2)));
      return rmsr * ratio;
    } else {
      const ratio =
        mask.length > 0
          ? math.sqrt(math.prod(math.size(this.residuals)) / math.sum(mask))
          : 1;
      const rmsr = math.sqrt(math.mean(math.dotPow(this.residuals, 2)));
      return rmsr * ratio;
    }
  }

  calculateResiduals(
    concentrationT: number[][],
    absorbances: number[][],
    refinedEpsilon: number[][]
  ) {
    // this.epsilon = refinedEpsilon;
    const concenEpsil = math.transpose(
      math.multiply(concentrationT, refinedEpsilon)
    );

    return math.subtract(absorbances, concenEpsil) as number[][];
  }

  /**
   * Finds epsilons from given concentration and absorbances.
   * @param concentration concentration data where data of only absorbing and refining species are left
   * @param absorbances absorbances data where data of only absorbing and refining species are left.
   * @param mask locations within the data that should be ignored.
   * @returns a list containing epsilon data for each species.
   */
  calculateRefinedAbsorbingEpsilon = (
    concentration: number[][],
    absorbances: number[][],
    mask: number[][]
  ) => {
    // create a copy of the original concentration
    const myconc = concentration.map((arr) => arr.slice());
    if (
      this.speciesRestricted.includes(-1) ||
      this.speciesRestricted.includes(0)
    ) {
      let count = 0;
      for (let i = 0; i < myconc[0].length; ++i) {
        if (this.speciesRestricted[count] === 0) {
          //removes the row in the concentration matrix corresponding to count
          for (let j = 0; j < myconc.length; ++j) {
            //removes the ith element
            myconc[j].splice(i, 1);
          }
          --i;
        } else if (this.speciesRestricted[count] === -1) {
          if (count === 0 && this.composition[0].indexOf(0) !== -1) {
            //removes the row in the concentration matrix corresponding to count
            for (let j = 0; j < myconc.length; ++j) {
              //removes the ith element
              myconc[j].splice(i, 1);
            }
            --i;
          } else if (count === 1) {
            console.error(
              "Error, no species found with B having a concentration of 0"
            );
          }
        }
        ++count;
      }
    }
    const refinedEpsilon = this.calculateFcnnlsMaskEpsilon(
      myconc,
      absorbances,
      mask
    );

    if (
      this.speciesRestricted.includes(-1) ||
      this.speciesRestricted.includes(0)
    ) {
      for (let i = 0; i < this.speciesRestricted.length; ++i) {
        if (this.speciesRestricted[i] === 0) {
          //Inserts a row of 0s into the result matrix
          refinedEpsilon.splice(
            i,
            0,
            new Array(refinedEpsilon[0].length).fill(0)
          );
        } else if (this.speciesRestricted[i] === -1) {
          let zeroindex = this.composition[1].indexOf(0);
          if (i === 1 && zeroindex !== -1) {
            refinedEpsilon.splice(i, 0, []);
            for (let j = 0; j < absorbances.length; ++j) {
              refinedEpsilon[i].push(absorbances[j][zeroindex]);
            }
          }
        }
      }
    }
    return refinedEpsilon;
  };

  calculateFcnnlsMaskEpsilon(
    myconc: number[][],
    absorbances: number[][],
    mask: number[][]
  ): number[][] {
    if (mask.length > 0) {
      const tempconc = myconc;
      const tempabs = absorbances;
      const tempmask = mask;

      const maskedEpsilon: number[][] = [];
      for (let i = 0; i < tempabs.length; ++i) {
        const iabs = [...tempabs[i]]; //take the ith row of the absorbance
        const imask = tempmask[i]; //take the ith row of the mask
        const iconc = [...tempconc]; //take the entire concentration matrix

        for (let j = imask.length - 1; j >= 0; --j) {
          if (imask[j] === 0) {
            iabs.splice(j, 1); //delete abs entries that correspond to imask values of zero.
            iconc.splice(j, 1); //delete conc columns that correspond to imask values of zero.
          }
        }
        const xresult = math.multiply(math.transpose(iconc), iconc);
        const yresult = math.multiply(math.transpose(iconc), iabs);

        const ytemp = math.reshape(yresult, [1, yresult.length]);
        const lineresult = fcnnlsVector(xresult, ytemp[0]);
        maskedEpsilon.push(lineresult);
      }
      const fcnnlsMasked = math.transpose(maskedEpsilon);
      return fcnnlsMasked;
    } else {
      const absorbancesT = math.transpose(absorbances);
      const fcnnlsUnmasked = fcnnls(myconc, absorbancesT);
      const fcnnlsUnmaskedArray = fcnnlsUnmasked.to2DArray();
      return fcnnlsUnmaskedArray;
    }
  }

  calculateRMSR(
    concenEpsilon: number[][],
    absorbances: number[][],
    mask: number[][]
  ): number {
    const residuals = math.subtract(absorbances, concenEpsilon) as number[][];
    let maskedResiduals = [];
    if (mask.length > 1) {
      //multiply masked matrix to residuals matrix
      maskedResiduals = math.dotMultiply(mask, residuals) as number[][];
    } else {
      maskedResiduals = residuals;
    }

    let initialRmsr = math.sqrt(math.mean(math.dotPow(maskedResiduals, 2)));
    if (mask.length > 0) {
      const ratio = math.sqrt(
        math.prod(math.size(maskedResiduals)) / math.sum(mask)
      );
      return initialRmsr * ratio;
    } else {
      return initialRmsr;
    }
  }

  async fminsearch(model: Model, logKs: number[]): Promise<number[]> {
    // transpose a copy of the concentration to maintain shape
    console.log([...this.concentration], "at the beginning of fminsearch");
    this.concentration = math.transpose(this.concentration);
    const initialRefinedEpsilon = this.calculateRefinedAbsorbingEpsilon(
      this.concentration,
      this.absorbances,
      this.mask
    );
    const initialConcenEpsilon = math.transpose(
      math.multiply(this.concentration, initialRefinedEpsilon)
    );

    const absorbances = this.absorbances.map((arr) => arr.slice());

    const mask = this.mask.map((arr) => arr.slice());
    const initialRmsr = this.calculateRMSR(
      initialConcenEpsilon,
      absorbances,
      mask
    );

    this.optimization = [
      {
        name: "RMSR",
        data: [initialRmsr],
      },
    ];

    for (let i = 0; i < logKs.length; ++i) {
      this.optimization.push({
        name: "logK" + (i + 1).toString(),
        data: [logKs[i]],
      });
    }

    let P0: number[] = [...logKs];
    let P1: number[] = [...logKs];
    const n = P0.length;
    let step: number[] = this.logKLocked.map((val) => {
      return val ? 0 : 1;
    });
    // optimize concentration based on P0
    // calculate residual
    // calculate RMS residual
    this.rmsr = this.iterate(P0);
    let lastRMSR = this.rmsr;
    while (true) {
      for (let j = 0; j < n; ++j) {
        if (!this.logKLocked[j]) {
          P1 = [...P0];
          P1[j] += step[j];
          // optimize concentration based on P1
          // calculate residual
          // calculate RMS residual
          let rmsr = this.iterate(P1);
          if (rmsr < this.rmsr) {
            step[j] *= 1.2; // then go a little faster
            P0 = [...P1];
            this.rmsr = rmsr;
          } else {
            step[j] *= -0.5; // otherwise reverse and go slower
          }
        }
      }
      // add rmsr value to RMSR optimization data
      this.optimization[0].data.push(this.rmsr);
      // add logK value to logK optimization data
      for (let j = 0; j < logKs.length; ++j) {
        this.optimization[j + 1].data.push(P0[j]);
      }
      //this.graphLogKRMSR(this.optimization);

      //end loop if change in logK values and RMSR is minimal
      if (
        Math.abs(lastRMSR - this.rmsr) < 1e-5 &&
        step.every((e) => Math.abs(e) < 0.005)
      ) {
        break;
      }
      lastRMSR = this.rmsr;
    }

    // optimize concentration based on P0
    // calculate residual
    // calculate RMS residual
    this.iterate(P0);

    await delay(1);

    const refinedEpsilon = this.calculateRefinedAbsorbingEpsilon(
      this.concentration,
      this.absorbances,
      this.mask
    );

    // calculation for molar absorptivity graph
    const molarDataT = this.getMolarDataTFromRefinedEpsilon(
      refinedEpsilon,
      this.wavelengths
    );
    // set global molarData so it can be graphed in onTabChanged
    this.molarData = molarDataT;
    this.molarColumns = this.getMolarColumns(
      this.chemicalSpeciesANum,
      this.chemicalSpeciesBNum,
      this.chemicalSpeciesCNum
    );

    const molarAbsorptivityMolarColumns = this.molarColumns.map((a) => a);
    const concentrationMolarColumns = this.molarColumns.map((a) => a).slice(1);

    // If B is unabsorbing, do not graph B's trace
    if (this.myModel.restricted[1] === 0) {
      this.molarData.splice(2, 1);
      this.molarColumns.splice(2, 1);
      molarAbsorptivityMolarColumns.splice(2, 1);
    }

    // If C is unabsorbing, do not graph C's trace
    if (this.myModel.restricted[2] === 0) {
      this.molarData.splice(2, 1);
      this.molarColumns.splice(2, 1);
      molarAbsorptivityMolarColumns.splice(2, 1);
    }

    this.graphMolarAbsorptivity(this.molarData, molarAbsorptivityMolarColumns);
    // transpose optimized concentration
    this.concentration = math.transpose(this.concentration);

    // graph 3D residual graph
    this.xStart = this.wavelengths[0];
    this.xEnd = this.wavelengths[this.wavelengths.length - 1];
    if (this.xStart > this.xEnd) {
      [this.xStart, this.xEnd] = [this.xEnd, this.xStart];
    }
    this.yStart = 0;
    this.yEnd = this.absorbances[0].length - 1;
    if (this.selectedTab === 3) {
      this.drawVisualization();
    }

    this.residBarData = [];
    for (let r = 0; r < this.residuals.length; ++r) {
      this.residBarData.push([
        this.wavelengths[r],
        math.sqrt(math.mean(math.dotPow(this.residuals[r], 2))),
      ]);
    }
    this.residBarData = [...this.residBarData];
    // this.absAreaData = [...this.residBarData];
    // const absAreaDataT = math.transpose(this.absAreaData);
    const absAreaData = [...this.residBarData];
    this.absAreaData = math.transpose(absAreaData);

    this.graphResidualBar(this.residBarData);
    this.graphAbsArea(this.absAreaData);

    // create deep copy of this.residuals
    const residuals = this.residuals.map((arr) => arr.slice());
    const residualsT = math.transpose(residuals);

    this.graphConcentrationLine(this.concentration, concentrationMolarColumns);
    this.concBarData = [];
    for (let r = 0; r < residualsT.length; ++r) {
      this.concBarData.push([
        r,
        math.sqrt(math.mean(math.dotPow(residualsT[r], 2))),
      ]);
    }
    const concBarDataT = math.transpose(this.concBarData);
    this.graphConcentrationBar(concBarDataT);

    return P0;
  }

  /**
   * Graph data according to tab selection
   * @param event
   */
  onTabChanged(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
    if (this.drawn) {
      // Raw Absorbance or NMR data Tab
      if (this.selectedTab === 0 && !this.rawAbsGraphDrawn) {
        this.rawAbsGraphDrawn = true;
        this.absData = [...this.absData];
      }
      // Molar Absorptivity Tab
      else if (this.selectedTab === 1 && !this.molarAbsGraphDrawn) {
        this.molarAbsGraphDrawn = true;
        this.absAreaData = [...this.absAreaData];

        this.graphMolarAbsorptivity(this.molarData, this.molarColumns);
        this.graphAbsArea(this.absAreaData);
      }
      // Concentration Data Tab
      // else if (this.selectedTab === 2 && !this.concentrationGraphDrawn) {
      //   this.concentrationGraphDrawn = true;
      //   this.graphConcentrationLine(this.concLineData);
      //   this.graphConcentrationBar(this.concBarData);
      // }
      // Residuals Tab
      else if (
        this.selectedTab === 3 &&
        !this.residualsGraphDrawn &&
        this.residuals.length
      ) {
        this.reset();
        this.graphResidualBar(this.residBarData);
      } else if (this.selectedTab === 4 && !this.bootGraphDrawn) {
        this.bootGraphDrawn = true;
        for (let i = 0; i < this.bootData.length; ++i) {
          this.bootData[i] = [...this.bootData[i]];
        }
      }
    }
  }

  // Called when the Visualization API is loaded.
  drawVisualization(
    solutions?: [number, number],
    wavelengthsList?: [number, number]
  ) {
    var counter = 0;
    let xStart = 0;
    let xEnd = 0;
    let yStart = 0;
    let yEnd = 0;

    //Deep copy residuals here then reverse
    let wavelengths = [...this.wavelengths];
    let residuals = this.residuals.map((arr) => arr.slice());

    if (this.wavelengths[0] > this.wavelengths[this.wavelengths.length - 1]) {
      wavelengths.reverse();
      residuals.reverse();
    }

    //Allows users to restrict which wavelengths will be shown in the residuals graph
    if (wavelengthsList) {
      for (let i = 0; i < wavelengths.length; ++i) {
        if (wavelengths[i] >= wavelengthsList[0]) {
          xStart = i;
          break;
        }
      }
      xEnd = wavelengthsList[1];
    } else {
      xEnd = wavelengths[wavelengths.length - 1];
    }

    //Allows users to restrict which solutions will be shown in the residuals graph
    if (solutions) {
      if (solutions[0] >= 0) {
        yStart = Math.round(solutions[0]);
      }
      if (solutions[1] > this.absorbances[0].length) {
        yEnd = this.absorbances[0].length;
      } else {
        yEnd = Math.round(solutions[1]);
      }
    } else {
      yEnd = this.absorbances[0].length;
    }

    let divnum = Math.round(this.wavelengths.length / 200);
    if (divnum < 1) divnum = 1;

    this.Graph3Ddata = [];
    //TODO: Do we want all residuals or just every third?
    for (
      let x = xStart;
      x < wavelengths.length && wavelengths[x] <= xEnd;
      x += divnum /*++x*/
    ) {
      for (let y = yStart; y < yEnd; ++y) {
        this.Graph3Ddata.push({
          id: counter++,
          x: wavelengths[x],
          y: y,
          z: residuals[x][y],
          style: residuals[x][y],
        });
      }
    }

    let width = "1000px";
    let height = "800px";

    if (this.container) {
      if (this.container.offsetWidth < 1000) {
        width = this.container.offsetWidth + "px";
        height = this.container.offsetWidth * (4 / 5) + "px";
      }

      let vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      if (vw < this.container.offsetWidth) {
        if (vw * 0.9 < 1000) {
          width = vw * 0.9 + "px";
          height = vw * 0.72 + "px";
        }
      }

      if (this.container.offsetWidth < 1000 && vw * 0.85 >= 1000) {
        width = "1000px";
        height = "800px";
      }

      let cameraPosition = {
        horizontal: 1.0,
        vertical: 0.5,
        distance: 2.0, //increase to start the camera more zoomed out
      };

      if (this.graph) {
        cameraPosition.horizontal = this.graph.camera.armRotation.horizontal;
        cameraPosition.vertical = this.graph.camera.armRotation.vertical;
        cameraPosition.distance = this.graph.camera.armLength;
      }

      let max = Math.max(
        Math.abs(math.max(this.residuals)),
        Math.abs(math.min(this.residuals))
      );
      let zMax = max;
      let zMin = -max;

      if (
        vw < this.container.offsetWidth ||
        this.container.offsetWidth < 1000 ||
        !this.residualsGraphDrawn
      ) {
        // specify options
        this.Graph3Doptions = {
          width: width,
          height: height,
          style: "surface", //surface or grid, grid makes it see through
          showPerspective: true,
          showGrid: true,
          showShadow: false,
          keepAspectRatio: false,
          verticalRatio: 0.5,
          zoomable: true,
          tooltip: true,
          xLabel: "Wavelength (nm)",
          yLabel: "Solution Number",
          zLabel: "Residual\t\t\t\t",
          axisFontSize: 25,
          cameraPosition: cameraPosition,
          zMin: zMin,
          zMax: zMax,
          //zStep: math.round(zMax/3,2),
        };
        this.graph = {
          ...new Graph3d(this.container, this.Graph3Ddata, this.Graph3Doptions),
        };
      }
      this.residualsGraphDrawn = true;
    }
  }

  //Redraws the 3D residuals graph using user set restrictions
  redraw(): void {
    this.residualsGraphDrawn = false;
    this.drawVisualization([this.yStart, this.yEnd], [this.xStart, this.xEnd]);
  }

  //Resets user restrictions on residuals graph back to their defaults
  reset(): void {
    this.residualsGraphDrawn = false;

    let wavelengths = [...this.wavelengths];

    if (this.wavelengths[0] > this.wavelengths[this.wavelengths.length - 1]) {
      wavelengths.reverse();
    }
    this.xStart = wavelengths[0];
    this.xEnd = wavelengths[wavelengths.length - 1];
    this.yStart = 0;
    this.yEnd = this.absorbances[0].length - 1;
    this.drawVisualization();
  }

  /**
   * creates molar absorbance export sheet
   */
  create_abs_datasheet() {
    const { chemicalSpeciesANum, chemicalSpeciesBNum, chemicalSpeciesCNum } =
      this.myModel;
    const species = this.formatterService.changeSpecFormat(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      chemicalSpeciesCNum
    );
    const speciesStrArray = species.map((spec) =>
      this.formatterService.spec(spec, true)
    );

    let abs_export_matrix = [];

    // dataset section of datasheet
    abs_export_matrix.push([
      "Dataset Name:",
      null,
      this.results[this.row_id].datasetName,
    ]);

    // model section of datasheet
    abs_export_matrix.push([
      "Model Name:",
      null,
      this.results[this.row_id].modelName,
    ]);

    // newline
    abs_export_matrix.push([]);

    abs_export_matrix.push([null].concat(speciesStrArray));

    let conc_array: any[][] = [this.wavelengths];
    this.epsilon.forEach((eps) => {
      conc_array.push(eps);
    });
    conc_array = math.transpose(conc_array);
    conc_array.forEach((array) => {
      abs_export_matrix.push(array);
    });

    let fileName =
      this.results[this.row_id].datasetName +
      "_" +
      this.results[this.row_id].modelName +
      "_molar_absorbance.xlsx";

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(abs_export_matrix);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  /**
   * creates concentration export sheet
   */
  create_conc_datasheet() {
    let species_strArray: any[] = [];

    // get array of species
    const { chemicalSpeciesANum, chemicalSpeciesBNum, chemicalSpeciesCNum } =
      this.myModel;
    const species = this.formatterService.changeSpecFormat(
      chemicalSpeciesANum,
      chemicalSpeciesBNum,
      chemicalSpeciesCNum
    );
    const speciesStrArray = species.map((spec) =>
      this.formatterService.spec(spec, true)
    );
    // get array of reactions
    const formattedReactions = this.formatReactions(this.myModel, true);
    console.log({ formattedReactions });
    // create array of species
    for (let j = 0; j < this.chemicalSpeciesANum.length; j++) {
      species_strArray[j] =
        "A" + this.chemicalSpeciesANum[j] + "B" + this.chemicalSpeciesBNum[j];
    }

    let conc_export_matrix = [];

    // dataset section of datasheet
    conc_export_matrix.push([
      "Dataset Name:",
      null,
      this.results[this.row_id].datasetName,
    ]);

    // model section of datasheet
    conc_export_matrix.push([
      "Model Name:",
      null,
      this.results[this.row_id].modelName,
    ]);

    conc_export_matrix.push([]);
    conc_export_matrix.push([
      "Chemical Reactions",
      null,
      "LogK Initial",
      null,
      "LogK Optimal",
    ]);

    const equations = formattedReactions.map((reaction, i) => {
      return {
        chem_reactions: reaction,
        logKInitial: this.logKInitial[i],
        logKOptimal: Math.round(this.logKOptimal[i] * 10) / 10,
      };
    });

    equations.forEach((equation) => {
      conc_export_matrix.push([
        [equation.chem_reactions],
        [],
        [equation.logKInitial],
        [],
        [equation.logKOptimal],
      ]);
    });

    // newline
    conc_export_matrix.push([]);
    conc_export_matrix.push([...Array(this.concentration[0].length).keys()]);

    speciesStrArray.forEach((spec, i) => {
      conc_export_matrix.push([spec].concat(this.concentration[i].map(String)));
    });

    let fileName =
      this.results[this.row_id].datasetName +
      "_" +
      this.results[this.row_id].modelName +
      "_concentration.xlsx";

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(conc_export_matrix);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  /**
   * creates residual export sheet
   */
  create_residual_datasheet() {
    let residual_export_matrix = [];

    // dataset section of datasheet
    residual_export_matrix.push([
      "Dataset Name: ",
      null,
      this.results[this.row_id].datasetName,
    ]);

    // model section of datasheet
    residual_export_matrix.push([
      "Model Name: ",
      null,
      this.results[this.row_id].modelName,
    ]);

    // newline
    residual_export_matrix.push([]);

    residual_export_matrix.push([
      "Result",
      null,
      "Restricted",
      null,
      "Unrestricted",
    ]);

    for (let i = 0; i < this.resData.length; i++) {
      residual_export_matrix.push([
        this.resData[i].results,
        null,
        this.resData[i].restricted,
        null,
        this.resData[i].unrestricted,
      ]);
    }

    // newline
    residual_export_matrix.push([]);
    for (let i = 0; i < this.residuals.length; i++) {
      residual_export_matrix.push(this.residuals[i]);
    }

    let fileName =
      this.results[this.row_id].datasetName +
      "_" +
      this.results[this.row_id].modelName +
      "_residuals.xlsx";

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(residual_export_matrix);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, fileName);
  }

  /**
   * Creates bootstrap export sheet
   */
  create_bootstrap_datasheet() {
    let boot_export_matrix: any[] = [];
    let fileName: string = "";

    // dataset section of datasheet
    boot_export_matrix.push([
      "Dataset Name: ",
      null,
      this.results[this.row_id].datasetName,
    ]);

    // model section of datasheet
    boot_export_matrix.push([
      "Model Name: ",
      null,
      this.results[this.row_id].modelName,
    ]);

    // newline
    boot_export_matrix.push([]);

    let started = true;

    // add bootstrap data to datasheet
    this.database.firestore
      .collection("results")
      .doc(this.result_id)
      .collection("bootstraps")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data: any = doc.data();

          if (!started) {
            boot_export_matrix.push(
              ["Solution #s"]
                .concat(new Array(data.bootIndexes[0].values.length))
                .concat(["log(k)s"])
            );
            started = false;
          }

          for (let i = 0; i < data.bootIndexes.length; i++) {
            boot_export_matrix.push(
              data.bootIndexes[i].values
                .concat([[]])
                .concat(data.bootLogKs[i].values)
            );
          }
        });

        fileName =
          this.results[this.row_id].datasetName +
          "_" +
          this.results[this.row_id].modelName +
          "_bootstrap.xlsx";

        // generate worksheet
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(boot_export_matrix);

        // generate workbook and add the worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // save to file
        XLSX.writeFile(wb, fileName);
      });
  }

  startTime = Date.now();

  /**
   * Add fit to pyBootstrap collection and trigger Borg calculation
   */
  async runBorgBootstrapping() {
    this.calculatingCloudBoot = true;
    this.remainingBoot = this.bootNum;
    this.concentration = [...this.composition];
    this.startTime = Date.now();

    // Estimate the total time it takes to bootstrap one dataset using borg bootstrapping
    this.totalTimeToBootstrapDataset =
      7 + (this.bootNum * this.chemicalSpeciesANum.length ** 1.3) / 100;

    // Time per one bootstrap
    // timePerBootstrap = totalTimeToBootstrapDataset / this.bootNum;

    this.bootProgress = this.totalTimeToBootstrapDataset; // decrement the progress bar

    for (let i = 2; i < this.mass_vec[0].length; ++i) {
      this.concentration.push(new Array(this.concentration[0].length).fill(0));
    }

    this.bootPerFunc = this.bootNum;
    this.deploys = 1;

    // add fit to pyBootstrap collection triggers Borg server
    const bootstrapJob: PyBootstrap = {
      bootNum: this.bootNum,
      id: this.result_id,
      composition: arrayToFirestoreMap(this.composition),
      mass_vec: arrayToFirestoreMap(this.mass_vec),
      chem_vec: arrayToFirestoreMap(this.chem_vec),
      absorbances: arrayToFirestoreMap(this.absorbances),
      mask:
        this.mask.length && this.mask[0].length
          ? arrayToFirestoreMap(this.mask)
          : 0,
      restricted: this.speciesRestricted,
      logK: this.logKOptimal,
      logKLocked: this.logKLocked,
      time: firebase.firestore.Timestamp.now(),
    };
    await this.dataService.setPyBootstrap(this.result_id, bootstrapJob);

    // listen for changes in the "results/<result id>/bootstraps" document while Borg is calculating bootstraps
    this.unsubscribe = this.database.firestore
      .collection("results")
      .doc(this.result_id)
      .collection("bootstraps")
      .onSnapshot(async (snapshot) => {
        this.bootIndexes = [];
        this.bootLogKs = [];

        snapshot.docs.forEach((doc) => {
          let data = doc.data() as Bootstrap;

          if (data.bootIndexes) {
            this.bootIndexes = this.bootIndexes.concat(
              mapToMatrix(data.bootIndexes)
            );
            this.bootLogKs = this.bootLogKs.concat(mapToMatrix(data.bootLogKs));
          }
        });
        this.isBootstrapping = true;

        const decrementProgressBar = setInterval(() => {
          // this.totalTimeToBootstrapDataset === estimated bootstrap
          // if the bootstrap is done, reset the bootProgress
          if (this.bootData[0][0].length) {
            clearInterval(decrementProgressBar);
            this.bootProgress = null;
            return;
          }
          // decrement the estimated time every second
          --this.bootProgress;
        }, 1000);

        // once Borg is done with calculation, visualize the data
        if (this.bootLogKs.length) {
          this.graphBootData();
          this.isBootstrapping = false;

          await this.dataService.modifyResultBoot(this.result_id, {
            bootNum: this.bootNum,
          });

          await this.dataService.modifyResultMinBoot(this.result_id, {
            bootstrapped: true,
          });

          // modify UI to bold bootstrapped fits
          this.TABLE_DATA[this.row_id].bootstrapped = true;
          this.myData = new MatTableDataSource(this.TABLE_DATA);

          if (this.bootNum <= this.bootLogKs.length) {
            this.calculatingCloudBoot = false;
            // stop listening to changes in "results" collection
            this.unsubscribe();
          }
        }
      });

    this.calculatingCloudBoot = false;
    this.isBootstrapping = false;
  }

  async runCloudBootstrapping() {
    this.startTime = Date.now();
    this.calculatingCloudBoot = true;
    this.remainingBoot = this.bootNum;
    // this.bootProgress = 0;
    this.concentration = [...this.composition];

    for (let i = 2; i < this.mass_vec[0].length; ++i) {
      this.concentration.push(new Array(this.concentration[0].length).fill(0));
    }

    //this.bootPerFunc = Math.ceil(Math.pow(2, 2 * (4 - Math.ceil(this.logKLocked.filter(val => !val).length / 3))));
    this.bootPerFunc =
      3 *
      Math.ceil(
        0.75 * Math.pow(2, 6 - this.logKLocked.filter((val) => !val).length)
      );
    this.deploys = Math.ceil(
      10 *
        Math.pow(
          2,
          4 - Math.ceil(this.logKLocked.filter((val) => !val).length / 2)
        )
    );
    if (
      this.rmsr >= 0.001 &&
      this.logKLocked.filter((val) => !val).length >= 3
    ) {
      this.bootPerFunc = Math.ceil(this.bootPerFunc / 3); //divide bootPerFunc by 3 if RMSR is high.
    }
    let totalDeploys = Math.ceil(this.remainingBoot / this.bootPerFunc);
    if (this.deploys > totalDeploys) {
      this.deploys = totalDeploys;
    }

    this.deploys = Math.min(totalDeploys, 25);

    await this.dataService.modifyResultBoot(this.result_id, {
      bootNum: this.bootNum,
      remainingBoot: Math.max(
        this.bootNum - this.deploys * this.bootPerFunc,
        0
      ),
    });

    await this.dataService.modifyResultMinBoot(this.result_id, {
      bootstrapped: true,
    });

    // modify UI to bold bootstrapped fits
    this.TABLE_DATA[this.row_id].bootstrapped = true;
    this.myData = new MatTableDataSource(this.TABLE_DATA);

    let remainder = this.bootNum;
    for (let i = 0; i < this.deploys; ++i) {
      let totalBoots =
        this.bootPerFunc *
        Math.ceil(remainder / this.bootPerFunc / (this.deploys - i));
      if (totalBoots > remainder) totalBoots = remainder;
      this.deployBootstrap(i % Math.ceil(this.bootNum / 500), totalBoots);
      remainder -= totalBoots;
    }

    this.unsubscribe = this.database.firestore
      .collection("results")
      .doc(this.result_id)
      .collection("bootstraps")
      .onSnapshot(async (snapshot) => {
        this.bootIndexes = [];
        this.bootLogKs = [];
        snapshot.docs.forEach((doc) => {
          let data = doc.data() as Bootstrap;
          if (data.bootIndexes) {
            this.bootIndexes = this.bootIndexes.concat(
              mapToMatrix(data.bootIndexes)
            );
            this.bootLogKs = this.bootLogKs.concat(mapToMatrix(data.bootLogKs));
          }
        });

        this.bootProgress = (this.bootLogKs.length / this.bootNum) * 100;

        if (this.bootLogKs.length) {
          this.graphBootData();
          if (this.bootNum <= this.bootLogKs.length) {
            this.calculatingCloudBoot = false;
            this.unsubscribe();
          }
        }
      });

    this.calculatingCloudBoot = false;
  }

  deployBootstrap(index: number, totalBoots: number) {
    if (this.remainingBoot > this.bootPerFunc) {
      this.remainingBoot -= this.bootPerFunc;
    } else {
      this.bootPerFunc = this.remainingBoot;
      this.remainingBoot = 0;
    }

    let bootNum = this.bootPerFunc;

    this.database.firestore.collection("newBootstrap").add({
      bootNum: bootNum,
      totalBoot: totalBoots,
      id: this.result_id,
      composition: arrayToFirestoreMap(this.composition),
      mass_vec: arrayToFirestoreMap(this.mass_vec),
      chem_vec: arrayToFirestoreMap(this.chem_vec),
      absorbances: arrayToFirestoreMap(this.absorbances),
      mask:
        this.mask.length && this.mask[0].length
          ? arrayToFirestoreMap(this.mask)
          : 0,
      restricted: this.speciesRestricted,
      logK: this.logKOptimal,
      logKLocked: this.logKLocked,
      index: index,
      time: firebase.firestore.Timestamp.now(),
    });
  }

  /**
   * Graph Bootstrap data for tab "Uncertainty Estimation"
   */
  graphBootData() {
    const arrayByLogK = math.transpose(this.bootLogKs);

    this.confInt = [];

    for (let i = 0; i < arrayByLogK.length; ++i) {
      const logKSort = [...arrayByLogK[i]].sort((a, b) => {
        return a > b ? 1 : -1;
      });
      const interval = logKSort.splice(
        Math.round(logKSort.length * 0.025),
        logKSort.length - Math.round(logKSort.length * 0.025) * 2
      );

      this.confInt.push([
        math.round(interval[0], 3),
        math.round(interval[interval.length - 1], 3),
        math.round(this.logKOptimal[i], 3),
      ]);
    }

    this.bootData = [];

    this.bootLogKs[0].forEach(() => {
      this.bootData.push([["Bootstrap #", "logK"]]);
    });
    for (let i = 0; i < arrayByLogK.length; ++i) {
      for (let j = 0; j < arrayByLogK[i].length; ++j) {
        this.bootData[i].push([j + 1, arrayByLogK[i][j]]);
      }
    }
    this.bootLogKs = [];
    this.bootIndexes = [];
  }

  // create export sheet of whole fit data
  export_fit() {
    let datasetNameExport: string = "";
    let modelNameExport: string = "";

    let bootArray: any[] = [];
    this.database.firestore
      .collection("results")
      .doc(this.result_id)
      .collection("bootstraps")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          bootArray.push({ ...doc.data() });
        });
        this.totalJSON["bootstrap"] = bootArray;
      });

    this.database.firestore
      .collection("results")
      .doc(this.result_id)
      .get()
      .then((doc) => {
        if (doc && doc.data()) {
          let result = doc.data() as Result;

          result.user = "";
          result.datasetId =
            result.datasetId === "Sample Dataset" ? "Sample Dataset" : "";
          result.modelId =
            result.modelId === "Sample Model" ? "Sample Model" : "";

          // make data into JSON
          this.totalJSON["result"] = result;

          let model = { ...this.myModel } as Model;
          model.user = "";
          modelNameExport = model.name;
          this.totalJSON["model"] = model;

          let dataset = { ...this.myDataset } as Dataset;
          dataset.user = "";
          datasetNameExport = dataset.name;
          this.totalJSON["dataset"] = dataset;

          let totalString = JSON.stringify(this.totalJSON);
          let fileName = datasetNameExport + "_" + modelNameExport + ".sivvu";

          // download JSON
          // reference: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
          var dataStr =
            "data:text/json;charset=utf-8," + encodeURIComponent(totalString);
          var downloadAnchorNode = document.createElement("a");
          downloadAnchorNode.setAttribute("href", dataStr);
          downloadAnchorNode.setAttribute("download", fileName);
          document.body.appendChild(downloadAnchorNode); // required for firefox
          downloadAnchorNode.click();
          downloadAnchorNode.remove();
        }
      })
      .catch((error: any) => {
        console.error("error: ", error);
      });
  }

  // reference: https://stackoverflow.com/questions/54971238/upload-json-file-using-angular-6
  onFileChanged(event: any) {
    this.selectedFile = event.target.files[0];
    if (
      this.selectedFile.name.slice(this.selectedFile.name.length - 6) !=
      ".sivvu"
    ) {
      this.wrong_extension = true;
    } else {
      const fileReader = new FileReader();
      fileReader.readAsText(this.selectedFile, "UTF-8");
      fileReader.onload = () => {
        if (fileReader.result) {
          this.fileContent = JSON.parse(fileReader.result as string);
          this.fileContentDataset = this.fileContent.dataset;
          this.fileContentModel = this.fileContent.model;
          this.fileContentResult = this.fileContent.result;
          this.fileContentBootstrap = this.fileContent.bootstrap;
        }
      };
      fileReader.onerror = (error) => {
        console.error(error);
      };
      this.show_import_popup = true;
    }
  }

  tryAgain() {
    this.show_import_popup = true;
  }

  close_extension_popup() {
    this.wrong_extension = false;
  }

  import_fit() {
    this.checkForDuplicateSet();
  }

  checkForDuplicateSet() {
    // calculate max B:A here
    let bToA: number = 0;
    for (
      let i = 0;
      i < this.fileContentDataset.reagents[0].values.length;
      i++
    ) {
      let quotient: number = 0;
      if (this.fileContentDataset.reagents[0].values[i] != 0) {
        quotient =
          this.fileContentDataset.reagents[1].values[i] /
          this.fileContentDataset.reagents[0].values[i];
        if (quotient > bToA) {
          bToA = Math.round(quotient * 100) / 100;
        }
      }
    }

    this.database.firestore
      .collection("datasetsmin")
      .where("user", "==", this.authService.uid())
      .get()
      .then((querySnapshot) => {
        this.duplicateSets = [];

        querySnapshot.docs.forEach((doc) => {
          let data = doc.data();
          this.duplicateSets.push({ name: data.name });

          let dimensions =
            this.fileContentDataset.absorbanceData.length +
            " x " +
            this.fileContentDataset.absorbanceData[0].values.length;
          if (
            this.fileContentDataset.temperature == data.temperature &&
            this.fileContentDataset.reagents[0].name == data.analyte &&
            this.fileContentDataset.reagents[1].name == data.titrant &&
            dimensions == data.dimensions &&
            bToA == data.highestBtoA
          ) {
            this.duplicate_confirmation = true;
            this.duplicate_dataset = true;
            this.show_import_popup = false;
            this.newDatasetId = doc.id;
          }
        });
        if (!this.duplicate_confirmation) {
          this.import_dataset();
        } else {
          console.log("duplicate");
        }
      });
    // .catch((error) => {
    //   console.error("error: ", error)
    // })
  }

  import_dataset() {
    if (this.rerun_dataset) {
      this.fileContentDataset.name = this.new_dataset_name_after_duplicate;
    }

    this.show_import_popup = false;
    this.duplicate_dataset_name = false;

    // check for duplicate dataset names
    for (let i = 0; i < this.duplicateSets.length; i++) {
      if (this.fileContentDataset.name == this.duplicateSets[i].name) {
        this.show_import_popup = false;
        this.duplicate_dataset_name = true;
        break;
      }
    }

    if (!this.duplicate_dataset_name) {
      let highestBtoA: number = 0;

      // calculate highest B to A ratio
      for (
        let i = 0;
        i < this.fileContentDataset.reagents[0].values.length;
        i++
      ) {
        let quotient: number = 0;
        if (this.fileContentDataset.reagents[0].values[i] != 0) {
          quotient =
            this.fileContentDataset.reagents[1].values[i] /
            this.fileContentDataset.reagents[0].values[i];
          if (quotient > highestBtoA) {
            highestBtoA = Math.round(quotient * 100) / 100;
          }
        }
      }

      // add to dataset and datasetmin collection of database
      this.database.firestore
        .collection("datasets")
        .add({
          name: this.fileContentDataset.name,
          user: this.authService.uid(),
          comment: this.fileContentDataset.comment,
          spectrometer: this.fileContentDataset.spectrometer,
          location: this.fileContentDataset.location,
          uploadDate: firebase.firestore.Timestamp.now(),
          experimentDate: new firebase.firestore.Timestamp(
            this.fileContentDataset.experimentDate.seconds,
            0
          ),
          pathlength: this.fileContentDataset.pathlength, //in cm [value (1)]
          temperature: this.fileContentDataset.temperature, //in K [value (298)]
          solvent: this.fileContentDataset.solvent, //ex: Water
          reagents: this.fileContentDataset.reagents,
          wavelengths: this.fileContentDataset.wavelengths,
          absorbanceData: this.fileContentDataset.absorbanceData,
          dataMask: this.fileContentDataset.dataMask,
          tag: "user",
        })
        .then((doc) => {
          this.newDatasetId = doc.id;
          this.database.firestore
            .collection("datasetsmin")
            .doc(doc.id)
            .set({
              name: this.fileContentDataset.name,
              user: this.authService.uid(),
              uploadDate: firebase.firestore.Timestamp.now(),
              temperature: this.fileContentDataset.temperature, //in K [value (298)]
              analyte: this.fileContentDataset.reagents[0].name,
              titrant: this.fileContentDataset.reagents[1].name,
              dimensions:
                this.fileContentDataset.absorbanceData.length +
                " x " +
                this.fileContentDataset.absorbanceData[0].values.length,
              highestBtoA: highestBtoA,
            })
            .then(() => {
              this.uploading = false;
              this.uploaded = true;
            });
          this.datasets.push({
            doc_id: this.newDatasetId,
            name: this.fileContentDataset.name,
          });
          this.DATASET_DATA.push({
            dataset_name: this.fileContentDataset.name,
            id: this.DATASET_DATA.length,
            datasetId: this.newDatasetId,
          });
        });
      this.show_import_popup = false;
      this.importLoadingPopup = true;
      this.duplicate_confirmation = false;
      this.import_model();
    } else {
      this.show_import_popup = false;
      this.duplicate_dataset_name = true;
      this.rerun_dataset = true;
    }
  }

  import_model() {
    if (this.new_model_name_after_duplicate === "")
      this.new_model_name_after_duplicate = this.fileContentModel.name;
    this.duplicate_model_name = false;
    //check for duplicate model names
    this.database.firestore
      .collection("models")
      .where("user", "==", this.authService.uid())
      .where("name", "==", this.new_model_name_after_duplicate)
      .get()
      .then((docs) => {
        let models: any[] = [];
        let duplicateModel: boolean = false;

        docs.forEach((doc) => {
          let data = doc.data() as Model;
          models.push({ ...data, doc_id: doc.id });
        });

        // check for duplicate dataset names
        for (let i = 0; i < models.length; i++) {
          if (
            arraysAreEqual(
              this.fileContentModel.chemicalSpeciesANum,
              models[i].chemicalSpeciesANum
            ) &&
            arraysAreEqual(
              this.fileContentModel.chemicalSpeciesBNum,
              models[i].chemicalSpeciesBNum
            ) &&
            arraysAreEqual(
              this.fileContentModel.logKInitial,
              models[i].logKInitial
            ) &&
            arraysAreEqual(
              this.fileContentModel.logKLocked,
              models[i].logKLocked
            )
          ) {
            duplicateModel = true;
            this.duplicate_model_name = false;
            this.newModelId = models[i].doc_id;
            break;
          } else {
            this.show_import_popup = false;
            this.duplicate_model_name = true;
          }
        }

        if (!this.duplicate_model_name && !duplicateModel) {
          // add to model collection of database
          this.database.firestore
            .collection("models")
            .add({
              name: this.new_model_name_after_duplicate,
              user: this.authService.uid(),
              uploadDate: firebase.firestore.Timestamp.now(),
              chemicalSpeciesANum: this.fileContentModel.chemicalSpeciesANum,
              chemicalSpeciesBNum: this.fileContentModel.chemicalSpeciesBNum,
              massBalance: this.fileContentModel.massBalance,
              massAction: this.fileContentModel.massAction,
              restricted: this.fileContentModel.restricted,
              logKInitial: this.fileContentModel.logKInitial,
              logKLocked: this.fileContentModel.logKLocked,
              logKChanged: this.fileContentModel.logKChanged,
              tag: "user",
            })
            .then((doc) => {
              this.newModelId = doc.id;
              this.uploading = false;
              this.uploaded = true;
            });
          this.import_result();
        } else if (duplicateModel) {
          this.import_result();
        } else {
          this.show_import_popup = false;
          this.duplicate_model_name = true;
          this.rerun_model = true;
          this.importLoadingPopup = false;
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  }

  import_result() {
    if (!this.rerun_dataset) {
      this.new_dataset_name_after_duplicate = this.fileContentDataset.name;
    }
    if (!this.rerun_model) {
      this.new_model_name_after_duplicate = this.fileContentModel.name;
    }

    let result: Result = {
      user: this.authService.uid(),
      date: firebase.firestore.Timestamp.now(),
      datasetName: this.new_dataset_name_after_duplicate,
      datasetId: this.newDatasetId,
      modelName: this.new_model_name_after_duplicate,
      modelId: this.newModelId,
      logKOptimal: this.fileContentResult.logKOptimal,
      logKInitial: this.fileContentResult.logKInitial,
      logKLocked: this.fileContentResult.logKLocked,
      chemicalSpeciesANum: this.fileContentResult.chemicalSpeciesANum,
      chemicalSpeciesBNum: this.fileContentResult.chemicalSpeciesBNum,
      equilibriumConcentrations:
        this.fileContentResult.equilibriumConcentrations,
      molarAbsorptivity: this.fileContentResult.molarAbsorptivity,
      RMSR: this.fileContentResult.RMSR,
      unrestrictedRMSR: this.fileContentResult.unrestrictedRMSR,
      reconstruction: this.fileContentResult.reconstruction,
      uReconstruction: this.fileContentResult.uReconstruction,
      rSqd: this.fileContentResult.rSqd,
      unrestrictedRSqd: this.fileContentResult.unrestrictedRSqd,
      imbeddederror: this.fileContentResult.imbeddederror,
      Uimbeddederror: this.fileContentResult.Uimbeddederror,
      optimization: this.fileContentResult.optimization,
    };
    if ("bootNum" in this.fileContentResult)
      result.bootNum = this.fileContentResult.bootNum;

    // add to result and resultmin collection of database
    this.database.firestore
      .collection("results")
      .add(result)
      .then((doc) => {
        this.result_id = doc.id;

        let bootBool = this.fileContentBootstrap.length == 0 ? false : true;

        // loop through each bootstrap within the subcollection
        if (bootBool) {
          for (let i = 0; i < this.fileContentBootstrap.length; i++) {
            this.database.firestore
              .collection("results")
              .doc(doc.id)
              .collection("bootstraps")
              .doc(i.toString())
              .set({
                bootIndexes: this.fileContentBootstrap[i].bootIndexes,
                bootLogKs: this.fileContentBootstrap[i].bootLogKs,
              });
          }
        }

        this.database.firestore
          .collection("resultsmin")
          .doc(doc.id)
          .set({
            user: this.authService.uid(),
            date: firebase.firestore.Timestamp.now(),
            datasetName: this.new_dataset_name_after_duplicate,
            datasetId: this.newDatasetId,
            modelName: this.new_model_name_after_duplicate,
            modelId: this.newModelId,
            RMSR: this.fileContentResult.RMSR,
            bootstrapped: bootBool,
          })
          .then(() => {
            this.uploading = false;
            this.uploaded = true;
            console.log("success");

            this.results.push({
              datasetName: this.new_dataset_name_after_duplicate,
              modelName: this.new_model_name_after_duplicate,
              date: new Date(),
              rmsr:
                Math.round(this.fileContentResult.RMSR * 10000000) / 10000000,
              id: this.TABLE_DATA.length,
              datasetId: this.newDatasetId,
              modelId: this.newModelId,
              doc_id: doc.id,
              bootstrapped: bootBool,
            });

            // update fits table
            this.TABLE_DATA.push({
              dataset_name: this.new_dataset_name_after_duplicate,
              model_name: this.new_model_name_after_duplicate,
              date: new Date(),
              rmsr:
                Math.round(this.fileContentResult.RMSR * 10000000) / 10000000,
              id: this.TABLE_DATA.length,
              datasetId: this.newDatasetId,
              modelId: this.newModelId,
              resultId: doc.id,
              bootstrapped: bootBool,
            });

            this.importLoadingPopup = false;

            this.results.sort((a, b) => (a.date < b.date ? 1 : -1));
            this.TABLE_DATA.sort((a, b) => (a.date < b.date ? 1 : -1));
            this.myData = new MatTableDataSource(this.TABLE_DATA);

            this.new_model_name_after_duplicate = "";

            // all imports successful
            this.successBar.openFromComponent(SuccessBarImportComponent, {
              duration: this.durationInSeconds * 2000,
            });
          });
      });
  }

  close_import_popup() {
    this.show_import_popup = false;
  }

  checkCode() {
    this.database.firestore
      .collection("userCodes")
      .where("email", "==", this.authService.email())
      .where("code", "==", this.userCode)
      .get()
      .then((docs) => {
        if (docs.docs.length === 0) {
          this.codeErrorMessage = "Code is incorrect.";
        } else {
          docs.forEach((doc) => {
            let data = doc.data() as { uses: any; code: string };
            let numUses = data.uses;
            if (typeof numUses === "number" && numUses >= this.bootNum / 100) {
              // reset
              this.codeErrorMessage = "";
              this.userCode = "";
              this.paymentPopup = false;
              this.codePopup = false;
              this.successBar.openFromComponent(SuccessBarCodeComponent, {
                duration: this.durationInSeconds * 2000,
              });
              if (this.results[this.row_id].bootstrapped) {
                this.database.firestore
                  .collection("results")
                  .doc(this.results[this.row_id].doc_id)
                  .get()
                  .then((doc) => {
                    let data = doc.data() as Result;
                    data.date = firebase.firestore.Timestamp.now();

                    this.database.firestore
                      .collection("results")
                      .add(data)
                      .then((doc) => {
                        this.result_id = doc.id;

                        this.database.firestore
                          .collection("resultsmin")
                          .doc(doc.id)
                          .set({
                            user: this.authService.uid(),
                            date: firebase.firestore.Timestamp.now(),
                            datasetName: data.datasetName,
                            datasetId: data.datasetId,
                            modelName: data.modelName,
                            modelId: data.modelId,
                            RMSR: data.RMSR,
                            bootstrapped: true,
                          })
                          .then(() => {
                            this.row_id = 0;

                            this.results.push({
                              datasetName: data.datasetName,
                              modelName: data.modelName,
                              date: data.date,
                              rmsr: data.RMSR,
                              id: this.TABLE_DATA.length,
                              datasetId: data.datasetId,
                              modelId: data.modelId,
                              doc_id: doc.id,
                              bootstrapped: true,
                            });

                            // update fits table
                            this.TABLE_DATA.push({
                              dataset_name: data.datasetName,
                              model_name: data.modelName,
                              date: new Date(),
                              rmsr: math.round(data.RMSR * 10000000) / 10000000,
                              id: this.TABLE_DATA.length,
                              datasetId: data.datasetId,
                              modelId: data.modelId,
                              resultId: doc.id,
                              bootstrapped: true,
                            });

                            this.results.sort((a, b) =>
                              a.date < b.date ? 1 : -1
                            );
                            this.TABLE_DATA.sort((a, b) =>
                              a.date < b.date ? 1 : -1
                            );
                            this.myData = new MatTableDataSource(
                              this.TABLE_DATA
                            );

                            this.selection.toggle(this.TABLE_DATA[0]);

                            this.bootData = [[[]]];

                            this.result_id = doc.id;

                            console.log("success");
                            this.paymentPopup = false;
                            this.submittingPayment = false;
                            if (this.runOnBorg) {
                              this.runBorgBootstrapping();
                              console.log("running borgbootstrap");
                            } else {
                              this.runCloudBootstrapping();
                              console.log("running cloudbootstrap");
                            }
                          });
                      });
                  });
              } else {
                this.paymentPopup = false;
                this.submittingPayment = false;
                if (this.runOnBorg) {
                  this.runBorgBootstrapping();
                  console.log("running borgbootstrap");
                } else {
                  this.runCloudBootstrapping();
                  console.log("running cloudbootstrap");
                }
              }

              // decrement uses in database
              numUses -= this.bootNum / 100;
              if (numUses) {
                this.database.firestore
                  .collection("userCodes")
                  .doc(doc.id)
                  .update({
                    uses: numUses,
                  });
              }
            } else if (typeof numUses !== "number") {
              this.codeErrorMessage =
                "Error: expected a number for numUses but received a " +
                typeof numUses;
            } else if (numUses < this.bootNum / 100) {
              this.codeErrorMessage =
                "The code you attempted to use can only be used for " +
                numUses * 100 +
                " bootstraps.";
            } else if (numUses == 0) {
              this.codeErrorMessage = "Your code has expired.";
            }
          });
        }
      });
  }

  saveLogKOptimal() {
    this.saveSpinner = true;
    if (this.logKOptimal.length) {
      this.database.firestore
        .collection("models")
        .doc(this.model_id)
        .update({
          logKInitial: this.logKOptimal,
          logKChanged: true,
        })
        .then(() => {
          this.saveSpinner = false;
        });

      if (this.model !== -1) {
        this.models[this.model].logKInitial = this.logKOptimal;
      }
    }
  }
}

//Delays further program movement by inputted milliseconds
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface Item {
  id: number | string;
  x: number;
  y: number;
  z: number;
  style: number;
}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_fits_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SuccessBarFitsComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_imports_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SuccessBarImportComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_code_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SuccessBarCodeComponent {}
