// reference: https://stackblitz.com/edit/angular-2yv8hk?file=app%2Ftable-selection-example.css
// Sorting reference: https://material.angular.io/components/sort/examples
declare let Plotly: any;
import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { SelectionModel } from "@angular/cdk/collections";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "../../services/auth.service";
import { Model } from "src/app/shared/interfaces/data.interface";
import * as math from "mathjs";
import { Sort } from "@angular/material/sort";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as XLSX from "xlsx";
import { Title } from "@angular/platform-browser";
import { mapToMatrix } from "src/app/shared/functions/util";
import { calculateK } from "src/app/shared/functions/calculation.util";
import { DataService } from "src/app/services/data.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteModelPopupComponent } from "./delete-model-popup/delete-model-popup.component";
import { FormatterService } from "src/app/services/formatter.service";
import {
  Reaction,
  Species,
} from "src/app/shared/interfaces/reaction.interface";

interface SpeciesVal {
  firstval: number;
  secondval: number;
  selected: boolean;
  selectable: boolean;
}

export interface Element {
  model_name: string;
  date: Date;
  species: string;
  id: number;
  doc_id: string;
}

@Component({
  selector: "app-models-page",
  templateUrl: "./models-page.component.html",
  styleUrls: ["./models-page.component.scss"],
})
export class ModelsPageComponent implements OnInit {
  @ViewChild("equilibriumConcentrationGraph")
  equilibriumConcentrationGraph!: ElementRef;
  BOUNDARY_INCREMENT: number = 0.3;
  LNC_RETRY: number = 5;
  chart: any;
  hide: boolean;
  // used as graph's x and y maxes
  @Input() q: number = 0;
  h: number = 0.01;
  bestH: number | string = 0.01;
  bestQ: number | string = 0;
  // AB_matrix[0]: A matrix
  // AB_matrix[1]: B matrix
  AB_matrix: number[][] = [];
  finalConcentration: number[][] = [];
  logH: number = -2;

  colors: string[] = [
    "#dc3912",
    "#3366cc",
    "#ff9900",
    "#109618",
    "#990099",
    "#0099c6",
    "#dd4477",
    "#000000",
    "#b82e2e",
    "#316395",
    "#994499",
    "#22aa99",
    "#aaaa11",
    "#6633cc",
    "#e67300",
    "#8b0707",
    "#651067",
    "#329262",
    "#5574a6",
    "#3b3eac",
    "#b77322",
    "#16d620",
    "#b91383",
    "#f4359e",
    "#9c5935",
    "#a9c413",
    "#2a778d",
    "#668d1c",
    "#bea413",
    "#0c5922",
    "#743411",
  ];
  rgbColors: string[] = [];

  // arrays and matrices
  TABLE_DATA: Element[] = [];
  columns: string[] = ["model_name", "date", "num_species", "delete"];
  myData = new MatTableDataSource(this.TABLE_DATA);
  models: any[] = [];
  species: Species[] = [];
  speciesNames: string[] = [];
  speciesHTML: string[] = [];
  speciesvals: SpeciesVal[] = [];
  selection = new SelectionModel<Element>(false, []);
  sampleSpecies: any[] = [];
  user_logK: number[] = [];
  export_data: any[][] = [];
  model_exports: any[] = [];
  impact_array: any[] = [];
  max_impact_array: any[] = [];
  logKBoolean: boolean[] = [];
  logKText: string[] = [];
  logKs: number[] = [];
  logK: number[] = [];
  new_logK: any[] = [];
  impact_logK: number[] = [];
  logKInitial: number[] = [];
  temp: number = 0;
  concentration: number[][] = [];
  composition: number[][] = [];
  massVec: number[][] = [];
  chemVec: number[][] = [];
  chemicalSpeciesANum: number[] = [];
  chemicalSpeciesBNum: number[] = [];

  concData: any[] = [];
  conc: any[] = [];

  // number variables
  table_id: number = 0;
  model_id_delete: number = -1;
  id: number = 0;
  imp_doc_id: any;
  impact_product: number = 1;
  max_impact_product: number = 1;
  num_opt: number = 0;

  // boolean variables
  show_spinner: boolean = true;
  popup: boolean = false;
  show_popup: boolean = false;
  chosen: boolean = true;
  redraw_bool: boolean = false;
  deleteButtonClicked: boolean = false;
  checked: boolean = false;
  logKChanged: boolean = true;
  reset: boolean = false;
  optimized: boolean = false;
  selectDelete: boolean = false;

  // string variables
  param_name: string = "";

  // used for mat snack bar
  durationInSeconds = 1;
  model: Model | null;

  reactions: Reaction[] = [];

  constructor(
    public router: Router,
    private database: AngularFirestore,
    private authService: AuthService,
    private successBar: MatSnackBar,
    private titleService: Title,
    private dataService: DataService,
    private formatterService: FormatterService,
    private dialog: MatDialog
  ) {
    this.titleService.setTitle("Models | SIVVU");
    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("models");
      this.router.navigate(["login"]);
    }

    this.database.firestore
      .collection("bootstraps")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          this.database.firestore
            .collection("bootstraps")
            .doc(doc.id)
            .delete()
            .then(() => {
              // console.log("Deleted");
            });
        });
      });
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.getData();
    } catch (err) {
      console.log(err);
    }
  }

  decrementCount() {
    this.num_opt = 0;
  }

  modelChangeH(e: Event) {
    // this.options.yaxis[0].max = this.h;
    this.redraw();
  }

  modelChangeQ(e: Event) {
    this.redraw();
  }

  /**
   * Retrieve model data from firebase and fill the table
   */
  async getData() {
    // put each dataset that fits the user login into an array
    try {
      const modelData = await this.dataService.getUserModels(true);
      this.models = [...modelData];
      // create array of species to be used in table
      const speciesHTML = [];
      for (let i = 0; i < this.models.length; i++) {
        const specHTML = [];

        // Format default species: pure A, B, C
        specHTML.push(this.models[i].restricted[0] === -1 ? "A#" : "A"); // if A is locked, add # indicator
        specHTML.push(this.models[i].restricted[1] === 0 ? "(B)" : "B"); // if B is unabsorbing, add () indicator
        if (this.models[i].chemicalSpeciesCNum) {
          specHTML.push(this.models[i].restricted[2] === 0 ? "(C)" : "C"); // if C exists and is unabsorbing, add ()
        }

        const {
          chemicalSpeciesANum,
          chemicalSpeciesBNum,
          chemicalSpeciesCNum,
        } = this.models[i];
        // format each species
        chemicalSpeciesANum.forEach((_: number, i: number) => {
          const element = {
            A: chemicalSpeciesANum[i],
            B: chemicalSpeciesBNum[i],
            C: chemicalSpeciesCNum ? chemicalSpeciesCNum[i] : 0,
          };
          specHTML.push(this.formatterService.spec(element));
        });

        // add * if initial logK values were changed by the user
        if (this.models[i].logKChanged) {
          this.models[i].name += " *";
        }
        speciesHTML.push(specHTML);
      }
      // create object with dataset data
      this.models.forEach((model, i) => {
        let date = model.uploadDate.toDate();
        this.TABLE_DATA[i] = {
          model_name: model.name,
          date: date,
          species: speciesHTML[i].join(", "),
          id: i,
          doc_id: model.doc_id,
        };
      });
      this.myData = new MatTableDataSource(this.TABLE_DATA);
      this.show_spinner = false;
    } catch (err) {
      console.log(err);
      throw new Error("getData()");
    }
  }

  /**
   * Sort model data based on model name, date, species
   * @param sort
   * @returns void
   */
  sortData(sort: Sort): void {
    const data = this.TABLE_DATA.slice();
    if (!sort.active || sort.direction === "") {
      this.TABLE_DATA = data;
      return;
    }

    this.TABLE_DATA = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "modelname":
          return this.compare(a.model_name, b.model_name, isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "species":
          return this.compare(a.species.length, b.species.length, isAsc);
        default:
          return 0;
      }
    });

    this.myData = new MatTableDataSource(this.TABLE_DATA);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // search bar function
  searchFor(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.myData.filter = filterValue.trim().toLowerCase();
  }

  // navigate to add model page
  onAddModel(): void {
    this.router.navigate([`${"/add-model"}`]);
  }

  /**
   * on click delete
   * @param i row index
   */
  onDelete(index: number) {
    this.selectDelete = true;
    const mid = this.models[index].doc_id;
    // if the model is displayable
    const currentMid = this.model ? this.model.doc_id : mid;
    const dialogRef = this.dialog.open(DeleteModelPopupComponent, {
      width: "50%",
      data: { mid, ...this.models[index] },
    });
    // after the dialog is closed, delete model from UI after it has been deleted from firestore
    dialogRef.afterClosed().subscribe((status) => {
      if (status) this.deleteModel(index, mid, currentMid);
    });
  }

  /**
   * delete model from UI
   * @param i row index
   * @param mid model doc id
   * @param currentMId model doc id of model currently on display
   */
  deleteModel(i: number, mid: string, currentMid: string) {
    // remove from models table
    this.models.splice(i, 1);
    this.speciesHTML.splice(i, 1);
    this.TABLE_DATA.splice(i, 1);
    this.myData = new MatTableDataSource(this.TABLE_DATA);

    if (mid === currentMid) {
      // clear values
      this.resetModelValues(this.models[i].advancedModel);
    }

    this.successBar.openFromComponent(SuccessBarComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

  closePopup() {
    this.show_popup = false;
  }

  /**
   * reset class variables when new model is selected
   */
  resetModelValues(advancedModel: boolean): void {
    this.conc = [];
    this.num_opt = 0;
    this.impact_array = [];
    this.model = null;
    // show graph html
    this.chosen = false;
    this.composition = [];
    this.concentration = [];

    this.chart = null;
    this.concData = [];
    this.sampleSpecies = [];
    // this.selectedSpecies = [];
    this.logKBoolean = [];
    this.logKText = [];
    // reset all impact optimization variables
    this.max_impact_array = [];
    this.max_impact_product = 0;
    this.impact_array = [];
    this.bestH = 0;
    this.bestQ = 0;
    this.q = 0;
    this.h = 0.01;
    this.logH = -2;
    //this.options.yaxis[0].max = this.h;

    this.setABMatrix(this.h, advancedModel);
  }

  /**
   * Initialize AB matrix with 2 rows if simple model, 3 rows if advanced model
   * This method modifies the class variable this.ABMatrix
   * @param h
   * @param isAdvanced true if model is advanced, undefined otherwise
   */
  setABMatrix(h: number, isAdvanced: boolean): void {
    this.AB_matrix = [];
    // initialize two rows, length 51 each
    this.AB_matrix[0] = new Array(51).fill(h); // set A array to values of constant h
    this.AB_matrix[1] = new Array(51);
    if (isAdvanced) this.AB_matrix[2] = new Array(51);
  }

  /**
   * Set AB matrix with values: B and C matricies
   * This method modifies the class variable this.ABMatrix
   * @param h
   * @param isAdvanced
   */
  fillABMatrix(h: number, isAdvanced: boolean): void {
    let initial = 0;
    const incrementer = h / 50;

    // fill array B with values of 0 to .01*q
    for (let i = 0; i < this.AB_matrix[1].length; i++) {
      this.AB_matrix[1][i] = initial * this.q;
      initial += incrementer;
    }

    if (isAdvanced) {
      initial = 0;
      for (let i = 0; i < this.AB_matrix[2].length; i++) {
        this.AB_matrix[2][i] = initial * this.q;
        initial += incrementer;
      }
    }
  }

  /**
   * Util function for getting all chemical species
   * @param chemicalSpeciesANum
   * @param chemicalSpeciesBNum
   * @returns list of all species in the model
   */
  getSpecies(model: Model): string[] {
    const species = ["A", "B"];
    const { chemicalSpeciesANum, chemicalSpeciesBNum } = model;

    this.chemicalSpeciesANum = chemicalSpeciesANum;
    this.chemicalSpeciesBNum = chemicalSpeciesBNum;
    this.chemicalSpeciesANum.forEach((_, i) => {
      species.push(
        "A" + this.chemicalSpeciesANum[i] + "B" + this.chemicalSpeciesBNum[i]
      );
    });
    return species;
  }

  /**
   * change hex string values to rgb values that can be used by plotly graphs
   * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
   * @param hex
   * @returns [r, g, b] values
   */
  hexToRgb(hex: string): number[] {
    return hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
  }

  /**
   * called when user presses a model
   * @param doc_id access firebase
   * @param id row id in local code
   */
  onSelectModel(doc_id: string, id: number): void {
    if (!this.selectDelete) {
      this.hide = true;
      this.optimized = false;
      const model = this.models[id];
      // reset values to show new model information
      this.resetModelValues(model.advancedModel);

      // set model to user selection
      this.table_id = id;
      this.imp_doc_id = doc_id;

      // format equations
      const { chemicalSpeciesANum, chemicalSpeciesBNum, chemicalSpeciesCNum } =
        model;

      // change species format to use util functions
      // refer to reaction.interface.ts
      // species contains pure & user-defined species
      const species = this.formatterService.changeSpecFormat(
        chemicalSpeciesANum,
        chemicalSpeciesBNum,
        chemicalSpeciesCNum
      );
      this.species = species;

      // get species name in the AxByCz html format
      this.speciesNames = this.species.map((spec) => {
        return this.formatterService.spec(spec);
      });

      // get reactions in order
      this.reactions = this.formatterService.getSequentialReaction(
        species,
        model.advancedModel
      );
      this.conc.push(model);

      // largest B to A ratio set to q
      const maxABRatio = this.calculateABRatio(species);
      this.q = maxABRatio;
      this.fillABMatrix(this.h, model.advancedModel);

      // fill in logK Boolean array from database
      this.logKBoolean = model.logKLocked;
      // set composition matrix equal to AB_matrix
      this.composition = this.AB_matrix;

      // set concentration matrix equal to AB_Matrix with arrays of zeros following,
      // corresponding to the number of species chosen
      this.concentration = this.AB_matrix;
      const start = model.advancedModel ? 3 : 2;
      for (let i = start; i < model.massBalance[0].values.length; ++i) {
        this.concentration.push(
          new Array(this.concentration[0].length).fill(0)
        );
      }

      this.logK = [...model.logKInitial];
      this.logKInitial = [...model.logKInitial];
      this.massVec = mapToMatrix(model.massBalance);
      this.chemVec = mapToMatrix(model.massAction);

      // to be used in toggleChange()
      if (this.logKBoolean) {
        this.logKBoolean.forEach((logKBool, i) => {
          this.logKText[i] = logKBool ? "Pinned" : "Pin";
        });
      }

      // use plotly to graph equilibrium concentration
      this.graphEquilibriumConcentration(model);

      // reset value for floating point representation on page
      this.q = Math.round(this.q * 1000) / 1000;
      this.concData = [...this.concData];

      // call impact function
      this.impactFunction();

      // reset the logK arrays
      this.logK[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;
      this.logKInitial[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;

      // set property to display concentration information
      this.model = model;
      this.hide = false;
    } else {
      this.selectDelete = false;
    }
  }

  /**
   * Always assume both A and B exist in at least 1 species
   * TODO: Exception handling for when maxRatio is 0
   * @param species
   * @returns maximum A/B ratio
   */
  calculateABRatio(species: Species[]): number {
    let maxRatio = 0;
    species.forEach((spec) => {
      const { A, B } = spec;
      if (A) maxRatio = math.max(maxRatio, B / A);
    });
    return maxRatio;
  }

  getLogKSub(reaction: Reaction, index: number): string {
    return this.formatterService.logKSub(reaction, index);
  }

  /**
   * Format the reaction
   * @param reaction
   * @returns formatted reaction in string form
   */
  getReaction(reaction: Reaction): string {
    return this.formatterService.reaction(reaction);
  }

  /**
   * Get the position of the reactions each species exist
   * e.g. {A: 1, B: 0, C: 0}: [0]
   *      if the species A exists only in the first reaction
   * e.g. {A: 0, B: B, C: 0}: [0, 1, 2]
   *      if the species B exists in the 0th, 1st, and 2nd reactions
   * @param species Species in the reactions
   * @param reactions All reactions
   * @returns mapping from species to the reactions they are part of
   */
  getSpeciesInReaction(
    species: Species[],
    reactions: Reaction[]
  ): Map<Species, number[]> {
    // initialize mapping
    const speciesToReactions = new Map<Species, number[]>();

    // a mapping for each species
    species.forEach((spec) => {
      // convert the spec object to a string for comparison
      const speciesString = JSON.stringify(spec);

      // search in both reactants and products to see if spec exists
      reactions.forEach((reaction, i) => {
        const { reactants, products } = reaction;

        // search reactants
        // add reaction to mapping if species exists in the reactants part of the reaction
        reactants.forEach((reactant) => {
          if (JSON.stringify(reactant) === speciesString) {
            // if the species exists in the mapping, add the reaction index
            // otherwise, initialize the key:value pair with a value of the reaction index
            speciesToReactions.has(spec)
              ? speciesToReactions.get(spec).push(i)
              : speciesToReactions.set(spec, [i]);
          }
        });

        // search products
        // add reaction to mapping if species exists in the products part of the reaction
        products.forEach((product) => {
          if (JSON.stringify(product) === speciesString) {
            // if the species exists in the mapping, add the reaction index
            // otherwise, initialize the key:value pair with a value of the reaction index
            speciesToReactions.has(spec)
              ? speciesToReactions.get(spec).push(i)
              : speciesToReactions.set(spec, [i]);
          }
        });
      });
    });

    // return the {species: reaction indices} mapping
    return speciesToReactions;
  }

  /**
   * Calculate concentration error bounds for each specimen
   * @param increment 0.3 or -0.3, depending on upper or lower error bound
   * @param logK original logK values
   * @param species Array of all species' names
   * @param concentration main concentration line
   * @returns upper/lower error bounds depending on 0.3/-0.3 increment value
   */
  getErrorBound(
    increment: number,
    logK: number[],
    concentration: number[][]
  ): number[][] {
    // there should be a logK value for every reaction
    if (this.reactions.length !== logK.length) {
      throw new Error(
        `number of reactions does not match number of logK values`
      );
    }
    const errorBound: number[][] = [];
    // get species to reaction mapping
    const speciesToReactionMap = this.getSpeciesInReaction(
      this.species,
      this.reactions
    );

    this.species.forEach((spec, i) => {
      // index of reactions in which the species exist
      const includedReactionIndex = speciesToReactionMap.get(spec);

      let deviationSum = math.zeros([
        concentration.length,
        concentration[0].length,
      ]);
      // only increment the logK values of the reaction that each species belongs
      includedReactionIndex.forEach((rIdx) => {
        const logKIterate = [...logK];
        logKIterate[rIdx] += increment;
        const logKIterateConc = this.getConcentration(logKIterate);
        // if getting the upper bound (+0.3): upperBound - mainConcentration
        // if getting the lower bound (-0.3): mainConcentration - lowerBound
        const deviation =
          increment > 0
            ? math.subtract(logKIterateConc, concentration)
            : math.subtract(concentration, logKIterateConc);
        deviationSum = math.add(deviationSum, deviation) as math.Matrix;
      });
      const specErrorBound =
        increment > 0
          ? math.add(concentration, deviationSum)[i]
          : math.subtract(concentration, deviationSum)[i];
      errorBound.push(specErrorBound);
    });

    return errorBound;
  }

  graphEquilibriumConcentration(model: Model): void {
    this.model = model;
    const concentration = this.getConcentration(this.logK);
    this.finalConcentration = concentration;
    const logK = this.logK;

    // calculate xAxis
    const xAxis: number[] = [];
    for (let i = 0; i < 51; i++) {
      xAxis[i] = (i / 50) * this.q;
    }
    // reverse() is an inplace operation, so create a copy before reversion xAxis
    const xAxisReverse = xAxis.map((num) => num).reverse();

    // get all species types
    this.chemicalSpeciesANum = model.chemicalSpeciesANum;
    this.chemicalSpeciesBNum = model.chemicalSpeciesBNum;

    const species = this.species.map((spec) =>
      this.formatterService.spec(spec)
    );

    const equilibriumConcentration = [];
    const rgbColors = this.colors.map((color) => this.hexToRgb(color));

    // add main line
    this.finalConcentration.forEach((concentration, i) => {
      equilibriumConcentration.push({
        x: xAxis,
        y: concentration,
        mode: "lines",
        name: this.speciesNames[i],

        line: {
          color: `rgb(${rgbColors[i][0]},${rgbColors[i][1]},${rgbColors[i][2]})`,
        },
      });
    });

    // calculate error boundaries (shaded regions)
    const upperBound = this.getErrorBound(
      this.BOUNDARY_INCREMENT,
      logK,
      concentration
    );
    const lowerBound = this.getErrorBound(
      -this.BOUNDARY_INCREMENT,
      logK,
      concentration
    );

    // the matrix dimensions of the upper and lower bounds should match
    if (
      upperBound.length !== lowerBound.length ||
      upperBound[0].length !== lowerBound[0].length
    ) {
      throw new Error("upperBound and lowerBound dimensions mismatch");
    }
    // the matrix dimensions of the boundaries and main concentration matrix should match
    if (
      upperBound.length !== this.finalConcentration.length ||
      upperBound[0].length !== this.finalConcentration[0].length ||
      lowerBound.length !== this.finalConcentration.length ||
      lowerBound[0].length !== this.finalConcentration[0].length
    ) {
      throw new Error("Boundaries and concentration dimensions mismatch");
    }

    // add shaded boundaries
    this.finalConcentration.forEach((_, i) => {
      equilibriumConcentration.push({
        x: xAxis.concat(xAxisReverse),
        y: upperBound[i].concat([...lowerBound[i]].reverse()),
        fill: "tozerox",
        fillcolor: `rgba(${rgbColors[i][0]},${rgbColors[i][1]},${rgbColors[i][2]},0.2)`,
        line: { color: "transparent" },
        name: species[i],
        showlegend: false, // don't show shaded regions in legend
        hoverinfo: "skip", // don't show shaded region values when hovering over lines
      });
    });

    // configure layout for entire graph
    const equilibriumConcentrationLayout = {
      autosize: true,
      xaxis: { title: "Equivalents (B/A)", range: [0, this.q] },
      yaxis: { title: "Equilibrium Concentration (M)", range: [0, this.h] },
      hovermode: "x unified",
      margin: {
        t: 40,
      },
    };

    Plotly.newPlot(
      this.equilibriumConcentrationGraph.nativeElement,
      equilibriumConcentration,
      equilibriumConcentrationLayout,
      { responsive: true } // respond to window resize
    );
  }

  /**
   * called when user changes logK values or axis maxes
   */
  redraw() {
    // reset
    this.chosen = false;
    this.composition = [];
    this.concentration = [];

    this.concData = [];
    this.num_opt = 0;
    this.setABMatrix(this.h, this.model.advancedModel);
    this.fillABMatrix(this.h, this.model.advancedModel);

    // set composition matrix equal to AB_matrix
    this.composition = this.AB_matrix;

    // set concentration matrix equal to AB_Matrix with arrays of zeros following,
    // corresponding to the number of species chosen
    this.concentration = this.AB_matrix;
    for (let i = 2; i < this.massVec[0].length; ++i) {
      this.concentration.push(new Array(this.concentration[0].length).fill(0));
    }

    this.graphEquilibriumConcentration(this.model);

    this.q = Math.round(this.q * 1000) / 1000;
    // convert h to number
    if (typeof this.h === "string") {
      this.h = parseFloat(this.h);
    }
    this.concData = [...this.concData];

    // call impact function
    this.impactFunction();

    // reset the logK arrays
    this.logK[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;
    this.logKInitial[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;
  }

  async redrawForImpact() {
    // reset
    // this.conc = [];
    this.chosen = false;
    this.composition = [];
    this.concentration = [];
    this.AB_matrix = [];
    this.concData = [];

    this.setABMatrix(this.h, this.model.advancedModel);

    // put each dataset that fits the user login into an array
    try {
      const model = this.models.find(
        (model) => model.doc_id === this.imp_doc_id
      );

      // fill array B with values of 0 to .01*q
      this.fillABMatrix(this.h, this.model.advancedModel);

      // set composition matrix equal to AB_matrix
      this.composition = this.AB_matrix;

      // set concentration matrix equal to AB_Matrix with arrays of zeros following,
      // corresponding to the number of species chosen
      this.concentration = this.AB_matrix;
      const start = model.advanceModel ? 3 : 2;
      for (let i = start; i < model.massBalance[0].values.length; ++i) {
        this.concentration.push(
          math.zeros(this.concentration[0].length) as number[]
        );
      }

      this.massVec = mapToMatrix(model.massBalance);
      this.chemVec = mapToMatrix(model.massAction);

      this.finalConcentration = this.getConcentration(this.logK);
      this.graphEquilibriumConcentration(model);

      this.q = Math.round(this.q * 1000) / 1000;
      this.concData = [...this.concData];
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async optimize() {
    this.num_opt++;

    // display popup
    this.reset = true;
    await delay(300);

    // reset
    this.max_impact_array = [];
    this.max_impact_product = 0;
    this.impact_array = [];
    this.bestH = 0;
    this.bestQ = 0;
    // this.q = 0;
    // this.h = .01;
    this.logH = -2;

    // run optimization code to find max impact value and parameters
    let bestImpact: number[] = this.fmaxsearch([this.logH, this.q]);
    this.bestH = bestImpact[0];
    this.bestQ = bestImpact[1];
    this.impact_array = this.max_impact_array;

    // rounding and notation adjustments to make numbers easier to read on screen
    this.bestH = parseFloat(this.bestH.toExponential());
    // if the analyte number is extremely small, show all digits until significant
    // if greater than 1.0, cut off at 2 digits after the decimal
    const sigDigits =
      1 - math.floor(math.log(this.bestH) / math.log(10)) > 0
        ? 1 - math.floor(math.log(this.bestH) / math.log(10))
        : 2;
    this.h = parseFloat(this.bestH.toFixed(sigDigits));

    this.q = math.round(this.bestQ, 0);
    this.bestH = this.bestH.toExponential(2);
    this.bestQ = math.round(this.bestQ, 0);

    this.optimized = true;

    // redraw graph
    await this.redrawForImpact();

    // cancel popup
    this.reset = false;
  }

  // finds best logH and q values that maximize the impact values
  fmaxsearch(Expar: number[]): number[] {
    let P0, P1: number[];
    P0 = P1 = [...Expar];
    let n = P0.length;
    let step: number[] = [1, 1];
    let bestImpact = this.optimizeProduct();
    let lastImpact = bestImpact;
    // this.rmsr = this.iterate(P0);
    let i = 0;
    while (true) {
      for (let j = 0; j < n; ++j) {
        P1 = [...P0];
        P1[j] += step[j];
        this.logH = P1[0];
        this.q = P1[1];
        let newImpact = this.optimizeProduct();
        if (bestImpact < newImpact) {
          step[j] *= 1.2; // then go a little faster
          P0 = [...P1];
          bestImpact = newImpact;
        } else {
          step[j] *= -0.5; // otherwise reverse and go slower
        }
      }
      // end loop if change in impacts is minimal
      if (
        Math.abs(lastImpact - bestImpact) < 1 &&
        Math.abs(step[0]) < 0.005 &&
        Math.abs(step[1]) < 0.8
      ) {
        break;
      }
      lastImpact = bestImpact;
      ++i;
    }
    return [Math.pow(10, P0[0]), P0[1], bestImpact];
  }

  optimizeProduct(): number {
    this.h = Math.pow(10, this.logH);
    this.runConc();
    this.impactFunction();

    // reset the logK arrays
    this.logK[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;
    this.logKInitial[this.logK.length - 1] -= this.BOUNDARY_INCREMENT;

    const impactProduct = this.impactProductFnc();

    return impactProduct;
  }

  impactFunction() {
    let doubleKConc: number[][] = [];
    let newSubtConc: number[][] = [];
    this.impact_array = [];

    // used for impact calculations
    for (let i = 0; i < this.logK.length; i++) {
      if (i === 0) {
        this.logK[i] += this.BOUNDARY_INCREMENT;
        this.logKInitial[i] += this.BOUNDARY_INCREMENT;
        doubleKConc = this.getConcentration(this.logK);
      } else {
        // reset previous logK
        this.logK[i - 1] -= this.BOUNDARY_INCREMENT;
        this.logKInitial[i - 1] -= this.BOUNDARY_INCREMENT;
        // increment current logK
        this.logK[i] += this.BOUNDARY_INCREMENT;
        this.logKInitial[i] += this.BOUNDARY_INCREMENT;
        doubleKConc = this.getConcentration(this.logK);
      }

      // matrix subtraction
      newSubtConc = math.subtract(
        this.finalConcentration,
        doubleKConc
      ) as number[][];

      let oldBRow: number[] = newSubtConc[1];

      // sqaure value of resulting matrix
      let sqVConc = math.square(newSubtConc);

      // take mean of that matrix (single value)
      let meanConc = math.mean(sqVConc);
      meanConc = math.sqrt(meanConc);
      // set to impact array
      this.impact_array[i] = ((meanConc / this.h) * 1000) / 1.5;
    }
  }

  // returns the product of the impact values
  impactProductFnc(): number {
    // reset
    this.impact_product = 1;
    for (let i = 0; i < this.impact_array.length; i++) {
      this.impact_product *= parseFloat(this.impact_array[i]);
    }
    if (this.impact_product > this.max_impact_product) {
      this.max_impact_product = this.impact_product;
      this.max_impact_array = this.impact_array;
    }
    return this.impact_product;
  }

  // adjust h and q to find max impact product
  runConc() {
    // this.conc = [];
    this.chosen = false;
    this.composition = [];
    this.concentration = [];
    this.AB_matrix = [];
    this.concData = [];

    // initialize rows, length 51 each
    this.setABMatrix(this.h, this.model.advancedModel);
    this.fillABMatrix(this.h, this.model.advancedModel);

    // set composition matrix equal to AB_matrix
    this.composition = this.AB_matrix;

    // set concentration matrix equal to AB_Matrix with arrays of zeros following,
    // corresponding to the number of species chosen
    this.concentration = this.AB_matrix;
    const start = this.model.advancedModel ? 3 : 2;
    for (let i = start; i < this.conc[0].massBalance[0].values.length; ++i) {
      this.concentration.push(
        math.zeros(this.concentration[0].length) as number[]
      );
    }

    this.massVec = mapToMatrix(this.conc[0].massBalance);
    this.chemVec = mapToMatrix(this.conc[0].massAction);

    this.finalConcentration = this.getConcentration(this.logK);
  }

  // updates logKInitial values in database according to set user values
  async onSetInitialLogK() {
    this.logKChanged = true;
    let doc_id = this.models[this.table_id].doc_id;

    // Set the "logKInitial" field of the desired model
    return this.database.firestore
      .collection("models")
      .doc(doc_id)
      .update({
        logKInitial: this.logK,
        logKLocked: this.logKBoolean,
        logKChanged: this.logKChanged,
      })
      .then(() => {
        this.successBar.openFromComponent(LogKSuccessBarComponent, {
          duration: this.durationInSeconds * 1500,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  /**
   * Set the "logKInitial" field of the desired model
   * toggles the text between pin and pinned
   * @param i model table id
   */
  async toggleChange(i: number) {
    if (this.logKBoolean[i] == true) {
      this.logKBoolean[i] = false;
      this.logKText[i] = "Pin";
    } else if (this.logKBoolean[i] == false) {
      this.logKBoolean[i] = true;
      this.logKText[i] = "Pinned";
    }

    this.logKChanged = true;
    let doc_id = this.models[this.table_id].doc_id;

    try {
      // update firebase data
      await this.database.firestore.collection("models").doc(doc_id).update({
        logKLocked: this.logKBoolean,
      });
      // update local data
      this.models[this.table_id].logKLocked = this.logKBoolean;
      // show snackbar
      if (this.logKBoolean[i] == true) {
        this.successBar.openFromComponent(PinSuccessBarComponent, {
          duration: this.durationInSeconds * 1500,
        });
      } else {
        this.successBar.openFromComponent(UnpinSuccessBarComponent, {
          duration: this.durationInSeconds * 1500,
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
  updateConcentrationMinMax(
    newConcentration: number[],
    maxConcentration: number,
    lnKAbsMax: number
  ): number[] {
    const nc = newConcentration.map((c) => c);
    //Makes sure that none of the concentrations are above our max concentration
    //If they are, we make a list of which ones need to be replaced and change
    //their value to a random non-zero value.
    if (Math.max(...nc) > maxConcentration) {
      let replace_indices = [];

      for (let i = 0; i < nc.length; ++i) {
        if (nc[i] > maxConcentration) {
          replace_indices.push(i);
        }
      }

      replace_indices.forEach((i) => {
        nc[i] = maxConcentration * Math.random() + 1e-20;
      });
    }

    //Makes sure that none of the concentrations are below 0 (non-negative)
    //If they are, we make a list of which ones need to be replaced and change
    //their value to a random non-zero value.
    if (Math.min(...nc) <= 0) {
      const replace_indices = [];
      // for (let i = 0; i < replace_indices.length; ++i) {
      for (let i = 0; i < nc.length; ++i) {
        if (nc[i] <= 0) {
          replace_indices.push(i);
        }
      }

      const minconc = math.exp(-100 * lnKAbsMax);
      replace_indices.forEach((i) => {
        nc[i] = minconc * Math.random() + 1e-20;
      });
    }
    return nc;
  }

  /**
   * Calculates the concentrations using the dataset and model
   * @param logK
   * @returns concentration matrix for each spec
   */
  getConcentration(logK: number[]): number[][] {
    const lnK = math.dotMultiply(logK, 2.3) as number[];

    // concentration for each spec at each point 0-50
    const concentration = math.transpose(this.concentration);
    // this.composition is not modified anywhere
    const composition = math.transpose(this.composition);
    const maxConcentration: number[] = composition.map((x) => math.sum(x) * 2);
    const converge = 1e-20;

    const ma = this.chemVec.map((arr) => arr.slice()); // massAction
    const R = ma.length; // number of reactions
    const mb = this.massVec.map((arr) => arr.slice()); // massBalance
    const B = mb.length; // 2 if simple model builder, 3 if advanced model model builder
    const numReactions = ma[0].length;
    const numSpecies = mb[0].length;

    // add 0-filled arrays for the number of pure species to build square matrix
    // ma dimensions: numReactions * numSpecies
    for (let i = 0; i < B; ++i) {
      ma.push(new Array(numReactions).fill(0));
    }

    //Sets any 0 composition values to be 1*10^-30
    composition.forEach((row, i) => {
      composition[i] = row.map((val) => (val === 0 ? 1e-30 : val));
    });

    // Main loop beginning
    for (let p = 0; p < concentration.length; ++p) {
      // if the concentration data has values of 0, Infinity, or NaN, replace all values with maxConcentration[p] + 1e-99
      // otherwise, continue with the given concentration data
      const initialTempConc = concentration[p].some((elem) =>
        [0, Infinity, NaN].includes(elem)
      )
        ? new Array(numSpecies).fill(maxConcentration[p] + 1e-99)
        : concentration[p];

      //Actual work loop beginning
      let fit2 = 1;

      // temp_conc is continuously modified in the while loop: keep a deep copy so we can come back to the initial value when lnC calculation fails
      let temp_conc = initialTempConc.map((c) => c);
      // keep track of the while-loop iteration to access the lnC value of previous iteration
      let iteration = 0;
      // keep track of successful lnC calculations; use iteration variable above to access last successful calculation in case of error
      const newConcentrationsLnC = [];

      let K = new Array(B).fill(0);
      while (fit2 > converge) {
        for (let i = 0; i < B; ++i) {
          const mbtempconcproduct = math.dotMultiply(
            mb[i],
            temp_conc
          ) as number[];

          //sums up the elements from the mbtempconcproduct array, then divides
          //each element in mbtempconcproduct by the summed value
          ma[R + i] = math.dotDivide(
            mbtempconcproduct,
            math.sum(mbtempconcproduct)
          ) as number[];

          K[i] =
            math.prod(math.dotPow(ma[R + i], ma[R + i])) /
            math.prod(math.dotPow(mb[i], ma[R + i]));

          lnK[R + i] = math.log(K[i]) + math.log(composition[p][i]);
        }

        //performs a linear solve on the problem A * x = b, where A is our
        //mass action vector, and b is lnK
        //as number[][] is used to tell the compiler to treat the function return
        //as a matrix of numbers

        let lnC: number[][];

        try {
          lnC = math.lusolve(ma, lnK) as number[][];
        } catch (err) {
          // retry calculating temp_conc
          let success = false;
          for (let i = 0; i < this.LNC_RETRY; i++) {
            const newConcLnC = newConcentrationsLnC[iteration - 1].map(
              (c) => c
            );
            const nc = this.updateConcentrationMinMax(
              [...newConcLnC],
              maxConcentration[p],
              Math.max(...lnK.map((x) => Math.abs(x)))
            );

            for (let i = 0; i < B; ++i) {
              const mbtempconcproduct = math.dotMultiply(mb[i], nc) as number[];

              //sums up the elements from the mbtempconcproduct array, then divides
              //each element in mbtempconcproduct by the summed value
              ma[R + i] = math.dotDivide(
                mbtempconcproduct,
                math.sum(mbtempconcproduct)
              ) as number[];

              K[i] =
                math.prod(math.dotPow(ma[R + i], ma[R + i])) /
                math.prod(math.dotPow(mb[i], ma[R + i]));

              lnK[R + i] = math.log(K[i]) + math.log(composition[p][i]);
            }
            try {
              lnC = math.lusolve(ma, lnK) as number[][];
              success = true;
              // console.log(`success on iteration ${i}! breaking out...`);
              break;
            } catch (err) {
              // console.log(`iteration ${i} errror. Trying again...`);
              continue;
            }
          }
          // console.log(
          //   "all retries have failed. defaulting to previous newConcentration"
          // );

          if (!success) {
            for (let i = 0; i < B; ++i) {
              const mbtempconcproduct = math.dotMultiply(
                mb[i],
                initialTempConc
              ) as number[];

              //sums up the elements from the mbtempconcproduct array, then divides
              //each element in mbtempconcproduct by the summed value
              ma[R + i] = math.dotDivide(
                mbtempconcproduct,
                math.sum(mbtempconcproduct)
              ) as number[];

              K[i] =
                math.prod(math.dotPow(ma[R + i], ma[R + i])) /
                math.prod(math.dotPow(mb[i], ma[R + i]));

              lnK[R + i] = math.log(K[i]) + math.log(composition[p][i]);
            }
            lnC = math.lusolve(ma, lnK) as number[][];
          }
        }

        // keep copy of lnC for handling errors
        const concLnC = lnC.map((c) => math.e ** c[0]);
        newConcentrationsLnC.push([...concLnC]);

        const newConcentration = this.updateConcentrationMinMax(
          concLnC,
          maxConcentration[p],
          Math.max(...lnK.map((x) => Math.abs(x)))
        );

        fit2 = math.sum(
          // math.dotPow(math.subtract(newconcentration, temp_conc), 2) as number[]
          math.dotPow(math.subtract(newConcentration, temp_conc), 2) as number[]
        );

        // update temp_conc for next cycle
        // temp_conc = newconcentration;
        temp_conc = newConcentration;

        //If the result of the current loop cycle creates NaN values in our
        //temp_conc array, reset the fit2 and temp_conc array so that we can
        //restart the loop and try again to not get NaNs.
        if (newConcentration.includes(NaN)) {
          temp_conc = new Array(numSpecies).fill(maxConcentration[p] + 1e-99);
          fit2 = 1;
        }
        iteration += 1;
      }

      concentration[p] = temp_conc;
      // # Main loop end
    }
    return math.transpose(concentration);
  }

  //returns the log_10(K)
  getLogKValue(reaction: Reaction): number {
    const numReactions = this.reactions.length;
    return Math.round(Math.log10(calculateK(reaction, numReactions)) * 10) / 10;
  }

  // reference: https://github.com/SheetJS/sheetjs
  // https://github.com/SheetJS/sheetjs/tree/3542d62fffc155dd505a23230ba182c4402a0e2c/demos/angular2
  // https://redstapler.co/sheetjs-tutorial-create-xlsx/

  // used to create the export sheet
  create_datasheet() {
    // initialize two rows, length 51 each
    this.AB_matrix[0] = new Array(51);
    this.AB_matrix[1] = new Array(51);

    // set A array to values of constant h
    for (let i = 0; i < 51; i++) {
      this.AB_matrix[0][i] = this.h;
    }

    this.database.firestore
      .collection("models")
      .doc(this.imp_doc_id)
      .get()
      .then((doc) => {
        const data = this.models.find(
          (model) => model.doc_id === this.imp_doc_id
        );
        // let data = doc.data() as Model;

        const lastIndex = data.chemicalSpeciesANum.length - 1;
        // largest B to A ratio set to q
        this.q =
          data.chemicalSpeciesBNum[lastIndex] /
          data.chemicalSpeciesANum[lastIndex];

        // fill array B with values of 0 to .01*q
        let initial = 0;
        for (let i = 0; i < 51; i++) {
          this.AB_matrix[1][i] = initial * this.q;
          initial += 0.0002;
        }

        // set composition matrix equal to AB_matrix
        this.composition = this.AB_matrix;

        // set concentration matrix equal to AB_Matrix with arrays of zeros following,
        // corresponding to the number of species chosen
        this.concentration = this.AB_matrix;
        for (let i = 2; i < data.massBalance[0].values.length; ++i) {
          this.concentration.push(
            math.zeros(this.concentration[0].length) as number[]
          );
        }

        this.logK = data.logKInitial;
        this.massVec = mapToMatrix(data.massBalance);
        this.chemVec = mapToMatrix(data.massAction);

        this.finalConcentration = this.getConcentration(this.logK);
        this.export_data = [];

        let name_array = [];
        name_array.push(["Model Name: "]);
        name_array.push([]);
        name_array.push(data.name);
        this.export_data.push(name_array);

        // newline
        this.export_data.push([]);

        let species_strArray: string[] = [];

        // create array of species
        for (let i = 0; i < data.chemicalSpeciesANum.length; i++) {
          species_strArray[i] = "";
          for (let j = 0; j < data.chemicalSpeciesANum.length; j++) {
            species_strArray[j] =
              "A" +
              data.chemicalSpeciesANum[j] +
              "B" +
              data.chemicalSpeciesBNum[j];
          }
        }

        let column_names: any[] = [];
        column_names.push("Species");
        column_names.push("Equilibrium Concentration Matrix");
        this.export_data.push(column_names);

        // pure A
        let a_Array: any[] = [];
        a_Array.push("A");
        for (let j = 0; j < this.finalConcentration[0].length; j++) {
          a_Array.push(this.finalConcentration[0][j]);
        }
        this.export_data.push(a_Array);

        // pure B
        let b_Array: any[] = [];
        b_Array.push("B");
        for (let j = 0; j < this.finalConcentration[0].length; j++) {
          b_Array.push(this.finalConcentration[1][j]);
        }
        this.export_data.push(b_Array);

        for (let i = 2; i < this.finalConcentration.length; i++) {
          let new_species_array: any[] = [];
          new_species_array.push(species_strArray[i - 2]);
          for (let j = 0; j < this.finalConcentration[0].length; j++) {
            new_species_array.push(this.finalConcentration[i][j]);
          }
          this.export_data.push(new_species_array);
        }

        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.export_data);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        let user_model_name: string = data.name + ".xlsx";
        XLSX.writeFile(wb, user_model_name);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  }
}

//Delays further program movement by inputted milliseconds
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// These components are used for the snack bar popups
@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_component.html",
  styles: [],
})
export class SuccessBarComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "log_k_success_bar_component.html",
  styles: [],
})
export class LogKSuccessBarComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "pin_success_bar_component.html",
  styles: [],
})
export class PinSuccessBarComponent {}

@Component({
  selector: "snack-bar-component-example-snack",
  templateUrl: "unpin_success_bar_component.html",
  styles: [],
})
export class UnpinSuccessBarComponent {}
