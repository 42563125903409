<h2 mat-dialog-title>Are you sure to delete this fit?</h2>
<mat-dialog-content>
  <table mat-table [dataSource]="fitTableData">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.date | date : "dd MMM hh:mm" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="dataset">
      <th mat-header-cell *matHeaderCellDef>Dataset</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dataset }}
      </td>
    </ng-container>
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">
        {{ element.model }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="fitTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: fitTableColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onDelete()">Delete</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-stroked-button color="warn" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>
