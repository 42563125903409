import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  firstName: string = "";
  lastName: string = "";
  email: string = "";
  password: string = "";
  confirmPassword: string = "";

  errormessage: string = "";

  constructor(
    private authService: AuthService,
    public router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Register | SIVVU");
    if (authService.isLoggedIn()) this.router.navigate(["fits"]);
  }

  ngOnInit(): void {}

  registerUser(): void {
    //https://www.w3resource.com/javascript/form/password-validation.php
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    //Checks to make sure the email and password fields aren't blank
    if (this.email != "" && this.password != "" && this.confirmPassword != "") {
      //Checks to make sure the password is at least 8 characters long
      if (this.password.length >= 8 && this.password.length <= 20) {
        //Checks to make sure the passwords match
        if (this.password === this.confirmPassword) {
          //Makes sure the password has a number and an uppercase and lowecase letter
          if (this.password.match(passw)) {
            //Registers the user using the provided credentials
            this.authService
              .register(
                this.email,
                this.password,
                this.firstName + " " + this.lastName
              )
              .then((x) => {
                console.log(x);
              })
              .catch((error) => {
                this.errormessage = error.message;
              });
          } else {
            this.errormessage =
              "Passwords must contain at least one number and one uppercase and lowercase letter";
          }
        } else {
          this.errormessage = "Passwords do not match";
        }
      } else {
        this.errormessage =
          "Passwords must be be between 8 and 20 characters long";
      }
    }
  }
}
