import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { Model } from "../../../shared/interfaces/data.interface";
import * as firebase from "firebase/compat/app";
import { Title } from "@angular/platform-browser";
import { arrayToFirestoreMap } from "src/app/shared/functions/util";
import { AddModelPopupComponent } from "../add-model-popup/add-model-popup.component";
import { DataService } from "src/app/services/data.service";
import Fraction from "fraction.js";

interface Species {
  firstval: number;
  secondval: number;
  selected: boolean;
  selectable: boolean;
}

interface SelectedSpecies {
  firstval: number;
  secondval: number;
  ratio: number;
}

@Component({
  selector: "app-add-model-page",
  templateUrl: "./add-model-page.component.html",
  styleUrls: ["./add-model-page.component.scss"],
})
export class AddModelPageComponent implements OnInit {
  //Account information
  name: string = "";
  giveName: boolean = true;
  firstvals: number[] = [];
  secondvals: number[] = [];
  massBalance: number[][] = [];
  massAction: number[][] = [];
  // absorbing: boolean[] = [];
  logKInitial: number[] = [];
  duplicate_models: any[] = [];

  isLoggedIn: boolean = false;

  //Grid list
  speciesvals: Species[] = [];
  selectedSpecies: SelectedSpecies[] = [];
  absorbing: boolean = false;
  locked: boolean = false;
  logKChanged: boolean = false;

  uploading: boolean = false;
  uploaded: boolean = false;

  duplicate: boolean = false;

  //Popup boolean
  popup: boolean = false;

  //Ripple
  centered = true;
  disabled = false;
  unbounded = false;

  radius: number = 70;
  color: string = "#ffebb355";

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private authService: AuthService,
    private titleService: Title,
    private dataService: DataService
  ) {
    this.titleService.setTitle("Model Builder | SIVVU");
    if (authService.isLoggedIn()) {
      console.log("login Status:", authService.isLoggedIn());
      this.name = authService.displayName();
      this.isLoggedIn = true;
    } else {
      this.authService.setRedirect("add-model");
      this.router.navigate(["login"]);
    }

    for (let i = 1; i <= 6; ++i) {
      for (let j = 1; j <= 6; ++j) {
        this.speciesvals.push({
          firstval: i,
          secondval: j,
          selected: false,
          selectable: true,
        });
      }
    }
    //this.updateSelection(1, 1);
  }

  ngOnInit(): void {}

  //Updates the selection for the matrix in the form
  updateSelection(firstVal: number, secondVal: number): void {
    let val = secondVal + 6 * (firstVal - 1) - 1;
    let num1 = 0;
    let num2 = 0;
    let select1 = 0;
    let select2 = 0;

    if (this.speciesvals[val].selectable) {
      this.speciesvals[val].selected = !this.speciesvals[val].selected;
      //Calculating ratio
      [num1, num2] = this.calculateRatio(firstVal, secondVal);

      this.updateSelections(
        firstVal,
        secondVal,
        num1,
        num2,
        this.speciesvals[val].selected
      );

      select1 = num1;
      select2 = num2;
      while (select1 <= 6 && select2 <= 6) {
        val = select2 + 6 * (select1 - 1) - 1;
        //makes sure to not make the species they selected unselectable
        if (firstVal != select1) {
          this.speciesvals[val].selectable = !this.speciesvals[val].selectable;
        }
        select1 += num1;
        select2 += num2;
      }
    }
  }

  //Calculates the ratio between 2 numbers, returning the A:B ratio in an array [A,B]
  calculateRatio(firstVal: number, secondVal: number): number[] {
    let num1 = 0;
    let num2 = 0;
    for (let num = secondVal; num >= 1; num--) {
      if (firstVal % num == 0 && secondVal % num == 0) {
        num1 = firstVal / num;
        num2 = secondVal / num;
        break;
      }
    }
    return [num1, num2];
  }

  /**
   * changes status of A or B and updates suggested model name accordingly
   * @param type absorbing or locked based on what is toggled
   */
  toggleSpec(type: "absorbing" | "locked") {
    if (type === "absorbing") {
      this.absorbing = !this.absorbing;
    } else if (type === "locked") {
      this.locked = !this.locked;
    }

    this.name = this.buildModelName(
      this.locked,
      this.absorbing,
      this.selectedSpecies
    );
  }

  /**
   * Reset suggested model name
   * @param locked A Status
   * @param absorbing B Status
   * @param selectedSpecies Selected species on table
   * @returns Suggested model name
   */
  buildModelName(
    locked: boolean,
    absorbing: boolean,
    selectedSpecies: SelectedSpecies[]
  ): string {
    let aName = locked ? "A#" : "A";
    let bName = absorbing ? "B" : "(B)";

    selectedSpecies.forEach((spec) => {
      aName += spec.firstval;
      bName += spec.secondval;
    });

    return aName + bName;
  }

  //Updates the users selections by removing/adding the selection from the selection list
  updateSelections(
    firstVal: number,
    secondVal: number,
    num1: number,
    num2: number,
    addition: boolean
  ): void {
    if (addition) {
      this.selectedSpecies.push({
        firstval: firstVal,
        secondval: secondVal,
        ratio: num1 / num2,
      });
      this.selectedSpecies.sort((a, b) => (a.ratio < b.ratio ? 1 : -1));
    } else {
      this.selectedSpecies = this.selectedSpecies.filter((s) => {
        return s.ratio !== num1 / num2;
      });
    }
    let aName = this.locked ? "A#" : "A";
    let bName = this.absorbing ? "B" : "(B)";

    this.selectedSpecies.forEach((item) => {
      aName += item.firstval;
      bName += item.secondval;
    });

    this.name = this.buildModelName(
      this.locked,
      this.absorbing,
      this.selectedSpecies
    );
  }

  //Removes all the user's selections from the build models tab
  removeSelections(): void {
    this.speciesvals.forEach((spec) => {
      spec.selected = false;
      spec.selectable = true;
    });

    this.selectedSpecies = [];
  }

  //Returns the Beta value for each equilbrium equation
  getBetaValue(index: number): number {
    if (index === 0) {
      let power = this.selectedSpecies[index].secondval;

      return Math.pow(this.getKValue(0), power);
    } else {
      const productCoef =
        this.selectedSpecies[index - 1].firstval /
        (this.selectedSpecies[index - 1].firstval *
          this.selectedSpecies[index].secondval -
          this.selectedSpecies[index].firstval *
            this.selectedSpecies[index - 1].secondval);

      const productCoefInversed = 1 / productCoef;

      const reactantCoef =
        this.selectedSpecies[index].firstval /
        (this.selectedSpecies[index - 1].firstval *
          this.selectedSpecies[index].secondval -
          this.selectedSpecies[index].firstval *
            this.selectedSpecies[index - 1].secondval);

      let powersec = productCoefInversed * reactantCoef;

      let betaValue =
        Math.pow(this.getKValue(index), productCoefInversed) *
        Math.pow(this.getBetaValue(index - 1), powersec);

      return betaValue;
    }
  }

  //returns the log_10(β)
  getLogBetaValue(index: number): number {
    return Math.round(Math.log10(this.getBetaValue(index)) * 10) / 10;
  }

  //Returns the K value for each equilbrium equation
  getKValue(index: number): number {
    const base = 0.01 / (this.selectedSpecies.length + 2);
    if (index === 0) {
      // calculate the exponent
      const productCoef = 1 / this.selectedSpecies[index].secondval;
      const reactantCoef =
        this.selectedSpecies[index].firstval /
        this.selectedSpecies[index].secondval;

      // 1 is the coefficient of the single reactant B
      const exp = productCoef - (reactantCoef + 1);
      const kValue = Math.pow(base, exp);
      return kValue;
    } else {
      const productCoef =
        this.selectedSpecies[index - 1].firstval /
        (this.selectedSpecies[index - 1].firstval *
          this.selectedSpecies[index].secondval -
          this.selectedSpecies[index].firstval *
            this.selectedSpecies[index - 1].secondval);
      const reactantCoef =
        this.selectedSpecies[index].firstval /
        (this.selectedSpecies[index - 1].firstval *
          this.selectedSpecies[index].secondval -
          this.selectedSpecies[index].firstval *
            this.selectedSpecies[index - 1].secondval);

      const exp = productCoef - (reactantCoef + 1);
      return Math.pow(base, exp);
    }
  }

  //returns the log_10(K)
  getLogKValue(index: number): number {
    return Math.round(Math.log10(this.getKValue(index)) * 10) / 10;
  }

  //Returns the greatest common divisor for 2 numbers;
  gcd(a: number, b: number): number {
    if (!b) return a;
    return this.gcd(b, a % b);
  }

  //Returns the HTML for the coeffecient for the analyte of a sequential reaction
  getAnalyteCoefficient(i: number): string {
    let numerator = this.selectedSpecies[i].firstval;
    let denominator = 0;
    if (i === 0) {
      denominator = this.selectedSpecies[i].secondval;
    } else {
      denominator =
        this.selectedSpecies[i - 1].firstval *
          this.selectedSpecies[i].secondval -
        this.selectedSpecies[i].firstval *
          this.selectedSpecies[i - 1].secondval;
    }

    //simplify the numerator and denominator using their greatest common divisor
    let gcd = this.gcd(numerator, denominator);
    numerator = numerator / gcd;
    denominator = denominator / gcd;

    if (denominator === numerator) {
      return "";
    } else if (denominator === 1) {
      return numerator.toString();
    } else {
      return (
        "<sup>" + numerator + "</sup>&frasl;<sub>" + denominator + "</sub>"
      );
    }
  }

  //Returns the HTML for the coeffecient for the product of a sequential reaction
  getProductsCoefficient(i: number): string {
    if (i === 0) {
      if (this.selectedSpecies[i].secondval !== 1) {
        return (
          "<sup>1</sup>&frasl;<sub>" +
          this.selectedSpecies[i].secondval +
          "</sub>"
        );
      } else {
        return "";
      }
    } else {
      let numerator = this.selectedSpecies[i - 1].firstval;
      let denominator =
        this.selectedSpecies[i - 1].firstval *
          this.selectedSpecies[i].secondval -
        this.selectedSpecies[i].firstval *
          this.selectedSpecies[i - 1].secondval;

      //simplify the numerator and denominator using their greatest common divisor
      let gcd = this.gcd(numerator, denominator);
      numerator = numerator / gcd;
      denominator = denominator / gcd;

      if (denominator === numerator) {
        return "";
      } else if (denominator === 1) {
        return numerator.toString();
      } else {
        return (
          "<sup>" + numerator + "</sup>&frasl;<sub>" + denominator + "</sub>"
        );
      }
    }
  }

  //Returns the Analyte Coefficient in number form
  getAnalyteCoefficientNum(i: number): number {
    let numerator = this.selectedSpecies[i].firstval;
    let denominator = 0;
    if (i === 0) {
      denominator = this.selectedSpecies[i].secondval;
    } else {
      denominator =
        this.selectedSpecies[i - 1].firstval *
          this.selectedSpecies[i].secondval -
        this.selectedSpecies[i].firstval *
          this.selectedSpecies[i - 1].secondval;
    }

    //simplify the numerator and denominator using their greatest common divisor
    let gcd = this.gcd(numerator, denominator);
    numerator = numerator / gcd;
    denominator = denominator / gcd;

    return numerator / denominator;
  }

  //Returns the HTML for the coeffecient for the product of a sequential reaction
  getProductsCoefficientNum(i: number): number {
    if (i === 0) {
      return 1 / this.selectedSpecies[i].secondval;
    } else {
      let numerator = this.selectedSpecies[i - 1].firstval;
      let denominator =
        this.selectedSpecies[i - 1].firstval *
          this.selectedSpecies[i].secondval -
        this.selectedSpecies[i].firstval *
          this.selectedSpecies[i - 1].secondval;

      //simplify the numerator and denominator using their greatest common divisor
      let gcd = this.gcd(numerator, denominator);
      numerator = numerator / gcd;
      denominator = denominator / gcd;
      return numerator / denominator;
    }
  }

  getModelData(): Model {
    //form mass action matrix
    this.massAction = [];
    for (let i = 0; i < this.selectedSpecies.length; ++i) {
      this.massAction.push(new Array(this.selectedSpecies.length + 2).fill(0));
      this.massAction[i][1] = -1;
      if (i === 0) {
        this.massAction[i][0] = -this.getAnalyteCoefficientNum(i);
      } else {
        this.massAction[i][i + 1] = -this.getAnalyteCoefficientNum(i);
      }
      this.massAction[i][i + 2] = this.getProductsCoefficientNum(i);
    }
    console.log("this.massAction: ", this.massAction);

    //build unabsorbing array
    //-1 = locked, 0 = unabsorbing, 1 = normal
    let restricted: number[] = new Array(2 + this.selectedSpecies.length).fill(
      1
    );
    restricted[0] = this.locked ? -1 : 1;
    restricted[1] = this.absorbing ? 1 : 0;

    // get the A and B coefficients
    for (let i = 0; i < this.selectedSpecies.length; i++) {
      this.firstvals[i] = this.selectedSpecies[i].firstval;
      this.secondvals[i] = this.selectedSpecies[i].secondval;
      this.logKInitial.push(this.getLogKValue(i));
    }

    console.log(this.logKInitial, "logKInitial");

    return {
      name: "",
      user: this.authService.uid(),
      uploadDate: firebase.default.firestore.Timestamp.now(),
      chemicalSpeciesANum: this.firstvals,
      chemicalSpeciesBNum: this.secondvals,
      massBalance: arrayToFirestoreMap([
        [1, 0, ...this.firstvals],
        [0, 1, ...this.secondvals],
      ]),
      massAction: arrayToFirestoreMap(this.massAction),
      restricted: restricted,
      logKInitial: this.logKInitial,
      logKLocked: new Array(this.firstvals.length).fill(false),
      logKChanged: this.logKChanged,
      tag: "user",
    };
  }

  /**
   * Open dialog to set model name
   * upload to firebase
   */
  async onSubmit() {
    const models = await this.dataService.getUserModels(false);
    const modelNames = models.map((model) => model.name);
    const modelData = this.getModelData();
    const dialog = this.dialog.open(AddModelPopupComponent, {
      data: { name: this.name, modelNames, modelData },
    });
    dialog.afterClosed().subscribe((status) => {
      if (status) {
        this.router.navigate(["models"]);
      }
    });
  }

  /**
   * Navigate to advaned model builder page
   */
  onBuildAdvancedModel() {
    this.router.navigateByUrl("advanced-model-builder");
  }
}
