<div class="wrapper">
  <div class="header-container">
    <h1>Advanced Model Builder</h1>
    <mat-card>
      <mat-card-title>Constraints</mat-card-title>
      <mat-card-content>
        <mat-list>
          <mat-list-item
            >1. Values must be integer values between 0 and 6</mat-list-item
          >
          <mat-list-item>2. No two species can be identical</mat-list-item>
          <mat-list-item
            >3. At least one species must have at least 1 A and at least 1
            B</mat-list-item
          >
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="content-container" *ngIf="modelForm; else loadingSpinner">
    <div class="form-container">
      <!-- Advanced model builder form -->
      <form [formGroup]="modelForm" (ngSubmit)="onSubmit()">
        <mat-table [dataSource]="modelFormDataSource" formArrayName="tableRows">
          <!-- Column A -->
          <ng-container matColumnDef="A">
            <mat-header-cell *matHeaderCellDef>
              <span>A</span>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let rowIndex = index"
              [formGroup]="row"
            >
              <mat-form-field>
                <input type="number" matInput formControlName="A" />
                <mat-error *ngIf="isInvalid('A', rowIndex)">
                  Invalid Value
                </mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <!-- Column B -->
          <ng-container matColumnDef="B">
            <mat-header-cell *matHeaderCellDef>
              <span>B</span>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let rowIndex = index"
              [formGroup]="row"
            >
              <mat-form-field>
                <input type="number" matInput formControlName="B" />
                <mat-error *ngIf="isInvalid('B', rowIndex)">
                  Value must be between 1 and 6
                </mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <!-- Column C -->
          <ng-container matColumnDef="C">
            <mat-header-cell *matHeaderCellDef>
              <span>C</span>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let row; let rowIndex = index"
              [formGroup]="row"
            >
              <mat-form-field>
                <input type="number" matInput formControlName="C" />

                <mat-error *ngIf="isInvalid('C', rowIndex)">
                  Value must be between 1 and 6
                </mat-error>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Species">
            <mat-header-cell *matHeaderCellDef>Species</mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <span [innerHTML]="getSpec(i)"></span
            ></mat-cell>
          </ng-container>

          <!-- Delete column for each row -->
          <ng-container matColumnDef="Delete">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row; let i = index" [formGroup]="row">
              <button
                mat-raised-button
                type="button"
                *ngIf="i === 0"
                (click)="onChangeMode('A')"
                [ngClass]="restricted.A ? 'restricted' : 'unrestricted'"
              >
                {{ restricted.A ? "locked" : "unlocked" }}
              </button>
              <button
                mat-raised-button
                type="button"
                *ngIf="i === 1"
                (click)="onChangeMode('B')"
                [ngClass]="restricted.B ? 'restricted' : 'unrestricted'"
              >
                {{ restricted.B ? "absorbing" : "non-absorbing" }}
              </button>
              <button
                mat-raised-button
                type="button"
                *ngIf="i === 2"
                (click)="onChangeMode('C')"
                [ngClass]="restricted.C ? 'restricted' : 'unrestricted'"
              >
                {{ restricted.C ? "absorbing" : "non-absorbing" }}
              </button>
              <button *ngIf="i >= 3" mat-icon-button (click)="onDeleteRow(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <!-- Row definition -->
          <mat-header-row *matHeaderRowDef="speciesColumns"></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: speciesColumns"
            [formGroup]="row"
          ></mat-row>
        </mat-table>

        <!-- buttons for adding new row & model submission -->
        <div class="button-container">
          <button
            mat-raised-button
            type="button"
            (click)="onAddRow()"
            class="add-button"
          >
            Add Row
          </button>
          <button
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="!modelForm.valid"
            class="save-button"
          >
            Save Model
          </button>
        </div>
      </form>
    </div>

    <div class="reaction-container">
      <p class="text-container">
        Corresponding chemical reactions with viable initial guesses:
      </p>
      <!-- Show reactions according to table -->
      <mat-table [dataSource]="reactionDataSource">
        <!-- Non-sequential Reactions Column -->
        <ng-container matColumnDef="nonsequentialReactions">
          <mat-header-cell *matHeaderCellDef>
            Non-sequential Reactions
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <div class="reaction">
              <span [innerHTML]="getReaction(element.nonsequential)"> </span>
            </div>

            <div class="log">
              logβ<sub>{{ getLogBetaSub(element.nonsequential) }}</sub> =
              {{ getLogBeta(element, i) }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- Sequential Reactions Column -->
        <ng-container matColumnDef="sequentialReactions">
          <mat-header-cell *matHeaderCellDef>
            Sequential Reactions
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <div class="reaction">
              <span [innerHTML]="getReaction(element.sequential)"></span>
            </div>

            <div class="log">
              logK<sub>{{ getLogKSub(element.sequential, i) }}</sub> =
              {{ getLogK(element.sequential) }}
            </div>
          </mat-cell>
        </ng-container>

        <!-- Define table rows -->
        <mat-header-row *matHeaderRowDef="reactionColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: reactionColumns"></mat-row>
      </mat-table>
    </div>
  </div>
  <ng-template #loadingSpinner>
    <mat-spinner></mat-spinner>
  </ng-template>
</div>
