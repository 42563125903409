import { Injectable } from "@angular/core";
import {
  Reaction,
  Species,
} from "../nav-pages/models-page/advanced-model-builder/advanced-model-builder.component";
import Fraction from "fraction.js";
import {
  getSequentialReaction,
  transformChemicals,
} from "../shared/functions/calculation.util";
@Injectable({
  providedIn: "root",
})
export class FormatterService {
  constructor() {}

  /**
   * format component
   * @param element in the format of {A: 1, B: 2, C:3}
   * @returns A1B2C3
   */
  spec(element: Species, exportMode?: boolean): string {
    const { A, B, C } = element;
    let aFac = "";
    let bFac = "";
    let cFac = "";
    if (A) {
      aFac = A === 1 ? "A" : exportMode ? `A${A}` : `A<sub>${A}</sub>`;
    }
    if (B) {
      bFac = B === 1 ? "B" : exportMode ? `B${B}` : `B<sub>${B}</sub>`;
    }
    if (C) {
      cFac = C === 1 ? "C" : exportMode ? `C${C}` : `C<sub>${C}</sub>`;
    }
    return `${aFac}${bFac}${cFac}`;
  }

  /**
   * Format reactants and products of chemical equation
   * @param elements
   * @param coefs
   * @returns list of formatted elements
   */
  side(elements: Species[], coefs: number[], exportMode?: boolean): string[] {
    const formattedSide = [];
    coefs.forEach((coef, i) => {
      const formattedSpec = this.spec(elements[i], exportMode);
      if (formattedSpec !== "") {
        const { n, d } = new Fraction(coef);

        if (coef === 1) {
          formattedSide.push(formattedSpec);
        } else {
          // if the coefficient is an integer, use as is
          // otherwise, format into a fraction
          formattedSide.push(
            Number.isInteger(coef)
              ? `${coef}${formattedSpec}`
              : exportMode
              ? `${n}/${d}${formattedSpec}`
              : `<sup>${n}</sup>&frasl;<sub>${d}</sub> ${formattedSpec}`
          );
        }
      }
    });
    return formattedSide;
  }

  /**
   * Format entire chemical reaction
   * @param reaction from user input
   * @returns formatted reaction string
   */
  reaction(reaction: Reaction, exportMode?: boolean) {
    const { reactants, products, reactantsCoef, productsCoef } = reaction;
    const formattedReactants = this.side(reactants, reactantsCoef, exportMode);
    const formattedProducts = this.side(products, productsCoef, exportMode);

    return `${formattedReactants.join(" + ")} ⇌ ${formattedProducts.join(
      " + "
    )}`;
  }
  /**
   * format subscripts for log values
   * @param reaction Reaction
   * @returns formatted string
   */
  logBetaSub(reaction: Reaction): string {
    const { products } = reaction;
    const productA = products[0].A ? products[0].A : 0;
    const productB = products[0].B ? products[0].B : 0;
    const productC = products[0].C ? products[0].C : 0;

    return `${productA}${productB}${productC}`;
  }
  logKSub(reaction: Reaction, index: number): string {
    const { reactants, products } = reaction;
    const fromAdvancedModel = reactants[0].C !== null && products[0].C !== null;
    const reactantA = reactants[0].A ? reactants[0].A : 0;
    const reactantB = reactants[0].B ? reactants[0].B : 0;
    const productA = products[0].A ? products[0].A : 0;
    const productB = products[0].B ? products[0].B : 0;
    if (!fromAdvancedModel) {
      return index === 0
        ? `00${productA}${productB}`
        : `${reactantA}${reactantB}${productA}${productB}}`;
    } else {
      const reactantC = reactants[0].C ? reactants[0].C : 0;
      const productC = products[0].C ? products[0].C : 0;
      return index === 0
        ? `000${productA}${productB}${products[0].C}`
        : `${reactantA}${reactantB}${reactantC}${productA}${productB}${productC}`;
    }
  }

  changeSpecFormat(
    chemicalSpeciesANum: number[],
    chemicalSpeciesBNum: number[],
    chemicalSpeciesCNum?: number[] | undefined
  ): Species[] {
    if (chemicalSpeciesANum.length !== chemicalSpeciesBNum.length) {
      throw new Error("Lengths do not match");
    }

    const pureSpecies = chemicalSpeciesCNum
      ? [
          { A: 1, B: 0, C: 0 },
          { A: 0, B: 1, C: 0 },
          { A: 0, B: 0, C: 1 },
        ]
      : [
          { A: 1, B: 0, C: 0 },
          { A: 0, B: 1, C: 0 },
        ];

    const userSpecies: Species[] = chemicalSpeciesANum.map((a, i) => {
      return {
        A: chemicalSpeciesANum[i],
        B: chemicalSpeciesBNum[i],
        C: chemicalSpeciesCNum ? chemicalSpeciesCNum[i] : 0,
      };
    });

    return [...pureSpecies, ...userSpecies];
  }

  /**
   *
   * @param species A, B, C, and additional user-input species
   * @param isAdvanced
   * @returns the sequential reactions in order
   */
  getSequentialReaction(species: Species[], isAdvanced: boolean): Reaction[] {
    // slice off pure species
    const cutPure = isAdvanced ? 3 : 2;
    const reactions = [];
    // get first user-input species
    const firstSpec = species[cutPure];
    const { reactants, reactantsCoef } = transformChemicals(firstSpec);
    const divider =
      reactantsCoef.length > 1 && reactantsCoef[1] !== 0 ? reactantsCoef[1] : 1;
    // coef should be 1 for pure B or C added to reactants
    const initialSeqReactantsCoef = reactantsCoef.map((coef) => coef / divider);

    species.slice(cutPure).forEach((spec, i, arr) => {
      if (i === 0) {
        reactions.push({
          reactants,
          products: [firstSpec],
          reactantsCoef: initialSeqReactantsCoef,
          productsCoef: [1 / divider],
        });
      } else {
        reactions.push(getSequentialReaction([arr[i - 1]], [spec]));
      }
    });
    return reactions;
  }
}
