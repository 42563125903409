<!--Data input form-->
<div class="content-wrapper">
    <div>
        <h1 style="margin-top: 20px">Enter Experimental Data</h1>

        <div style="display: flex; justify-content: center; margin-top: -10px; margin-bottom: -20px">
            <button mat-raised-button color="warn" (click)="checkReset()" class="reset-button">
                Reset Form
            </button>
        </div>

        <form class="datasetform" (ngSubmit)="submit()">

            <div class="formField">
                <mat-form-field appearance="fill" class="example-form-field">
                    <mat-label>Data Acquisition Date</mat-label>
                    <input matInput [formControl]="experimentDateControl" name="experimentDate"
                        [matDatepicker]="datepicker" required>
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>
                    <mat-error *ngIf="experimentDateControl.invalid">You must pick a date</mat-error>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip" matTooltip="The date when the data was recorded">
                    help
                </mat-icon>
            </div>

            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label>Spectrometer Make & Model</mat-label>
                    <input matInput [formControl]="spectrometerControl" required>
                    <mat-error *ngIf="spectrometerControl.invalid">{{getErrorMessage('spectrometer')}}</mat-error>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip"
                    matTooltip="If this data is simulated data, please enter 'simulation' or the name of the program used to simulate the data.">
                    help
                </mat-icon>
            </div>

            <div style="margin-bottom: 20px;">
                <mat-checkbox [formControl]="nmrControl">NMR Experiment</mat-checkbox>
            </div>


            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label>Location/Institution</mat-label>
                    <input matInput [formControl]="locationControl" required>
                    <mat-error *ngIf="locationControl.invalid">{{getErrorMessage('location')}}</mat-error>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip"
                    matTooltip="The location where the spectrometer is located">
                    help
                </mat-icon>
            </div>

            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label>Pathlength</mat-label>
                    <input matInput type="number" placeholder="Ex. 1" [formControl]="pathlengthControl" min="0" required>
                    <span matSuffix>cm</span>
                    <mat-error *ngIf="pathlengthControl.invalid">Min length: 0cm</mat-error>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip"
                    matTooltip="If not applicable, leave as 1">
                    help
                </mat-icon>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Temperature</mat-label>
                <input matInput type="number" placeholder="Ex. 298" [formControl]="temperatureControl" min="0" required>
                <span matSuffix>K</span>
                <mat-error *ngIf="temperatureControl.invalid">Min temp: 0K</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" style="margin-bottom: 20px;">
                <mat-label>Solvent</mat-label>
                <input matInput [formControl]="solventControl" required>
                <mat-error *ngIf="solventControl.invalid">{{getErrorMessage('solvent')}}</mat-error>
            </mat-form-field>

            <div style="width: 100%; display: flex; flex-direction: column; align-items: center;"
                *ngFor="reagent of reagents; index as i;">
                <h2 style="margin-top: 0px;">Reagent #{{i + 1}}
                    <ng-container *ngIf="i === 0"> (Analyte)</ng-container>
                    <ng-container *ngIf="i === 1"> (Titrant)</ng-container>
                </h2>
                <mat-form-field appearance="fill">
                    <mat-label>Label (Name or Formula)</mat-label>
                    <input matInput [(ngModel)]="reagents[i].name" name="reagent{{i}}" required>
                    <mat-error *ngIf="reagents[i].name === ''">You must enter a value</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Paste Concentration Values (Molarity) Here</mat-label>
                    <textarea matInput [formControl]="reagentValueControls[i]" required></textarea>
                    <mat-error *ngIf="reagents[i].values.length === 0">You must have at least 1 value</mat-error>
                </mat-form-field>

                <div class="tablediv" *ngIf="reagents[i].values.length">
                    <table>
                        <tr>
                            <td *ngFor="let val of reagents[i].values"
                                [ngStyle]="{'background-color': ((!val && val != 0) || val < 0) ? '#f66' : 'white'}">
                                {{val}}</td>
                        </tr>
                    </table>
                </div>
                <br />
            </div>

            <div class="button-wrap">
                <button mat-raised-button color="accent" (click)="addReagent()" type="button"
                    style="margin-top: -10px;">
                    Add Reagent
                </button>
                <button mat-raised-button color="warn" (click)="removeReagentConfirm()" type="button"
                    style="margin-top: -10px;">
                    Remove Reagent
                </button>
                <div style="display: inline-block;">
                    <mat-icon class="tooltip2" #tooltip="matTooltip" matTooltip="We recommend adding/logging concentration values for all distinct chemicals that were used to create your chemical solutions, including counter ions, buffers, or co-solvents. Only Reagent #1 and #2 will be used for analysis, so any newly equilibrated species must be some association of the first two reagents.">
                        help
                    </mat-icon>
                </div>
            </div>

            <hr style="width: 50%" />

            <br />
            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label *ngIf="!nmrControl.value">Paste Independent Axis Values (e.g. Wavelength) Here</mat-label>
                    <mat-label *ngIf="nmrControl.value">Paste Nominal NMR Peak Labels (e.g. 1 2 3 4 5 6) Here</mat-label>
                    <textarea matInput [formControl]="wavelengthValuesControl" required></textarea>
                    <mat-error *ngIf="wavelengths.length === 0">You must have at least 1 value</mat-error>
                </mat-form-field>
                <mat-icon 
                    *ngIf="!nmrControl.value" class="tooltip" #tooltip="matTooltip" matTooltip="For spectrophotometric titration, paste independent energy axis values here.">
                    help
                </mat-icon>
                <mat-icon 
                    *ngIf="nmrControl.value" class="tooltip" #tooltip="matTooltip" matTooltip="For NMR titration, paste nominal peak numbers here.">
                    help
                </mat-icon>
            </div>

            <div class="tablediv">
                <table>
                    <tr>
                        <td *ngFor="let val of wavelengths"
                            [ngStyle]="{'background-color': ((!val && val != 0) || val < 0) ? '#f66' : 'white'}">{{val}}
                        </td>
                    </tr>
                </table>
            </div>

            <!-- enter the matrix data -->
            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label *ngIf="!nmrControl.value">Paste Matrix of Spectrophotometric Data Here</mat-label>
                    <mat-label *ngIf="nmrControl.value">Paste matrix of NMR peak positions here</mat-label>
                    <textarea matInput [formControl]="absorbanceValuesControl" required></textarea>
                    <mat-error *ngIf="absorbanceValues.length === 0">You must have at least 1 value</mat-error>
                </mat-form-field>
                <mat-icon *ngIf="!nmrControl.value" class="tooltip" #tooltip="matTooltip" matTooltip="Paste the spectral curve for each chemical solution together as a single block of numbers. One dimension of the matrix must match the length of the reagent vectors. The other dimension must match the length of the Independent Axis values.">
                    help
                </mat-icon>
                <mat-icon *ngIf="nmrControl.value" class="tooltip" #tooltip="matTooltip" matTooltip="For NMR titration, paste peak positions for each chemical solution together as a single block of numbers. One dimension of the matrix must match the length of the reagent vectors. The other dimension must match the length of the NMR peak labels.">
                    help
                </mat-icon>
            </div>
            <!-- matrix is shown -->
            <div class="tablediv">
                <table>
                    <tr *ngFor="let row of absorbanceValues">
                        <td *ngFor="let cell of row.values"
                            [ngStyle]="{'background-color': ((!cell && cell != 0) || cell < 0) ? '#f66' : 'white'}">
                            {{cell}}</td>
                    </tr>
                </table>
            </div>

            <!-- enter the peak area data -->
            <div class="formField" *ngIf="nmrControl.value">
                <mat-form-field appearance="fill">
                    <mat-label>Paste NMR peak area data matrix here (Optional)</mat-label>
                    <textarea matInput [formControl]="absorbanceAreasValuesControl"></textarea>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip" matTooltip="For NMR titration, paste peak areas for each chemical solution together as a single block of numbers. One dimension of the matrix must match the length of the reagent vectors. The other dimension must match the length of the NMR peak labels.">
                    help
                </mat-icon>
            </div>
            <!-- matrix is shown -->
            <div class="tablediv" *ngIf="nmrControl.value">
                <table>
                    <tr *ngFor="let row of absorbanceAreaValues">
                        <td *ngFor="let cell of row.values"
                            [ngStyle]="{'background-color': ((!cell && cell != 0) || cell < 0) ? '#f66' : 'white'}">
                            {{cell}}</td>
                    </tr>
                </table>
            </div>

            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label>Paste Data Mask Matrix Here (Optional)</mat-label>
                    <textarea matInput [formControl]="dataMaskValuesControl"></textarea>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip" matTooltip="If desired, a block of numbers can be pasted here to screen or weight the residuals before they are fed back into the optimizer. The mask matrix must be the exact same dimensions as the spectroscopic data matrix. If all of the values of the mask are unity, then the optimization will be unaffected. Mask values of zero correspond to data values that will consequently be ignored in the optimization process.">
                    help
                </mat-icon>
            </div>

            <div class="tablediv">
                <table>
                    <tr *ngFor="let row of dataMask">
                        <td *ngFor="let cell of row.values"
                            [ngStyle]="{'background-color': ((!cell && cell != 0) || cell < 0) ? '#f66' : 'white'}">
                            {{cell}}</td>
                    </tr>
                </table>
            </div>

            <div class="formField">
                <mat-form-field appearance="fill">
                    <mat-label>Notes (Optional)</mat-label>
                    <textarea matInput [formControl]="commentControl"></textarea>
                </mat-form-field>
                <mat-icon class="tooltip" #tooltip="matTooltip" matTooltip="Add any notes here so that the experimental data is complete including any adjustments made to the data such as upshifting, deletions, merges etc..">
                    help
                </mat-icon>
            </div>

            <button mat-raised-button color="accent" type="submit" class="sub-button">
                Submit
            </button>
        </form>

        <ng-container *ngIf="loading_popup">
            <div class="gray" (click)="closePopup()"></div>
                <div style="display: flex; justify-content: center;">
                    <div class="popup">
                        <h2>This may take a second to load.</h2>
                        <h2>Thank you for your patience.</h2>
                        <mat-spinner></mat-spinner>
                    </div>
                </div>
        </ng-container>


        <ng-container *ngIf="popup">
            <div class="gray" (click)="closePopup()"></div>

            <div style="display: flex; justify-content: center;">
                <div class="popup">
                    <ng-container *ngIf="giveName">
                        <h2>Add a name for this dataset</h2>

                        <mat-form-field appearance="outline">
                            <mat-label>Name</mat-label>
                            <input matInput [(ngModel)]="name" required>
                        </mat-form-field>
                        <div>
                            <button mat-raised-button color="accent" (click)="submitData()"
                                [disabled]="name.length < 4 || name.length > 40">
                                Submit
                            </button>
                            <button mat-stroked-button color="warn" (click)="closePopup()">
                                Cancel
                            </button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="reset">
                        <h2 style="margin-top: 10px;">Are you sure you want to reset your form?</h2>
                        <h2 style="margin-top: -10px;">All fields will be erased.</h2>
                        <div style="margin-bottom: 10px;">
                            <button mat-raised-button color="accent" (click)="resetForm()">Yes</button>
                            <button mat-raised-button color="warn" (click)="popup = false; reset = false;">No</button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="duplicate">
                        <h2>An existing dataset already has this name.</h2>
                        <h2>Try another name.</h2>
                        <button mat-stroked-button color="warn" (click)="tryAgain()" style="margin-left: 10px;">
                            OK
                        </button>
                    </ng-container>

                    <ng-container *ngIf="confirmRemoveReagent">
                        <h2>Are you sure you want to remove the last reagent?</h2>
                        Upon deletion, all data will be lost.
                        <br />
                        <div>
                            <button mat-raised-button color="accent" (click)="closePopup()">
                                Keep
                            </button>
                            <button mat-raised-button color="warn" (click)="removeReagent()">
                                Remove
                            </button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="uploading">
                        <h2>Data is being uploaded...</h2>
                    </ng-container>

                    <ng-container *ngIf="uploaded">
                        <h2>Data has been uploaded</h2>
                        Choose which action you would like to take:

                        <hr />

                        <div style="display: flex; flex-wrap: wrap; justify-content: center;">
                            <a routerLink="/datasets">
                                <button mat-raised-button color="accent">
                                    Go To My Datasets
                                </button>
                            </a>
                            <a routerLink="/fits">
                                <button mat-raised-button color="accent">
                                    Go To My Fits
                                </button>
                            </a>
                        </div>
                        <button mat-raised-button color="primary" (click)="closePopup()">
                            Edit This Dataset
                        </button>
                    </ng-container>

                    <ng-container *ngIf="loading_popup">
                        <h2>This may take a second to load...</h2>
                        <h2>Thank you for your patience</h2>
                    </ng-container>

                    <ng-container *ngIf="leave_page_popup">
                        <h2>You have not uploaded your form yet.</h2>
                        <h2>Are you sure you want to leave the page?</h2>
                        <div>
                            <button mat-raised-button color="accent">Yes</button>
                            <button mat-raised-button color="warn">No</button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="error">
                        <h2>The following errors were encountered with your dataset:</h2>
                        <div *ngIf="reagentDimensionMismatch" class="errortext">
                            Dimension mismatch between reagent vectors and absorbance matrix. <br />
                            Reagent vector dimensions are:
                            [<span *ngFor="let r of reagents; index as i">
                                {{r.values.length}}<ng-container *ngIf="i !== reagents.length - 1">,</ng-container>
                            </span> ]
                            which did not match a dimension of absorbance matrix with dimensions: [
                            {{absorbanceValues.length}} x {{absorbanceValues[0].values.length}} ]
                        </div>
                        <div *ngIf="wavelengthDimensionMismatch" class="errortext">
                            Dimension mismatch between independent axis vector and absorbance matrix. <br />
                            Independent Axis vector has a length of {{wavelengths.length}}
                            which did not match a dimension of absorbance matrix with dimensions: [
                            {{absorbanceValues.length}} x {{absorbanceValues[0].values.length}} ]
                        </div>
                        <div *ngIf="dataMaskDimensionMismatch" class="errortext">
                            Dimension mismatch between data mask matrix and absorbance matrix. <br />
                            Data Mask matrix has dimensions of [ {{dataMask.length}} x {{dataMask[0].values.length}} ]
                            which did not match a dimension of absorbance matrix with dimensions: [
                            {{absorbanceValues.length}} x {{absorbanceValues[0].values.length}} ]
                        </div>
                        <div *ngIf="absorbanceDimensionMismatch" class="errortext">
                            Absorbance matrix row lengths are inconsistent <br />
                            Absorbance matrix had the following row lengths:
                            [<span *ngFor="let ab of absorbanceValues; index as i">
                                {{ab.values.length}}<ng-container *ngIf="i !== absorbanceValues.length - 1">,
                                </ng-container>
                            </span> ]
                        </div>
                        <div *ngIf="absorbanceAreaDimensionsMismatch" class="errortext">
                            Absorbance matrix dimensions are inconsistent with the absorbance area matrix <br />
                            Absorbance matrix has dimensions of [ {{absorbanceValues.length}} x {{absorbanceValues[0].values.length}} ] <br />
                            Absorbance area matrix had the following row lengths:
                            [<span *ngFor="let ab of absorbanceAreaValues; index as i">
                                {{ab.values.length}}<ng-container *ngIf="i !== absorbanceValues.length - 1">,
                                </ng-container>
                            </span> ]
                        </div>
                        <div *ngIf="negativeWavelengths" class="errortext">
                            Your Independent Axis vector contains negative numbers
                        </div>
                        <div *ngIf="negativeReagents" class="errortext">
                            At least one of your reagent composition vectors contains negative numbers
                        </div>
                    </ng-container>

                    <ng-container *ngIf="warn">
                        <h2>Warnings:</h2>
                        <div *ngFor="let warning of warnings" class="errortext">
                            {{warning}}
                        </div>
                        <br/>
                        <button mat-raised-button color="accent" (click)="warn = false; giveName = true">Continue</button>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
