import { Component, Inject, OnInit, Optional } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataService } from "src/app/services/data.service";
import { Model } from "src/app/shared/interfaces/data.interface";

@Component({
  selector: "app-add-model-popup",
  templateUrl: "./add-model-popup.component.html",
  styleUrls: ["./add-model-popup.component.scss"],
})
export class AddModelPopupComponent implements OnInit {
  modelNameForm: FormGroup;
  name: string;
  modelNames: string[];
  modelData: Model;
  settingName = true;
  modelDoc: any;
  errorStateMatcher = new InstantErrorStateMatcher();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dataService: DataService,
    private fb: FormBuilder
  ) {
    const { name, modelNames, modelData } = data;
    this.name = name;
    this.modelNames = modelNames;
    this.modelData = modelData;
  }

  ngOnInit(): void {
    // initialize form
    this.modelNameForm = this.fb.group({
      name: [
        this.name,
        [
          Validators.required,
          Validators.min(4),
          Validators.max(40),
          this.duplicateNameValidator(this.modelNames),
        ],
      ],
    });
  }

  /**
   * Upload model to Firebase
   */
  async onSubmit() {
    this.settingName = false; // turn on loading spinner
    this.modelData.name = this.name; // set name
    this.modelDoc = await this.dataService.addModel(this.modelData); // upload to Firebase
  }

  /**
   * Custom validator for checking if new model name is duplicated
   * @param modelNames List of user model names
   * @returns Validator Function
   */
  duplicateNameValidator(
    modelNames: string[]
  ): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (modelNames.includes(value)) {
        return { duplicate: true };
      }
      return null;
    };
  }
}

/**
 * https://stackoverflow.com/questions/51456487/why-mat-error-not-get-displayed-inside-mat-form-field-in-angular-material-6-with
 * show mat-error instantly if model name is duplicated
 */
export class InstantErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control && control.invalid;
  }
}
