import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  oldPassword: string = "";
  newPassword: string = "";
  confirmNewPassword: string = "";

  errormessage: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Change Password | SIVVU");
    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("change-password");
      this.router.navigate(["login"]);
    }
    if (authService.isGuest()) this.router.navigate(["fits"]);
  }

  ngOnInit(): void {}

  //TODO: Add check for if the current password they typed in is acutally their password
  changePassword(): void {
    //https://www.w3resource.com/javascript/form/password-validation.php
    const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;

    //Checks to make sure the email and password fields aren't blank
    if (
      this.oldPassword != "" &&
      this.newPassword != "" &&
      this.confirmNewPassword != ""
    ) {
      //Checks to make sure the password is at least 8 characters long
      if (this.newPassword.length >= 8 && this.newPassword.length <= 20) {
        //Checks to make sure the passwords match
        if (this.newPassword === this.confirmNewPassword) {
          //Makes sure the password has a number and an uppercase and lowecase letter
          if (this.newPassword.match(passw)) {
            //Registers the user using the provided credentials
            this.authService.changePassword(this.oldPassword, this.newPassword);
          } else {
            this.errormessage =
              "Passwords must contain at least one number and one uppercase and lowercase letter";
          }
        } else {
          this.errormessage = "Passwords do not match";
        }
      } else {
        this.errormessage =
          "Passwords must be be between 8 and 20 characters long";
      }
    }

    console.log(this.errormessage);
  }
}
