<div style="background-image: url('assets/img/chem.png')">
    <div class="vert-center">
        <mat-card class="card">
            <h1 class="card-header" style="margin-top: 10px;">Change Password</h1>
            <div class="center">

                <form (ngSubmit)="changePassword()">
                    <mat-form-field class="email-field">
                    <mat-label>Current Password</mat-label>
                    <input matInput type="password" [(ngModel)]="oldPassword" name="oldPassword" 
                    autocomplete="current-password" required class="pass"> <br/>
                    </mat-form-field> <br>

                    <mat-form-field class="email-field">
                    <mat-label>New Password</mat-label>
                    <input matInput type="password" [(ngModel)]="newPassword" name="newPassword" 
                    autocomplete="new-password" required class="pass"> <br/>
                    </mat-form-field> <br>

                    <mat-form-field class="email-field">
                    <mat-label>Confirm New Password</mat-label>
                    <input matInput type="password" [(ngModel)]="confirmNewPassword" name="confirmNewPassword" 
                    autocomplete="new-password" required class="pass"> <br/>
                    </mat-form-field> <br>

                    <div class="pass-btn">
                        <button mat-raised-button type="submit" class="btn">Change Password</button>
                    </div>

                </form>
                <p class="error">{{errormessage}}</p>

            </div>
        </mat-card>
    </div>
</div>