import { Injectable } from "@angular/core";
import {
  Bootstrap,
  Config,
  Dataset,
  MinDataset,
  Model,
  PyBootstrap,
  Result,
  Resultmin,
  UserCode,
} from "../shared/interfaces/data.interface";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AuthService } from "./auth.service";
import { mapToMatrix } from "../shared/functions/util";

@Injectable({
  providedIn: "root",
})
export class DataService {
  uid: string | null;
  constructor(private db: AngularFirestore, private authService: AuthService) {
    this.uid = this.authService.uid();
  }

  /**
   * get datasetsmin that fits the user login
   * @returns An array of datasetsmin objects
   */
  async getUserDatasetsmin(orderByDate?: boolean): Promise<MinDataset[]> {
    try {
      const datasetsmin = [];
      if (orderByDate) {
        const querySnapshot = await this.db.firestore
          .collection("datasetsmin")
          .where("user", "==", this.uid)
          .orderBy("uploadDate", "desc")
          .get();
        querySnapshot.docs.forEach((doc) => {
          let data = doc.data() as MinDataset;
          datasetsmin.push({ doc_id: doc.id, ...data });
        });

        return datasetsmin;
      } else {
        const querySnapshot = await this.db.firestore
          .collection("datasetsmin")
          .where("user", "==", this.uid)
          .get();

        querySnapshot.docs.forEach((doc) => {
          let data = doc.data() as MinDataset;
          datasetsmin.push({ doc_id: doc.id, ...data });
        });
        datasetsmin.sort((a, b) => a.name.localeCompare(b.name));

        return datasetsmin;
      }
    } catch (err) {
      console.log({ err });
      throw new Error(`READ user ${this.uid} 'Datasetsmin' Document`);
    }
  }

  /**
   * get resultsmin that fits the user login
   * @returns An array of resultsmin objects
   */
  async getUserResultsmin(): Promise<Resultmin[]> {
    try {
      const querySnapshot = await this.db.firestore
        .collection("resultsmin")
        .where("user", "==", this.uid)
        .orderBy("date", "desc")
        .get();
      const resultsmin = [];
      querySnapshot.docs.forEach((doc) => {
        const data = doc.data() as Resultmin;
        resultsmin.push({ doc_id: doc.id, ...data });
      });

      return resultsmin;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ user ${this.uid} 'Resultsmin' Document`);
    }
  }

  /**
   * Get and sort all user models
   * @returns An array of models
   */
  async getUserModels(orderByDate?: boolean): Promise<Model[]> {
    try {
      const userModels = [];
      if (orderByDate) {
        console.log("orderByDate");
        const userModelsDoc = await this.db.firestore
          .collection("models")
          .where("user", "==", this.uid)
          .orderBy("uploadDate", "desc")
          .get();
        userModelsDoc.docs.forEach((doc) => {
          let data = doc.data() as Model;
          userModels.push({ doc_id: doc.id, ...data });
        });
        return userModels;
      } else {
        const userModelsDoc = await this.db.firestore
          .collection("models")
          .where("user", "==", this.uid) // put each dataset that fits the user login into an array
          .get();

        userModelsDoc.forEach((doc) => {
          const modelData = doc.data() as Model;
          userModels.push({ doc_id: doc.id, ...modelData });
        });
        userModels.sort((a, b) => a.name.localeCompare(b.name));
        return userModels;
      }
    } catch (err) {
      console.log({ err });
      throw new Error(`READ user ${this.uid} 'Models' Document`);
    }
  }

  async getUserDatasets(orderByDate?: boolean): Promise<Dataset[]> {
    try {
      const userDatasets = [];
      if (orderByDate) {
        const userDatasetsDoc = await this.db.firestore
          .collection("datasets")
          .where("user", "==", this.uid)
          .orderBy("uploadDate", "desc")
          .get();
        userDatasetsDoc.docs.forEach((doc) => {
          let data = doc.data() as Model;
          userDatasets.push({ doc_id: doc.id, ...data });
        });
        return userDatasets;
      } else {
        const userDatasetsDoc = await this.db.firestore
          .collection("datasets")
          .where("user", "==", this.uid) // put each dataset that fits the user login into an array
          .get();

        userDatasetsDoc.forEach((doc) => {
          const datasetsData = doc.data() as Model;
          userDatasets.push({ doc_id: doc.id, ...datasetsData });
        });
        userDatasets.sort((a, b) => a.name.localeCompare(b.name));
        return userDatasets;
      }
    } catch (err) {
      console.log({ err });
      throw new Error(`READ user ${this.uid} 'Datasets' Document`);
    }
  }

  /**
   * Get Dataset
   * @param dsid Dataset id
   * @returns Dataset with specified id
   */
  async getDataset(dsid: string): Promise<Dataset> {
    try {
      const datasetDoc = await this.db.firestore
        .collection("datasets")
        .doc(dsid)
        .get();
      return datasetDoc.data() as Dataset;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'Datasets' ${dsid}`);
    }
  }

  /**
   * Get Dataset
   * @param mid Dataset id
   * @returns Model with specified id
   */
  async getModel(mid: string): Promise<Model> {
    try {
      const modelDoc = await this.db.firestore
        .collection("models")
        .doc(mid)
        .get();
      return modelDoc.data() as Model;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'Models' ${mid}`);
    }
  }

  /**
   * Get result (i.e. fit)
   * @param rid Result id
   * @returns Result with specified id
   */
  async getResult(rid: string): Promise<Result> {
    try {
      const resultsDoc = await this.db.firestore
        .collection("results")
        .doc(rid)
        .get();
      return resultsDoc.data() as Result;
    } catch (err) {
      console.log(err);
      throw new Error(`READ 'Result' ${rid}`);
    }
  }

  /**
   * get bootstrap config information
   * @returns bootstrap configuration
   */
  async getBootstrapConfig(): Promise<Config> {
    try {
      const config = await this.db.firestore
        .collection("config")
        .doc("config")
        .get();
      return config.data() as Config;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'config' for bootstrapping`);
    }
  }

  /**
   * get user code for running bootstrapping calculation
   * @returns User code information for running bootstrap
   */
  async getUserCodes(): Promise<UserCode[]> {
    try {
      const userCodeDocs = await this.db.firestore
        .collection("userCodes")
        .where("email", "==", this.authService.email())
        .get();

      const userCodes = [];
      userCodeDocs.forEach((doc) => {
        userCodes.push(doc.data() as UserCode);
      });

      return userCodes;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'userCodes' for user ${this.uid}`);
    }
  }

  /**
   * get bootstrap data
   * @param rid
   * @returns object with { bootIndexes, bootLogKs }
   */
  async getBootstraps(rid: string) {
    const snapshot = await this.db.firestore
      .collection("results")
      .doc(rid)
      .collection("bootstraps")
      .get();

    let bootIndexes = [];
    let bootLogKs = [];

    snapshot.docs.forEach((doc) => {
      const data = doc.data() as Bootstrap;
      if (data.bootIndexes) {
        bootIndexes = bootIndexes.concat(mapToMatrix(data.bootIndexes));
        bootLogKs = bootLogKs.concat(mapToMatrix(data.bootLogKs));
      }
    });
    return { bootIndexes, bootLogKs };
  }

  /**
   * Modify result after bootstrapping
   * @param rid Result ID
   * @param bootUpdate bootstrap status
   */
  async modifyResultBoot(
    rid: string,
    bootUpdate: { bootNum: number; remainingBoot?: number }
  ): Promise<void> {
    try {
      await this.db.firestore.collection("results").doc(rid).update(bootUpdate);
    } catch (err) {
      console.log({ err });
      throw new Error(`UPDATE 'results' ${rid}`);
    }
  }

  /**
   * Modify resultsmin after bootstrapping
   * @param rid Result ID
   * @param bootUpdate bootstrap status
   */
  async modifyResultMinBoot(
    rid: string,
    bootUpdate: { bootstrapped: boolean }
  ): Promise<void> {
    try {
      await this.db.firestore
        .collection("resultsmin")
        .doc(rid)
        .update(bootUpdate);
    } catch (err) {
      console.log({ err });
      throw new Error(`UPDATE 'resultsmin' ${rid}`);
    }
  }

  /** TODO: make into 1 function with addResult
   * Upload new model to Firebase
   * @param model new Model
   * @returns Document reference of new model
   */
  async addModel(model: Model): Promise<any> {
    try {
      const modelDoc = await this.db.firestore.collection("models").add(model);
      return modelDoc;
    } catch (err) {
      console.log({ err });
      throw new Error(`ADD 'models' ${model}`);
    }
  }

  /**
   * Upload the new fit to Firebase
   * @param result new Fit
   * @returns Document reference of new fit
   */
  async addResult(result: Result): Promise<any> {
    try {
      const resultDoc = await this.db.collection("results").add(result);
      return resultDoc;
    } catch (err) {
      throw new Error(err.message);
    }
  }

  async setResultsmin(rid: string, resultmin: Resultmin): Promise<void> {
    try {
      await this.db.firestore.collection("resultsmin").doc(rid).set(resultmin);
    } catch (err) {
      console.log({ err });
      throw new Error(`SET 'resultsmin' ${resultmin}`);
    }
  }

  /**
   * Add to pyBootstrap collection to trigger bootstrap calculation
   * @param rid result id
   * @param pyBootstrap object information needed for bootstrap calculation
   */
  async setPyBootstrap(rid: string, pyBootstrap: PyBootstrap): Promise<void> {
    try {
      await this.db.firestore
        .collection("pyBootstrap")
        .doc(rid)
        .set(pyBootstrap);
    } catch (err) {
      console.log({ err });
      throw new Error(`Set 'pyBootstrap' ${rid}`);
    }
  }

  /**
   * get user fits made with model
   * @param mid Model Id
   * @returns The fits that use the model
   */
  async getFitsWithModel(
    mid: string
  ): Promise<
    { dataset: string; model: string; date: Date; bootstrapped: boolean }[]
  > {
    try {
      const resultDocs = await this.db.firestore
        .collection("resultsmin")
        .where("modelId", "==", mid)
        .get();
      const modelFits = [];
      resultDocs.docs.forEach((doc) => {
        let data = doc.data() as Resultmin;
        modelFits.push({
          dataset: data.datasetName,
          model: data.modelName,
          date: data.date.toDate(),
          bootstrapped: data.bootstrapped,
        });
      });

      return modelFits;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'resultsmin' for model ${mid}`);
    }
  }

  /**
   * get user fits made with dataset
   * @param did dataset id
   * @returns The fits that use the dataset
   */
  async getFitsWithDataset(
    did: string
  ): Promise<
    { dataset: string; model: string; date: Date; bootstrapped: boolean }[]
  > {
    try {
      const resultDocs = await this.db.firestore
        .collection("resultsmin")
        .where("datasetId", "==", did)
        .get();
      const datasetFits = [];
      resultDocs.docs.forEach((doc) => {
        let data = doc.data() as Resultmin;
        datasetFits.push({
          dataset: data.datasetName,
          model: data.modelName,
          date: data.date.toDate(),
          bootstrapped: data.bootstrapped,
        });
      });

      return datasetFits;
    } catch (err) {
      console.log({ err });
      throw new Error(`READ 'resultsmin' for dataset ${did}`);
    }
  }

  /**
   * Delete the fit with model ID.
   * The fit is deleted from firestore.resultsmin and unassociated with the user in firestore.results
   * @param mid model ID
   */
  async deleteModel(mid: string): Promise<void> {
    // batch job to ensure the updates and deletes are synchronized
    const batch = this.db.firestore.batch();

    // delete model
    const modelRef = this.db.firestore.collection("models").doc(mid);
    batch.update(modelRef, { user: "", merge: true });

    // delete from 'resultsmin' collection
    const resultsminSnapshot = await this.db.firestore
      .collection("resultsmin")
      .where("modelId", "==", mid)
      .get();

    // batch delete from "resultsmin"
    resultsminSnapshot.docs.forEach((doc) => {
      const docRef = this.db.collection("resultsmin").doc(doc.id).ref;
      batch.delete(docRef);
    });

    // unassociate user from 'results' collection
    const resultsSnapshot = await this.db.firestore
      .collection("results")
      .where("modelId", "==", mid)
      .get();

    // batch upte to unassociate user from "results"
    resultsSnapshot.docs.forEach((doc) => {
      const docRef = this.db.collection("results").doc(doc.id).ref;
      batch.update(docRef, { user: "" });
    });

    batch
      .commit()
      .then(() => {
        console.log(`Deleted all fits with modelId: ${mid}`);
      })
      .catch((err) => {
        console.log({ err });
        throw new Error(`batch delete model failed`);
      });
  }

  /**
   * Delete the fit with dataset ID.
   * The fit is deleted from firestore.resultsmin and unassociated with the user in firestore.results
   * @param did dataset ID
   */
  async deleteDataset(did: string): Promise<void> {
    // batch job to ensure the updates and deletes are synchronized
    const batch = this.db.firestore.batch();

    // delete datasetsmin
    const datasetminRef = this.db.firestore.collection("datasetsmin").doc(did);
    batch.delete(datasetminRef);

    // "delete" dataset: unassociate with user
    const datasetRef = this.db.firestore.collection("datasets").doc(did);
    batch.update(datasetRef, { user: "" });

    // delete from 'resultsmin' collection
    const resultsminSnapshot = await this.db.firestore
      .collection("resultsmin")
      .where("datasetId", "==", did)
      .get();

    // batch delete from "resultsmin"
    resultsminSnapshot.docs.forEach((doc) => {
      const docRef = this.db.collection("resultsmin").doc(doc.id).ref;
      batch.delete(docRef);
    });

    // unassociate user from 'results' collection
    const resultsSnapshot = await this.db.firestore
      .collection("results")
      .where("datasetId", "==", did)
      .get();

    // batch upte to unassociate user from "results"
    resultsSnapshot.docs.forEach((doc) => {
      const docRef = this.db.collection("results").doc(doc.id).ref;
      batch.update(docRef, { user: "" });
    });

    batch
      .commit()
      .then(() => {
        console.log(`Deleted all fits with datasetId: ${did}`);
      })
      .catch((err) => {
        console.log({ err });
        throw new Error(`batch delete dataset failed`);
      });
  }

  /**
   * delete Fit from resultsmin & results documents
   * @param rid result/fit ID
   * @param bootstrapped unassociated if bootstrapped, delete if not
   */
  async deleteFit(rid: string, bootstrapped: boolean) {
    // batch job to ensure the updates and deletes from "resultsmin" and "results" are synchronized
    const batch = this.db.firestore.batch();

    // delete "resultsmin" document
    const resultsminRef = this.db.firestore.collection("resultsmin").doc(rid);
    batch.delete(resultsminRef);

    // update or delete "results" document
    const resultsRef = this.db.firestore.collection("results").doc(rid);
    if (bootstrapped) {
      console.log("fit is bootstrapped: unassociating with user");
      batch.update(resultsRef, { user: "" });
    } else {
      console.log("fit is not bootstrapped: deleting permanently");
      batch.delete(resultsRef);
    }

    batch
      .commit()
      .then(() => {
        console.log(`Deleted fit: ${rid}`);
      })
      .catch((err) => {
        console.log({ err });
        throw new Error(`batch delete fit failed`);
      });
  }
}
