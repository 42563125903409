import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import firebase from "firebase/compat/app";
import { Observable } from "rxjs";
import { User } from "firebase/auth";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: User) => {
        if (user) {
          // if user is logged in or in guest mode: allow access to page
          resolve(true);
        } else {
          // if user not logged in or in geust mode: redirect to login
          this.router.navigate(["login"]);
          resolve(false);
        }
      });
    });
  }
}
