import { Injectable, Component } from "@angular/core";
import { Router } from "@angular/router";
import firebase from "firebase/compat/app";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import {
  Dataset,
  MinDataset,
  Model,
} from "../shared/interfaces/data.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInAnonymously,
  signOut,
  sendEmailVerification,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  updateProfile,
  createUserWithEmailAndPassword,
  updatePassword,
  updateEmail,
  User,
} from "firebase/auth";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private $currentUser: BehaviorSubject<User | boolean | null> =
    new BehaviorSubject<User | boolean | null>(null);
  user?: firebase.User;
  _user: User | null;
  credential: any;
  redirect: string = "fits";

  durationInSeconds = 2;

  authSubscription: Subscription;

  //https://www.techiediaries.com/angular-firebase/angular-9-firebase-authentication-email-google-and-password/

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private database: AngularFirestore,
    private successBar: MatSnackBar
  ) {
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        console.log(`firebase user: ${user}`);
        this.$currentUser.next(user);
      } else {
        this.$currentUser.next(false);
      }
    });
  }

  // ngOnDestroy(): void {
  //   this.authSubscription.unsubscribe();
  // }

  //Logs a user into their account
  async login(email: string, password: string): Promise<number> {
    // Sets the authentication state to persist forever
    return new Promise((resolve, reject) => {
      const auth = getAuth();
      setPersistence(auth, browserLocalPersistence).then(async () => {
        signInWithEmailAndPassword(auth, email, password)
          .then(async (user) => {
            await delay(1000);
            // redirect to myFits
            this.router.navigate([this.redirect]);
            this.redirect = "fits";
            resolve(1);
            // display bottom snackbar message
            this.successBar.openFromComponent(SuccessBarAuthComponent, {
              duration: this.durationInSeconds * 1000,
            });
          })
          .catch(() => {
            resolve(0);
          });
      });
    });
  }

  //Logs a user into a guest account
  async loginAsGuest() {
    //Sets the authentication state to persist until the window is closed
    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        // return anonymous user credentials
        return signInAnonymously(auth);
      })
      .then(async (user) => {
        // give sample data
        this.giveSampleData(true);
        // open snackbar
        this.successBar.openFromComponent(SuccessBarAuthComponent, {
          duration: this.durationInSeconds * 1000,
        });
        await delay(1000);
        // navigate to myFits after 1s
        this.router.navigate([this.redirect]);
        this.redirect = "fits";
      });
  }

  //Can be used if we want to set up logging in with a Google account
  async loginWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    // sign in with Google Popup window
    signInWithPopup(auth, provider).then((result) => {
      // provide sample data if new user
      if (getAdditionalUserInfo(result).isNewUser) {
        this.giveSampleData(true);
      } else {
        this.router.navigate([this.redirect]);
        this.redirect = "fits";
      }
    });
    this.successBar.openFromComponent(SuccessBarAuthComponent, {
      duration: this.durationInSeconds * 1000,
    });
  }

  //Creates a new user with an email and password
  async register(email: string, password: string, displayName: string) {
    const auth = getAuth();

    // create a new user
    try {
      const userCreds = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      this.giveSampleData(false);

      // set the display name of the user
      try {
        await updateProfile(userCreds.user, {
          displayName: displayName,
        });
      } catch (updateErr) {
        // error setting display name
        console.log({ updateErr });
      }

      // send a verification email to the new user
      try {
        await this.sendEmailVerification();
      } catch (verificationErr) {
        // error sending verification email
        console.log({ verificationErr });
      }
    } catch (userCredsError) {
      // error getting new user credentials
      console.log({ userCredsError });
    }
  }

  giveSampleData = (navigate: boolean) => {
    this.database.firestore
      .collection("datasets")
      .doc("Sample Dataset")
      .get()
      .then((doc) => {
        let data = doc.data() as Dataset;
        data.user = this.uid();
        this.database.firestore
          .collection("datasets")
          .add(data)
          .then((datasetdoc) => {
            this.database.firestore
              .collection("datasetsmin")
              .doc("Sample Dataset")
              .get()
              .then((doc) => {
                let data = doc.data() as MinDataset;
                data.user = this.uid();
                this.database.firestore
                  .collection("datasetsmin")
                  .doc(datasetdoc.id)
                  .set(data)
                  .then(() => {
                    this.database.firestore
                      .collection("models")
                      .doc("Sample Model")
                      .get()
                      .then((doc) => {
                        let data = doc.data() as Model;
                        data.user = this.uid();
                        this.database.firestore
                          .collection("models")
                          .add(data)
                          .then(() => {
                            if (navigate) {
                              this.router.navigate([this.redirect]);
                              this.redirect = "fits";
                            }
                          })
                          .catch((error) => {
                            console.error("error: ", error);
                          });
                      });
                  })
                  .catch((error) => {
                    console.error("error: ", error);
                  });
              });
          })
          .catch((error) => {
            console.error("error: ", error);
          });
      });
  };

  //Sends an email to user when they sign up to verify their account
  sendEmailVerification = (): Promise<void> => {
    const auth = getAuth();
    this.router.navigate(["verify-email"]);
    return sendEmailVerification(auth.currentUser);
  };

  //Sends a reset email to the provided email so they can change their password.
  sendPasswordResetEmail = (passwordResetEmail: string) => {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, passwordResetEmail);
  };

  //Updates the user's password given their old password and a new password
  changePassword = async (
    oldPassword: string,
    newPassword: string
  ): Promise<void> => {
    let changed: boolean = false;
    const auth = getAuth();

    try {
      const userCreds = await signInWithEmailAndPassword(
        auth,
        this.email(),
        oldPassword
      );
      if (userCreds) {
        try {
          await updatePassword(userCreds.user, newPassword);
          changed = true;
        } catch (err) {
          alert(err);
        }
      }
    } catch (err) {
      alert(err);
    }

    if (changed) this.router.navigate(["account"]);
  };

  //Changes the user's email to the provided email
  changeEmail = async (
    newEmail: string,
    oldEmail: string,
    password: string
  ): Promise<boolean> => {
    let success = false;
    const auth = getAuth();
    try {
      // get current user credentials and the current user
      const userCreds = await signInWithEmailAndPassword(
        auth,
        oldEmail,
        password
      );
      const user = userCreds.user;
      // if user is not null, try updating email
      if (user) {
        try {
          await updateEmail(user, newEmail);
          return true;
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      // error
      console.log(err);
    }

    return success;
  };

  //Logs a user out of their account
  logout = async (): Promise<void> => {
    const auth = getAuth();
    // navigate back to login and sign out user
    return signOut(auth);
  };

  //Updates the user's display name to the new name provided
  async updateDisplayName(displayName: string) {
    const auth = getAuth();
    updateProfile(auth.currentUser, { displayName: displayName });
  }

  //Checks if a user is logged in.
  isLoggedIn(): boolean {
    const auth = getAuth();
    const user = auth.currentUser;
    // currentUser is null if no user is logged in
    return user !== null;
  }

  //Checks if a user is a guest account.
  isGuest(): boolean {
    const auth = getAuth();
    const user = auth.currentUser;
    return user?.isAnonymous;
  }

  //Returns the diplay name for the user
  displayName(): string {
    const auth = getAuth();
    const user = auth.currentUser;
    return user?.displayName;
  }

  //Returns the email for the user
  email(): string {
    const auth = getAuth();
    const user = auth.currentUser;
    return user?.email;
  }

  //Returns the UID for the user
  uid(): string {
    const auth = getAuth();
    const user = auth.currentUser;
    return user?.uid;
  }

  //Checks if a user is logged in using normal login or a special service like Google.
  isNormalAccount(): boolean {
    const auth = getAuth();
    const user = auth.currentUser;
    return user.providerId !== "google.com";
  }

  setRedirect(page: string): void {
    this.redirect = page;
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "snack-bar-component-example-snack",
  templateUrl: "success_bar_auth_component.html",
  styles: [
    `
      .example-pizza-party {
        color: hotpink;
      }
    `,
  ],
})
export class SuccessBarAuthComponent {
  text: string = "";
  constructor(private authService: AuthService) {
    if (!authService.isGuest()) {
      let firstName = this.authService.displayName().split(" ")[0];
      this.text = "Welcome to SIVVU, " + firstName;
    } else {
      this.text = "Welcome to SIVVU, Guest";
    }
  }
}
