import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from "src/app/services/data.service";

export interface Element {
  date: Date;
  dataset: string;
  model: string;
}

@Component({
  selector: "app-delete-dataset-popup",
  templateUrl: "./delete-dataset-popup.component.html",
  styleUrls: ["./delete-dataset-popup.component.scss"],
})
export class DeleteDatasetPopupComponent implements OnInit {
  did: string;
  datasetName: string;
  datasetFits: Element[];
  FITS_DATA: Element[] = [];
  datasetFitsTableData: MatTableDataSource<Element>;
  fitsColumns = ["date", "dataset", "model"];
  noFits: boolean = false;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteDatasetPopupComponent>,
    private dataService: DataService
  ) {
    const { did, datasetName } = data;
    this.did = did;
    this.datasetName = datasetName;
  }

  async ngOnInit(): Promise<void> {
    this.FITS_DATA = await this.dataService.getFitsWithDataset(this.did);
    if (this.FITS_DATA.length > 0) {
      this.datasetFitsTableData = new MatTableDataSource(this.FITS_DATA);
    } else {
      this.noFits = true;
    }
  }

  async onDelete(): Promise<void> {
    try {
      await this.dataService.deleteDataset(this.did);
      this.dialogRef.close(true);
    } catch (err) {
      console.log({ err });
    }
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
