import { Component, Input, OnInit } from "@angular/core";
import { Species } from "../advanced-model-builder.component";

@Component({
  selector: "app-chemical-reaction",
  templateUrl: "./chemical-reaction.component.html",
  styleUrls: ["./chemical-reaction.component.scss"],
})
export class ChemicalReactionComponent implements OnInit {
  @Input() type: string;
  @Input() currentFactors: Species;
  @Input() previousFactors: Species | null;
  reactants: string;
  products: string;

  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case "nonsequential":
        this.reactants = this.formatNonsequentialReactants(this.currentFactors);
        break;
      case "sequential":
        this.reactants = this.formatSequentialReactants(this.currentFactors);
    }
  }

  formatNonsequentialReactants(reaction: Species): string {
    const { A, B, C } = reaction;
    let aFac = "";
    let bFac = "";
    let cFac = "";
    if (A) {
      aFac = A === 1 ? "A" : `${A}A`;
    }
    if (B) {
      bFac = B === 1 ? "B" : `${B}B`;
      bFac = A ? ` + ${bFac}` : bFac;
    }
    if (C) {
      cFac = C === 1 ? "C" : `${C}C`;
      cFac = B ? ` + ${cFac}` : cFac;
    }
    return `${aFac}${bFac}${cFac}`;
  }

  // TODO: change for sequential reactions
  formatSequentialReactants(reaction: Species): string {
    const { A, B, C } = reaction;
    let aFac = "";
    let bFac = "";
    let cFac = "";
    if (A) {
      aFac = A === 1 ? "A" : `${A}A`;
    }
    if (B) {
      bFac = B === 1 ? "B" : `${B}B`;
      bFac = A ? ` + ${bFac}` : bFac;
    }
    if (C) {
      cFac = C === 1 ? "C" : `${C}C`;
      cFac = B ? ` + ${cFac}` : cFac;
    }
    return `${aFac}${bFac}${cFac}`;
  }
}
