import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  email: string = "";
  sentEmail: boolean = false;
  errorMessage: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Forgot Password | SIVVU");
    if (authService.isLoggedIn()) this.router.navigate(["fits"]);
  }

  sendEmail() {
    if (this.email != "" && this.email.indexOf("@") != -1) {
      this.authService.sendPasswordResetEmail(this.email);
      this.sentEmail = true;
    } else {
      this.errorMessage = "Please enter a valid email address";
    }
  }
}
