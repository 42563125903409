
<div style="background-image: url('assets/img/chem.png')">
    <div class="vert-center">
        <mat-card class="card">
        <div class="logo-con">
            <img src='../assets/logos/SIVVU.png' alt="SIVVU" class="logo">
        </div>
            <div class="whole-con">
                <div>
                    <form (ngSubmit)="loginUser()" #f="ngForm">

                        <div class="center-items">
                            <mat-form-field class="email-field" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="text" [(ngModel)]="email" name="email" autocomplete="email"
                                required autofocus>
                            </mat-form-field>
                        </div>
                            <br>
                        <div class="center-items">
                            <mat-form-field class="pass-field" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" [(ngModel)]="password" name="password" 
                                autocomplete="current-password" required>
                            </mat-form-field>
                            <br>
                        </div>

                        <div class="center-items">
                            <p class="error">{{error_message}}</p>
                        </div>

                        <div class="center-items">
                            <div class="mobile-center">
                                <button mat-raised-button class="login-button" type="submit" [disabled]="!f.valid">Login</button>
                            </div>
                        </div>
                    </form>

                    <div class="center-items">
                        <button mat-raised-button (click)="loginWithGoogle()" class="google">
                            <img width="18px" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in"
                                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                            Sign in with Google
                        </button>
                    </div>

                    <!-- <hr style="width: 100%;"/> -->

                    <div class="center-items">
                        <button mat-raised-button class="login-guest-button" color="primary" (click)="loginAsGuest()">
                            Login As Guest
                        </button>
                    </div>
                    <br />

                    <div style="width: 80%; text-align: center; margin-left: 10%; font-weight: bold;">
                        By signing in, you are agreeing to our 
                        <a routerLink="/privacy-policy">Privacy Policy</a>.
                    </div>
                    <br />

                    <div style="display: flex; align-items: center; justify-content: center;">
                        <div class="acct-link">
                            <a routerLink="/register">Register new account</a>
                        </div>
                        <br>
                        <div class="pass-link">
                            <a routerLink="/forgot-password">Recover password</a>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
