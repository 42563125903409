<div class="container">
  <h1 class="header">Build a Model</h1>

  <p style="text-align: center; margin-bottom: 4px; margin-top: -10px">
    Select all of the chemical species that you expect to find in equilibrium
    with each other.
  </p>
  <p style="text-align: center; margin-bottom: 1px">
    If <i>A</i> is your analyte (or <i>host</i>), and <i>B</i> is your titrant
    (or <i>guest</i>), then you can pick any one or more potential species to
    add to your model of the form A<sub>n</sub>B<sub>m</sub> where <i>n, m</i> =
    1 - 6.
  </p>

  <p style="text-align: center">
    To start, A<sub>1</sub>B<sub>1</sub> is selected for this guide. To deselect
    a species, simply press the selected box again.
  </p>

  <!-- grid to select the species -->
  <div class="table-wrapper">
    <div style="display: flex; justify-content: center">
      <div
        class="buttonB"
        (click)="toggleSpec('absorbing')"
        [ngClass]="absorbing ? 'button-selected' : 'button-unselected'"
      >
        B is currently
        <ng-container *ngIf="absorbing; else nonabsorbing"
          >absorbing</ng-container
        >
        <ng-template #nonabsorbing>non-absorbing</ng-template>
      </div>
    </div>
    <div class="grid-container">
      <div
        class="buttonA"
        (click)="toggleSpec('locked')"
        [ngClass]="locked ? 'button-selected' : 'button-unselected'"
      >
        <div class="rotate">
          A's absorptivity is currently
          <ng-container *ngIf="locked; else unlocked">locked</ng-container>
          <ng-template #unlocked>unlocked</ng-template>
        </div>
      </div>
      <div class="grid-list">
        <div
          *ngFor="let spec of speciesvals"
          (click)="updateSelection(spec.firstval, spec.secondval)"
          [ngClass]="
            spec.selectable
              ? spec.selected
                ? 'species-selected'
                : 'species-unselected'
              : 'species-unselectable'
          "
          class="mat-ripple grid-tile"
          matRipple
          [matRippleCentered]="centered"
          [matRippleDisabled]="!spec.selectable"
          [matRippleUnbounded]="unbounded"
          [matRippleRadius]="radius"
          [matRippleColor]="color"
        >
          A<sub>{{ spec.firstval }}</sub
          >B<sub>{{ spec.secondval }}</sub>
        </div>
      </div>
    </div>

    <div class="center-buttons">
      <div class="center-container">
        <button
          class="buildButton"
          mat-raised-button
          color="accent"
          (click)="onSubmit()"
        >
          Save Model
        </button>
        <button
          class="advanced-model-button"
          mat-raised-button
          color="accent"
          (click)="onBuildAdvancedModel()"
        >
          Advanced Model Builder
        </button>
      </div>
    </div>

    <p class="center-text">
      <b style="font-size: 16px">
        Corresponding chemical reactions with viable initial guesses:
      </b>
    </p>

    <hr style="visibility: hidden" />

    <div>
      <div class="non-seq-block">
        <p style="text-align: left; margin-left: 20vw; font-size: 16px">
          <u>Non-sequential Reactions</u>
        </p>
      </div>

      <div class="seq-block">
        <p style="text-align: right; margin-right: 19vw; font-size: 16px">
          <u>Sequential Reactions</u>
        </p>
      </div>
    </div>

    <div *ngFor="let spec of selectedSpecies; index as i">
      <div
        style="
          display: grid;
          grid-template-columns: 45vw 45vw;
          margin-left: 5vw;
        "
      >
        <div style="display: table; height: 100%">
          <div class="lfloat grid-item">
            <div class="firstEq">
              <ng-container *ngIf="spec.firstval !== 1">{{
                spec.firstval
              }}</ng-container
              >A + {{ spec.secondval }}B ⇌ A<sub>{{ spec.firstval }}</sub
              >B<sub>{{ spec.secondval }}</sub>
            </div>
            <div class="secondEq">
              logβ<sub>{{ spec.firstval }}{{ spec.secondval }}</sub> =
              {{ getLogBetaValue(i) }}
            </div>
          </div>
        </div>
        <div style="display: table">
          <div class="rfloat grid-item">
            <div class="thirdEq">
              <span [innerHTML]="getAnalyteCoefficient(i)"></span>

              <ng-container *ngIf="i === 0"> A + B ⇌ </ng-container>
              <ng-container *ngIf="i !== 0">
                A<sub>{{ selectedSpecies[i - 1].firstval }}</sub
                >B<sub>{{ selectedSpecies[i - 1].secondval }}</sub>
                + B ⇌
              </ng-container>

              <span [innerHTML]="getProductsCoefficient(i)"></span>

              A<sub>{{ spec.firstval }}</sub
              >B<sub>{{ spec.secondval }}</sub>
            </div>
            <div class="fourthEq">
              <ng-container *ngIf="i === 0; else greater">
                logK<sub>00{{ spec.firstval }}{{ spec.secondval }}</sub>
              </ng-container>
              <ng-template #greater>
                logK<sub
                  >{{ selectedSpecies[i - 1].firstval
                  }}{{ selectedSpecies[i - 1].secondval }}{{ spec.firstval
                  }}{{ spec.secondval }}</sub
                >
              </ng-template>
              = {{ getLogKValue(i) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </div>
</div>
