import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-change-email",
  templateUrl: "./change-email.component.html",
  styleUrls: ["./change-email.component.scss"],
})
export class ChangeEmailComponent implements OnInit {
  email: string = "";
  password: string = "";
  newEmail: string = "";
  confirmEmail: string = "";
  errorMessage: string = "";

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle("Change Email | SIVVU");
    if (!authService.isLoggedIn()) {
      this.authService.setRedirect("change-email");
      this.router.navigate(["login"]);
    }
    if (!authService.isNormalAccount()) this.router.navigate(["account"]);
    if (authService.isGuest()) this.router.navigate(["fits"]);
  }

  ngOnInit(): void {}

  changeEmail(): void {
    if (this.newEmail !== "" && this.email !== "" && this.password !== "") {
      if (this.newEmail.indexOf("@") !== -1) {
        if (this.newEmail === this.confirmEmail) {
          // need to fix this code
          this.authService.changeEmail(
            this.newEmail,
            this.email,
            this.password
          );
        }
      }
    }
  }
}
